import "rc-slider/assets/index.css";
import "./Slider.scss";

import LabelNumber from "../../custom/labels/labelNumber/LabelNumber";
import { Range } from "rc-slider";
import React from "react";

interface Props {
  value: App.RangeValue;
  onChange: (value: App.RangeValue) => void;

  name: string;
  min: number;
  max: number;

  valueName?: string;
  step?: number;
  order?: number;
}
interface State {}

class Slider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static defaultProps = {
    onChange() {},
  };

  private handleChange(value: App.RangeValue) {
    this.props.onChange(value);
  }
  private handleChangeFrom(value: number) {
    this.props.onChange([value, this.props.value[1]]);
  }
  private handleChangeTo(value: number) {
    this.props.onChange([this.props.value[0], value]);
  }
  private handleBlur() {
    const [from, to] = this.props.value;
    if (from > to) {
      this.props.onChange([to, from]);
    }
  }

  public render() {
    return (
      <div className="slider">
        <div className="name">
          {this.props.name}
          {this.props.valueName && (
            <span className="value">({this.props.valueName})</span>
          )}
        </div>
        <Range
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          value={this.props.value}
          onChange={this.handleChange.bind(this)}
          // it's need
          // just trust
          handleStyle={[
            { height: 20, width: 20, marginTop: -9.5 },
            { height: 20, width: 20, marginTop: -9.5 },
          ]}
        />
        <LabelNumber
          value={this.props.value[0]}
          onChange={this.handleChangeFrom.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          min={this.props.min}
          max={this.props.value[1]}
          order={this.props.order}
        />
        <div className="range-mark"></div>
        <LabelNumber
          value={this.props.value[1]}
          onChange={this.handleChangeTo.bind(this)}
          min={this.props.value[0]}
          max={this.props.max}
          order={this.props.order}
        />
      </div>
    );
  }
}

export default Slider;
