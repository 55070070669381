import "./Step.scss";

import CreateAnnouncementStepFooter from "./footer/Footer";
import CreateAnnouncementStepHeader from "./header/Header";
import LabelCheckbox from "../../../custom/labels/labelCheckbox/LabelCheckbox";
import LabelNumber from "../../../custom/labels/labelNumber/LabelNumber";
import ObjectMethods from "../../../../utils/object/ObjectMethods";
import React from "react";
import Select from "../../../custom/select/Select";

export interface Props {
  step: number;
  stepsNumber: number;
  className: string;
  name: string;
  disabled: boolean;
  complited: boolean;
  values: App.CreateAnnouncement.StepData;
  filters: Array<App.Announcement.FilterApi>;

  onGoBack: () => void;
  onGoNext: () => void;
  onSave: () => void;
  onChange: (
    values: App.CreateAnnouncement.StepData,
    filters: Array<App.Announcement.FilterApi>
  ) => void;

  before?: any;
  after?: any;
}
interface State {}

class CreateAnnouncementStep extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  static defaultProps = {
    complited: false,
    className: "",
    disabled: false,
    values: {
      checkboxList: [],
      multiselectList: [],
      selectList: [],
      numberList: [],
    },
    handleChange() {},
    onGoBack() {},
    onGoNext() {},
    onSave() {},

    filters: [],
    handleChangeFilters() {},
  };
  static stepsNumber = 9;

  public componentDidMount() {
    this.scrollUp();
  }
  public componentWillUnmount() {}

  private scrollUp() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  public handleChangeCheckbox(id: number | string, value: boolean) {
    const newValue = Object.assign({}, this.props.values);

    if (value) {
      newValue.checkboxList = newValue.checkboxList.concat(id);
    } else {
      newValue.checkboxList = newValue.checkboxList.filter((e) => e !== id);
    }

    this.props.onChange(newValue, this.props.filters);
  }

  public handleChangeSelect(
    id: number | string,
    value: number | string | null
  ) {
    const newValue = Object.assign({}, this.props.values);
    if (value) {
      if (newValue.selectList.some((e) => e.id === id)) {
        newValue.selectList = newValue.selectList.map((e) =>
          e.id === id ? { id, value } : e
        );
      } else {
        newValue.selectList = newValue.selectList.concat({ id, value });
      }
    } else {
      newValue.selectList = newValue.selectList.filter((e) => e.id !== id);
    }

    this.props.onChange(newValue, this.props.filters);
  }

  public handleChangeNumber(id: number, value: number) {
    const newValue = Object.assign({}, this.props.values);
    if (value) {
      if (newValue.numberList.some((e) => e.id === id)) {
        newValue.numberList = newValue.numberList.map((e) =>
          e.id === id ? { id, value } : e
        );
      } else {
        newValue.numberList = newValue.numberList.concat({ id, value });
      }
    } else {
      newValue.numberList = newValue.numberList.filter((e) => e.id !== id);
    }

    this.props.onChange(newValue, this.props.filters);
  }
  public handleChangeMultiselect(
    id: number | string,
    checkbox: number | string,
    value: boolean
  ) {
    const newValue: App.CreateAnnouncement.StepData = ObjectMethods.clone(
      this.props.values
    );
    const included = newValue.multiselectList.some((e) => e.id === id);
    if (value) {
      if (included) {
        newValue.multiselectList = newValue.multiselectList.map((e) =>
          e.id === id ? { id: e.id, value: e.value.concat(checkbox) } : e
        );
      } else {
        newValue.multiselectList = newValue.multiselectList.concat({
          id,
          value: [checkbox],
        });
      }
    } else {
      newValue.multiselectList = newValue.multiselectList.map((e) =>
        e.id === id
          ? { id: e.id, value: e.value.filter((c) => c !== checkbox) }
          : e
      );
    }

    this.props.onChange(newValue, this.props.filters);
  }

  private getFilterView(filter: App.Announcement.FilterApi) {
    switch (filter.type) {
      case "checkbox":
        return (
          <LabelCheckbox
            key={filter.id}
            name={filter.name}
            value={this.props.values.checkboxList.includes(filter.id)}
            onChange={(value) => this.handleChangeCheckbox(filter.id, value)}
          />
        );
      case "select":
        return (
          <Select
            key={filter.id}
            name={filter.name}
            options={filter.options}
            selected={
              this.props.values.selectList.find(({ id }) => id === filter.id)
                ?.value || null
            }
            multiselect={false}
            onChange={(value) => this.handleChangeSelect(filter.id, value)}
          />
        );
      case "multiselect":
        return (
          <div className="multiselect" key={filter.id}>
            <div className="name">{filter.name}</div>
            {filter.options.map((option) => (
              <LabelCheckbox
                key={option.id}
                name={option.name}
                value={
                  !!this.props.values.multiselectList
                    .find(({ id }) => id === filter.id)
                    ?.value.includes(option.id)
                }
                onChange={(value) =>
                  this.handleChangeMultiselect(filter.id, option.id, value)
                }
              />
            ))}
          </div>
        );
      case "range":
        return (
          <LabelNumber
            controlls
            key={filter.id}
            name={filter.name}
            min={filter.min}
            max={filter.max}
            value={
              this.props.values.numberList.find(({ id }) => id === filter.id)
                ?.value
            }
            onChange={() => {}}
          />
        );
      default:
        return <></>;
    }
  }

  public render() {
    return (
      <div className={`create-announcement__step ${this.props.className}`}>
        <CreateAnnouncementStepHeader
          step={this.props.step}
          name={this.props.name}
          allSteps={this.props.stepsNumber}
        />
        <div className="body">
          {this.props.before}
          {this.props.filters.map(this.getFilterView.bind(this))}
          {this.props.after}
        </div>
        <CreateAnnouncementStepFooter
          onGoBack={this.props.onGoBack}
          onGoNext={this.props.onGoNext}
          onSave={this.props.onSave}
          disabled={this.props.disabled}
          complited={this.props.complited}
        />
      </div>
    );
  }
}

export default CreateAnnouncementStep;
