import "./PageLoader.scss";

import React from "react";

interface Props {
  isOpen: boolean;
}
interface State {}

class PageLoader extends React.Component<Props, State> {
  public render() {
    return (
      <div className="page-loader" data-open={this.props.isOpen}>
        <div className="loader" title="Загрузка">
          <div className="cssload-dots">
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
          </div>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  result="blur"
                  stdDeviation="12"
                ></feGaussianBlur>
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                  result="goo"
                ></feColorMatrix>
                {/* <feBlend in2="goo" in="SourceGraphic" result="mix" ></feBlend> */}
              </filter>
            </defs>
          </svg>
        </div>
      </div>
    );
  }
}

export default PageLoader;
