import "./UserView.scss";

import { Route, RouteChildrenProps, Switch } from "react-router";
import {
  home,
  userAnnouncements,
  userSearchHistory,
  userSettings,
  userViewHistory,
} from "../../routes";

import React from "react";
import UserAnnouncementLists from "../../components/user/userAnnouncementLists/UserAnnouncementLists";
import UserMenuContainer from "../../containers/user/userMenu/UserMenuContainer";
import UserSearchHistoryContainer from "../../containers/user/searchHistory/UserSearchHistoryContainer";
import UserSettings from "../../components/user/settings/UserSettings";
import UserViewHistoryContainer from "../../containers/user/viewHistory/UserViewHistoryContainer";

type Props = RouteChildrenProps;
interface State {}

class UserView extends React.Component<Props, State> {
  public componentDidMount() {
    document.title = "Личный кабинет";
    window.scrollTo({ top: 0 });
  }
  private goToHome() {
    this.props.history.push(home);
  }

  public render() {
    return (
      <div className="user-view">
        <UserMenuContainer goToHome={this.goToHome.bind(this)} />
        <Switch>
          <Route path={userAnnouncements} component={UserAnnouncementLists} />
          <Route path={userSettings} component={UserSettings} />
          <Route
            path={userSearchHistory}
            component={UserSearchHistoryContainer}
          />
          <Route path={userViewHistory} component={UserViewHistoryContainer} />
          <Route>
            <h1 style={{ width: "100%", textAlign: "center" }}>
              В разаработке
            </h1>
          </Route>
        </Switch>
      </div>
    );
  }
}

export default UserView;
