import "./Option.scss";

import React from "react";

interface Props {
  option: App.Info;
  selected: boolean;
  onChange: (value: App.Info | null) => void;
}
interface State {}

class LabelSearchOption extends React.Component<Props, State> {
  private handleClick() {
    if (this.props.selected) {
      this.props.onChange(null);
    } else {
      this.props.onChange(this.props.option);
    }
  }
  public render() {
    return (
      <div
        data-selected={this.props.selected}
        className="label-search__option"
        onClick={this.handleClick.bind(this)}
      >
        <span>{this.props.option.name}</span>
      </div>
    );
  }
}
export default LabelSearchOption;
