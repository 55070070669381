import LabelCheckbox from "../../../../components/custom/labels/labelCheckbox/LabelCheckbox";
import React from "react";

interface Props {
  display: boolean;
  onChange: (display: boolean) => void;
}
interface State {}

class CreateAnnouncementDisplay extends React.Component<Props, State> {
  static defaultProps = {
    display: false,
  };

  public render() {
    return (
      <LabelCheckbox
        name="Опубликовать"
        value={this.props.display}
        onChange={this.props.onChange}
      />
    );
  }
}

export default CreateAnnouncementDisplay;
