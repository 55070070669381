import "./SignupMessage.scss";

import React from "react";

interface Props {}
interface State {}

class SignupMessage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="signup-message">
        <h2>На вашу почту отправлено подтверждение</h2>
        <p>
          На Ваш почтовый ящик отправлено сообщение, содержащее ссылку для
          подтверждения правильности e-mail адреса. Пожалуйста, перейдите по
          ссылке для завершения подписки.
        </p>
        <p>
          Если письмо не пришло в течение 15 минут, проверьте папку «Спам». Если
          письмо вдруг попало в эту папку, откройте письмо, нажмите кнопку «Не
          спам» и перейдите по ссылке подтверждения. Если же письма нет и в
          папке «Спам», попробуйте подписаться ещё раз. Возможно, вы ошиблись
          при вводе адреса.
        </p>
      </div>
    );
  }
}
export default SignupMessage;
