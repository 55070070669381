import CreateAnnouncementResultCard from "./card/ResultCard";
import React from "react";

interface Props {
  cards: Array<{ name: string; options: Array<string> }>;
}
interface State {}

class CreateAnnouncementResultCards extends React.Component<Props, State> {
  static defaultProps = { cards: [] };

  public render() {
    return (
      <div className="result-cards">
        {this.props.cards.map((card) => (
          <CreateAnnouncementResultCard key={card.name} card={card} />
        ))}
      </div>
    );
  }
}
export default CreateAnnouncementResultCards;
