import "./AnnouncementListFooter.scss";

import GreenArrowDownIcon from "../../../../assets/icons/green-arrow-down.svg";
import Pagination from "../../../../components/pagination/Pagination";
import React from "react";

interface Props {
  activePage: number;
  pageNumber: number;
  loading: boolean;
  isShowHidden: boolean;
  onChangePage: (page: number) => void;
  onShowMore: () => void;
  onToggleShowHidden: () => void;
}
interface State {}

class AnnouncementListFooter extends React.Component<Props, State> {
  public render() {
    return (
      <div className="announcement-list__footer">
        <Pagination
          activePage={this.props.activePage}
          pageNumber={this.props.pageNumber}
          countDisplayPages={3}
          onChange={this.props.onChangePage}
        />
        <button
          onClick={this.props.onShowMore}
          disabled={
            this.props.loading || this.props.activePage >= this.props.pageNumber
          }
        >
          Показать ещё <img src={GreenArrowDownIcon} alt="" />
        </button>
        <Pagination
          activePage={this.props.activePage}
          pageNumber={this.props.pageNumber}
          onChange={this.props.onChangePage}
        />
        <button
          className="show-hide"
          disabled={
            this.props.loading ||
            (this.props.activePage >= this.props.pageNumber &&
              this.props.isShowHidden)
          }
          onClick={this.props.onToggleShowHidden}
        >
          {this.props.isShowHidden ? "Убрать скрытые" : "Показать скрытые"}
        </button>
      </div>
    );
  }
}

export default AnnouncementListFooter;
