import CarService, { ModelData } from "../../../services/abstract/CarService";

import CarServiceApi from "../../../services/api/CarServiceApi";
import { OK } from "http-status-codes";
import React from "react";
import RedactModel from "../../../components/adminPanel/redactModel/RedactModel";
import SMessage from "../../../struct/message/SMessage";
import { addMessage } from "../../../store/actions/messages";
import { saveModels } from "../../../store/actions/car";
import store from "../../../store/store";

interface Props {
  brand: App.Car.Brand;
  onChangeActiveModel: (model?: App.Car.Model) => void;
}
interface State {
  model?: App.Car.Model;
  models: Array<App.Car.Model>;
}

class RedactModelContainer extends React.Component<Props, State> {
  private service: CarService;
  constructor(props: Props) {
    super(props);
    this.service = new CarServiceApi();
    this.state = {
      models: [],
    };
  }

  public componentDidMount() {
    this.getModels();
  }
  public componentDidUpdate(props: Props, state: State) {
    if (props.brand !== this.props.brand) {
      this.getModels();
      this.props.onChangeActiveModel();
    } else if (state.model !== this.state.model) {
      this.props.onChangeActiveModel(this.state.model);
    }
  }

  private async getModels() {
    const { data, status, errors } = await this.service.getModels(
      +this.props.brand.id
    );
    if (status === OK) {
      this.setState({ models: data, model: undefined });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }

  private async handleSave(model: ModelData) {
    if (this.state.model) {
      this.update(this.state.model.id, model);
    } else {
      this.add(model);
    }
  }

  private async add(model: ModelData) {
    model.brandId = +this.props.brand.id;
    const { data, status, errors } = await this.service.addModel(model);
    if (status === OK) {
      this.updateList(data, this.props.brand.id);
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }
  private async update(id: App.ID, model: ModelData) {
    const { data, status, errors } = await this.service.updateModel(id, model);
    if (status === OK) {
      this.updateList(data, this.props.brand.id);
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }

  private updateList(data: App.Car.Model, brand: App.ID) {
    if (this.state.models.some((v) => v.id === data.id)) {
      this.setState({
        model: data,
        models: this.state.models.map((v) => (v.id === data.id ? data : v)),
      });
      store.dispatch(
        saveModels(
          this.state.models.map((b) => (b.id === data.id ? data : b)),
          +brand
        )
      );
    } else {
      this.setState({
        model: data,
        models: this.state.models.concat(data),
      });
      store.dispatch(
        saveModels(
          this.state.models.map((b) => (b.id === data.id ? data : b)),
          +brand
        )
      );
    }
  }

  private async delete() {
    if (!this.state.model || !this.props.brand) return false;
    const brand = this.props.brand;
    const id = this.state.model.id;
    const { status, errors } = await this.service.deleteModel(id);
    if (status === OK) {
      this.setState({
        model: undefined,
        models: this.state.models.filter((b) => b.id !== id),
      });
      store.dispatch(
        saveModels(
          this.state.models.filter((b) => b.id !== id),
          +brand.id
        )
      );
      return true;
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
    return false;
  }

  private handleSelectModel(model: App.Car.Model) {
    if (this.state.model && this.state.model.id === model.id) {
      this.setState({ model: undefined });
    } else {
      this.setState({ model });
    }
  }

  public render() {
    return (
      <RedactModel
        models={this.state.models}
        model={this.state.model}
        onSave={this.handleSave.bind(this)}
        onDelete={this.delete.bind(this)}
        onSelectModel={this.handleSelectModel.bind(this)}
      />
    );
  }
}

export default RedactModelContainer;
