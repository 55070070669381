import {
  Acctions,
  addRequestType,
  removeRequestType,
} from "../actions/loading";

export interface LoadingStore {
  numberActiveRequests: number;
}

class LoadingReducer {
  public get defaultStore(): LoadingStore {
    return {
      numberActiveRequests: 0,
    };
  }

  public store(store: LoadingStore, action: Acctions): LoadingStore {
    switch (action.type) {
      case addRequestType:
        store.numberActiveRequests++;
        break;
      case removeRequestType:
        store.numberActiveRequests--;
        break;
      default:
        break;
    }
    return store;
  }
}

export default new LoadingReducer();
