import "./AdministrateAnnouncementsMenu.scss";

import AdministrateAnnouncementsMenuOption from "./administrateAnnouncementsMenuOption/AdministrateAnnouncementsMenuOption";
import React from "react";

interface Props {
  sort: string;
  newCheck: number;
  newSpec: number;
  onChangeSort: (sort: string) => void;
}
interface State {}

class AdministrateAnnouncementsMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="administrate-announcements-menu">
        <AdministrateAnnouncementsMenuOption
          name={"Все"}
          onClick={() => this.props.onChangeSort("")}
          active={!this.props.sort}
        />
        <AdministrateAnnouncementsMenuOption
          name={"Спецпредложения"}
          active={this.props.sort === "spec"}
          onClick={() => this.props.onChangeSort("spec")}
          after={this.props.newSpec ? `+${this.props.newSpec}` : undefined}
        />
        <AdministrateAnnouncementsMenuOption
          name={"Требуют проверки"}
          active={this.props.sort === "check"}
          onClick={() => this.props.onChangeSort("check")}
          after={this.props.newCheck ? `+${this.props.newCheck}` : undefined}
        />
      </div>
    );
  }
}
export default AdministrateAnnouncementsMenu;
