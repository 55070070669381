import React from "react";

class BlockedCircle extends React.Component<App.IconsProps> {
  static defaultProps = {
    background: "none",
    fill: "#ff0000",
  };
  public render() {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill={this.props.background}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="7" cy="7" r="7" fill={this.props.fill} />
        <rect x="2" y="6" width="10" height="2" fill="#ffffff" />
      </svg>
    );
  }
}

export default BlockedCircle;
