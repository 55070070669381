import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../struct/message/SMessage";
import { Unsubscribe } from "redux";
import UserSearchHistory from "../../../components/user/searchHistory/UserSearchHistory";
import UserService from "../../../services/abstract/UserService";
import UserServiceApi from "../../../services/api/UserServiceApi";
import { addMessage } from "../../../store/actions/messages";
import store from "../../../store/store";

interface Props {}
interface State {
  history: Array<App.FiltersHistory>;
  filters: Array<App.Announcement.FilterApi>;
  isEmpty: boolean;
}

class UserSearchHistoryContainer extends React.Component<Props, State> {
  private service: UserService;
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.service = new UserServiceApi();
    this.state = { history: [], filters: [], isEmpty: false };
  }

  public componentDidMount() {
    this.getHistory();
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }

  public componentWillUnmount() {
    this.unsubscribe();
  }

  private handleUpdateStore() {
    const {
      main,
      visibility,
      salon,
      multimedia,
      safety,
      comfort,
      other,
    } = store.getState().filters;
    const filters: Array<App.Announcement.FilterApi> = main.concat(
      visibility,
      salon,
      multimedia,
      safety,
      comfort,
      other
    );

    this.setState({
      filters,
    });
  }

  private async getHistory() {
    const { data, status, errors } = await this.service.getSearchHistory();
    console.log(data, status);
    if (status === OK) {
      this.setState({ history: data, isEmpty: data.length === 0 });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }
  public render() {
    return (
      <UserSearchHistory
        history={this.state.history}
        isEmpty={this.state.isEmpty}
        filters={this.state.filters}
      />
    );
  }
}

export default UserSearchHistoryContainer;
