import "./Progress.scss";

import {
  ANNOUNCEMENT_FILTER_CATEGORIES,
  BYN_ID,
  DEFAULT_STEP_DATA,
} from "../../../../Config";
import { CONFLICT, OK } from "http-status-codes";
import CreateAnnouncementStep, { Props as StepProps } from "../step/Step";

import AddContactContainer from "../../../../containers/user/addContact/AddContactContainer";
import AddPhotos from "../../../addPhotos/AddPhotos";
import AnnouncementService from "../../../../services/abstract/AnnouncementService";
import AnnouncementServiceApi from "../../../../services/api/AnnouncementServiceApi";
import CreateAnnouncementColor from "../../../../containers/createAnnouncement/selects/Color";
import CreateAnnouncementDescription from "../../../../containers/createAnnouncement/labels/main/Descriptin";
import CreateAnnouncementDisplay from "../../../../containers/createAnnouncement/labels/main/Display";
import CreateAnnouncementDistance from "../../../../containers/createAnnouncement/labels/main/Distance";
import CreateAnnouncementPrice from "../../../../containers/createAnnouncement/labels/main/Price";
import CreateAnnouncementResult from "../result/Result";
import { FiltersStore } from "../../../../store/reducers/FiltersReducer";
import LabelCity from "../../../custom/labels/labelCity/LabelCity";
import LabelSearchBrand from "../../../../containers/search/labels/Brand";
import LabelSearchModel from "../../../../containers/search/labels/Model";
import LabelVin from "../../../../containers/createAnnouncement/labels/vin/LabelVin";
import MainData from "../step/steps/MainData";
import React from "react";
import RegExp from "../../../../utils/string/RegExp";
import SAnnouncement from "../../../../struct/announcement/SAnnouncement";
import SMessage from "../../../../struct/message/SMessage";
import Select from "../../../custom/select/Select";
import Time from "../../../../utils/time/Time";
import { Unsubscribe } from "redux";
import VinErrorContainer from "../../../../containers/createAnnouncement/vinErrorContainer/VinErrorContainer";
import YandexService from "../../../../services/abstract/YandexService";
import YandexServiceApi from "../../../../services/api/YandexServiceApi";
import { addMessage } from "../../../../store/actions/messages";
import store from "../../../../store/store";

interface Props {
  type: App.CreateAnnouncement.Type;
  redact?: App.ID;
  toSignin: () => void;
  toSelectType: () => void;
  complite: (id: App.ID) => void;
}

interface State {
  step: number;
  brand: number;
  model: number;
  generations: Array<App.Car.Generation>;
  year: number;
  versions: Array<App.Car.Version>;
  carcass: App.ID;
  drive: App.ID;
  transmission: App.ID;
  motorType: App.ID;
  version: App.ID;
  price: number;
  currency: number;
  distance: number;
  display: boolean;
  description: string;
  photos: Array<File | string>;
  deletePhoto: Array<number>;
  deleteOptions: Array<number>;
  contacts: Array<any>;
  city: string;
  color: App.ID;
  contact: number;
  isCorrectCity: boolean;
  vin: string;
  filters: Array<{
    category: App.Announcement.Category;
    options: Array<App.Announcement.FilterApi>;
  }>;
  allFilters: FiltersStore;
  values: {
    visibility: App.CreateAnnouncement.StepData;
    salon: App.CreateAnnouncement.StepData;
    comfort: App.CreateAnnouncement.StepData;
    multimedia: App.CreateAnnouncement.StepData;
    safety: App.CreateAnnouncement.StepData;
    other: App.CreateAnnouncement.StepData;
  };
  preview: App.Announcement.Announcement;
  isVinError: boolean;
  email: string;

  cards: Array<{ name: string; options: Array<string> }>;
}

class CreateAnnouncementProgress extends React.Component<Props, State> {
  private mainData: MainData;
  private service: AnnouncementService;
  private mapService: YandexService;
  private timeoutCheckCity: number;
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.mainData = new MainData();
    this.service = new AnnouncementServiceApi();
    this.mapService = new YandexServiceApi();
    this.timeoutCheckCity = 0;
    this.unsubscribe = () => {};

    this.state = {
      step: 1,

      brand: 0,
      model: 0,
      generations: [],
      year: 0,
      versions: [],
      carcass: "",
      drive: "",
      transmission: "",
      motorType: "",
      version: 0,
      price: 0,
      currency: BYN_ID,
      distance: 0,
      display: true,
      description: "",
      vin: "",
      isVinError: false,
      color: 0,

      photos: [],
      deletePhoto: [],
      deleteOptions: [],

      filters: [],
      allFilters: store.getState().filters,

      city: "",
      contact: 0,
      isCorrectCity: false,
      contacts: store.getState().user.contacts,

      values: {
        visibility: DEFAULT_STEP_DATA(),
        salon: DEFAULT_STEP_DATA(),
        comfort: DEFAULT_STEP_DATA(),
        multimedia: DEFAULT_STEP_DATA(),
        safety: DEFAULT_STEP_DATA(),
        other: DEFAULT_STEP_DATA(),
      },
      // @ts-ignore
      preview: new SAnnouncement(),
      email: "",
      cards: [
        { name: "Обзор", options: [] },
        { name: "Салон", options: [] },
        { name: "Мультимедия", options: [] },
        { name: "Безопасность", options: [] },
        { name: "Комфорт", options: [] },
        { name: "Прочее", options: [] },
      ],
    };
  }

  public componentDidMount() {
    if (this.props.redact) {
      this.getAnnouncement(this.props.redact);
    }
    this.handleUpdateStore();
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }
  public componentDidUpdate(props: Props, state: State) {
    if (this.props.redact && this.props.redact !== props.redact) {
      this.getAnnouncement(this.props.redact);
    }
  }
  public componentWillUnmount() {
    this.unsubscribe();
    clearTimeout(this.timeoutCheckCity);
  }

  private get nextStep() {
    return this.state.step + 1;
  }
  private get previousStep() {
    return this.state.step - 1;
  }
  private get stepsNumber() {
    return this.props.type === "fast" ? 3 : 9;
  }
  private get stepNumber() {
    if (this.props.type === "fast" && this.state.step > 2) {
      return 9;
    }
    return this.state.step;
  }

  private get step(): StepProps {
    const stepData: StepProps = {
      complited: false,
      step: this.state.step,
      stepsNumber: this.stepsNumber,
      className: "",
      name: "",
      disabled: false,
      values: DEFAULT_STEP_DATA(),
      filters: [],
      before: <></>,
      after: <></>,

      onGoBack: () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.setState({ step: this.previousStep });
      },
      onGoNext: () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.setState({ step: this.nextStep });
      },
      onSave() {},
      onChange: this.handleChangeFilters.bind(this),
    };

    switch (this.stepNumber) {
      case ANNOUNCEMENT_FILTER_CATEGORIES.main.step:
        stepData.name = "Основные";
        stepData.className = "main-data";
        stepData.disabled =
          !this.state.version ||
          !this.state.color ||
          !this.state.price ||
          !this.state.vin.match(RegExp.vin);
        stepData.onGoBack = this.props.toSelectType;
        stepData.before = (
          <>
            <LabelSearchBrand
              selected={this.state.brand}
              onChange={(value, brand) =>
                this.handleChange("brand", value, brand)
              }
            />
            <LabelSearchModel
              disabled={!this.state.brand}
              selected={this.state.model}
              brand={this.state.brand}
              onChange={(value, model) =>
                this.handleChange("model", value, model)
              }
            />
            <Select
              name={"Год выпуска"}
              multiselect={false}
              onChange={(value) => this.handleChange("year", value)}
              selected={this.state.year || null}
              disabled={this.state.generations.length === 0}
              options={this.mainData.getYears(this.state.generations)}
            />
            <Select
              name={"Тип двигателя"}
              multiselect={false}
              disabled={!this.state.year}
              onChange={(value) => this.handleChange("motorType", value)}
              selected={this.state.motorType || null}
              options={this.mainData.getMotorTypes(this.state.versions, {})}
            />
            <Select
              name={"Кузов"}
              multiselect={false}
              disabled={!this.state.motorType}
              onChange={(value) => this.handleChange("carcass", value)}
              selected={this.state.carcass || null}
              options={this.mainData.getCarcasses(this.state.versions, {
                motorType: this.state.motorType.toString() || "",
              })}
            />
            <Select
              name={"Коробка"}
              multiselect={false}
              disabled={!this.state.carcass}
              onChange={(value) => this.handleChange("transmission", value)}
              selected={this.state.transmission || null}
              options={this.mainData.getTransmissions(this.state.versions, {
                motorType: this.state.motorType.toString() || "",
                carcass: this.state.carcass.toString() || "",
              })}
            />
            <Select
              name={"Привод"}
              multiselect={false}
              disabled={!this.state.transmission}
              onChange={(value) => this.handleChange("drive", value)}
              selected={this.state.drive || null}
              options={this.mainData.getDrives(this.state.versions, {
                motorType: this.state.motorType.toString() || "",
                carcass: this.state.carcass.toString() || "",
                transmission: this.state.transmission.toString() || "",
              })}
            />
            <Select
              name={"Версия"}
              multiselect={false}
              disabled={!this.state.drive}
              onChange={(value) => this.handleChange("version", value)}
              selected={this.state.version || null}
              options={this.mainData.getCarVersions(this.state.versions, {
                motorType: this.state.motorType.toString() || "",
                carcass: this.state.carcass.toString() || "",
                transmission: this.state.transmission.toString() || "",
                drive: this.state.drive.toString() || "",
              })}
            />
            <CreateAnnouncementPrice
              price={this.state.price}
              currency={this.state.currency}
              onChange={(value) => this.handleChange("price", value)}
              onChangeCurrency={(value) => this.handleChange("currency", value)}
            />
            <CreateAnnouncementDistance
              distance={this.state.distance}
              onChange={(value) => this.handleChange("distance", value)}
            />
            <LabelVin
              value={this.state.vin}
              disabled={!!this.props.redact}
              onChange={(value) => this.handleChange("vin", value)}
            />
            <CreateAnnouncementColor
              color={this.state.color}
              onChange={(value, color) =>
                this.handleChange("color", value, color)
              }
            />
            <CreateAnnouncementDisplay
              display={this.state.display}
              onChange={(value) => this.handleChange("display", value)}
            />
            <CreateAnnouncementDescription
              description={this.state.description}
              onChange={(value) => this.handleChange("description", value)}
            />
          </>
        );
        break;
      case ANNOUNCEMENT_FILTER_CATEGORIES.photo.step:
        this.getBaseStepData(ANNOUNCEMENT_FILTER_CATEGORIES.photo, stepData);
        stepData.disabled = this.state.photos.length === 0;
        stepData.className = "photos";
        stepData.before = (
          <>
            <span>
              Допускается загрузка не более 30 фотографий в формате JPG и PNG
              размером не более 8 МБ. Отметьте главное фото
            </span>
            <AddPhotos
              photos={this.state.photos.map((e) => (e instanceof File ? e : e))}
              onChange={(value) => this.handleChange("photos", value)}
              main={0}
            />
          </>
        );
        break;
      case ANNOUNCEMENT_FILTER_CATEGORIES.visibility.step:
        stepData.name = "Обзор";
        stepData.filters = this.state.allFilters.visibility;
        stepData.values = this.state.values.visibility;
        break;
      case ANNOUNCEMENT_FILTER_CATEGORIES.salon.step:
        stepData.name = "Салон";
        stepData.filters = this.state.allFilters.salon;
        stepData.values = this.state.values.salon;
        break;
      case ANNOUNCEMENT_FILTER_CATEGORIES.multimedia.step:
        stepData.name = "Мультимедиа";
        stepData.filters = this.state.allFilters.multimedia;
        stepData.values = this.state.values.multimedia;
        break;
      case ANNOUNCEMENT_FILTER_CATEGORIES.safety.step:
        stepData.name = "Безопасность";
        stepData.filters = this.state.allFilters.safety;
        stepData.values = this.state.values.safety;
        break;
      case ANNOUNCEMENT_FILTER_CATEGORIES.comfort.step:
        stepData.name = "Комфорт";
        stepData.filters = this.state.allFilters.comfort;
        stepData.values = this.state.values.comfort;
        break;
      case ANNOUNCEMENT_FILTER_CATEGORIES.other.step:
        stepData.name = "Прочее";
        stepData.filters = this.state.allFilters.other;
        stepData.values = this.state.values.other;
        break;
      case ANNOUNCEMENT_FILTER_CATEGORIES.contact.step:
        stepData.name = "Контакт";
        stepData.className = "contact";
        stepData.complited = true;
        stepData.disabled = !(this.state.isCorrectCity && this.state.contact);
        stepData.onSave = () => this.saveAnnouncmenet();
        stepData.before = (
          <>
            <LabelCity
              value={this.state.city}
              onChange={(value) => this.handleChange("city", value)}
              correct={this.state.isCorrectCity}
              maxLength={256}
            />
            <Select
              selected={this.state.contact}
              multiselect={false}
              disabled={this.state.contacts.length === 0}
              options={this.state.contacts.map((e) => ({
                id: e.id,
                name: `${e.name} ${e.phone}`,
              }))}
              onChange={(value) => this.handleChange("contact", value)}
              placeholder="Контакт"
            />
            <AddContactContainer />
          </>
        );
        break;
      default:
        break;
    }
    return stepData;
  }

  private getBaseStepData(
    stepDataConfig: { id?: number; name: string; step: number },
    stepData: StepProps
  ) {
    const { name, filters } = this.getFiltersForCategory(stepDataConfig);
    stepData.name = name;
    stepData.filters = filters;
    return stepData;
  }
  private getFiltersForCategory(category: {
    id?: number;
    name: string;
    step: number;
  }) {
    const data: {
      step: number;
      name: string;
      filters: Array<App.Announcement.FilterApi>;
    } = {
      step: category.step,
      name: category.name,
      filters: [],
    };
    if (category.id) {
      const filters = this.state.filters.find(
        (e) => e.category === category.id
      );
      if (filters) {
        data.filters = filters?.options;
      } else {
        this.getFilters(category.id);
      }
    }
    return data;
  }
  private async getFilters(category: App.Announcement.Category) {
    let filters: Array<{
      category: App.Announcement.Category;
      options: Array<App.Announcement.FilterApi>;
    }> = Object.assign([], this.state.filters).filter(
      (e: { category: App.Announcement.Category; options: [] }) =>
        e.category !== category
    );
    filters.push({ category, options: [] });
    this.setState({ filters });

    const { data, status } = await this.service.getFiltersByCategory(category);

    if (status === OK) {
      this.setState({
        filters: filters.map((e) =>
          e.category === category ? { category, options: data } : e
        ),
      });
    }
  }
  private async getAnnouncement(id: App.ID) {
    const { data, status, errors } = await this.service.getById(id);
    if (status === OK) {
      if (data.car.vin.includes("*")) {
        store.dispatch(
          addMessage(new SMessage("Не удалось подтвердить доступ к объявлению"))
        );
      }

      const generations = await this.mainData.getGeneration(+data.car.model.id);
      const versions = await this.mainData.getVersions(generations, data.year);
      const contact = Object.assign(
        data.contact,
        store.getState().user.contacts.find((c) => data.contact.id === c.id)
      );

      this.setState({
        preview: (() => {
          data.contact = contact || data.contact;
          return data;
        })(),
        deleteOptions: data.carOptionsData.map((e) => e.id),
        isCorrectCity: true,
        brand: +data.car.brand.id,
        model: +data.car.model.id,
        year: data.year,
        transmission: data.car.transmission.name,
        carcass: data.car.carcass.name,
        drive: data.car.drive.name,
        motorType: data.car.motor.type,
        version: data.car.version,
        currency: data.currency,
        generations,
        versions,
        city: data.contact.city,
        contact: data.contact.id,
        price: data.price,
        color: data.car.color.id,
        description: data.description,
        photos: data.images.map((e) => e.path),
        values: {
          visibility: this.parseFilters(data, this.state.allFilters.visibility),
          salon: this.parseFilters(data, this.state.allFilters.salon),
          comfort: this.parseFilters(data, this.state.allFilters.comfort),
          multimedia: this.parseFilters(data, this.state.allFilters.multimedia),
          safety: this.parseFilters(data, this.state.allFilters.safety),
          other: this.parseFilters(data, this.state.allFilters.other),
        },

        vin: data.car.vin,
      });

      this.updateCards(this.state.allFilters);
    } else {
      errors.forEach((error) => {
        store.dispatch(addMessage(new SMessage(error)));
      });
    }
  }
  private parseFilters(
    data: App.Announcement.Announcement,
    filters: Array<App.Announcement.FilterApi>
  ): App.CreateAnnouncement.StepData {
    return {
      checkboxList: data.carOptionsData
        .filter((e) => {
          return filters.some(
            (f) =>
              f.type === "checkbox" &&
              f.id === e.car_filter_id &&
              e.value === "1"
          );
        })
        .map((e) => e.car_filter_id),
      multiselectList: data.carOptionsData
        .filter((e) => {
          return (
            e.option_id &&
            filters.filter(
              (f) =>
                f.type === "multiselect" &&
                f.id === e.car_filter_id &&
                e.option_id
            )
          );
        })
        .reduce((g: Array<{ id: App.ID; value: Array<App.ID> }>, e) => {
          if (e.option_id) {
            const optionId = e.option_id;
            if (g.some((q) => q.id === e.car_filter_id)) {
              g.map((q) => {
                if (q.id === e.id) {
                  q.value = q.value.concat(optionId);
                }
                return q;
              });
            } else {
              g.push({ id: e.car_filter_id, value: [e.option_id] });
            }
          }
          return g;
        }, []),
      selectList: [],
      numberList: [],
    };
  }

  private async handleChange(field: string, value: any, additional?: App.Info) {
    const newState = {};
    // @ts-ignore
    newState[field] = value;
    this.setState(newState);
    switch (field) {
      case "brand": {
        this.setState({
          model: 0,
          year: 0,
          carcass: "",
          motorType: "",
          transmission: "",
          drive: "",
          version: 0,
          generations: [],
          versions: [],
          preview: (() => {
            // @ts-ignore
            const preview: App.Announcement.Announcement = new SAnnouncement(
              this.state.preview
            );
            if (additional) {
              preview.car.brand.name = additional.name;
              preview.car.model.name = "Модель";
            }
            return preview;
          })(),
        });
        break;
      }
      case "model": {
        this.setState({
          generations: await this.mainData.getGeneration(value),
          year: 0,
          carcass: "",
          motorType: "",
          transmission: "",
          drive: "",
          version: 0,
          versions: [],
          preview: (() => {
            // @ts-ignore
            const preview: App.Announcement.Announcement = new SAnnouncement(
              this.state.preview
            );
            if (additional) {
              preview.car.model.name = additional.name;
            }

            return preview;
          })(),
        });
        break;
      }
      case "year": {
        this.setState({
          versions: await this.mainData.getVersions(
            this.state.generations,
            value
          ),
          carcass: "",
          motorType: "",
          transmission: "",
          drive: "",
          version: 0,
          preview: (() => {
            // @ts-ignore
            const preview: App.Announcement.Announcement = new SAnnouncement(
              this.state.preview
            );
            preview.year = value;
            return preview;
          })(),
        });
        break;
      }
      case "motorType":
        this.setState({
          carcass: "",
          transmission: "",
          drive: "",
          version: 0,
        });
        break;
      case "carcass":
        this.setState({
          transmission: "",
          drive: "",
          version: 0,
        });
        break;
      case "transmission":
        this.setState({
          drive: "",
          version: 0,
        });
        break;
      case "drive":
        this.setState({
          version: 0,
        });
        break;
      case "version":
        this.setState({
          version: value,
          preview: (() => {
            // @ts-ignore
            const preview: App.Announcement.Announcement = new SAnnouncement(
              this.state.preview
            );
            const version = this.state.versions.find((v) => v.id === value);
            if (version) {
              preview.car = Object.assign({}, preview.car, version);
            }
            return preview;
          })(),
        });
        break;
      case "photos": {
        const remove = this.state.preview.images
          .filter((e) => !(e instanceof File))
          .find((e) => !value.includes(e.path))?.id;
        this.setState({
          deletePhoto: (remove
            ? this.state.deletePhoto.concat(+remove)
            : this.state.deletePhoto
          ).filter((e) => e),
          photos: value,
          preview: (() => {
            // @ts-ignore
            const preview: App.Announcement.Announcement = new SAnnouncement(
              this.state.preview
            );
            preview.images = value.map((e: File | string, i: number) => {
              if (e instanceof File) {
                return { id: `image-${i}`, path: URL.createObjectURL(e) };
              } else {
                return preview.images.find((q) => q.path === e);
              }
            });

            return preview;
          })(),
        });
        break;
      }
      case "city":
        clearTimeout(this.timeoutCheckCity);
        this.timeoutCheckCity = window.setTimeout(
          this.checkCity.bind(this),
          Time.second
        );
        this.setState({
          city: value,
          isCorrectCity: false,
          preview: (() => {
            // @ts-ignore
            const preview: App.Announcement.Announcement = new SAnnouncement(
              this.state.preview
            );
            preview.contact.city = value;
            return preview;
          })(),
        });
        break;
      case "contact":
        this.setState({
          contact: value,
          preview: (() => {
            // @ts-ignore
            const preview: App.Announcement.Announcement = new SAnnouncement(
              this.state.preview
            );
            const contact = this.state.contacts.find((e) => e.id === value);
            preview.contact.name = contact.name;
            preview.contact.phone = contact.phone;
            return preview;
          })(),
        });
        break;
      case "vin":
        this.setState({ vin: value });
        break;
      case "color":
        this.setState({
          color: value,
          preview: (() => {
            // @ts-ignore
            const preview: App.Announcement.Announcement = new SAnnouncement(
              this.state.preview
            );
            preview.car = Object.assign(this.state.preview.car, {
              color: additional,
            });
            return preview;
          })(),
        });

        break;
      case "distance":
        this.setState({
          distance: value,
          preview: (() => {
            // @ts-ignore
            const preview: App.Announcement.Announcement = new SAnnouncement(
              this.state.preview
            );
            preview.distance = value;
            return preview;
          })(),
        });
        break;
      case "price":
        this.setState({
          price: value,
          preview: (() => {
            // @ts-ignore
            const preview: App.Announcement.Announcement = new SAnnouncement(
              this.state.preview
            );
            preview.price = value;
            return preview;
          })(),
        });
        break;
      case "currency":
        this.setState({
          currency: value,
          preview: (() => {
            // @ts-ignore
            const preview: App.Announcement.Announcement = new SAnnouncement(
              this.state.preview
            );
            preview.currency = value;
            return preview;
          })(),
        });
        break;
      default:
        break;
    }
  }
  private handleChangeFilters(value: App.CreateAnnouncement.StepData) {
    const values: any = {};
    switch (this.state.step) {
      case ANNOUNCEMENT_FILTER_CATEGORIES.visibility.step:
        values.visibility = value;
        break;
      case ANNOUNCEMENT_FILTER_CATEGORIES.salon.step:
        values.salon = value;
        break;
      case ANNOUNCEMENT_FILTER_CATEGORIES.comfort.step:
        values.comfort = value;
        break;
      case ANNOUNCEMENT_FILTER_CATEGORIES.multimedia.step:
        values.multimedia = value;
        break;
      case ANNOUNCEMENT_FILTER_CATEGORIES.safety.step:
        values.safety = value;
        break;
      case ANNOUNCEMENT_FILTER_CATEGORIES.other.step:
        values.other = value;
        break;
      default:
        break;
    }
    this.updateCards(this.state.allFilters);
    this.setState({
      values: Object.assign(this.state.values, values),
    });
  }

  private async updateCards(filters: FiltersStore) {
    await new Promise((resolve) => {
      window.setTimeout(() => {
        resolve();
      });
    });
    let cards = this.updateCard(
      ANNOUNCEMENT_FILTER_CATEGORIES.visibility.name,
      filters.visibility,
      this.state.values.visibility
    );
    cards = this.updateCard(
      ANNOUNCEMENT_FILTER_CATEGORIES.comfort.name,
      filters.comfort,
      this.state.values.comfort,
      cards
    );
    cards = this.updateCard(
      ANNOUNCEMENT_FILTER_CATEGORIES.salon.name,
      filters.salon,
      this.state.values.salon,
      cards
    );
    cards = this.updateCard(
      ANNOUNCEMENT_FILTER_CATEGORIES.multimedia.name,
      filters.multimedia,
      this.state.values.multimedia,
      cards
    );
    cards = this.updateCard(
      ANNOUNCEMENT_FILTER_CATEGORIES.safety.name,
      filters.safety,
      this.state.values.safety,
      cards
    );
    cards = this.updateCard(
      ANNOUNCEMENT_FILTER_CATEGORIES.other.name,
      filters.other,
      this.state.values.other,
      cards
    );
    this.setState({ cards });
  }

  private updateCard(
    category: string,
    filters: Array<App.Announcement.FilterApi>,
    values: App.CreateAnnouncement.StepData,
    cards?: Array<{ name: string; options: Array<string> }>
  ) {
    const card: { name: string; options: Array<string> } = {
      name: category,
      options: [],
    };

    filters.forEach((filter) => {
      if (filter.type === "checkbox") {
        if (values.checkboxList.some((c) => c === filter.id)) {
          card.options.push(filter.name);
        }
      } else if (filter.type === "select") {
        const select = values.selectList.find(
          (s) => s.id === filter.id && s.value
        );
        if (select) {
          const option = filter.options.find(
            (option) => option.id === select.value
          );
          if (option) {
            card.options.push(option.name);
          }
        }
      } else if (filter.type === "multiselect") {
        const multiselect = values.multiselectList.find(
          (m) => m.id === filter.id && m.value.length > 0
        );
        if (multiselect) {
          filter.options.forEach((option) => {
            if (multiselect.value.includes(option.id)) {
              card.options.push(option.name);
            }
          });
        }
      }
    });
    return (cards || this.state.cards).map((e) =>
      e.name === card.name ? card : e
    );
  }

  private async checkCity() {
    const { status, data } = await this.mapService.checkCity(this.state.city);
    if (status === OK) {
      try {
        const isBelarus = data.response.GeoObjectCollection.featureMember.some(
          (e: any) => {
            return (
              e.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components.some(
                (c: any) => c.kind === "country" && c.name === "Беларусь"
              ) &&
              e.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components.some(
                (c: any) => c.kind === "locality"
              )
            );
          }
        );

        this.setState({ isCorrectCity: isBelarus });
      } catch {}
    } else {
    }
  }

  private handleUpdateStore() {
    const {
      user: { contacts, email, isAuth },
      filters,
    } = store.getState();
    if (filters !== this.state.allFilters) {
      this.setState({ allFilters: filters });
      this.updateCards(filters);
    }
    if (!isAuth) {
      this.props.toSignin();
    }
    this.setState({ contacts, email });
  }
  private async saveAnnouncmenet() {
    const data: any = {
      version_model_id: this.state.version,
      images: this.state.photos.filter((i) => i instanceof File),
      delete_images: this.state.deletePhoto,
      delete_options: this.state.deleteOptions,
      contacts: [this.state.contact],
      price: this.state.price,
      active_currency: this.state.currency,
      distance: this.state.distance,
      year: this.state.year,
      description: this.state.description,
      city_of_sale: this.state.city,
      color_id: this.state.color,
      email: this.state.email,
      // options: [],
      options: this.getCheckboxes()
        .concat(this.getSelects())
        .concat(this.getMultiselect()),
      vin: this.state.vin,
    };
    if (this.props.redact) {
      delete data.remove_image;
    }
    const { data: response, status, errors } = await (this.props.redact
      ? this.service.updateAnnouncement(data, this.props.redact)
      : this.service.addAnnouncement(data));
    if (status === OK) {
      this.props.complite(response.id);
    } else {
      if (status === CONFLICT) {
        this.openVinError();
      } else {
        errors.forEach((error) =>
          store.dispatch(addMessage(new SMessage(error)))
        );
      }
    }
  }
  private getCheckboxes(): Array<any> {
    const res: Array<any> = [];
    return res.concat(
      this.state.values.comfort.checkboxList.map((id) => ({
        car_filter_id: id,
        value: 1,
      })),
      this.state.values.multimedia.checkboxList.map((id) => ({
        car_filter_id: id,
        value: 1,
      })),
      this.state.values.other.checkboxList.map((id) => ({
        car_filter_id: id,
        value: 1,
      })),
      this.state.values.safety.checkboxList.map((id) => ({
        car_filter_id: id,
        value: 1,
      })),
      this.state.values.salon.checkboxList.map((id) => ({
        car_filter_id: id,
        value: 1,
      })),
      this.state.values.visibility.checkboxList.map((id) => ({
        car_filter_id: id,
        value: 1,
      }))
    );
  }
  private getSelects(): Array<any> {
    const res: Array<any> = [];
    return res.concat(
      this.state.values.comfort.selectList
        .filter(({ value }) => !!value)
        .map(({ id, value }) => ({ car_filter_id: id, value })),
      this.state.values.multimedia.selectList
        .filter(({ value }) => !!value)
        .map(({ id, value }) => ({ car_filter_id: id, value })),
      this.state.values.other.selectList
        .filter(({ value }) => !!value)
        .map(({ id, value }) => ({ car_filter_id: id, value })),
      this.state.values.safety.selectList
        .filter(({ value }) => !!value)
        .map(({ id, value }) => ({ car_filter_id: id, value })),
      this.state.values.salon.selectList
        .filter(({ value }) => !!value)
        .map(({ id, value }) => ({ car_filter_id: id, value })),
      this.state.values.visibility.selectList
        .filter(({ value }) => !!value)
        .map(({ id, value }) => ({ car_filter_id: id, value }))
    );
  }
  private getMultiselect(): Array<any> {
    const res: Array<any> = [];
    return res.concat(
      this.state.values.comfort.multiselectList
        .filter(({ value }) => value.length > 0)
        .map(({ id, value: options }) => ({ car_filter_id: id, options })),
      this.state.values.multimedia.multiselectList
        .filter(({ value }) => value.length > 0)
        .map(({ id, value: options }) => ({ car_filter_id: id, options })),
      this.state.values.other.multiselectList
        .filter(({ value }) => value.length > 0)
        .map(({ id, value: options }) => ({ car_filter_id: id, options })),
      this.state.values.safety.multiselectList
        .filter(({ value }) => value.length > 0)
        .map(({ id, value: options }) => ({ car_filter_id: id, options })),
      this.state.values.salon.multiselectList
        .filter(({ value }) => value.length > 0)
        .map(({ id, value: options }) => ({ car_filter_id: id, options })),
      this.state.values.visibility.multiselectList
        .filter(({ value }) => value.length > 0)
        .map(({ id, value: options }) => ({ car_filter_id: id, options }))
    );
  }

  private openVinError() {
    this.setState({ isVinError: true });
  }
  private closeVinError() {
    this.setState({ isVinError: false });
  }

  public render() {
    return (
      <div className="create-announcement__progress">
        <CreateAnnouncementStep {...this.step} />
        <CreateAnnouncementResult
          isShowCards={this.props.type === "full"}
          announcement={this.state.preview}
          cards={this.state.cards}
        />
        {this.state.isVinError && (
          <VinErrorContainer
            vin={this.state.vin}
            closePopup={this.closeVinError.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default CreateAnnouncementProgress;
