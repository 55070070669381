class SGeneration implements App.Car.Generation {
  public id: number;
  public name: string;
  public start: number;
  public end: number;
  public modelId: number;

  constructor(data?: any) {
    this.id = 0;
    this.modelId = 0;
    this.name = "";
    this.start = 0;
    this.end = 0;
    data && this.init(data);
  }

  private init(data: any) {
    try {
      this.id = data.id || 0;
      this.modelId = data.model_id || 0;
      this.name = data.name || "";
      this.start = data.start_create || 0;
      this.end = data.end_create || 0;
    } catch {}
  }
}

export default SGeneration;
