import "./AdminPanelForm.scss";

import React, { SyntheticEvent } from "react";

import Popup from "../../custom/popup/Popup";
import SubTitle from "../../custom/subtitle/SubTitle";

interface Props {
  isOpenPopup: boolean;
  question: string;
  loading: boolean;

  onSubmit: (event: SyntheticEvent<HTMLFormElement>) => void;
  onClose: (event?: SyntheticEvent) => void;
  onDelete: (event: SyntheticEvent) => void;
}
interface State {}

class AdminPanelForm extends React.Component<Props, State> {
  public render() {
    return (
      <form onSubmit={this.props.onSubmit} className="admin-panel-form">
        {this.props.children}
        {this.props.isOpenPopup && (
          <Popup
            onClose={this.props.onClose}
            className="admin-panel-delete-popup"
          >
            <SubTitle>{this.props.question}</SubTitle>
            <div className="buttons">
              <button
                disabled={this.props.loading}
                onClick={this.props.onDelete}
              >
                Да
              </button>
              <button
                disabled={this.props.loading}
                onClick={this.props.onClose}
              >
                Нет
              </button>
            </div>
          </Popup>
        )}
      </form>
    );
  }
}
export default AdminPanelForm;
