import "./AnnouncementStats.scss";

import { BYN_ID, USD_ID } from "../../../Config";

import AnnouncementName from "../../car/name/AnnouncementName";
import AnnouncementStatCarcass from "./stats/Carcass";
import AnnouncementStatColor from "./stats/Color";
import AnnouncementStatDistance from "./stats/Distance";
import AnnouncementStatDrive from "./stats/Drive";
import AnnouncementStatMotor from "./stats/Motor";
import AnnouncementStatSwap from "./stats/Swap";
import AnnouncementStatTransmission from "./stats/Transmission";
import AnnouncementStatYear from "./stats/Year";
import ButtonPrice from "../../price/button/ButtonPrice";
import PriceDisplay from "../../price/display/PriceDisplay";
import React from "react";

interface Props {
  announcement?: App.Announcement.Announcement;
  name?: boolean;
}
interface State {}

class AnnouncementStats extends React.Component<Props, State> {
  public render() {
    return (
      <div className="announcement-stats">
        {this.props.name && (
          <AnnouncementName announcement={this.props.announcement} />
        )}
        <div className="prices">
          <ButtonPrice
            price={this.props.announcement?.BYN || 0}
            currency={BYN_ID}
          />
          <PriceDisplay
            price={this.props.announcement?.USD || 0}
            currency={USD_ID}
            className="additional"
          />
        </div>
        <AnnouncementStatYear year={this.props.announcement?.year} />
        <AnnouncementStatDistance
          distance={this.props.announcement?.distance}
        />
        <AnnouncementStatCarcass
          carcass={this.props.announcement?.car.carcass}
        />
        <AnnouncementStatColor
          color={this.props.announcement?.car.color.name}
        />
        <AnnouncementStatMotor motor={this.props.announcement?.car.motor} />
        <AnnouncementStatTransmission
          transmission={this.props.announcement?.car.transmission.name}
        />
        <AnnouncementStatDrive
          drive={this.props.announcement?.car.drive.name}
        />
        <AnnouncementStatSwap swap={this.props.announcement?.swap} />
      </div>
    );
  }
}

export default AnnouncementStats;
