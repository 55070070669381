import { BASE_URL, BYN_ID, USD_ID } from "../../Config";

import SCar from "../car/SCar";
import SColor from "../color/SColor";
import Time from "../../utils/time/Time";
import store from "../../store/store";

interface CarFilter {
  id: number;
  car_filter_id: number;
  value: string | number | null;
  option_id: number | null;
}

class SAnnouncement implements App.Announcement.Announcement {
  public id: number;
  public car: App.Car.Car;
  public images: Array<App.Image>;
  public price: number;
  public currency: number;
  public year: number;
  public distance: number;
  public description: string;
  public state: "Новая" | "Хорошее" | "Плохое" | "Не на ходу" | "";
  public checked: boolean;
  public swap: boolean;
  public favorite: boolean;
  public contact: App.Announcement.Contact;
  public created: number;
  public viewsToday: number;
  public viewsTotal: number;
  public specialOffer: { from: Date | null; to: Date | null };
  public last_update: number;
  public history: Array<App.Car.CarHistory>;

  public carOptions: Array<CarFilter>;
  private settedOptions?: Array<App.Announcement.Option>;
  constructor(data?: any) {
    this.id = 0;
    this.car = new SCar();
    this.images = [];
    this.price = 0;
    this.currency = 0;
    this.year = 0;
    this.distance = 0;
    this.description = "";
    this.state = "";
    this.checked = false;
    this.swap = false;
    this.favorite = false;
    this.contact = { id: 0, name: "", city: "", email: "", phone: "" };
    this.created = 0;
    this.viewsToday = 0;
    this.viewsTotal = 0;
    this.last_update = 0;
    this.specialOffer = { from: null, to: null };
    this.carOptions = [];
    this.history = [];
    if (data && data instanceof SAnnouncement) {
      this.clone(data);
    } else {
      data && this.init(data);
    }
  }

  private init(data: any) {
    try {
      this.id = data.id;
      this.car = new SCar(data);
      this.images = data.car.images.map((e: { id: number; image: string }) => ({
        id: e.id,
        path: BASE_URL + e.image,
      }));
      this.distance = data.car.distance;
      this.currency = data.active_currency;
      this.price = data.price;
      this.year = data.car.year;
      this.description = data.description || "";
      this.state = "";
      this.checked = !!data.inspected;
      this.swap = !!data.special_ofer;
      this.favorite = !!data.favorite;
      this.created = +data.created_at * 1000;
      this.last_update = +data.updated_at * 1000;
      this.viewsToday = data.views.today;
      this.viewsTotal = data.views.all;
      this.specialOffer = data.special_offer
        ? {
            from: data.special_offer.from
              ? new Date(+data.special_offer.from * Time.second)
              : data.special_offer.from,
            to: data.special_offer.to
              ? new Date(+data.special_offer.to * Time.second)
              : data.special_offer.to,
          }
        : { from: null, to: null };
      this.carOptions = data.car.car_options || [];
      const contact = (data.contacts && data.contacts[0]) || {};
      this.contact = {
        id: contact?.id || 0,
        name: contact?.name || "",
        phone: contact?.phone || "",
        city: data.city_of_sale,
        email: data.email,
      };
      if (data?.history) {
        this.history = data?.history?.map((e: any) => ({
          id: e.id,
          color: new SColor(e.car.color),
          distance: e.car.distance,
          datetime: new Date(+e.closed_at * Time.second),
        }));
      }
    } catch (err) {}
  }
  private clone(data: SAnnouncement) {
    this.id = data.id;
    this.car = data.car;
    this.images = data.images;
    this.price = data.price;
    this.currency = data.currency;
    this.year = data.year;
    this.distance = data.distance;
    this.description = data.description;
    this.state = data.state;
    this.checked = data.checked;
    this.swap = data.swap;
    this.favorite = data.favorite;
    this.contact = data.contact;
    this.created = data.created;
    this.viewsToday = data.viewsToday;
    this.viewsTotal = data.viewsTotal;
    this.last_update = data.last_update;
    this.specialOffer = data.specialOffer;
    this.carOptions = data.carOptions;
  }

  public get BYN() {
    if (this.currency === USD_ID) {
      return Math.floor(this.price / store.getState().currency.USD.multiplier);
    } else {
      return this.price;
    }
  }
  public get USD() {
    if (this.currency === BYN_ID) {
      return Math.floor(this.price * store.getState().currency.USD.multiplier);
    } else {
      return this.price;
    }
  }

  public get options(): Array<App.Announcement.Option> {
    if (this.settedOptions) return this.settedOptions;
    const { filters } = store.getState();
    return [
      this.getOptionsForCategory("Обзор", filters.visibility),
      this.getOptionsForCategory("Мультимедия", filters.multimedia),
      this.getOptionsForCategory("Комфорт", filters.comfort),
      this.getOptionsForCategory("Безопасность", filters.safety),
      this.getOptionsForCategory("Салон", filters.salon),
      this.getOptionsForCategory("Прочее", filters.other),
    ].filter((e) => e.options.length > 0);
  }

  public set options(value: Array<App.Announcement.Option>) {
    this.settedOptions = value;
  }

  public get spec(): boolean {
    if (!this.specialOffer.from && !this.specialOffer.to) {
      return false;
    }
    if (this.specialOffer.from && +this.specialOffer.from > +new Date()) {
      return false;
    }
    if (this.specialOffer.to && +this.specialOffer.to < +new Date()) {
      return false;
    }
    return true;
  }

  // @ts-ignore
  public get carOptionsData() {
    return this.carOptions;
  }

  private getOptionsForCategory(
    category: string,
    filters: Array<App.Announcement.FilterApi>
  ) {
    const selected: Array<string> = [];
    this.carOptions.forEach((carFilter) => {
      const filter: App.Announcement.FilterApi | undefined = filters.find(
        (filter) => filter.id === carFilter.car_filter_id
      );
      if (filter) {
        if (
          carFilter.option_id &&
          (filter.type === "multiselect" || filter.type === "select")
        ) {
          const option = filter.options.find(
            (o) => o.id === carFilter.option_id
          );
          selected.push(option ? `${filter.name}: ${option.name}` : "");
        } else if (carFilter.value && filter.type === "range") {
          selected.push(`${filter.name}: ${carFilter.value}`);
        } else if (carFilter.value && filter.type === "checkbox") {
          selected.push(carFilter.value ? filter.name : "");
        }
      }
    });
    return { category, options: selected.filter((e) => e) };
  }
}

export default SAnnouncement;
