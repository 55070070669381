import LabelTextarea from "../../../../components/custom/labels/labelTextarea/LabelTextarea";
import React from "react";

interface Props {
  description?: string;
  onChange: (description: string) => void;
}
interface State {}

class CreateAnnouncementDescription extends React.Component<Props, State> {
  public render() {
    return (
      <LabelTextarea
        name="Описание"
        value={this.props.description}
        onChange={this.props.onChange}
      />
    );
  }
}

export default CreateAnnouncementDescription;
