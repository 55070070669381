import {
  AMOUNT_FOR_ANNOUNCEMENTS,
  BANNED_FILTERS,
  BRAND_FILTER_ID,
  MODEL_FILTER_ID,
} from "../../Config";
import AnnouncementService, {
  GetByFiltersOptions,
} from "../abstract/AnnouncementService";
import Axios, { AxiosResponse } from "axios";
import { handle403, handleError, handleResponse } from "./methods";

import { FORBIDDEN } from "http-status-codes";
import JWT from "../../utils/JWT/JWT";
import SAnnouncement from "../../struct/announcement/SAnnouncement";
import Time from "../../utils/time/Time";
import { serialize } from "object-to-formdata";

class AnnouncementServiceApi extends AnnouncementService {
  public async getById(
    id: number | string
  ): Promise<App.Response<App.Announcement.Announcement>> {
    const Authorization = await JWT.getAuth().catch(() => undefined);
    return Axios.get(`/announcement/${id}`, { headers: { Authorization } })
      .then((response) => {
        response.data = new SAnnouncement(response.data);
        return handleResponse(response);
      })
      .catch(handleError);
  }
  public async search(
    options: GetByFiltersOptions,
    session?: App.User.Session
  ): Promise<App.Response<App.ListMetadata<App.Announcement.Announcement>>> {
    const Authorization = await JWT.getAuth().catch(() => undefined);

    const params: any = {
      page: options.page || 1,
      amount: options.amount ? options.amount : AMOUNT_FOR_ANNOUNCEMENTS,
      sort: options.sort,
      key: options.keyword,
      show_all: options.showAll ? 1 : 0,
      special: options.special ? 1 : 0,
      favorite: options.favorite ? 1 : 0,
    };
    return Axios.get("/search", {
      params,
      headers: { Authorization },
    })
      .then((response: AxiosResponse<any>) => {
        response.data = {
          page: options.page || 1,
          totalPage: response.data.last_page,
          total: response.data.total,
          numberPerPage: response.data.per_page,
          items: response.data.data.map((e: any) => new SAnnouncement(e)),
        };
        return handleResponse(response);
      })
      .catch(handleError);
  }
  public async getByFilters(
    options: GetByFiltersOptions,
    session?: App.User.Session
  ): Promise<App.Response<App.ListMetadata<App.Announcement.Announcement>>> {
    const Authorization = await JWT.getAuth().catch(() => undefined);
    const filters = options.filters ? this.parseFilters(options.filters) : "[]";

    const params: any = {
      page: options.page || 1,
      amount: options.amount ? options.amount : AMOUNT_FOR_ANNOUNCEMENTS,
      sort: options.sort,
      keyword: options.keyword,
      show_all: options.showAll ? 1 : 0,
      special: options.special ? 1 : 0,
      favorite: options.favorite ? 1 : 0,
      filters,
    };

    if (!params.sort) delete params.sort;
    if (!params.keyword) delete params.keyword;
    if (!params.show_all) delete params.show_all;
    if (!params.special) delete params.special;
    if (!params.filters) delete params.filters;
    if (!params.favorite) delete params.favorite;

    return Axios.get("/filter", {
      params,
      headers: { Authorization },
    })
      .then((response: AxiosResponse<any>) => {
        response.data = {
          page: options.page || 1,
          totalPage: response.data.last_page,
          total: response.data.total,
          numberPerPage: response.data.per_page,
          items: response.data.data.map((e: any) => new SAnnouncement(e)),
        };
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(options, this.getByFilters);
        }
        return handleError(response);
      });
  }
  private parseFilters(filters: App.Announcement.FiltersForSearch): string {
    if (filters instanceof Array) return JSON.stringify(filters);
    const res: any = [];
    filters.car?.forEach((car) => {
      if (car.brand) {
        if (res[BRAND_FILTER_ID]) {
          res[BRAND_FILTER_ID].options = res[BRAND_FILTER_ID].options.concat(
            car.brand
          );
        } else {
          res[BRAND_FILTER_ID] = { options: [car.brand] };
        }
      }
      if (car.model) {
        if (res[MODEL_FILTER_ID]) {
          res[MODEL_FILTER_ID].options = res[MODEL_FILTER_ID].options.concat(
            car.model
          );
        } else {
          res[MODEL_FILTER_ID] = { options: [car.model] };
        }
      }
    });
    filters.other?.forEach((filter) => {
      switch (filter.type) {
        case "checkbox": {
          if (filter.value) {
            res.push({ id: filter.id, value: 1 });
          }
          break;
        }
        case "pallets": {
          if (filter.value.length) {
            res.push({ id: filter.id, options: filter.value });
          }
          break;
        }
        case "picker": {
          if (filter.value.length) {
            res.push({ id: filter.id, options: filter.value });
          }
          break;
        }
        case "radio": {
          if (filter.value) {
            res.push({ id: filter.id, value: filter.value });
          }
          break;
        }
        case "range": {
          if (filter.value) {
            res.push({
              id: filter.id,
              min: filter.value[0],
              max: filter.value[1],
            });
          }
          break;
        }
        default:
          break;
      }
    });
    return encodeURI(
      JSON.stringify(
        res
          .map((options: any, id: number) => ({ id, ...options }))
          .filter((e: any) => !!e)
      )
    );
  }
  public async getFiltersByCategory(
    category: App.Announcement.Category
  ): Promise<App.Response<any>> {
    return Axios.get("/car-filter", {
      params: {
        category,
      },
    })
      .then((response) => {
        response.data = response.data
          .map(this.parseFilterByType.bind(this))
          .filter((e: App.Announcement.FilterApi) => !!e)
          .filter((e: { id: number }) => !BANNED_FILTERS.includes(e.id));
        return handleResponse(response);
      })
      .catch(handleError);
  }
  private parseFilterByType(filter: any): App.Announcement.FilterApi | void {
    switch (filter.car_filter_type.name) {
      case "checkbox":
        return {
          id: filter.id,
          name: filter.name,
          type: "checkbox",
        };
      case "color-select":
        return {
          id: filter.id,
          name: filter.name,
          type: "color-picker",
          options: filter.options.map((e: any) => ({
            id: e.id,
            name: e.name,
            hex: e.hex,
          })),
        };
      case "range-input":
        return {
          id: filter.id,
          name: filter.name,
          type: "range",
          min: filter.min_value,
          max: filter.max_value,
        };
      case "multiselect":
        return {
          id: filter.id,
          name: filter.name,
          type: "multiselect",
          options: filter.options.map((e: any) => ({ id: e.id, name: e.name })),
        };
      case "select":
        return {
          id: filter.id,
          name: filter.name,
          type: "select",
          options: filter.options.map((e: any) => ({ id: e.id, name: e.name })),
        };
      default:
        return;
    }
  }
  public async addAnnouncement(
    data: any,
    session?: App.User.Session
  ): Promise<App.Response<App.Announcement.Announcement>> {
    const Authorization = await JWT.getAuth();
    const formData = serialize(data, {
      nullsAsUndefineds: false,
      indices: true,

      booleansAsIntegers: true,
      allowEmptyArrays: false,
    });

    return Axios.post("/announcement", formData, { headers: { Authorization } })
      .then(handleResponse)
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(data, this.addAnnouncement);
        }

        return handleError(response);
      });
  }
  public async updateAnnouncement(
    data: any,
    id: App.ID,
    session?: App.User.Session
  ): Promise<App.Response<App.Announcement.Announcement>> {
    const Authorization = await JWT.getAuth();

    data._method = "PUT";
    const formData = serialize(data, {
      nullsAsUndefineds: false,
      indices: true,

      booleansAsIntegers: true,
      allowEmptyArrays: false,
    });

    return Axios.post(`/announcement/${id}`, formData, {
      headers: { Authorization },
    })
      .then(handleResponse)
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(null, (trash, session) =>
            this.updateAnnouncement(data, id, session)
          );
        }

        return handleError(response);
      });
  }
  public async removeAnnouncement(
    id: App.ID,
    session?: App.User.Session
  ): Promise<App.Response<void>> {
    const Authorization = await JWT.getAuth();
    return Axios.delete(`/announcement/${id}`, { headers: { Authorization } })
      .then(handleResponse)
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(id, this.removeAnnouncement);
        }

        return handleError(response);
      });
  }
  public async toggleCheckedFlag(
    data: {
      id: App.ID;
      checked: boolean;
    },
    session?: App.User.Session
  ): Promise<App.Response<void>> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      `admin/announcement/inspect`,
      { announcement_id: data.id, inspected: data.checked },
      { headers: { Authorization } }
    )
      .then(handleResponse)
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(data, this.toggleCheckedFlag);
        }

        return handleError(response);
      });
  }
  public async saveSpecOffer(
    data: {
      id: App.ID;
      specialOffer: { from: Date | null; to: Date | null };
    },
    session?: App.User.Session
  ): Promise<App.Response<void>> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      `special-offer`,
      {
        announcement_id: data.id,
        from: data.specialOffer.from
          ? Math.floor(+data.specialOffer.from / Time.second)
          : data.specialOffer.from,
        to: data.specialOffer.to
          ? Math.floor(+data.specialOffer.to / Time.second)
          : data.specialOffer.to,
      },
      { headers: { Authorization } }
    )
      .then(handleResponse)
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(data, this.saveSpecOffer);
        }

        return handleError(response);
      });
  }
}

export default AnnouncementServiceApi;
