import AnnouncementBuyInfo from "../../../components/announcement/buyInfo/AnnouncementBuyInfo";
import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../struct/message/SMessage";
import { Unsubscribe } from "redux";
import UserService from "../../../services/abstract/UserService";
import UserServiceApi from "../../../services/api/UserServiceApi";
import { addMessage } from "../../../store/actions/messages";
import store from "../../../store/store";

interface Props {
  announcement?: App.Announcement.Announcement;
}
interface State {
  phoneNumber: string;
  BYN: number;
  USD: number;
  announcement?: App.Announcement.Announcement;
}

class AnnouncementBuyInfoContainer extends React.Component<Props, State> {
  private service: UserService;
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.service = new UserServiceApi();
    this.unsubscribe = () => {};
    this.state = {
      phoneNumber: "",
      BYN: 1,
      USD: store.getState().currency.USD.multiplier,
      announcement: props.announcement,
    };
  }
  static defaultProps = {
    className: "",
  };

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }
  public componentDidUpdate(props: Props) {
    if (
      this.props.announcement !== props.announcement ||
      this.props.announcement?.id !== props.announcement?.id
    ) {
      this.setState({ announcement: this.props.announcement });
    }
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  public get price() {
    return this.props.announcement?.price || 0;
  }
  private handleUpdateStore() {
    this.setState({
      USD: store.getState().currency.USD.multiplier,
    });
  }
  private async getPhoneNumber() {
    if (!this.props.announcement) return;
    const { data, status } = await this.service.getPhoneNumberForContact(
      this.props.announcement.contact.id
    );
    if (status === OK) {
      const announcement = Object.assign({}, this.props.announcement);
      announcement.contact.phone = data.toString();
      this.setState({ announcement });
    } else {
      store.dispatch(addMessage(new SMessage("Не удалось загрузить номер.")));
    }
  }

  public render() {
    return (
      <AnnouncementBuyInfo
        announcement={this.props.announcement}
        phoneNumber={this.state.phoneNumber}
        getPhoneNumber={this.getPhoneNumber.bind(this)}
      />
    );
  }
}

export default AnnouncementBuyInfoContainer;
