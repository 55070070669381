import "./AdministrateAnnouncementsChangeCheckedPopup.scss";

import LabelCheckbox from "../../../../../custom/labels/labelCheckbox/LabelCheckbox";
import Popup from "../../../../../custom/popup/Popup";
import React from "react";

interface Props {
  disabled: boolean;
  checked: boolean;
  onSubmit: (checked: boolean) => void;
  onClose: () => void;
}
interface State {
  checked: boolean;
}

class AdministrateAnnouncementsChangeCheckedPopup extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = { checked: props.checked };
  }

  private handleChangeChecked(checked: boolean) {
    this.setState({ checked });
  }

  public render() {
    return (
      <Popup
        className="administrate-announcements-change-checked-popup"
        onClose={this.props.onClose}
      >
        <h3>Смена статуса "Проверено"</h3>
        <LabelCheckbox
          value={this.state.checked}
          onChange={this.handleChangeChecked.bind(this)}
          name={"Проверено"}
          disabled={this.props.disabled}
        />
        <div className="buttons">
          <button
            onClick={() => this.props.onSubmit(this.state.checked)}
            disabled={this.props.disabled}
          >
            Сохранить
          </button>
          <button onClick={this.props.onClose} disabled={this.props.disabled}>
            Отмена
          </button>
        </div>
      </Popup>
    );
  }
}
export default AdministrateAnnouncementsChangeCheckedPopup;
