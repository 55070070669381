import "./UserAnnouncementLists.scss";

import React from "react";
import UserAnnouncementsContainer from "../../../containers/user/announcements/UserAnnouncementsContainer";
import UserFavoriteAnnouncementsContainer from "../../../containers/user/favoriteAnnouncements/UserFavoriteAnnouncementsContainer";

type Active = "self" | "favorite";
interface Props {}
interface State {
  active: Active;
}
const self: Active = "self";
const favorite: Active = "favorite";

class UserAnnouncementLists extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: self,
    };
  }

  public componentDidMount() {
    document.title = "Мои объявления";
    window.scrollTo({ top: 0 });
  }

  private changeActiveList(active: Active) {
    this.setState({ active });
    document.title = active === self ? "Мои объявления" : "Избранные";
  }

  public render() {
    return (
      <div className="users-announcemetns-list">
        <div className="users-announcemetns-list__nav">
          <span
            data-active={this.state.active === self}
            onClick={() => this.changeActiveList(self)}
          >
            Объявления
          </span>
          <span
            data-active={this.state.active === favorite}
            onClick={() => this.changeActiveList(favorite)}
          >
            Избранные
          </span>
        </div>
        {this.state.active === self && <UserAnnouncementsContainer />}
        {this.state.active === favorite && (
          <UserFavoriteAnnouncementsContainer />
        )}
      </div>
    );
  }
}

export default UserAnnouncementLists;
