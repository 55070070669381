import {
  ADVANCED_PAGE_ANNOUNCEMENT_LIST_CLASS_NAME,
  ADVANCED_PAGE_ANNOUNCEMENT_LIST_ID,
} from "../../../../Config";

import AnnouncementListContainer from "../../list/AnnouncementListContainer";
import AnnouncementListTagsController from "../../../../utils/tags/AnnouncementListTagsController";
import React from "react";
import { Unsubscribe } from "redux";
import { changeFilterAnnouncementsAdvanced } from "../../../../store/actions/announcements/advanced";
import { saveNumberOfResults } from "../../../../store/actions/announcements/advanced";
import store from "../../../../store/store";

interface Props {}
interface State {
  filters: App.Announcement.FiltersForSearch;
  page: number;
  sort: number;
  tags: Array<App.Tag>;
}

class AdvancedFiltersAnnouncementsList extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  private announcementListTagsController: AnnouncementListTagsController;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.announcementListTagsController = new AnnouncementListTagsController();
    this.state = {
      filters: store.getState().advanced.filters,
      page: 1,
      sort: 2,
      tags: [],
    };
  }

  public componentDidMount() {
    this.handleUpdateStore();
    this.setState({ tags: this.getTags() });
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  private handleUpdateStore() {
    this.setState((state) => {
      const filters = store.getState().advanced.filters;
      return {
        page: state.filters === filters ? state.page : 1,
        filters,
        tags: this.getTags(),
      };
    });
  }
  public handleChangePage(page: number) {
    this.setState({ page });
  }
  public handleChangeSort(sort: number) {
    this.setState({ page: 1, sort: sort });
  }
  private handleLoad(data: App.ListMetadata<App.Announcement.Announcement>) {
    store.dispatch(saveNumberOfResults(data.total));
  }
  public getTags(): Array<App.Tag> {
    const {
      main,
      visibility,
      salon,
      multimedia,
      safety,
      comfort,
      other,
    } = store.getState().filters;
    const filters: Array<App.Announcement.FilterApi> = main.concat(
      visibility,
      salon,
      multimedia,
      safety,
      comfort,
      other
    );

    return this.announcementListTagsController.getTags(
      this.state.filters.other,
      filters,
      (other) => {
        store.dispatch(
          changeFilterAnnouncementsAdvanced({
            car: this.state.filters.car,
            other,
          })
        );
      }
    );
  }

  private dropFilters() {
    store.dispatch(
      changeFilterAnnouncementsAdvanced({
        car: [],
        other: [],
      })
    );
  }

  public render() {
    return (
      <AnnouncementListContainer
        id={ADVANCED_PAGE_ANNOUNCEMENT_LIST_ID}
        className={ADVANCED_PAGE_ANNOUNCEMENT_LIST_CLASS_NAME}
        page={this.state.page}
        sort={this.state.sort}
        filters={this.state.filters}
        tags={this.state.tags}
        dropFilters={this.dropFilters.bind(this)}
        onChangePage={this.handleChangePage.bind(this)}
        onChangeSort={this.handleChangeSort.bind(this)}
        onLoad={this.handleLoad.bind(this)}
        errorMessage={
          this.state.filters.car.some((e) => e.brand) ||
          this.state.filters.other.length > 0
            ? "Попробуйте уменьшить колличество фильтров"
            : undefined
        }
      />
    );
  }
}

export default AdvancedFiltersAnnouncementsList;
