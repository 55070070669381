import "./Announcements.scss";

import AnnouncementsFiltersContainer from "../../containers/filters/announcementsFilters/AnnouncementsFiltersContainer";
import AnnouncementsPageAnnouncementsList from "../../containers/announcement/results/announcementsPage/AnnouncementsPageAnnouncementsList";
import React from "react";
import SearchByTextContainer from "../../containers/search/searchAnnouncment/SearchAnnouncementContainer";

interface Props {}
interface State {}

class AnnouncementsView extends React.Component<Props, State> {
  public componentDidMount() {
    document.title = "Объявления";
    window.scrollTo({ top: 0 });
  }
  public render() {
    return (
      <div className="announcements-view">
        <SearchByTextContainer />
        <div className="announcements-and-filters">
          <AnnouncementsFiltersContainer />
          <AnnouncementsPageAnnouncementsList />
        </div>
      </div>
    );
  }
}

export default AnnouncementsView;
