import React from "react";

class FullTypeImage extends React.Component {
  public render() {
    return (
      <div className="img">
        <div className="main">
          <div className="logo"></div>
          <div className="text">
            <div className="name"></div>
            <div className="text-stat">
              <div className="min description"></div>
              <div className="description"></div>
            </div>
            <div className="text-stat">
              <div className="min description"></div>
              <div className="description"></div>
            </div>
            <div className="text-stat">
              <div className="min description"></div>
              <div className="description"></div>
            </div>
            <div className="text-stat">
              <div className="min description"></div>
              <div className="description"></div>
            </div>
            <div className="text-stat">
              <div className="min description"></div>
              <div className="description"></div>
            </div>
          </div>
        </div>
        <div className="options">
          <div className="option-column">
            <div className="name"></div>
            <div className="description"></div>
            <div className="description"></div>
            <div className="description"></div>
            <div className="description"></div>
          </div>
          <div className="option-column">
            <div className="name"></div>
            <div className="description"></div>
            <div className="description"></div>
            <div className="description"></div>
            <div className="description"></div>
          </div>
          <div className="option-column">
            <div className="name"></div>
            <div className="description"></div>
            <div className="description"></div>
            <div className="description"></div>
            <div className="description"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default FullTypeImage;
