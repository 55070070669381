import "./LabelSearch.scss";

import React, { SyntheticEvent } from "react";

import LabelSearchOptions from "./options/Options";

export interface Props {
  onChange: (value: App.Info | null) => void;
  options: Array<App.Info>;
  selected: App.Info | null;
  name: string;
  disabled: boolean;
  placeholder?: boolean;
}
interface State {
  isOpen: boolean;
  isCurrent: boolean;
  keyword: string;
}

class LabelSearch extends React.Component<Props, State> {
  private handleClickOnWindow: () => void;
  constructor(props: Props) {
    super(props);
    this.handleClickOnWindow = () => {};
    this.state = {
      isOpen: false,
      isCurrent: false,
      keyword: "",
    };
  }

  static defaultProps = {
    disabled: false,
    selected: null,
  };

  public componentDidMount() {
    this.handleClickOnWindow = this.baseHandleClickOnWindow.bind(this);
    window.addEventListener("click", this.handleClickOnWindow);
  }
  public componentDidUpdate(props: Props) {
    if (this.props.selected === props.selected) return;
    this.setState({ keyword: this.getKeyword(this.props.selected) });
  }
  public componentWillUnmount() {
    window.removeEventListener("click", this.handleClickOnWindow);
  }

  public getKeyword(selected: App.Info | null) {
    if (selected) {
      return selected.name;
    } else {
      return "";
    }
  }

  public open() {
    this.setState({ isOpen: true });
  }
  public close() {
    this.setState({
      isOpen: false,
      keyword: this.getKeyword(this.props.selected),
    });
  }

  private handleChange(event: SyntheticEvent<HTMLInputElement>) {
    this.setState({
      keyword: event.currentTarget.value,
    });
  }
  private handleFocus() {
    this.open();
  }
  private handleBlur() {}
  private handleClick() {
    this.setState({ isCurrent: true });
  }
  private baseHandleClickOnWindow() {
    setTimeout(() => {
      if (!this.state.isCurrent) {
        this.close();
      } else {
        this.setState({
          isCurrent: false,
        });
      }
    });
  }
  private handleSelect(value: App.Info | null) {
    this.props.onChange(value);
    this.setState({ isOpen: false });
  }

  public render() {
    return (
      <div className="label-search" onClick={this.handleClick.bind(this)}>
        {!this.props.placeholder && (
          <div className="name">{this.props.name}</div>
        )}
        <input
          placeholder={this.props.placeholder ? this.props.name : ""}
          type="text"
          value={this.state.keyword}
          onChange={this.handleChange.bind(this)}
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          disabled={this.props.disabled}
        />
        {this.state.isOpen && (
          <LabelSearchOptions
            keyword={this.state.keyword}
            selected={this.props.selected}
            options={this.props.options}
            onChange={this.handleSelect.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default LabelSearch;
