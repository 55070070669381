import React, { SyntheticEvent } from "react";

import AdminPanelForm from "../form/AdminPanelForm";
import AdminPanelList from "../list/AdminPanelList";
import CarcassPickerContainer from "../../../containers/adminPanel/redactVersionContainer/carcassPickerContainer/CarcassPickerContainer";
import DrivePickerContainer from "../../../containers/adminPanel/redactVersionContainer/drivePickerContainer/DrivePickerContainer";
import LabelText from "../../custom/labels/labelText/LabelText";
import MotorPickerContainer from "../../../containers/adminPanel/redactVersionContainer/motorPickerContainer/MotorPickerContainer";
import ObjectMethods from "../../../utils/object/ObjectMethods";
import Title from "../../custom/title/Title";
import TransmissionPickerContainer from "../../../containers/adminPanel/redactVersionContainer/transmissionPickerContainer/TransmissionPickerContainer";
import { VersionData } from "../../../services/abstract/CarService";

interface Props {
  versions: Array<App.Car.Version>;
  onSave: (brandData: VersionData) => void;
  onDelete: () => Promise<boolean>;
  onSelectVersion: (version: App.Car.Version) => void;
  version?: App.Car.Version;
}
interface State {
  loading: boolean;
  isOpenPopupDelete: boolean;
  generationId: number;
  name: string;
  motor: App.ID;
  carcass: App.ID | null;
  drive: App.ID | null;
  transmission: App.ID | null;
}

class RedactVersion extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      isOpenPopupDelete: false,
      generationId: 0,
      name: "",
      motor: 0,
      carcass: null,
      drive: 0,
      transmission: null,
    };
  }

  public componentDidUpdate(props: Props) {
    if (!ObjectMethods.compare(props.version, this.props.version)) {
      if (this.props.version) {
        const {
          name,
          motor: { id: motor },
          carcass: { id: carcass },
          drive: { id: drive },
          transmission: { id: transmission },
          generationId,
        } = this.props.version;
        this.setState({
          name,
          motor,
          carcass,
          drive,
          transmission,
          generationId,
        });
      } else {
        this.setState({
          name: "",
          motor: 0,
          carcass: 0,
          drive: 0,
          transmission: 0,
          generationId: 0,
        });
      }
    }
  }

  private get correctName() {
    return !!(
      this.state.name.length && !this.state.name.match(/[^A-zА-я0-9-]/)
    );
  }
  private get uncorrectName() {
    return !!(this.state.name.length && this.state.name.match(/[^A-zА-я0-9-]/));
  }

  private get disabled() {
    return (
      this.state.loading ||
      !(
        this.correctName &&
        this.state.carcass &&
        this.state.drive &&
        this.state.transmission &&
        this.state.motor
      )
    );
  }

  private handleChangeName(name: string) {
    this.setState({ name });
  }
  private handleChangeDrive(drive: App.ID | null) {
    this.setState({ drive });
  }
  private handleChangeCarcass(carcass: App.ID | null) {
    this.setState({ carcass });
  }
  private handleChangeTransmission(transmission: App.ID | null) {
    this.setState({ transmission });
  }
  private handleChangeMotor(motor: App.ID) {
    this.setState({ motor });
  }

  private async handleClick() {
    this.setState({ loading: true });
    await this.props.onSave({
      name: this.state.name,
      generationId: this.state.generationId,
      drive: this.state.drive ? +this.state.drive : 0,
      carcass: this.state.carcass ? +this.state.carcass : 0,
      transmission: this.state.transmission ? +this.state.transmission : 0,
      motor: +this.state.motor,
    });
    this.setState({ loading: false });
  }

  private handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!this.disabled) {
      this.handleClick();
    }
  }
  private openDeletePopup(event: SyntheticEvent<HTMLButtonElement>) {
    event.preventDefault();
    this.setState({ isOpenPopupDelete: true });
  }
  private closeDeletePopup(event?: SyntheticEvent) {
    event?.preventDefault();
    this.setState({ isOpenPopupDelete: false });
  }
  private async deleteBrand(event?: SyntheticEvent) {
    event?.preventDefault();
    this.setState({ loading: true });
    const result = await this.props.onDelete();
    if (result) {
      this.setState({ loading: false, isOpenPopupDelete: false });
    } else {
      this.setState({ loading: false });
    }
  }

  public render() {
    return (
      <>
        <Title>Версия</Title>
        <AdminPanelList
          items={this.props.versions}
          selected={this.props.version?.id}
          onSelect={this.props.onSelectVersion}
        />
        <AdminPanelForm
          loading={this.state.loading}
          question="Удалить поколение?"
          isOpenPopup={this.state.isOpenPopupDelete}
          onSubmit={this.handleSubmit.bind(this)}
          onClose={this.closeDeletePopup.bind(this)}
          onDelete={this.deleteBrand.bind(this)}
        >
          <LabelText
            placeholder="Название"
            value={this.state.name}
            correct={this.correctName}
            uncorrect={this.uncorrectName}
            onChange={this.handleChangeName.bind(this)}
          />
          <MotorPickerContainer
            motor={this.state.motor}
            onChange={this.handleChangeMotor.bind(this)}
          />
          <CarcassPickerContainer
            value={this.state.carcass}
            onChange={this.handleChangeCarcass.bind(this)}
          />
          <TransmissionPickerContainer
            value={this.state.transmission}
            onChange={this.handleChangeTransmission.bind(this)}
          />
          <DrivePickerContainer
            value={this.state.drive}
            onChange={this.handleChangeDrive.bind(this)}
          />
          <div className="buttons">
            <button disabled={this.disabled}>Сохранить</button>
            <button
              className="remove"
              onClick={this.openDeletePopup.bind(this)}
              disabled={!this.props.version || this.state.loading}
            >
              Удалить
            </button>
          </div>
        </AdminPanelForm>
      </>
    );
  }
}
export default RedactVersion;
