import "./index.scss";
import "react-datepicker/dist/react-datepicker.css";

import * as serviceWorker from "./serviceWorker";

import Axios, { AxiosError } from "axios";
import { BrowserRouter, Route } from "react-router-dom";
import { addRequest, removeRequest } from "./store/actions/loading";
import { registerLocale, setDefaultLocale } from "react-datepicker";

import { BASE_URL } from "./Config";
import Footer from "./components/footer/Footer";
import Header from "./containers/header/HeaderContainer";
import MessagesContainer from "./containers/messages/MessagesContainer";
import PageLoaderContainer from "./containers/pageLoader/PageLoaderContainer";
import React from "react";
import ReactDOM from "react-dom";
import View from "./views/View";
import { loadCurrency } from "./store/actions/currency";
import { loadFilters } from "./store/actions/filters";
import ru from "date-fns/locale/ru";
import store from "./store/store";

registerLocale("ru", ru);
setDefaultLocale("ru");

initAxios();
loadFiltersAndCurrency();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header />
      <Route component={View} />
      <Footer />
      <MessagesContainer />
      <PageLoaderContainer />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();

function initAxios() {
  Axios.defaults.baseURL = BASE_URL;
  Axios.interceptors.request.use((config) => {
    store.dispatch(addRequest());
    return config;
  });
  Axios.interceptors.response.use(
    (response) => {
      store.dispatch(removeRequest());
      return response;
    },
    (error: AxiosError<any>) => {
      store.dispatch(removeRequest());
      throw error.response;
    }
  );
}

function loadFiltersAndCurrency() {
  store.dispatch(loadFilters());
  store.dispatch(loadCurrency());
}
