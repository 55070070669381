import "./UserSearchHistory.scss";

import React from "react";
import UserSearchHistoryPage from "./historyPage/UserSearchHistoryPage";

interface Props {
  history: Array<App.FiltersHistory>;
  isEmpty: boolean;
  filters: Array<App.Announcement.FilterApi>;
}
interface State {}

class UserSearchHistory extends React.Component<Props, State> {
  public render() {
    return (
      <div className="user-search-history">
        {this.props.isEmpty && (
          <div className="user-search-history-empty">Истоия поиска пуста</div>
        )}
        {this.props.history.map((h) => (
          <UserSearchHistoryPage
            key={h.id}
            hash={h.hash}
            filters={h.filters}
            date={h.date}
            filtersList={this.props.filters}
          />
        ))}
      </div>
    );
  }
}

export default UserSearchHistory;
