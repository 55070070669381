import "./NotFoundView.scss";

import { NavLink, RouteComponentProps } from "react-router-dom";
import {
  announcements,
  compareCar,
  filters,
  home,
  notFound,
  signin,
  userAnnouncements,
} from "../../routes";

import NotFoundImage from "./assets/not-found-image.png";
import React from "react";
import store from "../../store/store";

interface Props extends RouteComponentProps {}
interface State {
  isAuth: boolean;
}

class NotFoundView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isAuth: store.getState().user.isAuth,
    };
  }

  public componentDidMount() {
    if (this.props.location.pathname !== notFound)
      this.props.history.replace(notFound);
  }
  public render() {
    return (
      <div className="not-found-view">
        <div className="text">
          <h1>Ой!</h1>
          <h2>Похоже, мы не можем найти нужную вам страницу</h2>
          <h3>Код ошибки: 404</h3>
          <nav>
            <p>Вот несколько полезных ссылок:</p>
            <NavLink to={home}>Главная</NavLink>
            <NavLink to={announcements}>Обьявления</NavLink>
            <NavLink to={filters}>Расширенный фильтр</NavLink>
            <NavLink to={compareCar}>Сравнить авто</NavLink>

            {this.state.isAuth ? (
              <NavLink to={userAnnouncements}>Войти в личный кабинет</NavLink>
            ) : (
              <NavLink to={signin}>Вход</NavLink>
            )}
          </nav>
        </div>
        <img src={NotFoundImage} alt="" />
      </div>
    );
  }
}
export default NotFoundView;
