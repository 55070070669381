import ArrowDownIcon from "../../../../../assets/icons/gray-arrow-down.svg";
import React from "react";

interface Props {
  name: string;
  optionsNumber: number;
  isOpen: boolean;
  onClick: () => void;
}
interface State {}

class AnnouncementOptionName extends React.Component<Props, State> {
  public render() {
    return (
      <div className="name" onClick={this.props.onClick}>
        <h4>{this.props.name}</h4>
        <div>
          <span>Кол-во опций: {this.props.optionsNumber}</span>
          <img
            className="drop-down"
            src={ArrowDownIcon}
            data-open={this.props.isOpen}
            alt=""
          />
        </div>
      </div>
    );
  }
}

export default AnnouncementOptionName;
