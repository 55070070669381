import "./UserSearchHistoryPage.scss";

import AnnouncementListTagsController from "../../../../utils/tags/AnnouncementListTagsController";
import { NavLink } from "react-router-dom";
import PlayArrow from "./playArrow/PlayArrow";
import React from "react";
import Tag from "../../../tag/Tag";
import Time from "../../../../utils/time/Time";
import { announcements } from "../../../../routes";

interface Props {
  filters: App.AdvancedFilter.SelectedFilter;
  hash: string;
  filtersList: Array<App.Announcement.FilterApi>;
  date: Date;
}
interface State {
  isOpen: boolean;
  page: number;
  sort: number;
  tags: Array<App.Tag>;
  ref: React.RefObject<HTMLDivElement>;
}

class UserSearchHistoryPage extends React.Component<Props, State> {
  private announcementListTagsController: AnnouncementListTagsController;
  constructor(props: Props) {
    super(props);
    this.announcementListTagsController = new AnnouncementListTagsController();
    this.state = {
      isOpen: false,
      page: 1,
      sort: 2,
      ref: React.createRef(),
      tags: [],
    };
  }

  public componentDidUpdate(prevProps: Props) {}

  private handleChangePage(page: number) {
    this.goUp();
    this.setState({ page });
  }
  private handleChangeSort(sort: number) {
    this.goUp();
    this.setState({ page: 1, sort });
  }
  private goUp() {
    window.scrollTo({
      behavior: "smooth",
      top: (this.state.ref.current?.getBoundingClientRect().top || 0) + 100,
    });
  }
  private open() {
    this.setState({ isOpen: true });
  }

  private getTags(): Array<App.Tag> {
    try {
      return this.announcementListTagsController.getTags(
        this.props.filters,
        this.props.filtersList
      );
    } catch {
      return [];
    }
  }

  public render() {
    return (
      <div className="user-search-history-page" ref={this.state.ref}>
        <div className="user-search-history-page__head">
          <div className="date">{Time.beautifyDate(this.props.date)}</div>
          <div className="tags">
            {this.getTags().map((tag, tagIndex) => (
              <Tag key={tagIndex} {...tag} />
            ))}
          </div>
          <NavLink
            to={
              announcements +
              `#{"car":[{"brand":0,"model":null}],"other":${JSON.stringify(
                this.props.filters
              )}}`
            }
            target="_blank"
            className="show"
            // onClick={this.open.bind(this)}
          >
            <PlayArrow />
          </NavLink>
        </div>
        {/* {this.state.isOpen && (
          <AnnouncementListContainer
            tags={[]}
            page={this.state.page}
            sort={this.state.sort}
            filters={{ car: [], other: this.props.filters }}
            onChangePage={this.handleChangePage.bind(this)}
            onChangeSort={this.handleChangeSort.bind(this)}
          />
        )} */}
      </div>
    );
  }
}

export default UserSearchHistoryPage;
