import "./SearchByText.scss";

import React, { SyntheticEvent } from "react";

import AnnouncementInfirmationCard from "../../informationCard/AnnouncementInfirmationCard";
import LabelText from "../../../custom/labels/labelText/LabelText";
import SearchIcon from "../../../../assets/icons/search.svg";

interface Props {
  keyword: string;
  loading: boolean;
  announcments: Array<App.Announcement.Announcement>;
  handleChangeKeyword: (keyword: string) => void;
  onSearch: () => void;
}
interface State {
  isOpen: boolean;
}

class SearchByText extends React.Component<Props, State> {
  private windowHandler: () => void;
  constructor(props: Props) {
    super(props);
    this.windowHandler = this.close.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  public componentDidMount() {
    window.addEventListener("click", this.windowHandler);
  }
  public componentWillUnmount() {
    window.removeEventListener("click", this.windowHandler);
    this.close();
  }

  private open(event?: SyntheticEvent) {
    event?.stopPropagation();
    document.body.classList.add("disabled-window");
    this.setState({
      isOpen: true,
    });
  }
  private close(event?: SyntheticEvent) {
    event?.stopPropagation();
    document.body.classList.remove("disabled-window");
    this.setState({
      isOpen: false,
    });
  }

  public render() {
    return (
      <div
        className="search-by-text search"
        onClick={this.open.bind(this)}
        data-load={this.props.loading}
      >
        <div className="input">
          <LabelText
            value={this.props.keyword}
            placeholder="Поиск по объявлениям"
            onChange={this.props.handleChangeKeyword}
          />
          <button
            className="button-addition"
            title="Искать"
            onClick={this.props.onSearch}
          >
            <img src={SearchIcon} alt="" />
          </button>
        </div>

        {this.props.announcments.length > 0 && this.state.isOpen && (
          <>
            <div className="shadow" onClick={this.close.bind(this)}></div>
            <div className="results">
              {this.props.announcments.map((annnouncement) => (
                <AnnouncementInfirmationCard announcement={annnouncement} />
              ))}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default SearchByText;
