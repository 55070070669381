import AnnouncementContact from "../../../components/announcement/contact/Contact";
import { BYN_ID } from "../../../Config";
import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../struct/message/SMessage";
import UserService from "../../../services/abstract/UserService";
import UserServiceApi from "../../../services/api/UserServiceApi";
import { addMessage } from "../../../store/actions/messages";
import store from "../../../store/store";

interface Props {
  announcement: App.Announcement.Announcement;
  updateAnnouncement: (announcement: App.Announcement.Announcement) => void;
  currency: App.ID;
}
interface State {
  phoneNumber: string;
}

class AnnouncementContactContainer extends React.Component<Props, State> {
  private service: UserService;
  constructor(props: Props) {
    super(props);
    this.service = new UserServiceApi();
    this.state = { phoneNumber: "" };
  }

  private async getPhoneNumber() {
    if (
      !this.props.announcement.contact.id ||
      this.props.announcement.contact.phone
    )
      return;
    const { data, status } = await this.service.getPhoneNumberForContact(
      this.props.announcement.contact.id
    );
    if (status === OK) {
      this.props.updateAnnouncement(
        Object.assign(this.props.announcement, {
          contact: { ...this.props.announcement.contact, phone: data },
        })
      );
    } else {
      store.dispatch(addMessage(new SMessage("Не удалось загрузить номер.")));
    }
  }

  public render() {
    return (
      <AnnouncementContact
        getPhoneNumber={this.getPhoneNumber.bind(this)}
        spec={this.props.announcement.spec}
        contact={this.props.announcement.contact}
        price={
          this.props.currency === BYN_ID
            ? this.props.announcement.BYN
            : this.props.announcement.USD
        }
        currency={this.props.currency}
      />
    );
  }
}

export default AnnouncementContactContainer;
