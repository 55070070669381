import React from "react";

interface Props {
  picked: App.ID;
  demensions: Array<App.Info>;
  onChange: (picked: App.ID) => void;
}
interface State {}

class LabelNumberDemension extends React.Component<Props, State> {
  private get name() {
    return (
      this.props.demensions.find((e) => e.id === this.props.picked)?.name ||
      "***"
    );
  }

  private selectNext() {
    let index = -1;
    this.props.demensions.forEach((e, i) => {
      if (e.id === this.props.picked) {
        index = i;
      }
    });
    if (index < 0 || ++index === this.props.demensions.length) {
      index = 0;
    }
    this.props.onChange(this.props.demensions[index].id);
  }

  public render() {
    return (
      <div className="demension">
        <div className="picked" onClick={this.selectNext.bind(this)}>
          {this.name}
        </div>
      </div>
    );
  }
}

export default LabelNumberDemension;
