import "./AnnouncementViewBody.scss";

import AnnouncementStats from "../../../components/announcement/stats/AnnouncementStats";
import AnnouncementViewBodyInfo from "./info/AnnouncementViewBodyInfo";
import Carousel from "../../../components/carousel/Carousel";
import React from "react";

interface Props {
  announcement?: App.Announcement.Announcement;
}
interface State {}

class AnnouncementViewBody extends React.Component<Props, State> {
  public render() {
    return (
      <div className="announcement-view-body">
        <div className="main">
          <Carousel
            images={this.props.announcement?.images.map((e) => e.path) || []}
          />
          <AnnouncementStats announcement={this.props.announcement} />
        </div>
        <AnnouncementViewBodyInfo announcement={this.props.announcement} />
      </div>
    );
  }
}

export default AnnouncementViewBody;
