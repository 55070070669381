class SColor implements App.Car.Color {
  public id: number;
  public name: string;
  public hex: string;

  constructor(data?: any) {
    this.id = 0;
    this.name = "";
    this.hex = "";
    data && this.init(data);
  }

  private init(data: any) {
    try {
      this.id = data.id || 0;
      this.name = data.color_name || "";
      this.hex = data.color_hex || "";
    } catch {}
  }
}

export default SColor;
