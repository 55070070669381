import React from "react";

interface Props {
  active: App.DisplayType;
  onClick: (type: App.DisplayType) => void;
}
interface State {}

class TileDisplayType extends React.Component<Props, State> {
  static type: App.DisplayType = "tile";
  static defaultProps = {
    onClick() {},
  };

  private handleClick() {
    this.props.onClick(TileDisplayType.type);
  }

  public render() {
    return (
      <svg
        onClick={this.handleClick.bind(this)}
        className="display-type"
        width="20"
        height="20"
        // viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.6309 0H25V8.77978H17.6309V0ZM8.81543 8.77978V0H16.1846V8.77978H8.81543ZM17.6309 19V10.2202H25V19H17.6309ZM8.81543 19V10.2202H16.1846V19H8.81543ZM0 19V10.2202H7.36915V19H0ZM0 8.77978V0H7.36915V8.77978H0Z"
          fill={
            this.props.active === TileDisplayType.type ? "#12BBA4" : "#666666"
          }
        />
      </svg>
    );
  }
}
export default TileDisplayType;
