import CurrencyPicker from "../../../components/currency/picker/CurrencyPicker";
import React from "react";
import { Unsubscribe } from "redux";
import store from "../../../store/store";

interface Props {
  selected: App.ID;
  onChange: (id: App.ID) => void;
}
interface State {
  currencyList: Array<App.Currency.Currency>;
}

class CurrencyPickerContainer extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      currencyList: this.getCurrencyFromStore(),
    };
  }

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }

  public componentWillUnmount() {
    this.unsubscribe();
  }
  private handleUpdateStore() {
    this.setState({
      currencyList: this.getCurrencyFromStore(),
    });
  }
  private getCurrencyFromStore() {
    const currencyStore = store.getState().currency;
    return [currencyStore.BYN, currencyStore.USD];
  }

  private handleChange(id: App.ID | null) {
    if (id) {
      this.props.onChange(id);
    }
  }

  public render() {
    return (
      <CurrencyPicker
        selected={this.props.selected}
        currencyList={this.state.currencyList}
        onChange={this.handleChange.bind(this)}
      />
    );
  }
}

export default CurrencyPickerContainer;
