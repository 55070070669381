import "./AnnouncementListBody.scss";

import AnnouncementCard from "../../card/AnnouncementCard";
import AnnouncementInfirmationCard from "../../../../components/announcement/informationCard/AnnouncementInfirmationCard";
import AnnouncementListBodyLoader from "./announcementListBodyLoader/AnnouncementListBodyLoader";
import React from "react";

interface Props {
  announcements: Array<App.Announcement.Announcement>;
  updateAnnouncement: (announcement: App.Announcement.Announcement) => void;
  displayType: App.DisplayType;
  currency: App.ID;
  loading?: boolean;
}
interface State {}

class AnnouncementListBody extends React.Component<Props, State> {
  private get items() {
    if (this.props.children) {
      return this.props.children;
    } else if (this.props.displayType === "tile") {
      return this.tile;
    } else {
      return this.list;
    }
  }
  private get list() {
    return this.props.announcements.map((announcement) => (
      <AnnouncementInfirmationCard
        currency={this.props.currency}
        key={announcement.id}
        announcement={announcement}
        updateAnnouncement={this.props.updateAnnouncement}
      />
    ));
  }
  private get tile() {
    return this.props.announcements.map((announcement) => (
      <AnnouncementCard
        key={announcement.id}
        announcement={announcement}
        currency={this.props.currency}
      />
    ));
  }

  public render() {
    return (
      <div
        className="announcement-list__body"
        data-display={this.props.displayType}
        data-loading={this.props.loading}
      >
        {this.props.loading && <AnnouncementListBodyLoader />}
        {this.items}
      </div>
    );
  }
}

export default AnnouncementListBody;
