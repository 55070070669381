import "./Header.scss";

import AdvancedFilterHeaderResult from "./result/Result";
import AdvancedFilterHeaderTabs from "./tabs/Tabs";
import React from "react";

interface Props {}
interface State {}

class AdvancedFilterHeader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="advanced-filter__header">
        <AdvancedFilterHeaderTabs />
        <AdvancedFilterHeaderResult />
      </div>
    );
  }
}

export default AdvancedFilterHeader;
