import { handleError, handleResponse } from "./methods";

import Axios from "axios";
import YandexService from "../abstract/YandexService";

export default class YandexServiceApi extends YandexService {
  private format: string;
  private apikey: string;
  private lang: string;
  constructor() {
    super();
    this.format = "json";
    this.apikey = "b18ab33b-89e8-444d-9a8b-46a747b5e957";
    this.lang = "ru_RU";
  }
  public async checkCity(city: string): Promise<App.Response<any>> {
    return handleResponse(
      await Axios.get("https://geocode-maps.yandex.ru/1.x/", {
        params: {
          format: this.format,
          apikey: this.apikey,
          lang: this.lang,
          geocode: city,
        },
      })
        .then(handleResponse)
        .catch(handleError)
    );
  }
}
