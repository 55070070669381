import "./AddPhotos.scss";

import Photo from "./photo/Photo";
import React from "react";

interface Props {
  photos: Array<File | string>;
  main: string | number;
  maxNumberPhotos: number;

  maxEmpty: number;
  minEmpty: number;
  onChange: (photos: Array<File | string>) => void;
}
interface State {}

class AddPhotos extends React.Component<Props, State> {
  static defaultProps = {
    maxNumberPhotos: 30,
    maxEmpty: 6,
    minEmpty: 1,
  };

  private get photos() {
    const numberPhotos = Math.min(
      this.props.maxNumberPhotos - this.props.photos.length,
      Math.max(
        this.props.minEmpty,
        this.props.maxEmpty - this.props.photos.length
      )
    );
    const empty: Array<File> = new Array(numberPhotos).fill("");
    return this.props.photos.concat(empty);
  }

  public async addPhoto(photo: File | Array<File>) {
    this.props.onChange(this.props.photos.concat(photo));
  }
  public async changePhoto(index: number, photo: File) {
    this.props.onChange(
      this.props.photos.map((e, i) => (index === i ? photo : e))
    );
  }

  public removePhoto(index: number) {
    this.props.onChange(this.props.photos.filter((e, i) => index !== i));
  }

  public setMain(index: number) {
    if (this.props.photos[index]) {
      this.props.onChange(
        [this.props.photos[index]].concat(
          this.props.photos.slice(0, index),
          this.props.photos.slice(index + 1)
        )
      );
    }
  }

  public render() {
    return (
      <div className="add-photos">
        {this.photos.map((photo, index) => (
          <Photo
            key={index}
            photo={photo}
            main={index === this.props.main}
            index={index}
            add={this.addPhoto.bind(this)}
            change={this.changePhoto.bind(this)}
            remove={this.removePhoto.bind(this)}
            setMain={this.setMain.bind(this)}
          />
        ))}
      </div>
    );
  }
}

export default AddPhotos;
