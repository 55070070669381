class SMotor implements App.Car.Motor {
  public id: number;
  public power: number;
  public volume: number;
  public type: string;
  public typeId: number;

  constructor(data?: any) {
    this.id = 0;
    this.typeId = 0;
    this.type = "";
    this.power = 0;
    this.volume = 0;
    data && this.init(data);
  }

  private init(data: any) {
    try {
      this.id = data.id;
      this.typeId = data.car_motor_type.id;
      this.type = data.car_motor_type.name;
      this.power = data.power_horse;
      this.volume = data.volume_liters;
    } catch {}
  }
}

export default SMotor;
