import "./LabelNumber.scss";

import React, { SyntheticEvent } from "react";

import LabelNumberControlls from "./controlls/Controlls";
import LabelNumberDemension from "./demension/Demension";
import StringMethods from "../../../../utils/string/StringMethods";

interface Props {
  value?: number;
  onChange: (value: number) => void;
  onBlur: (event: SyntheticEvent<HTMLInputElement>) => void;
  onChangeDimension: (dimension: App.ID) => void;
  placeholder: string;
  step: number;
  controlls: boolean;
  order: number;
  min: number;
  max: number;
  beautify: boolean;
  className: string;
  correct?: boolean;
  uncorrect?: boolean;
  name?: string;
  dimensions: Array<App.Info>;
  dimension?: App.ID;
  maxLength?: number;
  minLength?: number;
}
interface State {
  value: string;
  focus: boolean;
}

class LabelNumber extends React.Component<Props, State> {
  private timeoutForSaveValueAfterChange: number;
  constructor(props: Props) {
    super(props);
    this.state = {
      value: "",
      focus: false,
    };
    this.timeoutForSaveValueAfterChange = 0;
  }

  static defaultProps = {
    placeholder: "",
    className: "",
    onChange() {},
    onChangeDimension() {},
    onBlur() {},
    beautify: true,
    step: 1,
    order: 0,
    dimensions: [],
    controlls: false,
    min: -Infinity,
    max: Infinity,
  };

  static allowedKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
    ",",
    "-",
    "Backspace",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
  ];
  static delayForPresentValue = 700;

  public componentDidMount() {
    if (this.props.value) {
      this.setState({ value: this.props.value.toString() });
    }
  }
  public componentDidUpdate(props: Props) {
    if (this.props.value === props.value) return;
    this.setState({ value: this.props.value?.toString() || "" });
  }

  private get value() {
    return this.state.focus
      ? this.state.value.toString()
      : this.present(+this.state.value || 0);
  }
  private up() {
    const value: number = +this.state.value + this.props.step;
    this.delayChange(this.checkBorders(value).toString() || "");
  }
  private down() {
    const value: number = +this.state.value - this.props.step;
    this.delayChange(this.checkBorders(value).toString() || "");
  }

  private checkBorders(value: number) {
    value = Math.max(value, this.props.min);
    value = Math.min(value, this.props.max);
    return value;
  }
  private present(value: number = 0) {
    if (this.props.beautify) {
      return StringMethods.beautifyNumber(value, this.props.order);
    } else {
      return value;
    }
  }

  private handleKeyDown(
    event: SyntheticEvent<HTMLInputElement, KeyboardEvent>
  ) {
    if (!LabelNumber.allowedKeys.includes(event.nativeEvent.key)) {
      event.preventDefault();
    }
  }
  private handleChange(event: SyntheticEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;
    this.delayChange(value);
  }
  private delayChange(value: string) {
    this.setState({ value });
    clearTimeout(this.timeoutForSaveValueAfterChange);
    this.timeoutForSaveValueAfterChange = window.setTimeout(() => {
      this.setState({
        value: this.checkBorders(parseFloat(value) || 0).toString(),
      });
      this.props.onChange(this.checkBorders(parseFloat(value) || 0));
    }, LabelNumber.delayForPresentValue);
  }

  private handleFocus() {
    this.setState({
      focus: true,
    });
  }
  private handleBlur(event: SyntheticEvent<HTMLInputElement>) {
    this.setState({
      focus: false,
    });
    this.props.onBlur(event);
  }

  public render() {
    return (
      <div className="label-number">
        {this.props.name && <div className="name">{this.props.name}</div>}
        <div className="input">
          <input
            data-correct={this.props.correct}
            data-uncorrect={this.props.uncorrect}
            onFocus={this.handleFocus.bind(this)}
            onBlur={this.handleBlur.bind(this)}
            type="text"
            placeholder={this.props.placeholder}
            value={this.value}
            onKeyDown={this.handleKeyDown.bind(this)}
            onChange={this.handleChange.bind(this)}
            maxLength={this.props.maxLength || 10}
            minLength={this.props.minLength}
          />
          {(this.props.dimension || this.props.controlls) && (
            <div className="addition">
              {!!this.props.dimension && (
                <LabelNumberDemension
                  picked={this.props.dimension}
                  demensions={this.props.dimensions}
                  onChange={this.props.onChangeDimension}
                />
              )}
              {this.props.controlls && (
                <LabelNumberControlls
                  up={this.up.bind(this)}
                  down={this.down.bind(this)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default LabelNumber;
