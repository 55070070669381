import "./AnnouncementFiltersButtons.scss";

import { NavLink } from "react-router-dom";
import React from "react";
import { filters } from "../../../../routes";

interface Props {
  onSearch: () => void;
}
interface State {}

class AnnouncementFiltersButtons extends React.Component<Props, State> {
  public render() {
    return (
      <div className="announcements-filters__buttons">
        <button className="search" onClick={this.props.onSearch}>
          Найти
        </button>
        <NavLink className="all-filters" to={filters}>
          <button>Расширеный фильтр</button>
        </NavLink>
      </div>
    );
  }
}

export default AnnouncementFiltersButtons;
