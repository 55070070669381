import AnnouncementList from "../../../components/announcement/list/AnnouncementList";
import AnnouncementService from "../../../services/abstract/AnnouncementService";
import AnnouncementServiceApi from "../../../services/api/AnnouncementServiceApi";
import ControllAnnouncement from "../../../components/announcement/controllAnnouncement/ControllAnnouncement";
import { OK } from "http-status-codes";
import Popup from "../../../components/custom/popup/Popup";
import React from "react";
import SMessage from "../../../struct/message/SMessage";
import UserService from "../../../services/abstract/UserService";
import UserServiceApi from "../../../services/api/UserServiceApi";
import { addMessage } from "../../../store/actions/messages";
import store from "../../../store/store";

interface Props {}
interface State {
  page: number;
  totalPage: number;
  sort: number;
  announcements: Array<App.Announcement.Announcement>;
  forDelete: App.ID | null;
}

class UserAnnouncementsContainer extends React.Component<Props, State> {
  private service: UserService;
  private announcementService: AnnouncementService;
  constructor(props: Props) {
    super(props);
    this.service = new UserServiceApi();
    this.announcementService = new AnnouncementServiceApi();
    this.state = {
      page: 1,
      totalPage: 1,
      sort: 2,
      announcements: [],
      forDelete: null,
    };
  }

  public componentDidMount() {
    this.search();
  }
  private handleChangePage(page: number) {
    this.setState({ page });
    window.setTimeout(() => this.search());
  }
  private async search() {
    const { data, status, errors } = await this.service.getUserAnnouncements(
      this.state.page,
      this.state.sort
    );
    if (status === OK) {
      this.setState({
        announcements: data.items,
        totalPage: data.totalPage,
        page: data.page,
      });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }

  private askDelete(id: App.ID | null) {
    this.setState({ forDelete: id });
  }

  private async delete() {
    if (!this.state.forDelete) return;
    const {
      status,
      errors,
    } = await this.announcementService.removeAnnouncement(this.state.forDelete);
    if (status === OK) {
      this.search();
      this.setState({ forDelete: null });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }

  public render() {
    return (
      <>
        <AnnouncementList
          className="user-announcements"
          displayHeader={false}
          page={this.state.page}
          totalPage={this.state.totalPage}
          onChangePage={this.handleChangePage.bind(this)}
        >
          {this.state.announcements.map((announcement) => (
            <ControllAnnouncement
              key={announcement.id}
              announcement={announcement}
              delete={this.askDelete.bind(this)}
            />
          ))}
        </AnnouncementList>
        {this.state.forDelete && (
          <Popup className="ask-delete">
            <h3>Вы действительно хотите удалить объявление?</h3>
            <div className="buttons">
              <button onClick={this.delete.bind(this)}>Да</button>
              <button onClick={() => this.askDelete(null)}>Нет</button>
            </div>
          </Popup>
        )}
      </>
    );
  }
}

export default UserAnnouncementsContainer;
