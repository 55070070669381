import "./PopularBrands.scss";

import BrandLogo from "./logo/BrandLogo";
import React from "react";
import SubTitle from "../../custom/subtitle/SubTitle";

interface Props {
  brands: Array<App.Car.Brand>;
  onClick: (brandId: number) => void;
}
interface State {}

class PopularBrands extends React.Component<Props, State> {
  public render() {
    return (
      <div className="popular-brands">
        <SubTitle className="popular-brands-title">
          Популярные марки автомобилей:
        </SubTitle>
        <div className="popular-brands-list">
          {this.props.brands.map((brand) => (
            <BrandLogo
              key={brand.id}
              brand={brand}
              onClick={this.props.onClick}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default PopularBrands;
