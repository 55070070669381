import "./AnnouncementInfirmationCard.scss";

import AnnouncementActionsContainer from "../../../containers/announcement/actions/AnnouncementActionsContainer";
import AnnouncementCardCarousel from "../card/carousel/Carousel";
import AnnouncementCardInfo from "../card/info/AnnouncementCardInfo";
import AnnouncementContactContainer from "../../../containers/announcement/contact/AnnouncementContactContainer";
import AnnouncementName from "../../car/name/AnnouncementName";
import AnnouncementStats from "../../car/stats/Stats";
import { BYN_ID } from "../../../Config";
import React from "react";
import Time from "../../../utils/time/Time";

interface Props {
  announcement: App.Announcement.Announcement;
  updateAnnouncement: (announcement: App.Announcement.Announcement) => void;
  currency: App.ID;
}
interface State {
  announcement: App.Announcement.Announcement;
}

class AnnouncementInfirmationCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      announcement: props.announcement,
    };
  }
  static defaultProps = {
    currency: BYN_ID,
    updateAnnouncement() {},
  };
  private handleUpdateAnnouncement(
    announcement: App.Announcement.Announcement
  ) {
    this.setState({ announcement });
  }
  public componentDidUpdate(prevProps: Props) {
    if (this.props.announcement !== prevProps.announcement) {
      this.setState({ announcement: this.props.announcement });
    }
  }
  public render() {
    return (
      <div className="information-card">
        <div className="information-card-image">
          <AnnouncementActionsContainer
            informationCard
            onUpdateAnnouncement={this.handleUpdateAnnouncement.bind(this)}
            announcement={this.state.announcement}
          />
          <AnnouncementCardCarousel
            id={this.state.announcement.id}
            images={this.state.announcement.images.map((e) => e.path)}
          />
        </div>
        <div className="information-card-info">
          <div className="information-card-info-text">
            <AnnouncementName announcement={this.state.announcement} />
            <AnnouncementStats announcement={this.state.announcement} />
            <AnnouncementCardInfo announcement={this.state.announcement} />
            {this.state.announcement.description.length !== 0 && (
              <p className="description">
                {this.state.announcement.description}
              </p>
            )}
            <div className="date-create">
              {Time.beautifyDate(this.state.announcement.created)}
            </div>
            <br />
            {/* {this.state.announcement.checked && <CheckedAuto />} */}
          </div>
          <AnnouncementContactContainer
            announcement={this.state.announcement}
            updateAnnouncement={this.props.updateAnnouncement}
            currency={this.props.currency}
          />
        </div>
      </div>
    );
  }
}

export default AnnouncementInfirmationCard;
