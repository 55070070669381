import Axios, { AxiosResponse } from "axios";
import CarService, {
  BrandData,
  GenerationData,
  ModelData,
  MotorData,
  VersionData,
} from "../abstract/CarService";
import { handle403, handleError, handleResponse } from "./methods";

import { FORBIDDEN } from "http-status-codes";
import JWT from "../../utils/JWT/JWT";
import SBrand from "../../struct/brand/SBrand";
import SCarcass from "../../struct/carcass/SCarcass";
import SDrive from "../../struct/drive/SDrive";
import SGeneration from "../../struct/generation/SGeneration";
import SModel from "../../struct/model/SModel";
import SMotor from "../../struct/motor/SMotor";
import STransmission from "../../struct/transmission/STransmission";
import SVersion from "../../struct/version/SVersion";
import { serialize } from "object-to-formdata";

class CarServiceApi extends CarService {
  public async getBrands(): Promise<App.Response<Array<App.Car.Brand>>> {
    return Axios.get("/brand")
      .then((response) => {
        response.data = response.data.map((e: any) => new SBrand(e));
        return handleResponse(response);
      })
      .catch(handleError);
  }
  public async getBrand(id: App.ID): Promise<App.Response<App.Car.Brand>> {
    return Axios.get(`/brand/${id}`)
      .then((response) => {
        response.data = response.data.map((e: any) => new SBrand(e));
        return handleResponse(response);
      })
      .catch(handleError);
  }
  public async addBrand(
    data: BrandData,
    session?: App.User.Session
  ): Promise<App.Response<App.Car.Brand>> {
    const Authorization = await JWT.getAuth();
    return Axios.post("/brand", serialize(data, { booleansAsIntegers: true }), {
      headers: { Authorization },
    })
      .then((response) => {
        response.data = new SBrand(response.data);
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(data, this.addBrand);
        }
        return handleError(response);
      });
  }
  public async updateBrand(
    id: App.ID,
    data: BrandData,
    session?: App.User.Session
  ): Promise<App.Response<App.Car.Brand>> {
    const Authorization = await JWT.getAuth();
    const method = new FormData();
    method.append("_method", "PUT");
    return Axios.post(
      `/brand/${id}`,
      serialize(data, { booleansAsIntegers: true }, method),
      {
        headers: { Authorization },
      }
    )
      .then((response) => {
        response.data = new SBrand(response.data);
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(null, (empty: null, session: App.User.Session) =>
            this.updateBrand(id, data, session)
          );
        }
        return handleError(response);
      });
  }
  public async deleteBrand(
    id: App.ID,
    session?: App.User.Session
  ): Promise<App.Response<void>> {
    const Authorization = await JWT.getAuth();
    return Axios.delete(`/brand/${id}`, {
      headers: { Authorization },
    })
      .then((response) => {
        return Axios.delete(`/brand/${id}`, {
          headers: { Authorization },
          data: response.data,
        })
          .then((response) => {
            return handleResponse(response);
          })
          .catch((response) => {
            if (response && response.status === FORBIDDEN && !session) {
              return handle403(id, this.deleteBrand);
            }
            return handleError(response);
          });
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(id, this.addBrand);
        }
        return handleError(response);
      });
  }
  public async getModels(
    brandId: number
  ): Promise<App.Response<Array<App.Car.Model>>> {
    return Axios.get(`/brand/${brandId}`)
      .then((response) => {
        response.data = response.data.models.map((e: any) => new SModel(e));
        return handleResponse(response);
      })
      .catch(handleError);
  }
  public async getModel(id: App.ID): Promise<App.Response<App.Car.Model>> {
    return Axios.get(`/model/${id}`)
      .then((response) => {
        response.data = new SModel(response.data);
        return handleResponse(response);
      })
      .catch(handleError);
  }
  public async addModel(
    data: ModelData,
    session?: App.User.Session
  ): Promise<App.Response<App.Car.Model>> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      `/model`,
      { ...data, brand_id: data.brandId },
      {
        headers: { Authorization },
      }
    )
      .then((response) => {
        response.data = new SModel(response.data);
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(data, this.addModel);
        }
        return handleError(response);
      });
  }
  public async updateModel(
    id: App.ID,
    data: ModelData,
    session?: App.User.Session
  ): Promise<App.Response<App.Car.Model>> {
    const Authorization = await JWT.getAuth();
    return Axios.put(
      `/model/${id}`,
      { ...data, brand_id: data.brandId },
      {
        headers: { Authorization },
      }
    )
      .then((response) => {
        response.data = new SModel(response.data);
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(null, (empty: null, session: App.User.Session) =>
            this.updateModel(id, data, session)
          );
        }
        return handleError(response);
      });
  }
  public async deleteModel(
    id: App.ID,
    session?: App.User.Session
  ): Promise<App.Response<void>> {
    const Authorization = await JWT.getAuth();
    return Axios.delete(`/model/${id}`, {
      headers: { Authorization },
    })
      .then((response) => {
        return Axios.delete(`/model/${id}`, {
          headers: { Authorization },
          data: response.data,
        })
          .then((response) => {
            return handleResponse(response);
          })
          .catch((response) => {
            if (response && response.status === FORBIDDEN && !session) {
              return handle403(id, this.addBrand);
            }
            return handleError(response);
          });
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(id, this.deleteModel);
        }
        return handleError(response);
      });
  }
  public async getGenerations(modelId: number) {
    return Axios.get(`/model/${modelId}`)
      .then((response) => {
        response.data = response.data.generation_models.map(
          (e: any) => new SGeneration(e)
        );
        return handleResponse(response);
      })
      .catch(handleError);
  }
  public async getGeneration(
    id: App.ID
  ): Promise<App.Response<App.Car.Generation>> {
    return Axios.get(`/generation-model/${id}`)
      .then((response) => {
        response.data = new SGeneration(response.data);
        return handleResponse(response);
      })
      .catch(handleError);
  }
  public async addGeneration(
    data: GenerationData,
    session?: App.User.Session
  ): Promise<App.Response<App.Car.Generation>> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      `/generation-model`,
      {
        name: data.name,
        model_id: data.modelId,
        start_create: data.start,
        end_create: data.end || null,
      },
      {
        headers: { Authorization },
      }
    )
      .then((response) => {
        response.data = new SGeneration(response.data);
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(data, this.addGeneration);
        }
        return handleError(response);
      });
  }
  public async updateGeneration(
    id: App.ID,
    data: GenerationData,
    session?: App.User.Session
  ): Promise<App.Response<App.Car.Generation>> {
    const Authorization = await JWT.getAuth();
    return Axios.put(
      `/generation-model/${id}`,
      {
        name: data.name,
        model_id: data.modelId,
        start_create: data.start,
        end_create: data.end || null,
      },
      {
        headers: { Authorization },
      }
    )
      .then((response) => {
        response.data = new SGeneration(response.data);
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(null, (empty: null, session: App.User.Session) =>
            this.updateGeneration(id, data, session)
          );
        }
        return handleError(response);
      });
  }
  public async deleteGeneration(
    id: App.ID,
    session?: App.User.Session
  ): Promise<App.Response<void>> {
    const Authorization = await JWT.getAuth();
    return Axios.delete(`/generation-model/${id}`, {
      headers: { Authorization },
    })
      .then((response) => {
        return Axios.delete(`/generation-model/${id}`, {
          headers: { Authorization },
          data: response.data,
        })
          .then((response) => {
            return handleResponse(response);
          })
          .catch((response) => {
            if (response && response.status === FORBIDDEN && !session) {
              return handle403(id, this.addBrand);
            }
            return handleError(response);
          });
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(id, this.deleteGeneration);
        }
        return handleError(response);
      });
  }
  public async getVersions(generationId: number) {
    return Axios.get(`/generation-model/${generationId}`)
      .then((response) => {
        response.data = response.data.version_models.map(
          (e: any) => new SVersion(e)
        );
        return handleResponse(response);
      })
      .catch(handleError);
  }
  public async getVersion(id: App.ID): Promise<App.Response<App.Car.Version>> {
    return Axios.get(`/version-model/${id}`)
      .then((response) => {
        response.data = new SVersion(response.data);
        return handleResponse(response);
      })
      .catch(handleError);
  }
  public async addVersion(
    data: VersionData,
    session?: App.User.Session
  ): Promise<App.Response<App.Car.Version>> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      `/version-model`,
      {
        name: data.name,
        generation_model_id: data.generationId,
        motor: data.motor,
        carcass: data.carcass,
        drive: data.drive,
        transmission: data.transmission,
      },
      {
        headers: { Authorization },
      }
    )
      .then((response) => {
        response.data = new SVersion(response.data);
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(data, this.addVersion);
        }
        return handleError(response);
      });
  }
  public async updateVersion(
    id: App.ID,
    data: VersionData,
    session?: App.User.Session
  ): Promise<App.Response<App.Car.Version>> {
    const Authorization = await JWT.getAuth();
    return Axios.put(
      `/version-model/${id}`,
      {
        name: data.name,
        generation_model_id: data.generationId,
        car_motor_id: data.motor,
        carcass_id: data.carcass,
        car_drive_id: data.drive,
        transmission_id: data.transmission,
      },
      {
        headers: { Authorization },
      }
    )
      .then((response) => {
        response.data = new SVersion(response.data);
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(null, (empty: null, session: App.User.Session) =>
            this.updateVersion(id, data, session)
          );
        }
        return handleError(response);
      });
  }
  public async deleteVersion(
    id: App.ID,
    session?: App.User.Session
  ): Promise<App.Response<void>> {
    const Authorization = await JWT.getAuth();
    return Axios.delete(`/version-model/${id}`, {
      headers: { Authorization },
    })
      .then((response) => {
        return Axios.delete(`/version-model/${id}`, {
          headers: { Authorization },
          data: response.data,
        })
          .then((response) => {
            return handleResponse(response);
          })
          .catch((response) => {
            if (response && response.status === FORBIDDEN && !session) {
              return handle403(id, this.addBrand);
            }
            return handleError(response);
          });
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(id, this.deleteVersion);
        }
        return handleError(response);
      });
  }
  public async getMotors(
    session?: App.User.Session
  ): Promise<App.Response<Array<App.Car.Motor>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/car-motor", {
      headers: { Authorization },
    })
      .then((response: AxiosResponse<Array<any>>) => {
        response.data = response.data.map((e) => new SMotor(e));
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(null, (empty: null, session: App.User.Session) =>
            this.getMotors(session)
          );
        }
        return handleError(response);
      });
  }
  public async getMotor(
    id: App.ID,
    session?: App.User.Session
  ): Promise<App.Response<App.Car.Motor>> {
    const Authorization = await JWT.getAuth();
    return Axios.get(`/car-motor/${id}`, {
      headers: { Authorization },
    })
      .then((response) => {
        response.data = new SMotor(response.data);
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(id, this.getMotor);
        }
        return handleError(response);
      });
  }
  public async getMotorOptions(
    session?: App.User.Session
  ): Promise<App.Response<{ car_motor_type_id: Array<App.Info> }>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/car-motor-search/options", {
      headers: { Authorization },
    })
      .then(handleResponse)
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(null, (empty: null, session: App.User.Session) =>
            this.getMotorOptions(session)
          );
        }
        return handleError(response);
      });
  }
  public async searchMotor(
    options: MotorData,
    session?: App.User.Session
  ): Promise<App.Response<Array<App.Car.Motor>>> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      "/car-motor-search",
      {
        power_horse: options.power,
        volume_liters: options.volume,
      },
      {
        headers: { Authorization },
      }
    )
      .then((response: AxiosResponse<Array<any>>) => {
        response.data = response.data.map((e) => new SMotor(e));
        return response;
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(options, this.searchMotor);
        }
        return handleError(response);
      });
  }
  public async addMotor(
    data: MotorData,
    session?: App.User.Session
  ): Promise<App.Response<App.Car.Motor>> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      `/car-motor`,
      {
        car_motor_type_id: data.type,
        power_horse: data.power,
        volume_liters: data.volume,
      },
      {
        headers: { Authorization },
      }
    )
      .then((response) => {
        response.data = new SVersion(response.data);
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(data, this.addMotor);
        }
        return handleError(response);
      });
  }
  public async getCarcass(
    id: App.ID,
    session?: App.User.Session
  ): Promise<App.Response<App.Car.Carcass>> {
    const Authorization = await JWT.getAuth();
    return Axios.get(`/carcass/${id}`, {
      headers: { Authorization },
    })
      .then((response: AxiosResponse<Array<any>>) => {
        response.data = response.data.map((e) => new SCarcass(e));
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(id, this.getCarcass);
        }
        return handleError(response);
      });
  }
  public async getCarcasses(
    session?: App.User.Session
  ): Promise<App.Response<Array<App.Car.Carcass>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/carcass", {
      headers: { Authorization },
    })
      .then((response: AxiosResponse<Array<any>>) => {
        response.data = response.data.map((e) => new SCarcass(e));
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(null, (empty: null, session: App.User.Session) =>
            this.getCarcasses(session)
          );
        }
        return handleError(response);
      });
  }
  public async getTransmissions(
    session?: App.User.Session
  ): Promise<App.Response<Array<App.Car.Transmission>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/transmission", {
      headers: { Authorization },
    })
      .then((response: AxiosResponse<Array<any>>) => {
        response.data = response.data.map((e) => new STransmission(e));
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(null, (empty: null, session: App.User.Session) =>
            this.getTransmissions(session)
          );
        }
        return handleError(response);
      });
  }
  public async getDrives(
    session?: App.User.Session
  ): Promise<App.Response<Array<App.Car.Drive>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/car-drive", {
      headers: { Authorization },
    })
      .then((response: AxiosResponse<Array<any>>) => {
        response.data = response.data.map((e) => new SDrive(e));
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(null, (empty: null, session: App.User.Session) =>
            this.getDrives(session)
          );
        }
        return handleError(response);
      });
  }
}

export default CarServiceApi;
