import "./UserNav.scss";

import { sell, userAnnouncements } from "../../../routes";

import { NavLink } from "react-router-dom";
import React from "react";

interface Props {
  name: string;
}
interface State {}

class UserNav extends React.Component<Props, State> {
  public render() {
    return (
      <div className="user-nav">
        <NavLink to={sell} className="sell">
          {"+  Продать"}
        </NavLink>
        <NavLink className="user-menu" to={userAnnouncements}>
          <span className="user-name">{this.props.name}</span>
        </NavLink>
      </div>
    );
  }
}

export default UserNav;
