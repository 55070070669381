import {
  changeFilterAnnouncements,
  researchAnnouncements,
} from "../../../store/actions/announcements/announcements";

import AnnouncementsFilters from "../../../components/filters/announcementsFilters/AnnouncementsFilters";
import React from "react";
import { Unsubscribe } from "redux";
import store from "../../../store/store";

interface Props {}
export interface State {
  filters: Array<App.Announcement.FilterApi>;
  car: Array<App.AdvancedFilter.CarFilter>;
  other: App.AdvancedFilter.SelectedFilter;
}

class AnnouncementsFiltersContainer extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;

  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      filters: this.getFilters(),
      ...this.parseHash(),
    };
  }

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
    store.dispatch(
      changeFilterAnnouncements({
        car: this.state.car,
        other: this.state.other,
      })
    );
  }
  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.state.car !== prevState.car ||
      this.state.other !== prevState.other
    ) {
      this.saveHash();
    }
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }
  public componentDidCatch() {
    this.setState(this.parseHash());
  }

  private handleUpdateStore() {
    this.setState({
      filters: this.getFilters(),
      ...store.getState().announcements.filters,
    });
    // this.saveHash();
  }

  // location hash
  private getHash() {
    return (
      "#" + JSON.stringify({ car: this.state.car, other: this.state.other })
    );
  }
  private saveHash() {
    setTimeout(() => {
      window.location.hash = this.getHash();
    });
  }

  private parseHash(): App.Announcement.FiltersForSearch {
    try {
      const data = JSON.parse(decodeURI(window.location.hash.slice(1)));
      if (data.car.length === 0) {
        data.car[0] = { brand: null, model: null };
      }
      return data;
    } catch {
      return {
        car: [{ brand: 0, model: null }],
        other: [],
      };
    }
  }

  // update state
  private getFilters(): Array<App.Announcement.FilterApi> {
    return store.getState().filters.main || [];
  }
  private handleChangeCarFilter(car: Array<App.AdvancedFilter.CarFilter>) {
    this.saveFilter({ car, other: this.state.other });
  }
  private handleChange(
    id: App.ID,
    value: App.AdvancedFilter.SelectedFilterValue
  ) {
    this.saveFilter({
      car: this.state.car,
      other: this.state.other.filter((f) => f.id !== id).concat(value),
    });
  }
  private saveFilter(values: App.Announcement.FiltersForSearch) {
    store.dispatch(changeFilterAnnouncements(values));
  }

  private search() {
    store.dispatch(researchAnnouncements());
  }

  public render() {
    return (
      <AnnouncementsFilters
        car={this.state.car}
        values={this.state.other}
        filters={this.state.filters}
        onChangeCarFilter={this.handleChangeCarFilter.bind(this)}
        onChange={this.handleChange.bind(this)}
        onSearch={this.search.bind(this)}
      />
    );
  }
}

export default AnnouncementsFiltersContainer;
