import "./Result.scss";

import AnnouncementStats from "../../../announcement/stats/AnnouncementStats";
import Carousel from "../../../carousel/Carousel";
import CreateAnnouncementMiddleInfo from "./middleInfo/MiddleInfo";
import CreateAnnouncementResultCards from "./cards/ResultCards";
import React from "react";

interface Props {
  announcement: App.Announcement.Announcement;
  isShowCards: boolean;
  cards: Array<{ name: string; options: Array<string> }>;
}
interface State {}

class CreateAnnouncementResult extends React.Component<Props, State> {
  public render() {
    return (
      <div className="create-announcement__result">
        <div className="main">
          <Carousel
            images={this.props.announcement.images.map((e) => e.path)}
          />

          <AnnouncementStats name announcement={this.props.announcement} />
        </div>
        <CreateAnnouncementMiddleInfo
          description={this.props.announcement.description}
          contact={this.props.announcement.contact}
        />
        {this.props.isShowCards && (
          <CreateAnnouncementResultCards
            // cards={this.props.announcement.options}
            cards={this.props.cards}
          />
        )}
      </div>
    );
  }
}

export default CreateAnnouncementResult;
