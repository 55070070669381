class SDrive implements App.Car.Drive {
  public id: number;
  public name: string;

  constructor(data?: any) {
    this.id = 0;
    this.name = "";
    data && this.init(data);
  }

  private init(data: any) {
    try {
      this.id = data.id || 0;
      this.name = data.name || "";
    } catch {}
  }
}

export default SDrive;
