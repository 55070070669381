class SMessage implements App.Messages.Message {
  public id: number;
  public type: App.Messages.MessageType;
  public text: string;
  public createdAt: Date;
  constructor(text: string, type: App.Messages.MessageType = "error") {
    this.id = SMessage.ID++;
    this.type = type;
    this.text = text;
    this.createdAt = new Date();
  }

  static ID = 0;
}

export default SMessage;
