import React from "react";

interface Props {}
interface State {}

class PhoneIcon extends React.Component<Props, State> {
  public render() {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3.60938 7.78125C5.10938 10.6875 7.3125 12.8906 10.2188 14.3906L12.4219 12.1875C12.7344 11.875 13.0781 11.7969 13.4531 11.9531C14.5781 12.3281 15.7656 12.5156 17.0156 12.5156C17.2969 12.5156 17.5312 12.6094 17.7188 12.7969C17.9062 12.9844 18 13.2188 18 13.5V17.0156C18 17.2969 17.9062 17.5312 17.7188 17.7188C17.5312 17.9062 17.2969 18 17.0156 18C12.3281 18 8.3125 16.3438 4.96875 13.0312C1.65625 9.6875 0 5.67188 0 0.984375C0 0.703125 0.09375 0.46875 0.28125 0.28125C0.46875 0.09375 0.703125 0 0.984375 0H4.5C4.78125 0 5.01562 0.09375 5.20312 0.28125C5.39062 0.46875 5.48438 0.703125 5.48438 0.984375C5.48438 2.23438 5.67188 3.42188 6.04688 4.54688C6.17188 4.95312 6.09375 5.29688 5.8125 5.57812L3.60938 7.78125Z" />
      </svg>
    );
  }
}
export default PhoneIcon;
