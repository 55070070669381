import {
  AnnouncementsAdvancedActions,
  changeFiltersAnnouncementsAdvancedType,
  researchAnnouncementsAdvancedType,
  saveNumberOfResultsType,
} from "../../actions/announcements/advanced";

export interface AnnouncementsAdvancedStore {
  filters: App.Announcement.FiltersForSearch;
  research: number;
  number: number;
}

class AnnouncementsAdvancedReducer {
  public get defaultStore(): AnnouncementsAdvancedStore {
    return {
      filters: {
        car: [{ brand: null, model: null }],
        other: [],
      },
      research: 0,
      number: 0,
    };
  }
  public store(
    store: AnnouncementsAdvancedStore,
    action: AnnouncementsAdvancedActions
  ) {
    switch (action.type) {
      case changeFiltersAnnouncementsAdvancedType:
        store.filters = action.filters;
        break;
      case researchAnnouncementsAdvancedType:
        store.research = +new Date();
        break;
      case saveNumberOfResultsType:
        store.number = action.number;
        break;

      default:
        break;
    }
    return store;
  }
}

export default new AnnouncementsAdvancedReducer();
