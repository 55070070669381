import React from "react";

class ListIcon extends React.Component<App.IconsProps> {
  static defaultProps = {
    background: "none",
    fill: "#aaaaaa",
  };
  public render() {
    return (
      <svg
        {...this.props}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill={this.props.background}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0H23V2.81879H0V0ZM0 8.5906V5.7047H23V8.5906H0ZM0 20V17.1812H23V20H0ZM0 14.2953V11.4094H23V14.2953H0Z"
          fill={this.props.fill}
        />
      </svg>
    );
  }
}
export default ListIcon;
