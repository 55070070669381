import "./Options.scss";

import React from "react";

interface Props {
  options: Array<App.Announcement.AnnouncementTypeOption>;
}
interface State {}

class CreateAnnouncementTypeOptions extends React.Component<Props, State> {
  public render() {
    return (
      <ul className="create-announcement-type__options">
        {this.props.options.map((option) => (
          <li key={option.text} className={option.type}>
            <span>{option.text}</span>
          </li>
        ))}
      </ul>
    );
  }
}

export default CreateAnnouncementTypeOptions;
