import AnnouncementCustomService from "../../../../services/abstract/announcements/AnnouncementCustomService";
import AnnouncementCustomServiceApi from "../../../../services/api/announcements/AnnouncementCustomServiceApi";
import AnnouncementsPallets from "../../../../components/announcement/pallets/AnnouncementsPallets";
import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../../struct/message/SMessage";
import { addMessage } from "../../../../store/actions/messages";
import store from "../../../../store/store";

interface Props {}
interface State {
  loading: boolean;
  announcements: Array<App.Announcement.Announcement>;
}

class NewAnnouncementsList extends React.Component<Props, State> {
  private service: AnnouncementCustomService;
  constructor(props: Props) {
    super(props);
    this.service = new AnnouncementCustomServiceApi();
    this.state = {
      loading: false,
      announcements: [],
    };
  }

  public componentDidMount() {
    this.loadAnnouncements();
  }
  public async loadAnnouncements() {
    this.setState({ loading: true });
    const { data, status } = await this.service.getNew();
    if (status === OK) {
      this.setState({ loading: false, announcements: data });
    } else {
      store.dispatch(
        addMessage(new SMessage("Не удалось загрузить список новых объявлений"))
      );
      this.setState({ loading: false });
    }
  }

  public render() {
    return (
      <AnnouncementsPallets
        title={"Новые объявления"}
        announcements={this.state.announcements}
        loading={this.state.loading}
      />
    );
  }
}

export default NewAnnouncementsList;
