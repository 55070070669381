import React from "react";

class NotificationIcon extends React.Component<App.IconsProps> {
  static defaultProps = {
    background: "none",
    fill: "#12BBA4",
  };
  public render() {
    return (
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill={this.props.background}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 13.9844L17.0156 16V16.9844H0.984375V16L3 13.9844V9.01562C3 7.45312 3.39062 6.09375 4.17188 4.9375C4.98438 3.78125 6.09375 3.03125 7.5 2.6875V1.98438C7.5 1.57812 7.64062 1.23438 7.92188 0.953125C8.20312 0.640625 8.5625 0.484375 9 0.484375C9.4375 0.484375 9.79688 0.640625 10.0781 0.953125C10.3594 1.23438 10.5 1.57812 10.5 1.98438V2.6875C11.9062 3.03125 13 3.78125 13.7812 4.9375C14.5938 6.09375 15 7.45312 15 9.01562V13.9844ZM9 19.9844C8.4375 19.9844 7.95312 19.7969 7.54688 19.4219C7.17188 19.0156 6.98438 18.5469 6.98438 18.0156H11.0156C11.0156 18.5469 10.8125 19.0156 10.4062 19.4219C10 19.7969 9.53125 19.9844 9 19.9844Z"
          fill={this.props.fill}
        />
      </svg>
    );
  }
}

export default NotificationIcon;
