import "./Contact.scss";

import ButtonPrice from "../../price/button/ButtonPrice";
import DotsIcon from "../../../assets/icons/green-dots-vert.svg";
import PeopleAndPlace from "./peopleAndPlace/PeopleAndPlace";
import React from "react";
import StringMethods from "../../../utils/string/StringMethods";

interface Props {
  spec?: boolean;
  contact?: App.Announcement.Contact;
  price?: number;
  currency?: App.ID;
  phoneNumber?: string;
  getPhoneNumber: () => void;
}
interface State {
  isOpen: boolean;
}

class AnnouncementContact extends React.Component<Props, State> {
  private ignore: boolean;
  private handleClickOnWindow: () => void;
  constructor(props: Props) {
    super(props);
    this.ignore = false;
    this.handleClickOnWindow = this.closeInfoForAdditionBlock.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  public componentDidMount() {
    window.addEventListener("click", this.handleClickOnWindow);
  }
  public componentWillUnmount() {
    window.removeEventListener("click", this.handleClickOnWindow);
  }
  public get phone() {
    if (this.props.contact?.phone) {
      return (
        <a href={`tel:375${this.props.contact.phone}`}>
          <button className="show-number">
            {StringMethods.beautifyPhoneNumber(this.props.contact.phone)}
          </button>
        </a>
      );
    } else {
      return (
        <button
          className="show-number"
          data-empty={!this.props.contact?.id}
          onClick={this.props.getPhoneNumber}
        >
          {!this.props.contact?.id ? "Нет номера" : "Показать номер"}
        </button>
      );
    }
  }

  private openInfoForAdditionBlock() {
    this.setState({ isOpen: true });
  }
  private closeInfoForAdditionBlock() {
    if (this.ignore) {
      this.ignore = false;
      return;
    }
    this.setState({ isOpen: false });
  }
  private ignoreClick() {
    this.ignore = true;
  }

  public render() {
    return (
      <div className="announcement-contact">
        <div className="announcement-contact-main">
          {this.props.price && (
            <ButtonPrice
              currency={this.props.currency}
              price={this.props.price}
            />
          )}
          <PeopleAndPlace contact={this.props.contact} />
          {this.phone}
        </div>
        <div
          className="announcement-contact-addition"
          onClick={this.ignoreClick.bind(this)}
        >
          <ButtonPrice
            currency={this.props.currency}
            price={this.props.price || 0}
          />
          <button
            className="more"
            onClick={this.openInfoForAdditionBlock.bind(this)}
          >
            <img src={DotsIcon} alt="" />
          </button>
          {this.state.isOpen && (
            <div className="more-opend">
              <PeopleAndPlace contact={this.props.contact} />
              {this.phone}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AnnouncementContact;
