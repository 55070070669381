import AnnouncementStat from "../stat/AnnouncementStat";
import React from "react";

interface Props {
  drive?: App.Car.Drive | string;
}
interface State {}

class AnnouncementStatDrive extends React.Component<Props, State> {
  public get value() {
    if (!this.props.drive) {
      return "";
    } else if (typeof this.props.drive === "string") {
      return this.props.drive;
    } else {
      return this.props.drive.name;
    }
  }
  public render() {
    return <AnnouncementStat name={"Привод"} value={this.value} />;
  }
}

export default AnnouncementStatDrive;
