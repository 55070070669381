import "./AnnouncementName.scss";

import { NavLink } from "react-router-dom";
import React from "react";
import SubTitle from "../../custom/subtitle/SubTitle";
import { announcement } from "../../../routes";

interface Props {
  announcement?: App.Announcement.Announcement;
  name?: string;
}

class AnnouncementName extends React.Component<Props> {
  public render() {
    return (
      <SubTitle className="announcement-name">
        {this.props.name && <span>{this.props.name}</span>}
        {this.props.announcement && (
          <NavLink to={announcement(this.props.announcement.id)}>
            <span>{this.props.announcement.car.brand.name}</span>
            <span>{this.props.announcement.car.model.name}</span>
          </NavLink>
        )}
        {!this.props.announcement && <span>Марка Модель</span>}
      </SubTitle>
    );
  }
}

export default AnnouncementName;
