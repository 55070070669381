import SBrand from "../brand/SBrand";
import SCarcass from "../carcass/SCarcass";
import SColor from "../color/SColor";
import SDrive from "../drive/SDrive";
import SGeneration from "../generation/SGeneration";
import SModel from "../model/SModel";
import SMotor from "../motor/SMotor";
import STransmission from "../transmission/STransmission";

class SCar implements App.Car.Car {
  public version: App.ID;
  public brand: App.Info;
  public model: App.Info;
  public generation: App.Info;
  public carcass: App.Car.Carcass;
  public transmission: App.Car.Transmission;
  public motor: App.Car.Motor;
  public drive: App.Car.Drive;
  public color: App.Car.Color;
  public vin: string;

  constructor(data?: any) {
    this.version = 0;
    this.brand = new SBrand();
    this.model = new SModel();
    this.generation = new SGeneration();
    this.carcass = new SCarcass();
    this.transmission = new STransmission();
    this.motor = new SMotor();
    this.drive = new SDrive();
    this.color = new SColor();
    this.vin = "";
    data && this.init(data);
  }

  private init(data: any) {
    try {
      this.version = data.car.version_model.id || 0;
      this.brand = new SBrand(
        data.car.version_model.generation_model.model.brand
      );
      this.model = new SModel(data.car.version_model.generation_model.model);
      this.generation = new SGeneration(
        data.car.version_model.generation_model
      );
      this.carcass = new SCarcass(data.car.version_model.carcass);
      this.transmission = new STransmission(
        data.car.version_model.transmission
      );
      this.motor = new SMotor(data.car.version_model.car_motor);
      this.drive = new SDrive(data.car.version_model.car_drive);
      this.color = new SColor(data.car.color);
      this.vin = data.car.vin || "";
    } catch {}
  }
}

export default SCar;
