import ArrowUpIcon from "../../../../../assets/icons/arrow-up.svg";
import React from "react";

interface Props {
  up: () => void;
  down: () => void;
}
interface State {}

class LabelNumberControlls extends React.Component<Props, State> {
  public render() {
    return (
      <div className="controlls">
        <img src={ArrowUpIcon} onClick={this.props.up} alt="" />
        <img src={ArrowUpIcon} onClick={this.props.down} alt="" />
      </div>
    );
  }
}

export default LabelNumberControlls;
