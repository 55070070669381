import "./Logo.scss";

import { NavLink } from "react-router-dom";
import React from "react";
import { home } from "../../routes";

interface Props {}
interface State {}

class Logo extends React.Component<Props, State> {
  public render() {
    return (
      <NavLink className="logo" to={home}>
        <span className="S">S</span>
        <span className="M">M</span>
        <span className="A">A</span>
        <span className="R">R</span>
        <span className="T">T</span>
      </NavLink>
    );
  }
}

export default Logo;
