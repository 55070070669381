import { changeContacts, saveUser } from "../../../store/actions/user";

import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../struct/message/SMessage";
import { Unsubscribe } from "redux";
import UserContactsList from "../../../components/user/contactsList/UserContactsList";
import UserDeleteContactPopup from "../../../components/user/userDeleteContactPopup/UserDeleteContactPopup";
import UserService from "../../../services/abstract/UserService";
import UserServiceApi from "../../../services/api/UserServiceApi";
import { addMessage } from "../../../store/actions/messages";
import store from "../../../store/store";

interface Props {}
interface State {
  contacts: Array<App.User.Contact>;
  loading: boolean;
  isOpen: boolean;
  contactForDelete?: App.User.Contact;
}

class UserContactsListContainer extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  private service: UserService;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.service = new UserServiceApi();
    this.state = {
      contacts: this.getUserContacts(),
      loading: false,
      isOpen: false,
    };
  }

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
    this.getUserInfo();
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  private handleUpdateStore() {
    this.setState({
      contacts: this.getUserContacts(),
    });
  }
  private getUserContacts() {
    return store.getState().user.contacts;
  }
  private async handleDeleteContact(contact: App.User.Contact) {
    this.setState({ contactForDelete: contact, isOpen: true });
  }

  private async getUserInfo() {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const { data, status, errors } = await this.service.getUserInfo();
    this.setState({ loading: false });
    if (status === OK) {
      store.dispatch(saveUser(data));
    } else {
      errors.map((error) => store.dispatch(addMessage(new SMessage(error))));
    }
  }

  private handleClosePopup() {
    this.setState({ isOpen: false, contactForDelete: undefined });
  }
  private async handleSubmitDelete() {
    if (this.state.loading || !this.state.contactForDelete) return;
    const id = this.state.contactForDelete.id;
    this.setState({ loading: true });
    const { status, errors } = await this.service.deleteContact(id);
    this.setState({ loading: false });
    if (status === OK) {
      store.dispatch(
        changeContacts(
          store.getState().user.contacts.filter((c) => c.id !== id)
        )
      );
      this.handleClosePopup();
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }
  public render() {
    return (
      <>
        <UserContactsList
          contacts={this.state.contacts}
          onDelete={this.handleDeleteContact.bind(this)}
          refresh={this.getUserInfo.bind(this)}
          loading={this.state.loading}
        />
        {this.state.isOpen && (
          <UserDeleteContactPopup
            onClose={this.handleClosePopup.bind(this)}
            onSubmit={this.handleSubmitDelete.bind(this)}
            disabled={this.state.loading}
          />
        )}
      </>
    );
  }
}

export default UserContactsListContainer;
