import "./Messages.scss";

import Message from "./message/Message";
import React from "react";

interface Props {
  messages: Array<App.Messages.Message>;
  remove: (id: number) => void;
}
interface State {}

class Messages extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="messages-container">
        {this.props.messages.slice(0, 2).map((m) => (
          <Message key={m.id} message={m} remove={this.props.remove} />
        ))}
      </div>
    );
  }
}

export default Messages;
