import AnnouncementOptions from "../../../components/announcement/announcementOptions/AnnouncementOptions";
import React from "react";
import { Unsubscribe } from "redux";
import store from "../../../store/store";

interface Props {
  announcement: App.Announcement.Announcement;
}
interface State {
  options: Array<App.Announcement.Option>;
}

class AnnouncementOptionsContainer extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      options: this.props.announcement.options,
    };
  }
  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }
  public componentDidUpdate(props: Props) {
    if (this.props.announcement !== props.announcement) {
      this.setState({
        options: this.props.announcement.options,
      });
    }
  }

  private handleUpdateStore() {
    this.setState({
      options: this.props.announcement.options,
    });
  }

  public render() {
    // return <></>;
    return <AnnouncementOptions options={this.state.options} />;
  }
}

export default AnnouncementOptionsContainer;
