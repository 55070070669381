import React, { SyntheticEvent } from "react";

import AdminPanelForm from "../form/AdminPanelForm";
import AdminPanelList from "../list/AdminPanelList";
import { BrandData } from "../../../services/abstract/CarService";
import LabelCheckbox from "../../custom/labels/labelCheckbox/LabelCheckbox";
import LabelText from "../../custom/labels/labelText/LabelText";
import ObjectMethods from "../../../utils/object/ObjectMethods";
import Photo from "../../addPhotos/photo/Photo";
import Title from "../../custom/title/Title";

interface Props {
  brands: Array<App.Car.Brand>;
  onSave: (brandData: BrandData) => void;
  onDelete: () => Promise<boolean>;
  onSelectBrand: (brand: App.Car.Brand) => void;
  brand?: App.Car.Brand;
}
interface State {
  loading: boolean;
  src?: string | File;
  name: string;
  country: string;
  city: string;
  main: boolean;
  isOpenPopupDelete: boolean;
}

class RedactBrand extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      name: "",
      country: "",
      city: "",
      main: false,
      isOpenPopupDelete: false,
    };
  }

  public componentDidUpdate(props: Props) {
    if (!ObjectMethods.compare(props.brand, this.props.brand)) {
      if (this.props.brand) {
        const { name, country, city, popular, src } = this.props.brand;
        this.setState({ name, country, city, main: popular, src });
      } else {
        this.setState({
          name: "",
          country: "",
          city: "",
          src: undefined,
          main: false,
        });
      }
    }
  }

  static regForName = /[^A-zА-я0-9 -]{1,}/;
  static regForCountry = /[^A-zА-я -]{1,}/;
  static regForCity = /[^A-zА-я -]{1,}/;

  private get correctName() {
    return !!(
      this.state.name.length && !this.state.name.match(RedactBrand.regForName)
    );
  }
  private get uncorrectName() {
    return !!(
      this.state.name.length && this.state.name.match(RedactBrand.regForName)
    );
  }
  private get correctCountry() {
    return !!(
      this.state.country.length &&
      !this.state.country.match(RedactBrand.regForCountry)
    );
  }
  private get uncorrectCountry() {
    return !!(
      this.state.country.length &&
      this.state.country.match(RedactBrand.regForCountry)
    );
  }
  private get correctCity() {
    return !!(
      this.state.city.length && !this.state.city.match(RedactBrand.regForCity)
    );
  }
  private get uncorrectCity() {
    return !!(
      this.state.city.length && this.state.city.match(RedactBrand.regForCity)
    );
  }
  private get disabled() {
    return (
      this.state.loading ||
      !(this.correctName && !this.uncorrectCountry && !this.uncorrectCity)
    );
  }

  private handleChangeName(name: string) {
    this.setState({ name });
  }
  private handleChangeCountry(country: string) {
    this.setState({ country });
  }
  private handleChangeCity(city: string) {
    this.setState({ city });
  }
  private handleChangeMain(main: boolean) {
    this.setState({ main });
  }
  private async handleClick() {
    this.setState({ loading: true });
    const data: BrandData = {
      name: this.state.name,
      country: this.state.country,
      city: this.state.city,
      main: this.state.main,
    };
    if (this.state.src && this.state.src instanceof File) {
      data.logo = this.state.src;
    } else if (!this.state.src && this.props.brand?.src) {
      data.delete_logo = true;
    }
    await this.props.onSave(data);
    this.setState({ loading: false });
  }

  private addPhoto(src: Array<File> | File) {
    this.setState({ src: Array.isArray(src) ? src[0] : src });
  }
  private changePhoto(index: number, src: File) {
    this.setState({ src });
  }
  private removePhoto() {
    this.setState({ src: undefined });
  }
  private handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!this.disabled) {
      this.handleClick();
    }
  }
  private openDeletePopup(event: SyntheticEvent<HTMLButtonElement>) {
    event.preventDefault();
    this.setState({ isOpenPopupDelete: true });
  }
  private closeDeletePopup(event?: SyntheticEvent) {
    event?.preventDefault();
    this.setState({ isOpenPopupDelete: false });
  }
  private async deleteBrand(event?: SyntheticEvent) {
    event?.preventDefault();
    this.setState({ loading: true });
    const result = await this.props.onDelete();
    if (result) {
      this.setState({ loading: false, isOpenPopupDelete: false });
    } else {
      this.setState({ loading: false });
    }
  }

  public render() {
    return (
      <>
        <Title>Бренд</Title>
        <AdminPanelList
          items={this.props.brands}
          selected={this.props.brand?.id}
          onSelect={this.props.onSelectBrand}
        />
        <AdminPanelForm
          loading={this.state.loading}
          question="Удалить бренд?"
          isOpenPopup={this.state.isOpenPopupDelete}
          onSubmit={this.handleSubmit.bind(this)}
          onClose={this.closeDeletePopup.bind(this)}
          onDelete={this.deleteBrand.bind(this)}
        >
          <span className="name">Логотип</span>
          <Photo
            photo={this.state.src}
            add={this.addPhoto.bind(this)}
            change={this.changePhoto.bind(this)}
            remove={this.removePhoto.bind(this)}
          />
          <LabelText
            uncorrect={this.uncorrectName}
            correct={this.correctName}
            placeholder="Название"
            onChange={this.handleChangeName.bind(this)}
            value={this.state.name}
            maxLength={32}
          />
          <LabelText
            uncorrect={this.uncorrectCountry}
            correct={this.correctCountry}
            placeholder="Страна"
            onChange={this.handleChangeCountry.bind(this)}
            value={this.state.country}
            maxLength={32}
          />
          <LabelText
            placeholder={"Город"}
            uncorrect={this.uncorrectCity}
            correct={this.correctCity}
            onChange={this.handleChangeCity.bind(this)}
            value={this.state.city}
            maxLength={32}
          />
          <LabelCheckbox
            name="Основной"
            onChange={this.handleChangeMain.bind(this)}
            value={this.state.main}
          />
          <div className="buttons">
            <button disabled={this.disabled}>Сохранить</button>
            <button
              className="remove"
              onClick={this.openDeletePopup.bind(this)}
              disabled={!this.props.brand || this.state.loading}
            >
              Удалить
            </button>
          </div>
        </AdminPanelForm>
      </>
    );
  }
}
export default RedactBrand;
