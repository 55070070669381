import "./HistoryStep.scss";

import React from "react";
import Time from "../../../../utils/time/Time";

interface Props {
  history: App.Car.CarHistory;
}
interface State {}

class HistoryStep extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="history-step">
        <div className="color">{this.props.history.color.name}</div>
        <div className="distance">
          {this.props.history.distance
            ? `${this.props.history.distance} км.`
            : "Новая"}
        </div>
        <div className="created">
          Создано {Time.beautifyDateTime(this.props.history.datetime)}
        </div>
      </div>
    );
  }
}
export default HistoryStep;
