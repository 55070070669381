import "./Contact.scss";

import CrossIcon from "../../../icons/Cross";
import React from "react";
import StringMethods from "../../../utils/string/StringMethods";

interface Props {
  contact: App.User.Contact;
  disabled?: boolean;
  onDelete: (contact: App.User.Contact) => void;
}
interface State {}

class UserContact extends React.Component<Props, State> {
  private handleClickDelete() {
    this.props.onDelete(this.props.contact);
  }
  public render() {
    return (
      <div className="user-contact" data-disabled={this.props.disabled}>
        <div className="info">
          <div className="name">{this.props.contact.name}</div>
          <div className="phone">
            {StringMethods.beautifyPhoneNumber(this.props.contact.phone)}
          </div>
        </div>
        <div className="buttons">
          <button
            onClick={this.handleClickDelete.bind(this)}
            title="Удалить"
            disabled={this.props.disabled}
          >
            <CrossIcon />
          </button>
        </div>
      </div>
    );
  }
}

export default UserContact;
