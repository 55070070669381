import AdminPanel from "../../components/adminPanel/AdminPanel";
import React from "react";
import { Unsubscribe } from "redux";
import store from "../../store/store";

interface Props {
  goToSignin: () => void;
}
interface State {}

class AdminPanelContainer extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {};
  }

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  private handleUpdateStore() {
    if (!store.getState().user.isAdmin) {
      this.props.goToSignin();
    }
  }

  public render() {
    return <AdminPanel />;
  }
}

export default AdminPanelContainer;
