import "./CompareCarImageRow.scss";

import AnnouncementCardCarousel from "../../../announcement/card/carousel/Carousel";
import CompareCarRow from "../../compareCarRow/CompareCarRow";
import CrossIcon from "../../../../icons/Cross";
import LabelCheckbox from "../../../custom/labels/labelCheckbox/LabelCheckbox";
import React from "react";

interface Props {
  isShowDifferent: boolean;
  announcements: Array<App.Announcement.Announcement>;
  onChangeIsShowDifferent: (value: boolean) => void;
  onClickOnCross: (id: App.ID) => void;
}
interface State {}

class CompareCarImageRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <CompareCarRow
        key="-1"
        className={"image-row"}
        head={
          <div className="compare-car__page-info">
            <h3>Сравнение обьявлений</h3>
            <LabelCheckbox
              name="Показать только отличия"
              value={this.props.isShowDifferent}
              onChange={this.props.onChangeIsShowDifferent}
            />
          </div>
        }
        values={this.props.announcements.map((a) => (
          <div className="compare-car-image">
            <AnnouncementCardCarousel
              id={a.id}
              images={a.images.map((e) => e.path)}
            />
            <div
              className="cross-icon-container"
              onClick={() => this.props.onClickOnCross(a.id)}
            >
              <CrossIcon />
            </div>
          </div>
        ))}
      />
    );
  }
}
export default CompareCarImageRow;
