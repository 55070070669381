import Image1 from "../../assets/dev-images/car.png";
import Image2 from "../../assets/dev-images/car2.jpg";
import Image3 from "../../assets/dev-images/car3.png";
import Image4 from "../../assets/dev-images/car4.jpg";

export const annnouncement = {
  id: 1,

  images: [Image1, Image2, Image3, Image4],
  price: 100000,
  distance: 1000,
  year: 2000,
  description:
    "В отличном состоянии, есть мелкие косметические недостатки в виде сколов и трещины на лобовом, в ближайшее время буду устранять. Пока не устранил немного поторгуюсь. Сейчас авто в заводском окрасе.",
  car: {
    brand_id: 1,
    model_id: 1,
    carcass_id: 1,
    transmission_id: 1,
    motor_type_id: 1,
    drive_id: 1,
    color_id: 1,
  },

  spec: false,
  checked: false,
  contact: {
    id: 1,
    name: "Андрей",
    city: "Минск",
  },
  options: [
    {
      category: "Обзор",
      options: [
        "Бронированное стекло",
        "Бронированное стекло заднего вида",
        "Тонировка",
      ],
    },
    {
      category: "Безопасность",
      options: [
        "Подушка безопасности водителя",
        "Подушка безопасности пассажира",
        "Оконные подушки бегопасности",
        "Рама",
      ],
    },
  ],
  state: "Новая",
  swap: true,

  created: +new Date(),
  last_update: +new Date(),
};
export const announcementOptions: Array<App.Announcement.Option> = [
  {
    category: "Комфорт",
    options: ["Комфорт 1", "Комфорт 2", "Комфорт 3", "Комфорт 4", "Комфорт 5"],
  },
  {
    category: "Безопасность",
    options: ["Безопасность 1", "Безопасность 4", "Безопасность 5"],
  },
  {
    category: "Обзор",
    options: ["Обзор 1", "Обзор 2", "Обзор 3", "Обзор 4", "Обзор 5"],
  },
  {
    category: "Экстерьер",
    options: [
      "Экстерьер 1",
      "Экстерьер 2",
      "Экстерьер 3",
      "Экстерьер 4",
      "Экстерьер 5",
    ],
  },
  {
    category: "Защита от угона",
    options: [
      "Защита от угона 1",
      "Защита от угона 2",
      "Защита от угона 3",
      "Защита от угона 4",
      "Защита от угона 5",
    ],
  },
];

export const mainFilters: Array<App.AdvancedFilter.Row> = [
  [
    [
      {
        id: 10,
        name: "Цена",
        type: "range",

        min: 0,
        max: 10000000,
        step: 1000,
        dimensions: "BYN",
        slider: true,
      },
    ],
    [
      {
        id: 11,
        name: "Год",
        type: "range",

        min: 1900,
        max: new Date().getFullYear(),
        step: 1,
        slider: true,
      },
    ],
    [
      {
        id: 12,
        name: "Объем двигателя",
        type: "range",

        min: 0,
        max: 10,
        step: 0.1,
        dimensions: "л.",
        slider: true,
      },
    ],
    [],
  ],
  [
    [
      {
        id: 20,
        type: "picker",
        name: "Двигатель",
        options: [
          { id: 1, name: "Бензин" },
          { id: 2, name: "Гибрид" },
          { id: 3, name: "Дизель" },
          { id: 4, name: "Электро" },
        ],
      },
    ],
    [
      {
        id: 21,
        type: "picker",
        name: "Привод",
        options: [
          { id: 1, name: "Передний" },
          { id: 2, name: "Полный" },
          { id: 3, name: "Задний" },
        ],
      },
    ],
    [
      {
        id: 22,
        type: "radio",
        name: "Коробка",
        options: [
          { id: 1, name: "Механика" },
          { id: 2, name: "Автомат" },
          { id: 3, name: "Любая" },
        ],
      },
    ],
    [
      {
        id: 23,
        name: "Пробег",
        type: "range",

        min: 0,
        max: 10000000,
        step: 1000,
        dimensions: "км.",
        slider: true,
      },
    ],
  ],
  [
    [
      {
        id: 24,
        name: "На гарантии",
        type: "checkbox",
      },
    ],
    [],
    [],
    [],
  ],
];

export const visibilityFilters: Array<App.AdvancedFilter.Row> = [
  [
    [
      {
        id: 30,
        name: "Фары",
        type: "picker",
        options: [
          {
            id: 1,
            name: "Противотуманные фары",
            className: "option-full-width",
          },
          {
            id: 2,
            name: "Автоматический корректор фар",
            className: "option-full-width",
          },
          { id: 3, name: "Омыватель фар", className: "option-full-width" },
        ],
      },
    ],
    [
      {
        id: 31,
        name: "Электрообогрев",
        type: "picker",
        options: [
          {
            id: 1,
            name: "Зоны стеклоочистителей",
            className: "option-full-width",
          },
          { id: 2, name: "Лобового стекла", className: "option-full-width" },
          { id: 3, name: "Боковых зеркал", className: "option-full-width" },
        ],
      },
    ],
    [
      {
        id: 32,
        name: "Датчики",
        type: "picker",
        options: [
          { id: 1, name: "Дождя" },
          { id: 2, name: "Света" },
        ],
      },
    ],
    [],
  ],
];

export const salonFilters: Array<App.AdvancedFilter.Row> = [
  [
    [
      {
        id: 40,
        name: "Материал салона",
        type: "picker",
        options: [
          { id: 1, name: "Ткань" },
          { id: 2, name: "Велюр" },
          { id: 3, name: "Комбинир." },
          { id: 4, name: "Искусств. кожа" },
          { id: 5, name: "Алькантра" },
          { id: 6, name: "Кожа" },
        ],
      },
    ],
    [
      {
        id: 41,
        name: "Электрорегулировка сидений",
        type: "radio",
        options: [
          { id: 1, name: "Сидения водителя", className: "option-full-width" },
          { id: 2, name: "Передних сидений", className: "option-full-width" },
        ],
      },
    ],
    [
      {
        id: 42,
        name: "Память положения сидений",
        type: "radio",
        options: [
          { id: 1, name: "Сидения водителя", className: "option-full-width" },
          { id: 2, name: "Передних сидений", className: "option-full-width" },
        ],
      },
    ],
    [
      {
        id: 43,
        name: "Обогрев рулевого колеса",
        type: "checkbox",
      },
      {
        id: 44,
        name: "Отделка кожей рулевого колеса",
        type: "checkbox",
      },
    ],
  ],
  [
    [
      {
        id: 50,
        name: "Люк",
        type: "checkbox",
      },
      {
        id: 51,
        name: "Панорамная крыша",
        type: "checkbox",
      },
      {
        id: 53,
        name: "Передний центральный подлокотник",
        type: "checkbox",
      },
    ],
    [
      {
        id: 54,
        name: "Спортивные передние сидения",
        type: "checkbox",
      },
      {
        id: 55,
        name: "Третий ряд сидений",
        type: "checkbox",
      },
    ],
    [],
    [],
  ],
];

export const comfortFilters: Array<App.AdvancedFilter.Row> = [
  [
    [
      {
        id: 50,
        name: "Электростеклоподъемники",
        type: "picker",
        options: [
          { id: 1, name: "Передние" },
          { id: 2, name: "Задние" },
        ],
      },
    ],
    [
      {
        id: 51,
        name: "Круиз-контроль",
        type: "radio",
        options: [
          { id: 1, name: "Круиз-контроль", className: "option-full-width" },
          {
            id: 2,
            name: "Адаптивный круиз-контроль",
            className: "option-full-width",
          },
        ],
      },
    ],
    [
      {
        id: 52,
        name: "Усилитель руля",
        type: "radio",
        options: [
          { id: 1, name: "Усилитель руля", className: "option-full-width" },
          {
            id: 2,
            name: "Адаптивный усилитель руля",
            className: "option-full-width",
          },
        ],
      },
    ],
    [
      {
        id: 53,
        name: "Регулировка руля",
        type: "picker",
        options: [
          { id: 1, name: "По высоте" },
          { id: 2, name: "По вылету" },
          { id: 3, name: "Электрорегулировка", className: "option-full-width" },
          {
            id: 4,
            name: "С памятью положения",
            className: "option-full-width",
          },
        ],
      },
    ],
  ],
  [
    [
      {
        id: 60,
        name: "Помощник на парковке ",
        type: "picker",
        options: [
          {
            id: 1,
            name: "Парктроник передний",
            className: "option-full-width",
          },
          { id: 2, name: "Парктроник задний", className: "option-full-width" },
          {
            id: 3,
            name: "Автоматическая парковка",
            className: "option-full-width",
          },
        ],
      },
    ],
    [
      {
        id: 61,
        name: "Камера ",
        type: "picker",
        options: [
          { id: 1, name: "Передняя" },
          { id: 2, name: "Задняя" },
          { id: 3, name: "360" },
        ],
      },
    ],
    [
      {
        id: 62,
        name: "Кондиционер ",
        type: "picker",
        options: [
          { id: 1, name: "Любой", className: "option-full-width" },
          {
            id: 2,
            name: "Климат-контроль 1-зонный",
            className: "option-full-width",
          },
          {
            id: 3,
            name: "Климат-контроль 2-зонный",
            className: "option-full-width",
          },
          {
            id: 4,
            name: "Климат-контроль многозонный",
            className: "option-full-width",
          },
        ],
      },
    ],
    [],
  ],
  [
    [
      {
        id: 63,
        name: "Бортовой компьютер",
        type: "checkbox",
      },
      {
        id: 64,
        name: "Электронная приборная панель",
        type: "checkbox",
      },
      {
        id: 65,
        name: "Система доступа без ключа",
        type: "checkbox",
      },
      {
        id: 66,
        name: "Запуск двигателя с кнопки",
        type: "checkbox",
      },
    ],
    [
      {
        id: 67,
        name: "Система “старт-стоп”",
        type: "checkbox",
      },
      {
        id: 68,
        name: "Электроскладывание зеркал",
        type: "checkbox",
      },
      {
        id: 69,
        name: "Мультифункциональное рулевое колесо",
        type: "checkbox",
      },
      {
        id: 70,
        name: "Регулируемый педальный узел",
        type: "checkbox",
      },
    ],
    [
      {
        id: 71,
        name: "Дистанционный запуск двигателя",
        type: "checkbox",
      },
      {
        id: 72,
        name: "Программируемый предпусковой отопитель",
        type: "checkbox",
      },
      {
        id: 89,
        name: "Электропривод крышки багажника",
        type: "checkbox",
      },
      {
        id: 73,
        name: "Открытие багажника без помощи рук",
        type: "checkbox",
      },
    ],
    [
      {
        id: 74,
        name: "Электропривод зеркал",
        type: "checkbox",
      },
      {
        id: 75,
        name: "Подрулевые лепестки переключения передач",
        type: "checkbox",
      },
    ],
  ],
];
export const multimediaFilters: Array<App.AdvancedFilter.Row> = [
  [
    [
      {
        id: 76,
        name: "Аудиосистема ",
        type: "picker",
        options: [
          { id: 1, name: "AUX" },
          { id: 2, name: "USB" },
          { id: 3, name: "Bluetooth" },
        ],
      },
    ],
    [
      {
        id: 77,
        name: "Навигациаонная система",
        type: "checkbox",
      },
    ],
    [
      {
        id: 78,
        name: "Голосовое управление",
        type: "checkbox",
      },
    ],
    [],
  ],
];
export const safetyFilters: Array<App.AdvancedFilter.Row> = [
  [
    [
      {
        id: 79,
        name: "Подушки безопасности",
        type: "picker",
        options: [
          { id: 1, name: "Водителя" },
          { id: 2, name: "Пассажира" },
          { id: 3, name: "Боковые задние", className: "option-full-width" },
          { id: 4, name: "Боковые передние", className: "option-full-width" },
          { id: 5, name: "Оконные", className: "option-full-width" },
          { id: 6, name: "Коленей водителя", className: "option-full-width" },
        ],
      },
    ],
    [
      {
        id: 80,
        name: "Защита от угона",
        type: "picker",
        options: [
          { id: 1, name: "Сигнализация", className: "option-full-width" },
          { id: 2, name: "Центральный замок", className: "option-full-width" },
          { id: 3, name: "Иммобилайзер", className: "option-full-width" },
        ],
      },
    ],
    [
      {
        id: 80,
        name: "Система крепления isofix",
        type: "checkbox",
      },
      {
        id: 81,
        name: "Датчик давления в шинах",
        type: "checkbox",
      },
      {
        id: 82,
        name: "Блокировка замков задних дверей",
        type: "checkbox",
      },
      {
        id: 83,
        name: "Антиблокировочная система (ABS)",
        type: "checkbox",
      },
      {
        id: 84,
        name: "Система стабилизации (ESP)",
        type: "checkbox",
      },
    ],
    [
      {
        id: 85,
        name: "Вспомогательные системы",
        type: "picker",
        options: [
          {
            id: 1,
            name: "Антипробуксовочная (ARS)",
            className: "option-full-width",
          },
          {
            id: 2,
            name: "Помощи при торможении(BAS,EBD)",
            className: "option-full-width",
          },
          {
            id: 3,
            name: "Помощи при старте в гору",
            className: "option-full-width",
          },
          {
            id: 4,
            name: "Предотвращения столкновения",
            className: "option-full-width",
          },
          {
            id: 5,
            name: "Контроля слепых зон",
            className: "option-full-width",
          },
          {
            id: 6,
            name: "Контроля за полосой движения",
            className: "option-full-width",
          },
          {
            id: 7,
            name: "Датчик усталости водителя",
            className: "option-full-width",
          },
          {
            id: 8,
            name: "Распознавания дорожных знаков",
            className: "option-full-width",
          },
          { id: 9, name: "Ночного видения", className: "option-full-width" },
        ],
      },
    ],
  ],
];
export const otherFilters: Array<App.AdvancedFilter.Row> = [
  [
    [
      {
        id: 86,
        name: "Тип дисков",
        type: "picker",
        options: [
          { id: 1, name: "Стальные" },
          { id: 2, name: "Легкосплавные" },
        ],
      },
    ],
    [
      {
        id: 87,
        name: "Размер дисков",
        type: "range",
        slider: true,
        min: 12,
        max: 28,
        step: 1,
        dimensions: "*",
      },
    ],
    [
      {
        id: 88,
        name: "Рейлинги на крыше",
        type: "checkbox",
      },
    ],
    [],
  ],
];

export const mainFiltersCA: [
  Array<App.Filter.Filter>,
  Array<App.Filter.Filter>
] = [
  [
    {
      id: 1,
      name: "Пробег",
      type: "number",
      min: 0,
      max: 10000000,
      step: 1000,
      dimensions: [{ id: 1, name: "км." }],
    },
    {
      id: 2,
      name: "Объем двигателя",
      type: "number",
      min: 0,
      max: 10,
      step: 0.1,
      dimensions: [{ id: 1, name: "л." }],
    },
    {
      id: 3,
      name: "Коробка",
      type: "picker",
      options: [
        { id: 1, name: "Автомат" },
        { id: 2, name: "Механика" },
      ],
    },
    {
      id: 4,
      name: "На гарантии",
      type: "checkbox",
    },
  ],
  [
    {
      id: 5,
      name: "Двигатель",
      type: "select",
      options: [
        { id: 1, name: "Бензин" },
        { id: 2, name: "Гибрид" },
        { id: 3, name: "Дизель" },
        { id: 4, name: "Электро" },
      ],
    },
    {
      id: 6,
      name: "Тип кузова",
      type: "select",
      options: [
        { id: 1, name: "Внедорожник" },
        { id: 2, name: "Седан" },
        { id: 3, name: "Хэтчбек" },
        { id: 4, name: "Универсал" },
        { id: 5, name: "Лифтбек" },
        { id: 6, name: "Минивэн" },
        { id: 7, name: "Купе" },
        { id: 8, name: "Пикап" },
        { id: 9, name: "Кабриолет" },
        { id: 10, name: "Фургон" },
      ],
    },
    {
      id: 7,
      name: "Тип кузова",
      type: "select",
      options: [
        { id: 1, name: "Передний" },
        { id: 2, name: "Полный" },
        { id: 3, name: "Задний" },
      ],
    },
  ],
];
export const visibilityFiltersCA: [
  Array<App.Filter.Filter>,
  Array<App.Filter.Filter>
] = [
  [
    {
      name: "Фары",
      type: "group",
      checkboxes: [
        { id: 1, type: "checkbox", name: "Противотуманные фары" },
        { id: 2, type: "checkbox", name: "Омыватель фар" },
        { id: 3, type: "checkbox", name: "Автоматический корректор фар" },
      ],
    },
    {
      name: "Датчики",
      type: "group",
      checkboxes: [
        { id: 4, type: "checkbox", name: "Дождя" },
        { id: 5, type: "checkbox", name: "Света" },
      ],
    },
  ],
  [
    {
      name: "Электрообогрев",
      type: "group",
      checkboxes: [
        { id: 7, type: "checkbox", name: "Зоны стеклоочистителей" },
        { id: 8, type: "checkbox", name: "Лобового стекла" },
        { id: 6, type: "checkbox", name: "Боковых зеркал" },
      ],
    },
  ],
];
export const salonFiltersCA: [
  Array<App.Filter.Filter>,
  Array<App.Filter.Filter>
] = [
  [
    {
      id: 9,
      name: "Материал салона",
      type: "select",
      options: [
        { id: 1, name: "Ткань" },
        { id: 2, name: "Велюр" },
        { id: 3, name: "Комбинир." },
        { id: 4, name: "Искусств. кожа" },
        { id: 5, name: "Алькантра" },
        { id: 6, name: "Кожа" },
      ],
    },
    {
      id: 10,
      name: "Электрорегулировка сидений",
      type: "select",
      options: [
        { id: 1, name: "Сидения водителя" },
        { id: 2, name: "Передних сидений" },
      ],
    },
    {
      type: "group",
      checkboxes: [
        { id: 11, type: "checkbox", name: "Обогрев рулевого колеса" },
        { id: 12, type: "checkbox", name: "Отделка кожей рулевого колеса" },
        { id: 13, type: "checkbox", name: "Люк" },
        { id: 14, type: "checkbox", name: "Панорамная крыша" },
        { id: 15, type: "checkbox", name: "Передний центральный подлокотник" },
        { id: 16, type: "checkbox", name: "Спортивные передние сидения" },
        { id: 17, type: "checkbox", name: "Третий ряд сидений" },
      ],
    },
  ],
  [
    {
      id: 18,
      name: "Память положения сидений",
      type: "select",
      options: [
        { id: 1, name: "Сидения водителя" },
        { id: 2, name: "Передних сидений" },
      ],
    },
  ],
];
export const multimediaFiltersCA: [
  Array<App.Filter.Filter>,
  Array<App.Filter.Filter>
] = [
  [
    {
      type: "group",
      checkboxes: [
        { id: 19, name: "AUX", type: "checkbox" },
        { id: 20, name: "USB", type: "checkbox" },
        { id: 21, name: "Bluetooth", type: "checkbox" },
      ],
    },
    {
      type: "group",
      checkboxes: [
        { id: 22, name: "Навигационная система", type: "checkbox" },
        { id: 23, name: "Голосовое управлление", type: "checkbox" },
      ],
    },
  ],
  [],
];
export const otherFiltersCA: [
  Array<App.Filter.Filter>,
  Array<App.Filter.Filter>
] = [
  [
    {
      id: 72,
      name: "Тип дисков",
      type: "select",
      options: [
        { id: 1, name: "Стальные" },
        { id: 2, name: "Легкосплавные" },
      ],
    },
    {
      id: 73,
      type: "checkbox",
      name: "Рейлинги на крыше",
    },
  ],
  [
    {
      id: 74,
      name: "Размер дисков",
      type: "number",
      min: 12,
      max: 30,
      step: 1,
    },
  ],
];
export const safetyFiltersCA: [
  Array<App.Filter.Filter>,
  Array<App.Filter.Filter>
] = [
  [
    {
      type: "group",
      name: "Подушки безопасности",
      checkboxes: [
        { id: 24, name: "Водителя", type: "checkbox" },
        { id: 25, name: "Пассажира", type: "checkbox" },
        { id: 26, name: "Боковые задние", type: "checkbox" },
        { id: 27, name: "Боковые передние", type: "checkbox" },
        { id: 28, name: "Оконные", type: "checkbox" },
        { id: 29, name: "Коленей водителя", type: "checkbox" },
      ],
    },
    {
      type: "group",
      name: "Прочее",
      checkboxes: [
        { id: 30, name: "Система крепления isofix", type: "checkbox" },
        { id: 31, name: "Датчик давления в шинах", type: "checkbox" },
        { id: 32, name: "Блокировка замков задних дверей", type: "checkbox" },
        { id: 33, name: "Антиблокировочная система (ABS)", type: "checkbox" },
        { id: 34, name: "Система стабилизации (ESP)", type: "checkbox" },
      ],
    },
  ],
  [
    {
      type: "group",
      name: "Защита от угона",
      checkboxes: [
        { id: 35, name: "Сигнализация ", type: "checkbox" },
        { id: 36, name: "Центральный замок", type: "checkbox" },
        { id: 37, name: "Иммобилайзер", type: "checkbox" },
      ],
    },
    {
      type: "group",
      name: "Вспомогательные системы",
      checkboxes: [
        { id: 37, name: "Антипробуксовочная (ARS)", type: "checkbox" },
        { id: 38, name: "Помощи при торможении(BAS,EBD)", type: "checkbox" },
        { id: 39, name: "Помощи при старте в гору", type: "checkbox" },
        { id: 40, name: "Предотвращения столкновения", type: "checkbox" },
        { id: 41, name: "Контроля слепых зон", type: "checkbox" },
        { id: 42, name: "Контроля за полосой движения", type: "checkbox" },
        { id: 43, name: "Датчик усталости водителя", type: "checkbox" },
        { id: 44, name: "Распознавания дорожных знаков", type: "checkbox" },
        { id: 45, name: "Ночного видения", type: "checkbox" },
      ],
    },
  ],
];
export const comfortFiltersCA: [
  Array<App.Filter.Filter>,
  Array<App.Filter.Filter>
] = [
  [
    {
      id: 46,
      name: "Кондиционер",
      type: "select",
      options: [
        { id: 1, name: "Любой" },
        {
          id: 2,
          name: "Климат-контроль 1-зонный",
        },
        {
          id: 3,
          name: "Климат-контроль 2-зонный",
        },
        {
          id: 4,
          name: "Климат-контроль многозонный",
        },
      ],
    },
    {
      id: 47,
      name: "Круиз-контроль",
      type: "select",
      options: [
        { id: 1, name: "Круиз-контроль" },
        {
          id: 2,
          name: "Адаптивный круиз-контроль",
        },
      ],
    },
    {
      type: "group",
      name: "Электростеклоподъемники",
      checkboxes: [
        { id: 48, name: "Передние", type: "checkbox" },
        { id: 49, name: "Задние", type: "checkbox" },
      ],
    },
    {
      type: "group",
      name: "Камера",
      checkboxes: [
        { id: 50, name: "Передняя", type: "checkbox" },
        { id: 51, name: "Задняя", type: "checkbox" },
        { id: 52, name: "360", type: "checkbox" },
      ],
    },
    {
      type: "group",
      name: "Регулировка руля",
      checkboxes: [
        { id: 53, name: "По высоте", type: "checkbox" },
        { id: 54, name: "По вылету", type: "checkbox" },
        { id: 55, name: "Электрорегулировка", type: "checkbox" },
        { id: 56, name: "С памятью положения", type: "checkbox" },
      ],
    },
    {
      type: "group",
      name: "Помощник на парковке",
      checkboxes: [
        { id: 57, name: "Парктроник передний", type: "checkbox" },
        { id: 58, name: "Парктроник задний", type: "checkbox" },
        { id: 59, name: "Автоматиеская парковка", type: "checkbox" },
      ],
    },
  ],
  [
    {
      id: 60,
      name: "Усилитель руля",
      type: "select",
      options: [
        { id: 1, name: "Усилитель руля" },
        {
          id: 2,
          name: "Адаптивный усилитель руля",
        },
      ],
    },
    {
      type: "group",
      name: "Прочее",
      checkboxes: [
        { id: 61, name: "Бортовой компьютер", type: "checkbox" },
        { id: 62, name: "Электронная приборная панель", type: "checkbox" },
        { id: 63, name: "Система доступа без ключа", type: "checkbox" },
        { id: 64, name: "Запуск двигателя с кнопки", type: "checkbox" },
        {
          id: 65,
          name: "Программируемый предпусковой отопитель",
          type: "checkbox",
        },
        { id: 66, name: "Электропривод крышки багажника", type: "checkbox" },
        { id: 67, name: "Система “старт-стоп”", type: "checkbox" },
        { id: 68, name: "Электроскладывание зеркал", type: "checkbox" },
        {
          id: 69,
          name: "Мультифункциональное рулевое колесо",
          type: "checkbox",
        },
        { id: 70, name: "Регулируемый педальный узел", type: "checkbox" },
        { id: 71, name: "Дистанционный запуск двигателя", type: "checkbox" },
        { id: 72, name: "Электропривод зеркал", type: "checkbox" },
        { id: 73, name: "Подрулевые лепестки передач", type: "checkbox" },
      ],
    },
  ],
];

export const brands: Array<App.Car.Brand> = [
  {
    id: 1,
    name: "Lada",
    src: "https://pngimg.com/uploads/lada/lada_PNG124.png",
  },
  {
    id: 2,
    name: "Renault",
    src:
      "https://img2.freepng.ru/20180410/klw/kisspng-renault-symbol-car-mazda-peugeot-renault-5acd8077c588a8.3187181415234172078091.jpg",
  },
  {
    id: 3,
    name: "AUDI",
    src:
      "https://avtokristall.ru/upload/medialibrary/038/038a7ec2777263427d3e16607b82c196.jpg",
  },
  {
    id: 4,
    name: "BMW",
    src: "https://pbs.twimg.com/media/D5-4E01W4AE53hp.png",
  },
  {
    id: 5,
    name: "Mersedes",
    src:
      "https://banner2.cleanpng.com/20171220/fie/mercedes-logo-png-5a3a1e3887a2c0.04630940151375826455563099.jpg",
  },
  {
    id: 6,
    name: "Voltsvagen",
    src:
      "https://img2.freepng.ru/20180614/fca/kisspng-volkswagen-golf-car-chevrolet-corvette-volkswagen-polo-5b21faea8413c9.284405321528953578541.jpg",
  },
  {
    id: 7,
    name: "Ford",
    src:
      "https://a.mktgcdn.com/p/M6fWGM7MOgELqsa4Lq9C4ZieeRks2m4r2SWd39JBqWc/2000x2000.jpg",
  },
  {
    id: 8,
    name: "Opel",
    src: "https://pngimg.com/uploads/opel/opel_PNG2.png",
  },
  {
    id: 9,
    name: "Tesla",
    src: "https://i.ya-webdesign.com/images/tesla-car-logo-png-5.png",
  },
  {
    id: 10,
    name: "Masda",
    src: "https://www.equipnet.ru/netcat_files/u/mazda_autoprodix_e_logo.png",
  },
];
export const models: Array<App.Car.Model> = [
  { id: 1, name: "Vesta", brand_id: 1 },
  { id: 2, name: "X-ray", brand_id: 1 },
  { id: 3, name: "Granta", brand_id: 1 },
  { id: 4, name: "Arkana", brand_id: 2 },
  { id: 5, name: "Logan", brand_id: 2 },
  { id: 6, name: "Duster", brand_id: 2 },
  { id: 7, name: "TT", brand_id: 3 },
  { id: 8, name: "A6", brand_id: 3 },
  { id: 9, name: "A3", brand_id: 3 },
  { id: 10, name: "x5", brand_id: 4 },
  { id: 11, name: "x6", brand_id: 4 },
  { id: 12, name: "100", brand_id: 5 },
  { id: 13, name: "Golf", brand_id: 6 },
  { id: 14, name: "Polo", brand_id: 6 },
  { id: 15, name: "Mustang", brand_id: 7 },
  { id: 16, name: "GT-40", brand_id: 7 },
  { id: 17, name: "Astra", brand_id: 8 },
  { id: 18, name: "Zafira", brand_id: 8 },
  { id: 19, name: "Cybertrack", brand_id: 9 },
  { id: 20, name: "3", brand_id: 10 },
  { id: 21, name: "CX-5", brand_id: 10 },
];
export const carcasses: Array<App.Car.Carcass> = [
  { id: 1, name: "Внедорожник", img: "", doors_number: 5 },
  { id: 2, name: "Седан", img: "", doors_number: 5 },
  { id: 3, name: "Хэтчбек", img: "", doors_number: 5 },
  { id: 4, name: "Универсал", img: "", doors_number: 5 },
  { id: 5, name: "Лифтбек", img: "", doors_number: 5 },
  { id: 6, name: "Минивэн", img: "", doors_number: 5 },
  { id: 7, name: "Купе", img: "", doors_number: 5 },
  { id: 8, name: "Пикап", img: "", doors_number: 5 },
  { id: 9, name: "Кабриолет", img: "", doors_number: 5 },
  { id: 10, name: "Фургон", img: "", doors_number: 5 },
];
export const transmissions: Array<App.Car.Transmission> = [
  { id: 1, name: "Ручная" },
  { id: 2, name: "Автомат" },
];
export const motorTypes: Array<App.Car.MotorType> = [
  { id: 1, name: "Бензин" },
  { id: 2, name: "Дизель" },
  { id: 3, name: "Электро" },
];
export const drives: Array<App.Car.Drive> = [
  { id: 1, name: "Передний" },
  { id: 2, name: "Задний" },
  { id: 3, name: "Полный" },
];
export const colors: Array<App.Car.Color> = [
  { id: 1, name: "Красный", hash: "#ff0000" },
  { id: 2, name: "Розовый", hash: "#ff00ff" },
  { id: 3, name: "Зелёный", hash: "#00ff00" },
];
