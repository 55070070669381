import SCarcass from "../carcass/SCarcass";
import SDrive from "../drive/SDrive";
import SMotor from "../motor/SMotor";
import STransmission from "../transmission/STransmission";

class SVersion implements App.Car.Version {
  public id: number;
  public generationId: number;
  public name: string;
  public carcass: App.Car.Carcass;
  public transmission: App.Car.Transmission;
  public drive: App.Car.Drive;
  public motor: App.Car.Motor;

  constructor(data?: any) {
    this.id = 0;
    this.generationId = 0;
    this.name = "";
    this.carcass = new SCarcass();
    this.transmission = new STransmission();
    this.drive = new SDrive();
    this.motor = new SMotor();
    data && this.init(data);
  }

  private init(data: any) {
    try {
      this.id = data.id || 0;
      this.generationId = data.generationId || 0;
      this.name = data.name || "";
      this.carcass = new SCarcass(data.carcass);
      this.transmission = new STransmission(data.transmission);
      this.drive = new SDrive(data.car_drive);
      this.motor = new SMotor(data.car_motor);
    } catch {}
  }
}

export default SVersion;
