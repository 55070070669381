import "./SigninForm.scss";

import React, { SyntheticEvent } from "react";

import LabelText from "../../custom/labels/labelText/LabelText";
import { NavLink } from "react-router-dom";
import RegExp from "../../../utils/string/RegExp";
import { signup } from "../../../routes";

export type SigninData = {
  email: string;
  password: string;
};
interface Props {
  value: SigninData;
  errors: Array<string>;
  onChange: (value: SigninData) => void;
  onSubmit: () => void;
}
interface State {}

class SigninForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  private get isUncorrectEmail() {
    return !(this.props.value.email.length === 0 || this.isCorrectEmail);
  }

  private get isUncorrectPassword() {
    return !(this.props.value.password.length === 0 || this.isCorrectPassword);
  }
  private get isCorrectEmail() {
    const match = this.props.value.email.match(RegExp.email);
    if (!match) return false;
    return match[0] === this.props.value.email;
  }
  private get isCorrectPassword() {
    return (
      this.props.value.password.length > 3 &&
      this.props.value.password.length <= 32
    );
  }
  private get isCorrect() {
    return this.isCorrectEmail && this.isCorrectPassword;
  }

  private handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    if (this.isCorrect) {
      this.props.onSubmit();
    }
  }

  private handleChangeEmail(value: string) {
    this.props.onChange(Object.assign({}, this.props.value, { email: value }));
  }

  private handleChangePassword(value: string) {
    this.props.onChange(
      Object.assign({}, this.props.value, { password: value })
    );
  }

  public render() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)} className="signin-form">
        <div className="signin-form-header">
          <h3>Войти</h3>
          <span>
            Новый пользователь? <NavLink to={signup}>Регистрация</NavLink>{" "}
          </span>
        </div>
        <LabelText
          name="Email"
          type="email"
          uncorrect={this.isUncorrectEmail}
          error={
            this.isUncorrectEmail
              ? "Email должен соответствовать виду email@mail.com"
              : undefined
          }
          correct={this.isCorrectEmail}
          value={this.props.value.email}
          onChange={this.handleChangeEmail.bind(this)}
          required
        />

        <LabelText
          name="Пароль"
          type="password"
          uncorrect={this.isUncorrectPassword}
          error={
            this.isUncorrectPassword
              ? "Пароль должен содержать от 4 до 32 символов"
              : undefined
          }
          correct={this.isCorrectPassword}
          value={this.props.value.password}
          onChange={this.handleChangePassword.bind(this)}
          minLength={4}
          maxLength={32}
          required
        />

        {this.props.errors.length > 0 && (
          <div className="errors">
            {this.props.errors.map((error, key) => (
              <span className="error" key={key}>
                {error}
              </span>
            ))}
          </div>
        )}

        <button disabled={!this.isCorrect}>Вход</button>
      </form>
    );
  }
}
export default SigninForm;
