import "./Result.scss";

import React from "react";
import StringMethods from "../../../../utils/string/StringMethods";
import { Unsubscribe } from "redux";
import { researchAnnouncementsAdvanced } from "../../../../store/actions/announcements/advanced";
import store from "../../../../store/store";

interface Props {}
interface State {
  numberResults: number;
}

class AdvancedFilterHeaderResult extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.state = {
      numberResults: 0,
    };
    this.unsubscribe = () => {};
  }

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  public get value() {
    return this.state.numberResults
      ? `Найдено ${StringMethods.beautifyNumber(this.state.numberResults)}`
      : "Ничего не найдено";
  }
  private handleUpdateStore() {
    const { number } = store.getState().advanced;
    this.setState({
      numberResults: number,
    });
  }

  private handleClick() {
    store.dispatch(researchAnnouncementsAdvanced());
  }
  public render() {
    return (
      <div
        className="advanced-filter__result"
        onClick={this.handleClick.bind(this)}
      >
        {this.value}
      </div>
    );
  }
}

export default AdvancedFilterHeaderResult;
