import "./AnnouncementPageStatistic.scss";

import AnnouncementActionsContainer from "../../../../containers/announcement/actions/AnnouncementActionsContainer";
import React from "react";
import Time from "../../../../utils/time/Time";
import ViewsIcon from "./assets/view-eye.png";

interface Props {
  announcement?: App.Announcement.Announcement;
  onUpdateAnnouncement: (announcement: App.Announcement.Announcement) => void;
}
interface State {}

class AnnouncementPageStatistic extends React.Component<Props, State> {
  public render() {
    return (
      <div className="announcement-page-statistic">
        <div>
          {this.props.announcement
            ? Time.beautifyDate(this.props.announcement?.created)
            : "**.**.****"}
        </div>
        <div className="views">
          <img src={ViewsIcon} alt="" />
          <span>
            {this.props.announcement?.viewsTotal || 0} (
            {this.props.announcement?.viewsToday || 0} сегодня)
          </span>
        </div>
        <div>
          <span>№{this.props.announcement?.id || 0}</span>
        </div>
        {this.props.announcement && (
          <AnnouncementActionsContainer
            onUpdateAnnouncement={this.props.onUpdateAnnouncement}
            announcement={this.props.announcement}
            informationCard
          />
        )}
      </div>
    );
  }
}
export default AnnouncementPageStatistic;
