import "./ContactForm.scss";

import { AddContactValue } from "../../../containers/user/addContact/AddContactContainer";
import LabelText from "../../custom/labels/labelText/LabelText";
import React from "react";
import RegExp from "../../../utils/string/RegExp";
import SubTitle from "../../custom/subtitle/SubTitle";
import Time from "../../../utils/time/Time";

interface Props {
  disabled: boolean;
  value: AddContactValue;
  onChange: (value: AddContactValue) => void;
  code: string;
  onChangeCode: (code: string) => void;
  complited: boolean;
  isDisplayCodeLabel: boolean;
  startTimestampResendCode: number;
  errors: Array<string>;
  restart: () => void;
  onGetSmsCode: () => void;
  onSubmit: () => void;
}
interface State {
  sec: number;
}

class ContactForm extends React.Component<Props, State> {
  private interval: number;
  constructor(props: Props) {
    super(props);
    this.interval = 0;
    this.state = {
      sec: 0,
    };
  }

  public componentDidUpdate(props: Props) {
    if (this.props.startTimestampResendCode === props.startTimestampResendCode)
      return;
    clearInterval(this.interval);
    this.interval = window.setInterval(
      this.updateSec.bind(this),
      Time.second / 10
    );
  }

  private updateSec() {
    this.setState({
      sec: Math.round(
        (this.props.startTimestampResendCode + 30 * Time.second - +new Date()) /
          Time.second
      ),
    });
    if (this.state.sec === 0) {
      clearInterval(this.interval);
    }
  }
  private get isCorrectPhone() {
    const match = this.props.value.phone.match(RegExp.phone);
    if (match) {
      return match[0] === this.props.value.phone;
    }
    return false;
  }
  private get isCorrectName() {
    return this.props.value.name.length > 1;
  }
  private handleChangePhone(phone: string) {
    this.props.onChange(Object.assign({}, this.props.value, { phone }));
  }
  private handleChangeName(name: string) {
    this.props.onChange(Object.assign({}, this.props.value, { name }));
  }

  public render() {
    return (
      <div className="contact-form">
        <SubTitle>Добавть контакт</SubTitle>
        <LabelText
          type="phone"
          placeholder="+375290000000"
          value={this.props.value.phone}
          onChange={this.handleChangePhone.bind(this)}
          uncorrect={!this.isCorrectPhone && this.props.value.phone.length > 0}
          correct={this.isCorrectPhone}
          disabled={this.props.disabled || this.props.isDisplayCodeLabel}
        />
        <LabelText
          value={this.props.value.name}
          placeholder="Имя"
          uncorrect={this.props.value.name.length === 1}
          correct={this.isCorrectName}
          onChange={this.handleChangeName.bind(this)}
          maxLength={32}
          disabled={this.props.disabled || this.props.isDisplayCodeLabel}
        />

        {!this.props.isDisplayCodeLabel && (
          <button
            disabled={
              this.props.disabled || !this.isCorrectPhone || !this.isCorrectName
            }
            onClick={this.props.onGetSmsCode}
          >
            Далее
          </button>
        )}

        {this.props.isDisplayCodeLabel && (
          <>
            <LabelText
              placeholder="000000"
              value={this.props.code}
              onChange={this.props.onChangeCode}
              maxLength={6}
              disabled={this.props.disabled}
            />
            <button
              onClick={this.props.restart}
              className="restart"
              disabled={this.props.disabled}
            >
              Отмена
            </button>
            <button
              onClick={this.props.onSubmit}
              disabled={this.props.disabled}
            >
              Сохранить
            </button>

            {this.state.sec ? (
              <span>
                Повторно можно получить код через {this.state.sec} сек.
              </span>
            ) : (
              <button
                onClick={this.props.onGetSmsCode}
                disabled={this.props.disabled}
              >
                Получить код
              </button>
            )}
          </>
        )}
        {this.props.errors.length > 0 && (
          <div className="error">{this.props.errors.join(" ")}</div>
        )}
      </div>
    );
  }
}

export default ContactForm;
