import "./BaseNav.scss";

import {
  adminPanel,
  administrate,
  announcements,
  compareCar,
  filters,
} from "../../../routes";

import { NavLink } from "react-router-dom";
import React from "react";

interface Props {
  isAdmin: boolean;
}
interface State {}

class BaseNav extends React.Component<Props, State> {
  public render() {
    return (
      <nav className="base-nav">
        <NavLink to={announcements}>ОБЪЯВЛЕНИЯ</NavLink>
        <NavLink to={compareCar}>СРАВНЕНИЕ АВТО</NavLink>
        <NavLink to={filters}>РАСШИРЕННЫЙ ФИЛЬТР</NavLink>
        {this.props.isAdmin && <NavLink to={adminPanel}>СОЗДАНИЕ АВТО</NavLink>}
        {this.props.isAdmin && <NavLink to={administrate}>АДМИНКА</NavLink>}
      </nav>
    );
  }
}

export default BaseNav;
