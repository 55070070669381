import React from "react";

interface Props {
  page: number;
  active: boolean;
  onClick: (page: number) => void;
}
interface State {}

class PaginationPage extends React.Component<Props, State> {
  static defaultProps = {
    active: false,
  };
  private handleClick() {
    this.props.active || this.props.onClick(this.props.page);
  }
  public render() {
    return (
      <div
        className="pagination__page pagination-tab"
        onClick={this.handleClick.bind(this)}
        data-active={this.props.active}
      >
        {this.props.page}
      </div>
    );
  }
}

export default PaginationPage;
