import "./AnnouncementListTags.scss";

import React from "react";
import Tag from "../../../tag/Tag";

interface Props {
  tags?: Array<App.Tag>;
  dropFilters?: () => void;
}
interface State {}

class AnnouncementListTags extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <>
        {this.props.tags && (
          <div className="announcement-list-tags">
            {this.props.tags.map((tag, tagIndex) => (
              <Tag key={tagIndex} name={tag.name} remove={tag.remove} />
            ))}
            {this.props.tags.length > 0 && this.props.dropFilters && (
              <button className="drop-filters" onClick={this.props.dropFilters}>
                Сбросить фильтры
              </button>
            )}
          </div>
        )}
      </>
    );
  }
}
export default AnnouncementListTags;
