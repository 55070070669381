import "./ResultCard.scss";

import React from "react";

interface Props {
  card: { name: string; options: Array<string> };
}
interface State {}

class CreateAnnouncementResultCard extends React.Component<Props, State> {
  public render() {
    return (
      <div className="result-card">
        <div className="name">{this.props.card.name}</div>
        <div className="result-card__tags">
          {this.props.card.options.map((tag, key) => (
            <span key={key} className="result-card__tag">
              {tag}
            </span>
          ))}
        </div>
      </div>
    );
  }
}
export default CreateAnnouncementResultCard;
