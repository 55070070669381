import {
  BYN_ID,
  PRICE_MAX,
  PRICE_MIN,
  PRICE_STEP,
  USD_ID,
} from "../../../../Config";

import LabelNumber from "../../../../components/custom/labels/labelNumber/LabelNumber";
import React from "react";

interface Props {
  currency: App.ID;
  price?: number;
  onChange: (price: number) => void;
  onChangeCurrency: (currency: App.ID) => void;
}
interface State {
  currencyList: Array<App.Info>;
}

class CreateAnnouncementPrice extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currencyList: [
        { id: BYN_ID, name: "BYN" },
        { id: USD_ID, name: "USD" },
      ],
    };
  }

  public render() {
    return (
      <LabelNumber
        value={this.props.price}
        onChange={this.props.onChange}
        onChangeDimension={this.props.onChangeCurrency}
        name="Цена"
        dimension={this.props.currency}
        dimensions={this.state.currencyList}
        controlls={true}
        min={PRICE_MIN}
        step={PRICE_STEP}
        max={PRICE_MAX}
        maxLength={7}
      />
    );
  }
}

export default CreateAnnouncementPrice;
