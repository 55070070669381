import "./Carousel.scss";

import CarouselActiveImage from "./activeImage/ActiveImage";
import CarouselImagesList from "./list/List";
import React from "react";

interface Props {
  images: Array<string>;
  active: number;
}
interface State {
  active: number;
}

class Carousel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: 0,
    };
  }

  static defaultProps = {
    active: 0,
  };

  public componentDidMount() {
    this.setState({ active: this.props.active });
  }
  public componentDidUpdate(props: Props) {
    if (this.props.active === props.active) return;
    this.handleChangeActiveImage(this.props.active);
  }
  public handleChangeActiveImage(active: number) {
    if (active >= this.props.images.length) {
      active = this.props.images.length - 1;
    } else if (isNaN(active) || active < 0) {
      active = 0;
    }

    const newState = { active };
    this.setState(newState);
    return newState;
  }

  public render() {
    return (
      <div className="carousel">
        <CarouselActiveImage image={this.props.images[this.state.active]} />

        <CarouselImagesList
          onChange={this.handleChangeActiveImage.bind(this)}
          images={this.props.images}
          active={this.state.active}
        />
      </div>
    );
  }
}

export default Carousel;
