import CarService, {
  GenerationData,
} from "../../../services/abstract/CarService";

import CarServiceApi from "../../../services/api/CarServiceApi";
import { OK } from "http-status-codes";
import React from "react";
import RedactGeneration from "../../../components/adminPanel/redactGeneration/RedactGeneration";
import SMessage from "../../../struct/message/SMessage";
import { addMessage } from "../../../store/actions/messages";
import store from "../../../store/store";

interface Props {
  model: App.Car.Model;
  onChangeActiveGeneration: (generation?: App.Car.Generation) => void;
}
interface State {
  generation?: App.Car.Generation;
  generations: Array<App.Car.Generation>;
}

class RedactGenerationContainer extends React.Component<Props, State> {
  private service: CarService;
  constructor(props: Props) {
    super(props);
    this.service = new CarServiceApi();
    this.state = {
      generations: [],
    };
  }

  public componentDidMount() {
    this.getGenerations();
  }
  public componentDidUpdate(props: Props, state: State) {
    if (props.model !== this.props.model) {
      this.getGenerations();
      this.props.onChangeActiveGeneration();
    } else if (state.generation !== this.state.generation) {
      this.props.onChangeActiveGeneration(this.state.generation);
    }
  }

  private async getGenerations() {
    const { data, status, errors } = await this.service.getGenerations(
      +this.props.model.id
    );
    if (status === OK) {
      this.setState({ generations: data, generation: undefined });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }

  private async handleSave(generation: GenerationData) {
    if (this.state.generation) {
      this.update(this.state.generation.id, generation);
    } else {
      this.add(generation);
    }
  }

  private async add(generation: GenerationData) {
    generation.modelId = +this.props.model.id;
    const { data, status, errors } = await this.service.addGeneration(
      generation
    );
    if (status === OK) {
      this.updateList(data);
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }
  private async update(id: App.ID, generation: GenerationData) {
    const { data, status, errors } = await this.service.updateGeneration(
      id,
      generation
    );
    if (status === OK) {
      this.updateList(data);
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }
  private updateList(data: App.Car.Generation) {
    if (this.state.generations.some((v) => v.id === data.id)) {
      this.setState({
        generation: data,
        generations: this.state.generations.map((v) =>
          v.id === data.id ? data : v
        ),
      });
    } else {
      this.setState({
        generation: data,
        generations: this.state.generations.concat(data),
      });
    }
  }

  private async delete() {
    if (!this.state.generation) return false;
    const id = this.state.generation.id;
    const { status, errors } = await this.service.deleteGeneration(id);
    if (status === OK) {
      this.setState({
        generation: undefined,
        generations: this.state.generations.filter((g) => g.id !== id),
      });
      return true;
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
    return false;
  }

  private handleSelectGeneration(generation: App.Car.Generation) {
    if (this.state.generation && this.state.generation.id === generation.id) {
      this.setState({ generation: undefined });
    } else {
      this.setState({ generation });
    }
  }

  public render() {
    return (
      <RedactGeneration
        generations={this.state.generations}
        generation={this.state.generation}
        onSave={this.handleSave.bind(this)}
        onDelete={this.delete.bind(this)}
        onSelectGeneration={this.handleSelectGeneration.bind(this)}
      />
    );
  }
}

export default RedactGenerationContainer;
