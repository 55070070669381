import "./Tag.scss";

import CrossIcon from "../../icons/Cross";
import React from "react";

interface Props {
  name: string;
  remove?: () => void;
}
interface State {}

class Tag extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="tag">
        <div className="name">{this.props.name}</div>
        {this.props.remove && (
          <div onClick={this.props.remove} className="close">
            <CrossIcon />
          </div>
        )}
      </div>
    );
  }
}
export default Tag;
