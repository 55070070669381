import { Route, RouteComponentProps, Switch } from "react-router";
import { administrateAnnouncements, signin } from "../../routes";

import AdministrateAnnouncementsContainer from "../../containers/administrateAnnouncements/AdministrateAnnouncementsContainer";
import React from "react";
import { Unsubscribe } from "redux";
import store from "../../store/store";

interface Props extends RouteComponentProps {}
interface State {}

class AdministrateView extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {};
  }
  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
    document.title = "Администрирование";
    window.scrollTo({ top: 0 });
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }
  private handleUpdateStore() {
    if (!store.getState().user.isAdmin) {
      this.props.history.push(signin);
    }
  }
  public render() {
    return (
      <Switch>
        <Route
          path={administrateAnnouncements}
          component={AdministrateAnnouncementsContainer}
        />
        <Route
          render={() => {
            this.props.history.replace(administrateAnnouncements);
            return <></>;
          }}
        />
      </Switch>
    );
  }
}

export default AdministrateView;
