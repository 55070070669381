import React from "react";

interface Props {
  contact?: App.Announcement.Contact;
}
interface State {}
class PeopleAndPlace extends React.Component<Props, State> {
  public get city() {
    return this.props.contact?.city || "Город";
  }
  public render() {
    return (
      <div className="announcement-contact__info">
        {this.props.contact && (
          <span className="name">{this.props.contact.name}</span>
        )}
        <span className="city">{this.city}</span>
      </div>
    );
  }
}

export default PeopleAndPlace;
