type LOAD_CYRRENCY = "LOAD_CYRRENCY";
type SAVE_CYRRENCY = "SAVE_CYRRENCY";

interface SaveCurrencyAction {
  type: SAVE_CYRRENCY;
  USD: App.Currency.Currency;
}
interface LoadCurrencyAction {
  type: LOAD_CYRRENCY;
}

export type Actions = SaveCurrencyAction | LoadCurrencyAction;

export const saveCurrencyType: SAVE_CYRRENCY = "SAVE_CYRRENCY";
export const loadCurrencyType: LOAD_CYRRENCY = "LOAD_CYRRENCY";

export function saveCurrency(USD: App.Currency.Currency): SaveCurrencyAction {
  return {
    type: saveCurrencyType,
    USD,
  };
}
export function loadCurrency(): LoadCurrencyAction {
  return {
    type: loadCurrencyType,
  };
}
