import "./AnnouncementListBodyLoader.scss";

import React from "react";

interface Props {}
interface State {}

class AnnouncementListBodyLoader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="announcement-list-body-loader">
        <div id="circleG">
          <div id="circleG_1" className="circleG"></div>
          <div id="circleG_2" className="circleG"></div>
          <div id="circleG_3" className="circleG"></div>
        </div>
      </div>
    );
  }
}
export default AnnouncementListBodyLoader;
