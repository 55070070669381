import AnnouncementStat from "../stat/AnnouncementStat";
import React from "react";

interface Props {
  swap?: boolean;
}
interface State {}

class AnnouncementStatSwap extends React.Component<Props, State> {
  public get value() {
    if (this.props.swap) {
      return "Да";
    } else {
      return "Нет";
    }
  }
  public render() {
    return <AnnouncementStat name={"Обмен"} value={this.value} />;
  }
}

export default AnnouncementStatSwap;
