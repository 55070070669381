import { INTERNAL_SERVER_ERROR, OK } from "http-status-codes";
import { logout, saveSession } from "../../store/actions/user";

import { AxiosResponse } from "axios";
import { FATAL_ERROR_SERVER } from "../../Config";
import Time from "../../utils/time/Time";
import UserServiceApi from "./UserServiceApi";
import store from "../../store/store";

let updatingTokens: any | null = null;
let isSending: boolean = false;

export function handleResponse(
  response: AxiosResponse | App.Response<any>
): App.Response<any> {
  return {
    data: response.data,
    status: response.status,
    errors: [],
  };
}
export function handleError(
  response?: AxiosResponse<Array<string>>
): App.Response<any> {
  if (response && Object.keys(response.data || {}).includes("trace")) {
    response = undefined;
  }
  const newResponse: App.Response<void> = {
    data: undefined,
    status: response?.status || INTERNAL_SERVER_ERROR,
    errors: response?.data || [FATAL_ERROR_SERVER],
  };
  return newResponse;
}

export async function handle403(
  data: any,
  callback: (data: any, tokens: App.User.Session) => any
) {
  const response = await updateTokens();
  if (response.status === OK) {
    store.dispatch(saveSession(response.data));
    return callback(data, response.data);
  } else {
    store.dispatch(logout());
    response.errors = ["Ошибка авторизации"];
    return response;
  }
}

export function updateTokens() {
  if (!isSending) {
    isSending = true;
    updatingTokens = new UserServiceApi()
      .updateSession(store.getState().user.session)
      .then((response) => {
        isSending = false;
        return response;
      });
  }

  return updatingTokens;
}

export async function getAuthorization(session?: App.User.Session) {
  const token = await checkToken(
    session?.accessToken || store.getState().user.session.accessToken
  );
  return "Bearer " + token;
}

async function checkToken(token: string) {
  try {
    const [, payload] = token.split(".");
    const { exp } = JSON.parse(atob(payload));
    if (exp > +new Date() / Time.second) {
      return token;
    }
    const { data, status } = await updateTokens();
    if (status === OK) {
      store.dispatch(saveSession(data));
      return data.accessToken;
    } else {
      return token;
    }
  } catch {
    return "";
  }
}
