import Axios, { AxiosResponse } from "axios";
import { FORBIDDEN, NOT_FOUND, OK } from "http-status-codes";
import UserService, {
  ChangePasswordData,
  MessageForAdministration,
  SaveContactData,
} from "../abstract/UserService";
import { handle403, handleError, handleResponse } from "./methods";

import { AMOUNT_FOR_ANNOUNCEMENTS } from "../../Config";
import JWT from "../../utils/JWT/JWT";
import SAnnouncement from "../../struct/announcement/SAnnouncement";
import { SigninData } from "../../components/auth/signinForm/SigninForm";
import { SignupData } from "../../components/auth/signupForm/SignupForm";
import StringMethods from "../../utils/string/StringMethods";
import Time from "../../utils/time/Time";
import { saveSession } from "../../store/actions/user";
import sha256 from "sha256";
import store from "../../store/store";

type DataSession = AxiosResponse<{
  access_token: string;
  refresh_token: string;
  token_type: "Bearer";
  expires_in: number;
}>;

export default class UserServiceApi extends UserService {
  public async confirmationEmail(key: string): Promise<App.Response<void>> {
    return Axios.get(`/api/auth/signup/activate/${key}`)
      .then(handleResponse)
      .catch(handleError);
  }
  public async signin(
    data: SigninData
  ): Promise<App.Response<App.User.Session>> {
    return Axios.post("/api/auth/login", {
      email: data.email,
      password: sha256(data.password),
    })
      .then((response: DataSession) => {
        const newResponse: App.Response<App.User.Session> = this.handleSessionData(
          response
        );
        return handleResponse(newResponse);
      })
      .catch(handleError);
  }
  public async updateSession(
    session: App.User.Session
  ): Promise<App.Response<App.User.Session>> {
    return Axios.post("/api/auth/refresh/", {
      refresh_token: session.refreshToken,
      access_token: session.accessToken,
    })
      .then((response: DataSession) => {
        const newResponse: App.Response<App.User.Session> = this.handleSessionData(
          response
        );
        store.dispatch(saveSession(newResponse.data));
        return handleResponse(newResponse);
      })
      .catch((response) => handleResponse(response));
  }
  private handleSessionData(
    response: DataSession
  ): App.Response<App.User.Session> {
    return {
      status: response.status,
      data: {
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
      },
      errors: [],
    };
  }
  public async signup(data: SignupData): Promise<App.Response<void>> {
    return Axios.post("/api/auth/signup", {
      email: data.email,
      name: data.name,
      password: sha256(data.password),
      password_confirmation: sha256(data.passwordComparison),
    })
      .then(handleResponse)
      .catch((response) => {
        if (!response || !response.data) {
          response = { data: {}, status: 400 };
        }
        let errors: Array<string> = [];
        errors = response.data.email
          ? errors.concat(response.data.email)
          : errors;
        errors = response.data.password
          ? errors.concat(response.data.password)
          : errors;
        errors = response.data.name
          ? errors.concat(response.data.name)
          : errors;
        response.data = errors;
        return handleError(response);
      });
  }
  public async logout(
    session: App.User.Session
  ): Promise<App.Response<boolean>> {
    return Axios.post("/auth/logout", {
      data: session,
    });
  }
  public async getUserInfo(
    session?: App.User.Session
  ): Promise<App.Response<App.User.User>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/user/info", {
      headers: { Authorization },
    })
      .then((response) => {
        response.data = {
          name: response.data.name,
          role: response.data.role,
          email: response.data.email,
          contacts: response.data.contacts.map((e: any) => ({
            id: e.id,
            name: e.name,
            phone: e.phone_number,
          })),
        };
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(null, (data: null, session: App.User.Session) =>
            this.getUserInfo(session)
          );
        }
        return handleError(response);
      });
  }
  public async getSmsCode(
    phone: string,
    session?: App.User.Session
  ): Promise<App.Response<void>> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      "/get-sms-code",
      { phone_number: phone.trim().slice(-9) },
      { headers: { Authorization } }
    )
      .then(handleResponse)
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(phone, this.getSmsCode.bind(this));
        }
        return handleError(response);
      });
  }
  public async saveContact(
    data: SaveContactData,
    session?: App.User.Session
  ): Promise<App.Response<Array<App.User.Contact>>> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      "/user/contact",
      {
        phone_number: data.phone.trim().slice(-9),
        name: data.name.trim(),
        sms_code: data.code.trim(),
      },
      { headers: { Authorization } }
    )
      .then((response) => {
        response.data = response.data.map((e: any) => ({
          id: e.id,
          name: e.name,
          phone: e.phone_number,
        }));
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(data, this.saveContact.bind(this));
        }
        return handleError(response);
      });
  }
  public async deleteContact(
    id: App.ID,
    session?: App.User.Session
  ): Promise<App.Response<void>> {
    const Authorization = await JWT.getAuth();
    return Axios.delete(`/user/contact/${id}`, {
      headers: { Authorization },
    })
      .then(handleResponse)
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(id, this.deleteContact.bind(this));
        }
        return handleError(response);
      });
  }
  public async getPhoneNumberForContact(
    contactId: App.ID
  ): Promise<App.Response<string>> {
    return Axios.get(`/user/contact/phone-number/${contactId}`)
      .then((response) => {
        response.data = response.data.toString();
        return handleResponse(response);
      })
      .catch(handleError);
  }
  public async toggleFavorite(
    announcement_id: App.ID,
    session?: App.User.Session
  ): Promise<App.Response<void>> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      "/user/favorite",
      { announcement_id },
      { headers: { Authorization } }
    )
      .then(handleResponse)
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(announcement_id, this.toggleFavorite.bind(this));
        }
        return handleError(response);
      });
  }
  public async toggleHide(
    announcement_id: App.ID,
    session?: App.User.Session
  ): Promise<App.Response<void>> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      "/user/hidden",
      { announcement_id },
      { headers: { Authorization } }
    )
      .then(handleResponse)
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(announcement_id, this.toggleHide.bind(this));
        }
        return handleError(response);
      });
  }
  public async getUserAnnouncements(
    page: number,
    sort: number = 0,
    favorite: boolean = false,
    session?: App.User.Session
  ): Promise<App.Response<App.ListMetadata<App.Announcement.Announcement>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("/user/announcement", {
      headers: { Authorization },
      params: {
        page,
        amount: AMOUNT_FOR_ANNOUNCEMENTS,
        sort,
        favorite: +favorite,
      },
    })
      .then((response) => {
        response.data = {
          page: page,
          totalPage: response.data.last_page,
          total: response.data.total,
          numberPerPage: response.data.per_page,
          items: response.data.data.map((e: any) => new SAnnouncement(e)),
        };
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(null, (data: any, s: App.User.Session) => {
            return this.getUserAnnouncements(page, sort, favorite, s);
          });
        }
        return handleError(response);
      });
  }
  public async changePassword(
    data: ChangePasswordData,
    session?: App.User.Session
  ): Promise<App.Response<App.User.Session>> {
    const Authorization = await JWT.getAuth();
    return Axios.post(
      "api/password/change",
      {
        old_password: StringMethods.sha256(data.oldPassword),
        new_password: StringMethods.sha256(data.newPassword),
        new_password_confirmation: StringMethods.sha256(
          data.comparisonNewPassword
        ),
      },
      {
        headers: { Authorization },
      }
    )
      .then((response) => {
        response.data = {
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
        };
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(data, this.changePassword.bind(this));
        }
        return handleError(response);
      });
  }
  public async sendMessageToAdministration(
    data: MessageForAdministration
  ): Promise<App.Response<void>> {
    return Axios.post("callback/request/add", data)
      .then(handleResponse)
      .catch(handleError);
  }
  public async getSearchHistory(
    session?: App.User.Session
  ): Promise<App.Response<Array<App.FiltersHistory>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("user/search-history", {
      headers: { Authorization },
    })
      .then((response) => {
        console.log(response.data);
        response.data = response.data
          .map(
            (
              e: {
                created_at: number;
                filters: string;
                keyword: null | string;
                show_all: number;
                special: number;
              },
              id: number
            ) => {
              try {
                return {
                  id,
                  date: new Date(+e.created_at * Time.second),
                  hash: e.filters,
                  filters: JSON.parse(e.filters).map(
                    (f: any): App.AdvancedFilter.SelectedFilterValue => {
                      if (f.hasOwnProperty("min") && f.hasOwnProperty("max")) {
                        return {
                          id: f.id,
                          type: "range",
                          value: [f.min, f.max],
                        };
                      } else if (f.hasOwnProperty("options")) {
                        return {
                          id: f.id,
                          type: "picker",
                          value: f.options,
                        };
                      } else if (f.hasOwnProperty("value") && f.value === 1) {
                        return {
                          id: f.id,
                          type: "checkbox",
                          value: true,
                        };
                      }
                      return f;
                    }
                  ),
                };
              } catch {
                return null;
              }
            }
          )
          .filter((e: any) => !!e);
        return handleResponse(response);
      })
      .catch((response) => {
        console.log(response);
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(null, (data: null, session: App.User.Session) =>
            this.getSearchHistory(session)
          );
        }
        return handleError(response);
      });
  }
  public async getViewHistory(
    params: { page: number; sort: number; amount?: number },
    session?: App.User.Session
  ): Promise<App.Response<App.ListMetadata<App.Announcement.Announcement>>> {
    const Authorization = await JWT.getAuth();
    return Axios.get("user/announcement-view-history", {
      headers: { Authorization },
      params: {
        page: params.page,
        amount: params.amount || AMOUNT_FOR_ANNOUNCEMENTS,
        sort: params.sort,
      },
    })
      .then((response) => {
        response.data = {
          page: response.data.current_page,
          totalPage: response.data.last_page,
          total: response.data.total,
          numberPerPage: response.data.per_page,
          items: response.data.data.map((e: any) => new SAnnouncement(e)),
        };
        return handleResponse(response);
      })
      .catch((response) => {
        if (response && response.status === FORBIDDEN && !session) {
          return handle403(params, this.getViewHistory.bind(this));
        }

        if (response && response.status === NOT_FOUND) {
          response.data = {
            page: response.data.current_page,
            totalPage: 0,
            total: 0,
            numberPerPage: 0,
            items: [],
          };
          response.status = OK;
          return handleResponse(response);
        }
        return handleError(response);
      });
  }
}
