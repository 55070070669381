import CreateAnnouncementService, {
  AnnouncementData,
} from "../abstract/CreateAnnouncementService";
import {
  brands,
  carcasses,
  comfortFiltersCA,
  drives,
  models,
  motorTypes,
  multimediaFiltersCA,
  otherFiltersCA,
  safetyFiltersCA,
  salonFiltersCA,
  transmissions,
  visibilityFiltersCA,
} from "./database";

import { DEFAULT_ANNOUNCEMENT } from "../../Config";
import { OK } from "http-status-codes";
import { delay } from "./methods";

class CreateAnnouncementServiceDefault extends CreateAnnouncementService {
  public getStepFilters(
    step: App.CreateAnnouncement.Step
  ): Promise<
    App.Response<[Array<App.Filter.Filter>, Array<App.Filter.Filter>]>
  > {
    let answer = {
      data: this.getFiltersByCategory(step),
      status: OK,
      errors: [],
    };
    return delay(answer);
  }

  public getPreview(): Promise<App.Response<App.Announcement.Announcement>> {
    try {
      const data: AnnouncementData = this.getAnnouncementData();
      return delay({
        status: OK,
        errors: [],
        data: {
          id: 0,
          images: DEFAULT_ANNOUNCEMENT().images,
          car: {
            brand: brands.find((brand) => brand.id === data.brand) || {
              id: 0,
              name: "Марка",
            },
            model: models.find((model) => model.id === data.model) || {
              id: 0,
              name: "Модель",
            },
            carcass: carcasses.find(
              (carcass) => carcass.id === data.carcass
            ) || { id: 0, name: "", doors_number: 5, img: "" },
            transmission: transmissions.find(
              (transmission) => transmission.id === data.transmission
            ) || { id: 0, name: "" },
            motor: {
              id: 0,
              type:
                motorTypes.find((type) => type.id === data.motorType)?.name ||
                "",
              motorPower: 0,
              motorVolume: data.motorVolume || 0,
            },
            drive: drives.find((drive) => drive.id === data.drive) || {
              id: 0,
              name: "",
            },
            color: { id: 0, name: "", hash: "rgba(0,0,0,0)" },
          },
          price: data.price || 0,
          year: data.year || 0,
          distance: data.distance || 0,
          description: data.description || "",
          state: "",
          contact: this.getContactData(),
          options: this.getOptions(),
        },
      });
    } catch {
      return delay({ data: DEFAULT_ANNOUNCEMENT(), status: OK, errors: [] });
    }
  }

  public getData(): Promise<App.Response<AnnouncementData>> {
    return delay({
      data: this.getAnnouncementData(),
      status: OK,
      errors: [],
    });
  }
  private getAnnouncementData(): AnnouncementData {
    try {
      return JSON.parse(localStorage.smartCreateAnnouncementData);
    } catch {
      return {};
    }
  }
  public saveData(data: AnnouncementData): Promise<App.Response<void>> {
    localStorage.smartCreateAnnouncementData = JSON.stringify(data);
    return delay({ status: OK, errors: [] });
  }
  private getOptions(): Array<App.Announcement.Option> {
    return [
      { category: "Обзор", options: this.getOptionsByCategory(3) },
      { category: "Салон", options: this.getOptionsByCategory(4) },
      {
        category: "Мультимедия",
        options: this.getOptionsByCategory(5),
      },
      {
        category: "Безопасность",
        options: this.getOptionsByCategory(6),
      },
      { category: "Комфорт", options: this.getOptionsByCategory(7) },
      { category: "Прочее", options: this.getOptionsByCategory(8) },
    ];
  }
  private getOptionsByCategory(
    category: App.CreateAnnouncement.Step
  ): Array<string> {
    try {
      const selected: App.CreateAnnouncement.StepData = JSON.parse(
        localStorage[`smartStep${category}`]
      );
      const [c1, c2] = this.getFiltersByCategory(category);
      const c3 = c1.concat(c2);
      const tags: Array<string> = [];

      c3.forEach((e) => {
        if (e.type === "group") {
          e.checkboxes
            .filter((c) => selected.checkboxList.includes(c.id))
            .forEach((c) => tags.push(c.name));
        } else if (
          e.type === "checkbox" &&
          selected.checkboxList.includes(e.id)
        ) {
          tags.push(e.name);
        } else if (e.type === "select") {
          const s = selected.selectList.some((s) => s.id === e.id);
          if (s) {
            tags.push(e.name);
          }
        }
      });
      return tags;
    } catch {
      return [];
    }
  }
  private getFiltersByCategory(category: App.CreateAnnouncement.Step) {
    switch (category) {
      case 7:
        return comfortFiltersCA;
      case 5:
        return multimediaFiltersCA;
      case 8:
        return otherFiltersCA;
      case 6:
        return safetyFiltersCA;
      case 4:
        return salonFiltersCA;
      case 3:
        return visibilityFiltersCA;
      default:
        return [[], []];
    }
  }
  public saveStep(
    step: App.CreateAnnouncement.Step,
    selected: App.CreateAnnouncement.StepData
  ): Promise<App.Response<Array<App.Announcement.Option>>> {
    localStorage[`smartStep${step}`] = JSON.stringify(selected);
    return delay({ data: this.getOptions(), status: OK, errors: [] });
  }
  public getStep(
    step: App.CreateAnnouncement.Step
  ): Promise<App.Response<App.CreateAnnouncement.StepData>> {
    try {
      return delay({
        data: JSON.parse(localStorage[`smartStep${step}`]),
        status: OK,
        errors: [],
      });
    } catch {
      return delay({ data: [], status: OK, errors: [] });
    }
  }

  public saveContact(
    contact: App.Announcement.Contact
  ): Promise<App.Response<void>> {
    localStorage.smartContact = JSON.stringify(contact);
    return delay({ status: OK, errors: [] });
  }
  private getContactData(): App.Announcement.Contact {
    try {
      return JSON.parse(localStorage.smartContact);
    } catch {
      return {
        name: "Имя",
        city: "Город",
        phone: "Телефон",
        email: "Email",
      };
    }
  }
  public complite(): Promise<App.Response<void>> {
    Object.keys(localStorage).forEach((e) => {
      if (e.match(/smart(Contact||Step||CreateAnnouncementData)/)) {
        localStorage.removeItem(e);
      }
    });
    return delay({ status: OK, errors: [] });
  }
}
export default CreateAnnouncementServiceDefault;
