import "./AdministrateAnnouncementsListItemActions.scss";
import "react-datepicker/dist/react-datepicker.css";

import AdministrateAnnouncementsChangeCheckedPopup from "./administrateAnnouncementsChangeCheckedPopup/AdministrateAnnouncementsChangeCheckedPopup";
import AdministrateAnnouncementsChangeSpecPopup from "./administrateAnnouncementsChangeSpecPopup/AdministrateAnnouncementsChangeSpecPopup";
import AdministrateAnnouncementsDeletePopup from "./administrateAnnouncementsDeletePopup/AdministrateAnnouncementsDeletePopup";
import React from "react";

interface Props {
  announcement: App.Announcement.Announcement;
  onChangeChecked: (id: App.ID, checked: boolean) => Promise<boolean>;
  onChangeSpec: (
    id: App.ID,
    spec: { from: Date | null; to: Date | null }
  ) => Promise<boolean>;
  onDelete: (id: App.ID) => Promise<boolean>;
}
interface State {
  isOpenDeletePopup: boolean;
  isOpenCheckedPopup: boolean;
  isOpenSpecPopup: boolean;

  loading: boolean;
}

class AdministrateAnnouncementsListItemActions extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenDeletePopup: false,
      isOpenCheckedPopup: false,
      isOpenSpecPopup: false,

      loading: false,
    };
  }

  private openDeletePopup() {
    this.setState({ isOpenDeletePopup: true });
  }
  private openCheckedPopup() {
    this.setState({ isOpenCheckedPopup: true });
  }
  private openSpecPopup() {
    this.setState({ isOpenSpecPopup: true });
  }

  private handleChangeChecked(checked: boolean) {
    this.setState({ loading: true });
    this.props
      .onChangeChecked(this.props.announcement.id, checked)
      .then(this.handleResultRequest.bind(this));
  }
  private handleChangeSpec(spec: { from: Date | null; to: Date | null }) {
    this.setState({ loading: true });
    this.props
      .onChangeSpec(this.props.announcement.id, spec)
      .then(this.handleResultRequest.bind(this));
  }
  private handleDeleteAnnouncement() {
    this.setState({ loading: true });
    this.props
      .onDelete(this.props.announcement.id)
      .then(this.handleResultRequest.bind(this));
  }
  private handleResultRequest(success: boolean) {
    this.setState({ loading: false });
    if (success) this.closePopup();
  }

  private closePopup() {
    this.setState({
      isOpenDeletePopup: false,
      isOpenCheckedPopup: false,
      isOpenSpecPopup: false,
    });
  }

  public render() {
    return (
      <div className="administrate-announcements-list-item-actions">
        <div
          className="action"
          onClick={this.openCheckedPopup.bind(this)}
          data-active={this.props.announcement.checked}
        >
          Отметить как проверенное
        </div>
        <div
          className="action"
          onClick={this.openSpecPopup.bind(this)}
          data-active={this.props.announcement.spec}
        >
          Отметить как спецпредложение
        </div>
        {/* <div
          className="action delete"
          onClick={this.openDeletePopup.bind(this)}
        >
          Удалить
        </div> */}

        {this.state.isOpenDeletePopup && (
          <AdministrateAnnouncementsDeletePopup
            disabled={this.state.loading}
            onSubmit={this.handleDeleteAnnouncement.bind(this)}
            onClose={this.closePopup.bind(this)}
          />
        )}
        {this.state.isOpenCheckedPopup && (
          <AdministrateAnnouncementsChangeCheckedPopup
            disabled={this.state.loading}
            checked={this.props.announcement.checked}
            onSubmit={this.handleChangeChecked.bind(this)}
            onClose={this.closePopup.bind(this)}
          />
        )}
        {this.state.isOpenSpecPopup && (
          <AdministrateAnnouncementsChangeSpecPopup
            disabled={this.state.loading}
            spec={this.props.announcement.specialOffer}
            onSubmit={this.handleChangeSpec.bind(this)}
            onClose={this.closePopup.bind(this)}
          />
        )}
      </div>
    );
  }
}
export default AdministrateAnnouncementsListItemActions;
