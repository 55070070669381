import "./MotorPicker.scss";

import LabelNumber from "../../../custom/labels/labelNumber/LabelNumber";
import { MotorData } from "../../../../services/abstract/CarService";
import Popup from "../../../custom/popup/Popup";
import React from "react";
import Select from "../../../custom/select/Select";

interface Props {
  motor?: App.Car.Motor;
  types: Array<App.Info>;
  onSearch: (options: MotorData) => void;
}
interface State {
  type: App.ID | null;
  power: number;
  volume: number;
  isOpen: boolean;
}

class MotorPicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      type: null,
      power: 100,
      volume: 1,
      isOpen: false,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.motor !== prevProps.motor) {
      if (this.props.motor) {
        this.setState({
          type: this.props.motor.typeId,
          power: this.props.motor.power,
          volume: this.props.motor.volume,
        });
      } else {
        this.setState({
          type: null,
          power: 100,
          volume: 1,
        });
      }
    }
  }

  private handleChangeMotorType(type: App.ID | null) {
    this.setState({ type });
  }
  private handleChangeMotorPower(power: number) {
    this.setState({ power });
  }
  private handleChangeMotorVolume(volume: number) {
    this.setState({ volume });
  }

  private async search() {
    if (this.state.type && this.state.power && this.state.volume) {
      this.props.onSearch({
        type: +this.state.type,
        power: this.state.power,
        volume: this.state.volume,
      });
      this.close();
    }
  }
  private open() {
    this.setState({ isOpen: true });
  }
  private close() {
    this.setState({ isOpen: false });
  }
  public render() {
    return (
      <div className="motor-picker">
        <button onClick={this.open.bind(this)}>
          {this.props.motor
            ? `${this.props.motor.type} ${this.props.motor.power} л.с.`
            : "Выбрать двигатель"}
        </button>
        {this.state.isOpen && (
          <Popup onClose={this.close.bind(this)}>
            <Select
              name={"Тип двигателя"}
              multiselect={false}
              selected={this.state.type}
              options={this.props.types}
              onChange={this.handleChangeMotorType.bind(this)}
            />
            <LabelNumber
              value={this.state.power}
              onChange={this.handleChangeMotorPower.bind(this)}
              dimension={1}
              dimensions={[{ id: 1, name: "л.с." }]}
              name={"Мощность двигателя"}
            />
            <LabelNumber
              value={this.state.volume}
              onChange={this.handleChangeMotorVolume.bind(this)}
              name={"Объём"}
            />
            <button onClick={this.search.bind(this)}>Сохранить</button>
          </Popup>
        )}
      </div>
    );
  }
}

export default MotorPicker;
