import { handleError, handleResponse } from "../methods";

import { AMOUNT_FOR_ANNOUNCEMENTS } from "../../../Config";
import AnnouncementCustomService from "../../abstract/announcements/AnnouncementCustomService";
import Axios from "axios";
import SAnnouncement from "../../../struct/announcement/SAnnouncement";

export default class AnnouncementCustomServiceApi extends AnnouncementCustomService {
  public async getRecomended(): Promise<
    App.Response<Array<App.Announcement.Announcement>>
  > {
    return this.getNew();
  }
  public async getNew(): Promise<
    App.Response<Array<App.Announcement.Announcement>>
  > {
    return Axios.get(`/random/all`, {
      params: {
        amount: AMOUNT_FOR_ANNOUNCEMENTS,
      },
    })
      .then((response) => {
        response.data = response.data.map((e: any) => new SAnnouncement(e));
        return handleResponse(response);
      })
      .catch(handleError);
  }
  public async getChecked(): Promise<
    App.Response<Array<App.Announcement.Announcement>>
  > {
    return Axios.get(`/random/inspected`, {
      params: {
        amount: AMOUNT_FOR_ANNOUNCEMENTS,
      },
    })
      .then((response) => {
        response.data = response.data.map((e: any) => new SAnnouncement(e));
        return handleResponse(response);
      })
      .catch(handleError);
  }
  public async getSpec(): Promise<
    App.Response<Array<App.Announcement.Announcement>>
  > {
    return Axios.get(`/random/special`, {
      params: {
        amount: 2,
      },
    })
      .then((response) => {
        response.data = response.data.map((e: any) => new SAnnouncement(e));
        return handleResponse(response);
      })
      .catch(handleError);
  }
}
