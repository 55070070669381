import {
  changeFilterAnnouncementsHome,
  researchAnnouncementsHome,
} from "../../../store/actions/announcements/home";

import HomeFilter from "../../../components/mainFilter/HomeFilter";
import React from "react";
import { Unsubscribe } from "redux";
import { loadModels } from "../../../store/actions/car";
import store from "../../../store/store";

export type SliderName = "price" | "year" | "distance";
interface Props {}
export interface State {
  filters: Array<App.Announcement.FilterApi>;
  car: Array<App.AdvancedFilter.CarFilter>;
  other: App.AdvancedFilter.SelectedFilter;
}

class HomeFilterContainer extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      ...this.parseHash(),
      filters: this.getFilters(),
    };
  }

  static filtersIds: Array<App.ID> = [9, 10, 12];

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
    store.dispatch(
      changeFilterAnnouncementsHome({
        car: this.state.car,
        other: this.state.other,
      })
    );
  }
  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.state.car !== prevState.car ||
      this.state.other !== prevState.other
    ) {
      this.saveHash();
    }
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }
  public componentDidCatch() {
    this.setState(this.parseHash());
  }

  private handleUpdateStore() {
    this.setState({
      filters: this.getFilters(),
      ...store.getState().home.filters,
    });
    // this.saveHash();
  }

  // location hash
  private getHash() {
    return (
      "#" + JSON.stringify({ car: this.state.car, other: this.state.other })
    );
  }
  private saveHash() {
    setTimeout(() => {
      window.location.hash = this.getHash();
    });
  }
  private parseHash(): App.Announcement.FiltersForSearch {
    try {
      const data = JSON.parse(decodeURI(window.location.hash.slice(1)));
      if (data.car.length === 0) {
        data.car[0] = { brand: null, model: null };
      }
      return data;
    } catch (err) {
      // this.saveHash();
      return {
        car: [{ brand: null, model: null }],
        other: [],
      };
    }
  }

  // update state
  private getFilters() {
    return store
      .getState()
      .filters.main.filter((f) =>
        HomeFilterContainer.filtersIds.includes(f.id)
      );
  }

  private handleSelectBrand(brand: App.ID) {
    store.dispatch(loadModels(+brand));
    this.handleChangeCarFilter(
      this.state.car.map((car, index, self) =>
        index + 1 === self.length ? { brand } : car
      ) || []
    );
  }
  private handleChangeCarFilter(car: Array<App.AdvancedFilter.CarFilter>) {
    this.saveFilter({ car, other: this.state.other });
  }
  private handleChange(
    id: App.ID,
    value: App.AdvancedFilter.SelectedFilterValue
  ) {
    this.saveFilter({
      car: this.state.car,
      other: this.state.other.filter((f) => f.id !== id).concat(value),
    });
  }
  private saveFilter(values: App.Announcement.FiltersForSearch) {
    // this.setState(values);
    store.dispatch(changeFilterAnnouncementsHome(values));
    // this.saveHash();
  }

  // search
  private async search() {
    store.dispatch(researchAnnouncementsHome());
  }

  public render() {
    return (
      <HomeFilter
        car={this.state.car}
        onChangeCarFilter={this.handleChangeCarFilter.bind(this)}
        filters={this.state.filters}
        values={this.state.other}
        onChange={this.handleChange.bind(this)}
        onStatrtSearch={this.search.bind(this)}
        onSelectBrand={this.handleSelectBrand.bind(this)}
      />
    );
  }
}

export default HomeFilterContainer;
