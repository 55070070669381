import AnnouncementPageHead from "../../../components/announcement/page/head/AnnouncementPageHead";
import AnnouncementService from "../../../services/abstract/AnnouncementService";
import AnnouncementServiceApi from "../../../services/api/AnnouncementServiceApi";
import AnnouncementViewBody from "../../../views/announcement/body/AnnouncementViewBody";
import { OK } from "http-status-codes";
import React from "react";

interface Props {
  id: string;
  toNotFound: () => void;
}
interface State {
  announcement?: App.Announcement.Announcement;
}

class AnnouncementContainer extends React.Component<Props, State> {
  private service: AnnouncementService;
  constructor(props: Props) {
    super(props);
    this.service = new AnnouncementServiceApi();
    this.state = {
      announcement: undefined,
    };
  }
  public componentDidMount() {
    this.loadAnnouncement(this.props.id);
  }
  public componentDidUpdate(props: Props) {
    if (props.id === this.props.id) return;
    this.loadAnnouncement(this.props.id);
  }

  private async loadAnnouncement(id: string) {
    const { data, status } = await this.service.getById(id);

    if (status === OK) {
      this.setState({
        announcement: data,
      });
    } else {
      this.setState({
        announcement: undefined,
      });
      this.props.toNotFound();
    }
  }

  private handleUpdateAnnouncement(
    announcement: App.Announcement.Announcement
  ) {
    this.setState({ announcement });
  }
  public render() {
    return (
      <div className="main">
        <AnnouncementPageHead
          announcement={this.state.announcement}
          onUpdateAnnouncement={this.handleUpdateAnnouncement.bind(this)}
        />
        <AnnouncementViewBody announcement={this.state.announcement} />
      </div>
    );
  }
}

export default AnnouncementContainer;
