import React from "react";

class ErrorIcon extends React.Component<App.IconsProps> {
  static defaultProps = {
    background: "none",
    fill: "#FF0000",
  };
  public render() {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill={this.props.background}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0156 10.9844V9.01562H4.98438V10.9844H15.0156ZM2.92188 2.96875C4.89062 1 7.25 0.015625 10 0.015625C12.75 0.015625 15.0938 1 17.0312 2.96875C19 4.90625 19.9844 7.25 19.9844 10C19.9844 12.75 19 15.1094 17.0312 17.0781C15.0938 19.0156 12.75 19.9844 10 19.9844C7.25 19.9844 4.89062 19.0156 2.92188 17.0781C0.984375 15.1094 0.015625 12.75 0.015625 10C0.015625 7.25 0.984375 4.90625 2.92188 2.96875Z"
          fill={this.props.fill}
        />
      </svg>
    );
  }
}

export default ErrorIcon;
