import "./RecomendedAnnouncements.scss";

import AnnouncementCard from "../../../../components/announcement/card/AnnouncementCard";
import React from "react";
import Title from "../../../custom/title/Title";

interface Props {
  announcements: Array<App.Announcement.Announcement>;
  loading: boolean;
}
interface State {}

class RecomendedAnnouncements extends React.Component<Props, State> {
  public render() {
    return (
      <>
        <Title className="recomended-announcements-title">
          Рекомендуем посмотреть
        </Title>
        <div
          className={`recomended-announcements ${
            this.props.loading ? "loading" : ""
          }`}
        >
          {this.props.announcements.map((announcement) => (
            <div key={announcement.id} className="mini-box">
              <AnnouncementCard announcement={announcement} />
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default RecomendedAnnouncements;
