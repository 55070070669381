import "./MenuWithBurger.scss";

import React, { SyntheticEvent } from "react";
import {
  adminPanel,
  administrateAnnouncements,
  announcements,
  compareCar,
  filters,
  sell,
  signin,
  userAnnouncements,
} from "../../../routes";

import ListIcon from "../../../icons/List";
import { NavLink } from "react-router-dom";

interface Props {
  isAdmin: boolean;
  isAuth: boolean;
  name: string;
}
interface State {
  isOpen: boolean;
}

class MenuWithBurger extends React.Component<Props, State> {
  private handleClickOnWindow: () => void;
  constructor(props: Props) {
    super(props);
    this.handleClickOnWindow = this.closePopup.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  public componentDidMount() {
    window.addEventListener("click", this.handleClickOnWindow);
  }
  public componentWillUnmount() {
    window.removeEventListener("click", this.handleClickOnWindow);
  }

  private closePopup() {
    this.setState({ isOpen: false });
  }
  private openCloseMenu(event: SyntheticEvent) {
    event.stopPropagation();
    this.setState({ isOpen: !this.state.isOpen });
  }
  public render() {
    return (
      <div className="menu-with-burger">
        <ListIcon
          className={"burger-menu"}
          fill={"white"}
          onClick={this.openCloseMenu.bind(this)}
        />
        {this.state.isOpen && (
          <div className="popup-menu">
            {!this.props.isAuth && <NavLink to={signin}>Войти</NavLink>}
            {this.props.isAuth && (
              <>
                <NavLink to={sell} className="sell">
                  + Продать
                </NavLink>
                <NavLink to={userAnnouncements}>{this.props.name}</NavLink>
              </>
            )}
            <NavLink to={announcements}>Объявления</NavLink>
            <NavLink to={compareCar}>Сравнение авто</NavLink>
            <NavLink to={filters}>Расширеные фильтры</NavLink>
            {this.props.isAdmin && (
              <>
                <NavLink to={adminPanel}>Создание авто</NavLink>
                <NavLink to={administrateAnnouncements}>
                  Администртрование
                </NavLink>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default MenuWithBurger;
