class Time {
  static milisecond: number = 1;
  static second: number = Time.milisecond * 1000;
  static minute: number = Time.second * 60;
  static hour: number = Time.minute * 60;
  static day: number = Time.hour * 24;
  static week: number = Time.day * 7;

  static beautifyDateTime(d: Date | number): string {
    return `${Time.beautifyDate(d)} ${Time.beautifyTime(d)}`;
  }

  static beautifyDate(d: Date | number, separator: string = "."): string {
    const date = new Date(d);
    return `${Time.toTwoSymbols(date.getDate())}${separator}${Time.toTwoSymbols(
      1 + date.getMonth()
    )}${separator}${date.getFullYear()}`;
  }
  static beautifyTime(d: Date | number, separator: string = ":"): string {
    const date = new Date(d);
    return `${Time.toTwoSymbols(
      date.getHours()
    )}${separator}${Time.toTwoSymbols(date.getMinutes())}`;
  }
  static toTwoSymbols(value: number | string): string {
    return ("0" + value).slice(-2);
  }
  static getTime(time: number): string {
    const hours = ("0" + (Math.floor(time / Time.hour) || 0)).slice(-2);
    time %= Time.hour;
    const minutes = ("0" + (Math.floor(time / Time.minute) || 0)).slice(-2);
    return `${hours}:${minutes}`.trim();
  }
  static getDuration(time: number) {
    time %= Time.hour;
    const minutes = ("0" + (Math.floor(time / Time.minute) || 0)).slice(-2);
    time %= Time.minute;
    const seconds = ("0" + (Math.floor(time / Time.second) || 0)).slice(-2);
    return `${minutes}:${seconds}`.trim();
  }
}
export default Time;
