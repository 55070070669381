import Header from "../../components/header/Header";
import React from "react";
import { Unsubscribe } from "redux";
import store from "../../store/store";

interface Props {}
interface State {
  name: string;
  isAuth: boolean;
  isAdmin: boolean;
}
class HeaderContainer extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      isAdmin: false,
      isAuth: false,
      name: "",
    };
  }

  public componentDidMount() {
    this.handleUpdateStore();
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  private handleUpdateStore() {
    const { name, isAuth, isAdmin } = store.getState().user;
    this.setState({
      name,
      isAuth,
      isAdmin,
    });
  }

  public render() {
    return <Header {...this.state} />;
  }
}

export default HeaderContainer;
