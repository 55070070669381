import "./CompareCarCarcassRow.scss";

import CompareCarRow from "../../compareCarRow/CompareCarRow";
import React from "react";

interface Props {
  announcements: Array<App.Announcement.Announcement>;
}
interface State {}

class CompareCarCarcassRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <CompareCarRow
        key="-3"
        head={<b>Кузов </b>}
        values={this.props.announcements.map((a) => (
          <span>{a.car.carcass.name}</span>
        ))}
      />
    );
  }
}
export default CompareCarCarcassRow;
