import advanced, {
  AnnouncementsAdvancedStore,
} from "./reducers/announcements/AnnouncementsAdvancedReducer";
import announcements, {
  AnnouncementsStore,
} from "./reducers/announcements/AnnouncementsReducer";
import car, { CarStore } from "./reducers/CarReducer";
import compareCar, { CompareCarStore } from "./reducers/CompareCarReducer";
import createAnnouncement, {
  CreateAnnouncementStore,
} from "./reducers/CreateAnnouncementReducer";
import currency, { CurrencyStore } from "./reducers/CurrencyReducer";
import filters, { FiltersStore } from "./reducers/FiltersReducer";
import home, {
  AnnouncementsHomeStore,
} from "./reducers/announcements/AnnouncementsHomeReducer";
import loading, { LoadingStore } from "./reducers/LoadingReducer";
import messages, { MessageStore } from "./reducers/MessageReducer";
import user, { UserStore } from "./reducers/UserReducer";

import { createStore } from "redux";

interface Store {
  advanced: AnnouncementsAdvancedStore;
  announcements: AnnouncementsStore;
  car: CarStore;
  compareCar: CompareCarStore;
  createAnnouncement: CreateAnnouncementStore;
  currency: CurrencyStore;
  filters: FiltersStore;
  home: AnnouncementsHomeStore;
  loading: LoadingStore;
  messages: MessageStore;
  user: UserStore;
}

const defaultStore: Store = {
  announcements: announcements.defaultStore,
  advanced: advanced.defaultStore,
  car: car.defaultStore,
  compareCar: compareCar.defaultStore,
  createAnnouncement: createAnnouncement.defaultStore,
  currency: currency.defaultStore,
  filters: filters.defaultStore,
  home: home.defaultStore,
  loading: loading.defaultStore,
  messages: messages.defaultStore,
  user: user.defaultStore,
};

export default createStore(function (
  state: Store = defaultStore,
  action: any
): Store {
  return {
    advanced: advanced.store(state.advanced, action),
    announcements: announcements.store(state.announcements, action),
    car: car.store(state.car, action),
    compareCar: compareCar.store(state.compareCar, action),
    createAnnouncement: createAnnouncement.store(
      state.createAnnouncement,
      action
    ),
    currency: currency.store(state.currency, action),
    filters: filters.store(state.filters, action),
    home: home.store(state.home, action),
    loading: loading.store(state.loading, action),
    messages: messages.store(state.messages, action),
    user: user.store(state.user, action),
  };
});
