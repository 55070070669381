import "./SpecialAuto.scss";

import React from "react";
import StarBorderIcon from "../../../icons/StarBorderIcon";

interface Props {}
interface State {}

class SpecialAuto extends React.Component<Props, State> {
  public render() {
    return (
      <div className="special-auto">
        <StarBorderIcon />
      </div>
    );
  }
}

export default SpecialAuto;
