import { handleError, handleResponse } from "./methods";

import Axios from "axios";

interface CurrencyFromBase {
  id: number;
  value: number;
  short_name: string;
  name: string;
  updated_at: number;
}

class CurrencyServiceApi {
  public async getCurrency(): Promise<
    App.Response<Array<App.Currency.Currency>>
  > {
    return Axios.get("/storage/currency")
      .then((response) => {
        response.data = response.data.map((e: CurrencyFromBase) => {
          return {
            id: e.id,
            name: e.short_name,
            multiplier: e.value,
          };
        });
        return handleResponse(response);
      })
      .catch(handleError);
  }
}

export default CurrencyServiceApi;
