import {
  Actions,
  addToCompareType,
  removeFromCompareType,
} from "../actions/compareCars";

import { AMOUNT_FOR_COMPARISON } from "../../Config";
import SMessage from "../../struct/message/SMessage";
import { addMessage } from "../actions/messages";
import appStore from "../store";

export interface CompareCarStore {
  cars: Set<App.ID>;
}

class CompareCarReducer {
  public get defaultStore(): CompareCarStore {
    try {
      const cars: Array<App.ID> = JSON.parse(localStorage.compareCars);
      return {
        cars: cars.reduce((g: Set<App.ID>, e: App.ID) => {
          g.add(e);
          return g;
        }, new Set()),
      };
    } catch {
      return {
        cars: new Set(),
      };
    }
  }

  public store(store: CompareCarStore, action: Actions): CompareCarStore {
    switch (action.type) {
      case addToCompareType:
        if (store.cars.size >= AMOUNT_FOR_COMPARISON) {
          setTimeout(() => {
            appStore.dispatch(
              addMessage(
                new SMessage(
                  "Выбрано максимум объявлений для сравнения.",
                  "warning"
                )
              )
            );
          });
          break;
        }
        store.cars.add(action.id);
        break;
      case removeFromCompareType:
        store.cars.delete(action.id);
        break;
      default:
        break;
    }
    localStorage.compareCars = JSON.stringify(Array.from(store.cars));
    return store;
  }
}

export default new CompareCarReducer();
