import "./AnnouncementsPallets.scss";

import AnnouncementCard from "../card/AnnouncementCard";
import React from "react";

interface Props {
  announcements: Array<App.Announcement.Announcement>;
  loading?: boolean;
  title?: string | JSX.Element;
  icon?: string;
  className: string;
}
interface State {}

class AnnouncementsPallets extends React.Component<Props, State> {
  static defaultProps = {
    className: "",
  };

  public render() {
    return (
      <div className={"announcements-pallets " + this.props.className}>
        {this.props.title && (
          <h3 className="announcements-pallets-title">{this.props.title}</h3>
        )}

        <div
          className="announcements-pallets-list"
          data-loading={this.props.loading}
        >
          {this.props.announcements.map((announcement) => (
            <AnnouncementCard
              key={announcement.id}
              announcement={announcement}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default AnnouncementsPallets;
