import "./AdministrateAnnouncements.scss";

import AdministrateAnnouncementsList from "./administrateAnnouncementsList/AdministrateAnnouncementsList";
import AdministrateAnnouncementsMenu from "./administrateAnnouncementsMenu/AdministrateAnnouncementsMenu";
import React from "react";
import SearchLabel from "./searchLabel/SearchLabel";

interface Props {
  announcements: Array<App.Announcement.Announcement>;
  loading: boolean;
  isEnd: boolean;
  keyword: string;
  sort: string;
  newCheck: number;
  newSpec: number;

  loadMore: () => void;
  onChangeKeyword: (keyword: string) => void;
  onChangeSort: (sort: string) => void;

  onChangeChecked: (id: App.ID, checked: boolean) => Promise<boolean>;
  onChangeSpec: (
    id: App.ID,
    spec: { from: Date | null; to: Date | null }
  ) => Promise<boolean>;
  onDelete: (id: App.ID) => Promise<boolean>;
}
interface State {}

class AdministrateAnnouncements extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  private handleSearch() {}

  public render() {
    return (
      <div className="administrate-announcements">
        {false && (
          <AdministrateAnnouncementsMenu // don't work
            sort={this.props.sort}
            newCheck={this.props.newCheck}
            newSpec={this.props.newSpec}
            onChangeSort={this.props.onChangeSort}
          />
        )}
        <div className="announcements">
          <SearchLabel
            keyword={this.props.keyword}
            onChangeKeyword={this.props.onChangeKeyword}
            onSearch={this.handleSearch.bind(this)}
          />
          <AdministrateAnnouncementsList
            announcemnets={this.props.announcements}
            isEnd={this.props.isEnd}
            loading={this.props.loading}
            loadMore={this.props.loadMore}
            onChangeChecked={this.props.onChangeChecked}
            onChangeSpec={this.props.onChangeSpec}
            onDelete={this.props.onDelete}
          />
        </div>
      </div>
    );
  }
}
export default AdministrateAnnouncements;
