import SigninForm, {
  SigninData,
} from "../../../components/auth/signinForm/SigninForm";
import { saveSession, saveUser } from "../../../store/actions/user";

import { OK } from "http-status-codes";
import React from "react";
import { RouteChildrenProps } from "react-router";
import UserService from "../../../services/abstract/UserService";
import UserServiceApi from "../../../services/api/UserServiceApi";
import { home } from "../../../routes";
import store from "../../../store/store";

interface Props extends RouteChildrenProps {}
interface State {
  value: SigninData;
  complited: boolean;
  errors: Array<string>;
}

class SigninContainer extends React.Component<Props, State> {
  private service: UserService;
  constructor(props: Props) {
    super(props);
    this.service = new UserServiceApi();
    this.state = {
      value: {
        email: "",
        password: "",
      },
      complited: false,
      errors: [],
    };
  }
  private toHome() {
    this.props.history.push(home);
  }

  private handleChangeChange(value: SigninData) {
    if (this.state.complited) return;
    this.setState({ value });
  }

  private async handleSubmit() {
    if (this.state.complited) return;
    this.setState({ errors: [] });
    const { data, errors } = await this.service.signin(this.state.value);
    if (data) {
      this.saveSession(data);
      this.getUserInfo(data);
    } else {
      this.setState({ errors });
    }
  }

  private async getUserInfo(session: App.User.Session) {
    const { data, status, errors } = await this.service.getUserInfo(session);
    if (status === OK) {
      this.saveUser(data);
      this.toHome();
    } else {
      this.setState({ errors });
    }
  }

  private saveSession(session: App.User.Session) {
    store.dispatch(saveSession(session));
  }
  private saveUser(user: App.User.User) {
    store.dispatch(saveUser(user));
  }

  public render() {
    return (
      <SigninForm
        value={this.state.value}
        onChange={this.handleChangeChange.bind(this)}
        onSubmit={this.handleSubmit.bind(this)}
        errors={this.state.errors}
      />
    );
  }
}

export default SigninContainer;
