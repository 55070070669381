import "./Pagination.scss";

import GrayArrowBackIcon from "../../assets/icons/gray-arrow-back.svg";
import GrayArrowForwardIcon from "../../assets/icons/gray-arrow-forward.svg";
import PaginationPage from "./page/Page";
import React from "react";
import SelectPage from "./selectPage/SelectPage";

interface Props {
  pageNumber: number;
  activePage: number;
  onChange: (page: number) => void;
  countDisplayPages: number;
}
interface State {}

class Pagination extends React.Component<Props, State> {
  static numberForDisplaySelect = 5;
  static defaultProps = {
    countDisplayPages: 3,
  };

  private get pages() {
    if (this.props.activePage <= 2) {
      return [2, 3, 4].filter((e) => e < this.props.pageNumber);
    }
    if (this.props.activePage + 1 >= this.props.pageNumber) {
      return [
        this.props.pageNumber - 3,
        this.props.pageNumber - 2,
        this.props.pageNumber - 1,
      ].filter((e) => e > 1);
    }
    return [
      this.props.activePage - 1,
      this.props.activePage,
      this.props.activePage + 1,
    ];
  }

  private goBack() {
    if (1 === this.props.activePage) return;
    this.props.onChange(this.props.activePage - 1);
  }
  private goForward() {
    if (this.props.pageNumber === this.props.activePage) return;
    this.props.onChange(this.props.activePage + 1);
  }
  public render() {
    return (
      <>
        {this.props.pageNumber > 1 && (
          <div className="pagination">
            <img
              src={GrayArrowBackIcon}
              alt=""
              data-disabled={this.props.activePage === 1}
              onClick={this.goBack.bind(this)}
            />
            <PaginationPage
              page={1}
              onClick={this.props.onChange}
              active={this.props.activePage === 1}
            />
            {this.pages.map((page) => (
              <PaginationPage
                key={page}
                page={page}
                onClick={this.props.onChange}
                active={this.props.activePage === page}
              />
            ))}
            {this.props.pageNumber > Pagination.numberForDisplaySelect && (
              <SelectPage
                onChange={this.props.onChange}
                pageNumber={this.props.pageNumber}
                activePage={this.props.activePage}
              />
            )}
            <PaginationPage
              page={this.props.pageNumber}
              onClick={this.props.onChange}
              active={this.props.pageNumber === this.props.activePage}
            />

            <img
              src={GrayArrowForwardIcon}
              alt=""
              data-disabled={this.props.activePage === this.props.pageNumber}
              onClick={this.goForward.bind(this)}
            />
          </div>
        )}
      </>
    );
  }
}

export default Pagination;
