import "./MiddleInfo.scss";

import CreateAnnouncementMiddleInfoContact from "./contact/Contact";
import CreateAnnouncementMiddleInfoDescription from "./description/Description";
import React from "react";

interface Props {
  description?: string;
  contact: {
    name: string;
    city: string;
    email: string;
    phone: string;
  };
}
interface State {}

class CreateAnnouncementMiddleInfo extends React.Component<Props, State> {
  public render() {
    return (
      <div className="middle-info">
        <CreateAnnouncementMiddleInfoDescription
          description={this.props.description}
        />
        <CreateAnnouncementMiddleInfoContact {...this.props.contact} />
      </div>
    );
  }
}
export default CreateAnnouncementMiddleInfo;
