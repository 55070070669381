import "./ArrowTab.scss";

import ArrowUpIcon from "../../../../assets/icons/arrow-up.svg";
import React from "react";

interface Props {
  type: "previous" | "next";
  onClick: () => void;
}
interface State {}

class CarouselArrowTab extends React.Component<Props, State> {
  public render() {
    return (
      <div
        className="carousel__arrow-tab"
        data-type={this.props.type}
        onClick={this.props.onClick}
      >
        <img src={ArrowUpIcon} alt="" />
      </div>
    );
  }
}

export default CarouselArrowTab;
