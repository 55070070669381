import "./LabelCheckbox.scss";

import CheckmarkIcon from "../../../../assets/icons/checkmark.svg";
import React from "react";

interface Props {
  value: boolean;
  name: string;
  type: "checkmark" | "radio";
  className: string;
  disabled: boolean;
  onChange: (value: boolean) => void;
}
interface State {}

class LabelCheckbox extends React.Component<Props, State> {
  static defaultProps = {
    disabled: false,
    className: "",
    type: "checkmark",
    onChange() {},
  };

  private handleClick() {
    if (this.props.disabled) return;
    this.props.onChange(!this.props.value);
  }
  public render() {
    return (
      <div
        className={"label-checkbox " + this.props.className}
        data-disabled={this.props.disabled}
        onClick={this.handleClick.bind(this)}
      >
        {this.props.type === "checkmark" && (
          <div className="checkbox">
            {this.props.value && <img src={CheckmarkIcon} alt="" />}
          </div>
        )}

        {this.props.type === "radio" && (
          <div className="radio-circle">
            {this.props.value && <div className="radio-mark"></div>}
          </div>
        )}

        <div className="name">{this.props.name}</div>
      </div>
    );
  }
}

export default LabelCheckbox;
