import React from "react";

interface Props {
  active: App.DisplayType;
  onClick: (displayType: App.DisplayType) => void;
}
interface State {}

class ListDisplayType extends React.Component<Props, State> {
  static type: App.DisplayType = "list";
  static defaultProps = {
    onClick() {},
  };
  private handleClick() {
    this.props.onClick(ListDisplayType.type);
  }
  public render() {
    return (
      <svg
        onClick={this.handleClick.bind(this)}
        className="display-type"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0H23V2.81879H0V0ZM0 8.5906V5.7047H23V8.5906H0ZM0 20V17.1812H23V20H0ZM0 14.2953V11.4094H23V14.2953H0Z"
          fill={
            this.props.active === ListDisplayType.type ? "#12BBA4" : "#666666"
          }
        />
      </svg>
    );
  }
}
export default ListDisplayType;
