import "./AdministrateAnnouncementsDeletePopup.scss";

import Popup from "../../../../../custom/popup/Popup";
import React from "react";

interface Props {
  disabled: boolean;
  onSubmit: () => void;
  onClose: () => void;
}
interface State {}

class AdministrateAnnouncementsDeletePopup extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <Popup
        onClose={this.props.onClose}
        className="administrate-announcements-delete-popup"
      >
        <h3>Вы действительно хотите удалить объявление?</h3>
        <div className="buttons">
          <button onClick={this.props.onSubmit} disabled={this.props.disabled}>
            Да
          </button>
          <button onClick={this.props.onClose} disabled={this.props.disabled}>
            Нет
          </button>
        </div>
      </Popup>
    );
  }
}
export default AdministrateAnnouncementsDeletePopup;
