import React from "react";

class WarningIcon extends React.Component<App.IconsProps> {
  static defaultProps = {
    background: "none",
    fill: "#FF7A00",
  };
  public render() {
    return (
      <svg
        width="24"
        height="19"
        viewBox="0 0 24 19"
        fill={this.props.background}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9844 12.0156V7.98438H11.0156V12.0156H12.9844ZM12.9844 16V13.9844H11.0156V16H12.9844ZM0.984375 19L12 0.015625L23.0156 19H0.984375Z"
          fill={this.props.fill}
        />
      </svg>
    );
  }
}

export default WarningIcon;
