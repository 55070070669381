import "./Block.scss";

import Filter from "../../../utils/filter/Filter";
import React from "react";
import Title from "../../../components/custom/title/Title";

interface Props {
  className: string;
  filters: Array<App.Announcement.FilterApi>;
  selected: App.AdvancedFilter.SelectedFilter;
  onChange: (value: App.AdvancedFilter.SelectedFilter) => void;
  title: string;
  id?: string;
}
interface State {
  isOpen: boolean;
  selected: App.AdvancedFilter.SelectedFilter;
}

class AdvancedFilterBlock extends React.Component<Props, State> {
  private filter: Filter;
  constructor(props: Props) {
    super(props);
    this.filter = new Filter(this);
    this.state = { isOpen: true, selected: props.selected };
  }

  static defaultProps = {
    className: "",
    selected: [],
    onChange() {},
  };

  public componentDidUpdate(prosp: Props) {
    if (this.props.selected === prosp.selected) return;
    this.setState({ selected: this.props.selected });
  }

  public open() {
    this.setState({ isOpen: true });
  }
  public close() {
    this.setState({ isOpen: false });
  }
  public handleChange(value: App.AdvancedFilter.SelectedFilterValue) {
    if (this.props.selected.some((e) => e.id === value.id)) {
      this.props.onChange(
        // @ts-ignore
        this.props.selected.map((e) => (e.id === value.id ? value : e))
      );
    } else {
      // @ts-ignore
      this.props.onChange(this.props.selected.concat(value));
    }
  }

  public render() {
    return (
      <div
        id={this.props.id}
        className={"advanced-filter__block " + this.props.className}
      >
        <div className="header">
          <Title>{this.props.title}</Title>
          {this.props.children}
        </div>
        {this.state.isOpen && (
          <div className="body">
            {this.props.filters.map(this.filter.getFilter.bind(this.filter))}
          </div>
        )}
        <div className="footer">
          {this.state.isOpen && (
            <span onClick={this.close.bind(this)}>Свернуть</span>
          )}
          {!this.state.isOpen && (
            <span onClick={this.open.bind(this)}>Развернуть</span>
          )}
        </div>
      </div>
    );
  }
}

export default AdvancedFilterBlock;
