import {
  Actions,
  changeContactsType,
  logoutType,
  saveSessionType,
  saveUserType,
} from "../actions/user";

export interface UserStore {
  isAuth: boolean;
  name: string;
  email: string;
  isAdmin: boolean;
  contacts: Array<App.User.Contact>;
  role: App.User.Role;
  session: App.User.Session;
}

class UserReducer {
  private get user(): App.User.User | undefined {
    try {
      return JSON.parse(localStorage.user);
    } catch {}
    return undefined;
  }
  private get session(): App.User.Session {
    try {
      return JSON.parse(localStorage.session);
    } catch {}
    return {
      accessToken: "",
      refreshToken: "",
    };
  }
  public get defaultStore(): UserStore {
    const user = this.user;

    return {
      name: user?.name || "",
      email: user?.email || "",
      contacts: user?.contacts || [],
      isAdmin: user?.role === "admin",
      role: user?.role || "guest",
      session: this.session,
      isAuth: !!this.session.accessToken && !!this.user,
    };
  }
  public store(store: UserStore, action: Actions): UserStore {
    switch (action.type) {
      case saveUserType:
        localStorage.user = JSON.stringify(action.user);
        store.name = action.user.name;
        store.email = action.user.email;
        store.contacts = action.user.contacts || [];
        store.isAdmin = action.user.role === "admin";
        break;

      case saveSessionType:
        localStorage.session = JSON.stringify(action.session);
        store.session = action.session;
        store.isAuth = true;
        break;
      case changeContactsType:
        store.contacts = action.contacts;
        break;

      case logoutType:
        store.name = "";
        store.isAuth = false;
        store.isAdmin = false;
        store.contacts = [];
        store.session = {
          accessToken: "",
          refreshToken: "",
        };
        localStorage.removeItem("user");
        localStorage.removeItem("session");
        break;

      default:
        break;
    }
    return store;
  }
}

export default new UserReducer();
