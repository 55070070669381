import PageLoader from "../../components/pageLoader/PageLoader";
import React from "react";
import { Unsubscribe } from "redux";
import store from "../../store/store";

interface Props {}
interface State {
  isOpen: boolean;
}

class PageLoaderContainer extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      isOpen: this.isActiveRequestsNumber(),
    };
  }

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  private handleUpdateStore() {
    this.setState({
      isOpen: this.isActiveRequestsNumber(),
    });
  }
  private isActiveRequestsNumber() {
    return store.getState().loading.numberActiveRequests !== 0;
  }
  public render() {
    return <PageLoader isOpen={this.state.isOpen} />;
  }
}

export default PageLoaderContainer;
