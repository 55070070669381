import "./AnnouncementCardInfo.scss";

import React from "react";

interface Props {
  announcement: App.Announcement.Announcement;
}

class AnnouncementCardInfo extends React.Component<Props> {
  public render() {
    return (
      <div className="announcement-info">
        <span>{this.props.announcement.car.transmission.name},</span>
        <span>{this.props.announcement.car.motor.volume} л.,</span>
        <span>{this.props.announcement.car.motor.type},</span>
        <span>{this.props.announcement.car.carcass.name},</span>
        <span>
          {this.props.announcement.distance
            ? this.props.announcement.distance + " км."
            : "Новый"}
        </span>
      </div>
    );
  }
}

export default AnnouncementCardInfo;
