import "./CreateAnnouncementType.scss";

import CreateAnnouncementTypeOptions from "./options/Options";
import React from "react";

interface Props {
  type: App.CreateAnnouncement.Type;
  price: number;
  name: string;
  options: Array<App.Announcement.AnnouncementTypeOption>;
  className: string;
  onClick: (type: App.CreateAnnouncement.Type) => void;
}
interface State {}

class CreateAnnouncementType extends React.Component<Props, State> {
  static defaultProps = {
    className: "",
  };

  private handleClick() {
    this.props.onClick(this.props.type);
  }

  public render() {
    return (
      <div
        className={`create-announcement-type ${this.props.className}`}
        onClick={this.handleClick.bind(this)}
      >
        <div className="price-type">
          {this.props.price ? this.props.price + " BYN" : "Бесплатно"}
        </div>
        <div className="card">
          <div className="name">{this.props.name}</div>
          {this.props.children}
          <CreateAnnouncementTypeOptions options={this.props.options} />
        </div>
      </div>
    );
  }
}

export default CreateAnnouncementType;
