import "./Picker.scss";

import PickerOption from "./option/Option";
import React from "react";

interface Props {
  name?: string;
  small?: boolean;
  picked: Array<number | string>;
  options: Array<App.Info>;
  className: string;
  onChange: (value: Array<number | string>) => void;
}
interface State {
  isOpen: boolean;
}

class Picker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  static defaultProps = {
    className: "",
    onChange() {},
  };

  private get showMessage() {
    return this.state.isOpen ? "Свернуть" : "Развернуть";
  }
  public handleClick(id: number | string, picked: boolean) {
    if (picked) {
      this.props.onChange(this.props.picked.filter((e) => e !== id));
    } else {
      this.props.onChange(this.props.picked.concat(id));
    }
  }
  private toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  public render() {
    return (
      <div className={"picker " + this.props.className}>
        {this.props.name && <div className="name">{this.props.name}</div>}
        {this.props.options
          .slice(0, this.state.isOpen ? undefined : 6)
          .map((option) => (
            <PickerOption
              {...option}
              key={option.id}
              selected={this.props.picked.includes(option.id)}
              onClick={this.handleClick.bind(this)}
            />
          ))}
        {this.props.options.length > 6 && (
          <span
            className="picker__show-options"
            onClick={this.toggle.bind(this)}
          >
            {this.showMessage}
          </span>
        )}
      </div>
    );
  }
}

export default Picker;
