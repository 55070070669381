import Messages from "../../components/messages/Messages";
import React from "react";
import { Unsubscribe } from "redux";
import { removeMessage } from "../../store/actions/messages";
import store from "../../store/store";

interface Props {}
interface State {
  messages: Array<App.Messages.Message>;
}

class MessagesContainer extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      messages: [],
    };
  }

  public componentDidMount() {
    this.handleUpdateStore();
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  private remove(id: number) {
    store.dispatch(removeMessage(id));
  }
  private handleUpdateStore() {
    this.setState({
      messages: store.getState().messages.messages,
    });
  }

  public render() {
    return (
      <Messages
        messages={this.state.messages}
        remove={this.remove.bind(this)}
      />
    );
  }
}

export default MessagesContainer;
