import "./LinkToAuth.scss";

import { NavLink } from "react-router-dom";
import React from "react";
import { signin } from "../../../routes";

interface Props {}
interface State {}

class LinkToAuth extends React.Component<Props, State> {
  public render() {
    return (
      <NavLink to={signin} className="header__link-to-auth">
        Войти
      </NavLink>
    );
  }
}

export default LinkToAuth;
