import "./View.scss";

import { Route, RouteComponentProps, Switch } from "react-router";
import {
  adminPanel,
  administrate,
  announcement,
  announcements,
  auth,
  compareCar,
  confirmation,
  filters,
  home,
  redactAnnouncement,
  sell,
  special,
  user,
} from "../routes";

import AdminPanelView from "./adminPanel/AdminPanelView";
import AdministrateView from "./administarate/Administrate";
import AdvancedFilter from "./advancedFilter/AdvancedFilter";
import Announcement from "./announcement/Announcement";
import Announcements from "./announcements/Announcements";
import AuthView from "./auth/Auth";
import CompareCarView from "./compareCar/CompareCarView";
import ConfirmationView from "./confirmation/Confirmation";
import CreateAnnouncement from "./create/CreateAnnouncement";
import Home from "./home/Home";
import NotFoundView from "./notFoundView/NotFoundView";
import { PROD } from "../Config";
import React from "react";
import RedactAnnouncementView from "./redactAnnouncement/RedactAnnouncementView";
import SpecialView from "./special/SpecialView";
import UserView from "./user/UserView";

interface Props extends RouteComponentProps {}
interface State {}

class View extends React.Component<Props, State> {
  public componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }
  public componentDidCatch(error: Error) {
    console.log(error);
    localStorage?.clear();
    this.props.history.replace("/");
    if (!PROD) {
      alert("ВОЗНИКЛА НЕПРЕДВИДЕННАЯ ОШИБКА!!!");
    }
  }

  public render() {
    return (
      <div className="view">
        <Switch>
          <Route path={announcements} component={Announcements} />
          <Route path={announcement()} component={Announcement} />
          <Route
            path={redactAnnouncement()}
            component={RedactAnnouncementView}
          />
          <Route path={auth} component={AuthView} />
          <Route path={sell} component={CreateAnnouncement} />
          <Route path={user} component={UserView} />
          <Route path={filters} component={AdvancedFilter} />
          <Route path={compareCar} component={CompareCarView} />
          <Route path={special} component={SpecialView} />
          <Route path={home} exact component={Home} />
          <Route path={adminPanel} component={AdminPanelView} />
          <Route path={confirmation} exact component={ConfirmationView} />
          <Route path={administrate} component={AdministrateView} />
          <Route component={NotFoundView} />
        </Switch>
      </div>
    );
  }
}

export default View;
