import "./Header.scss";

import BaseNav from "../nav/base/BaseNav";
import LinkToAuth from "../nav/auth/LinkToAuth";
import Logo from "../logo/Logo";
import MenuWithBurger from "../nav/menuWithBurger/MenuWithBurger";
import React from "react";
import UserNav from "../nav/user/UserNav";

interface Props {
  isAuth: boolean;
  isAdmin: boolean;
  name: string;
}
interface State {}

class Header extends React.Component<Props, State> {
  public render() {
    return (
      <header>
        <div className="max-width">
          <Logo />
          <BaseNav isAdmin={this.props.isAdmin} />
          {this.props.isAuth && <UserNav name={this.props.name} />}
          {!this.props.isAuth && <LinkToAuth />}
        </div>
        <div className="small-screen">
          <Logo />
          <MenuWithBurger {...this.props} />
        </div>
      </header>
    );
  }
}

export default Header;
