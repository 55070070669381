import "./AdvancedFilter.scss";

import AdvancedFilterHeader from "../../containers/advancedFilter/header/Header";
import AdvancedFiltersAnnouncementsList from "../../containers/announcement/results/advancedFilters/AdvancedFiltersAnnouncementsList";
import AdvancedFiltersContainer from "../../containers/advancedFilter/filters/AdvancedFiltersContainer";
import React from "react";
import SubTitle from "../../components/custom/subtitle/SubTitle";

interface Props {}
interface State {}

class AdvancedFilter extends React.Component<Props, State> {
  public componentDidMount() {
    document.title = "Расширенные фильтры";
    window.scrollTo({ top: 0 });
  }
  public render() {
    return (
      <div className="advanced-filter">
        <div className="filters">
          <SubTitle>Расширенный фильтр</SubTitle>
          <AdvancedFilterHeader />
          <AdvancedFiltersContainer />
        </div>
        <AdvancedFiltersAnnouncementsList />
      </div>
    );
  }
}

export default AdvancedFilter;
