import "./SearchLabel.scss";

import LabelText from "../../custom/labels/labelText/LabelText";
import React from "react";
import SearchIcon from "./assets/search.svg";

interface Props {
  keyword: string;
  onChangeKeyword: (keyword: string) => void;
  onSearch: () => void;
}
interface State {}

class SearchLabel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="search-label">
        <LabelText
          value={this.props.keyword}
          placeholder="Поиск по объявлениям"
          onChange={this.props.onChangeKeyword}
        />
        <button
          className="button-addition"
          title="Искать"
          onClick={this.props.onSearch}
        >
          <img src={SearchIcon} alt="" />
        </button>
      </div>
    );
  }
}
export default SearchLabel;
