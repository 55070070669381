import "./CompareCarNameRow.scss";

import AnnouncementName from "../../../car/name/AnnouncementName";
import CompareCarRow from "../../compareCarRow/CompareCarRow";
import React from "react";

interface Props {
  announcements: Array<App.Announcement.Announcement>;
}
interface State {}

class CompareCarNameRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <CompareCarRow
        key="-2"
        head={<b>Марка, модель поколение </b>}
        values={this.props.announcements.map((a) => (
          <AnnouncementName announcement={a} />
        ))}
      />
    );
  }
}
export default CompareCarNameRow;
