import "./RadioButtons.scss";

import LabelCheckbox from "../../custom/labels/labelCheckbox/LabelCheckbox";
import React from "react";
import SubTitle from "../../custom/subtitle/SubTitle";

interface Props {
  name: string;
  options: Array<App.Info>;
  selected: number | string | null;
  onChange: (value: number | string | null) => void;
}
interface State {}

class RadioButtons extends React.Component<Props, State> {
  static defaultProps = {
    selected: null,
    onChange() {},
  };

  private handleChange(id: number) {
    return (value: boolean) => {
      if (value) {
        this.props.onChange(id);
      } else {
        this.props.onChange(null);
      }
    };
  }
  public render() {
    return (
      <div className="radio-bottons">
        <SubTitle className="name">{this.props.name}</SubTitle>
        {this.props.options.map((option) => (
          <LabelCheckbox
            type={"radio"}
            key={option.id}
            {...option}
            onChange={this.handleChange(option.id)}
            value={option.id === this.props.selected}
          />
        ))}
      </div>
    );
  }
}

export default RadioButtons;
