import React, { RefObject } from "react";

import AnnouncementList from "../../../components/announcement/list/AnnouncementList";
import AnnouncementService from "../../../services/abstract/AnnouncementService";
import AnnouncementServiceApi from "../../../services/api/AnnouncementServiceApi";
import { OK } from "http-status-codes";
import SMessage from "../../../struct/message/SMessage";
import { addMessage } from "../../../store/actions/messages";
import store from "../../../store/store";

interface Props {
  id?: string;
  className?: string;
  title?: string;
  total?: boolean;
  special?: boolean;

  page: number;
  sort: number;
  errorMessage?: string;

  tags?: Array<App.Tag>;
  dropFilters?: () => void;
  onChangePage: (page: number) => void;
  onChangeSort: (page: number) => void;
  filters?: App.Announcement.FiltersForSearch;
  search?: () => Promise<
    App.Response<App.ListMetadata<App.Announcement.Announcement>>
  >;
  onLoad?: (data: App.ListMetadata<App.Announcement.Announcement>) => void;
}

interface State {
  totalPage: number;
  totalAnnouncments: number;
  announcements: Array<App.Announcement.Announcement>;
  loading: boolean;
  ref: RefObject<HTMLDivElement>;
  started: boolean;
  isShowHidden: boolean;
  errorMessage?: string;
}

class AnnouncementListContainer extends React.Component<Props, State> {
  private service: AnnouncementService;
  constructor(props: Props) {
    super(props);
    this.service = new AnnouncementServiceApi();
    this.state = {
      totalPage: 0,
      totalAnnouncments: 0,
      announcements: [],
      loading: false,
      ref: React.createRef(),
      started: false,
      isShowHidden: false,
    };
  }

  public componentDidMount() {
    this.loadAnnouncements();
  }
  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.page !== prevProps.page ||
      this.state.isShowHidden !== prevState.isShowHidden ||
      JSON.stringify(this.props.filters) !==
        JSON.stringify(prevProps.filters) ||
      this.props.sort !== prevProps.sort
    ) {
      this.loadAnnouncements();
    }
  }

  private get loading() {
    return this.state.loading;
  }
  private set loading(loading: boolean) {
    this.setState({ loading });
  }

  private get title() {
    if (this.props.title) {
      return `${this.props.title} ${
        this.props.total && `(${this.state.totalAnnouncments})`
      }`;
    }
    return undefined;
  }

  public handleChangePage(page: number) {
    if (this.state.loading) return;
    this.props.onChangePage(page);
  }
  public handleChangeSort(sort: App.ID | null) {
    if (this.state.loading) return;
    this.props.onChangeSort(sort ? +sort : 0);
  }

  public async loadAnnouncements() {
    this.loading = true;
    const filters = this.props.filters;
    const { data, status, errors } = await (!!this.props.filters
      ? this.getAnnouncements(this.props.filters)
      : // @ts-ignore
        this.props.search());

    if (
      filters &&
      JSON.stringify(this.props.filters) !== JSON.stringify(filters)
    ) {
      return;
    }
    if (status === OK) {
      this.props.onLoad && this.props.onLoad(data);
      this.setState({
        announcements: data.items,
        totalPage: data.totalPage,
        totalAnnouncments: data.total,
        started: true,
        errorMessage: undefined,
      });

      if (data.items.length === 0) {
        this.setState({
          errorMessage: !this.props.filters
            ? undefined
            : "По выбранным фильтрам найти авто не удалось",
        });
        store.dispatch(
          addMessage(new SMessage("Нет ни одного объявления.", "warning"))
        );
      }
    } else {
      errors.forEach((e: string) => {
        store.dispatch(addMessage(new SMessage(e)));
      });
    }

    this.loading = false;
  }

  private async getAnnouncements(filters: App.Announcement.FiltersForSearch) {
    return this.service.getByFilters({
      filters,
      page: this.props.page,
      sort: this.props.sort,
      special: this.props.special,
      showAll: this.state.isShowHidden,
    });
  }

  private handleShowMore() {
    if (this.props.page + 1 <= this.state.totalPage) {
      this.handleChangePage(this.props.page + 1);
    }
  }

  private updateAnnouncement(announcement: App.Announcement.Announcement) {
    this.setState({
      announcements: this.state.announcements.map((a) =>
        a.id === announcement.id ? announcement : a
      ),
    });
  }
  private toggleShowHidden() {
    this.setState({ isShowHidden: !this.state.isShowHidden });
    setTimeout(() => this.props.onChangePage(1));
  }

  public render() {
    return (
      <AnnouncementList
        tags={this.props.tags}
        refLink={this.state.ref}
        id={this.props.id}
        className={this.props.className}
        page={this.props.page}
        sort={this.props.sort}
        isShowHidden={this.state.isShowHidden}
        totalPage={this.state.totalPage}
        title={this.title}
        announcements={this.state.announcements}
        dropFilters={this.props.dropFilters}
        updateAnnouncement={this.updateAnnouncement.bind(this)}
        onChangePage={this.handleChangePage.bind(this)}
        onChangeSort={this.handleChangeSort.bind(this)}
        onShowMore={this.handleShowMore.bind(this)}
        onToggleShowHidden={this.toggleShowHidden.bind(this)}
        loading={this.state.loading}
        errorMessage={this.state.errorMessage}
        notFound={
          !this.state.loading &&
          this.state.announcements.length === 0 &&
          !this.props.children
        }
      />
    );
  }
}

export default AnnouncementListContainer;
