import "./AnnouncementPageHead.scss";

import AnnouncementName from "../../../car/name/AnnouncementName";
import AnnouncementPageStatistic from "../statistic/AnnouncmentPageStatistic";
import React from "react";

interface Props {
  announcement?: App.Announcement.Announcement;
  onUpdateAnnouncement: (announcement: App.Announcement.Announcement) => void;
}
interface State {}

class AnnouncementPageHead extends React.Component<Props, State> {
  public render() {
    return (
      <div className="announcement-page-head">
        <AnnouncementName announcement={this.props.announcement} />
        <AnnouncementPageStatistic
          onUpdateAnnouncement={this.props.onUpdateAnnouncement}
          announcement={this.props.announcement}
        />
      </div>
    );
  }
}
export default AnnouncementPageHead;
