import "./AnnouncementActions.scss";

import React, { SyntheticEvent } from "react";

import CompareIcon from "../../../icons/CompareIcon";
import CrossIcon from "../../../icons/Cross";
import { PAGE_URL } from "../../../Config";
import ShareIcon from "../../../icons/ShareIcon";
import SocialNetworks from "./socialNetworks/SocialNetworks";
import StarBorderIcon from "../../../icons/StarBorderIcon";

interface Props {
  id: number;
  isFavorite: boolean;
  isCompared: boolean;
  isHidden: boolean;
  onToggleFavorite: () => void;
  onToggleComparison: () => void;
  onHide: () => void;
}
interface State {
  isOpenSocialNetworks: boolean;
}

class AnnouncementActions extends React.Component<Props, State> {
  private handleClick: () => void;
  constructor(props: Props) {
    super(props);
    this.handleClick = this.handleClickBase.bind(this);
    this.state = { isOpenSocialNetworks: false };
  }

  public componentDidMount() {
    window.addEventListener("click", this.handleClick);
  }
  public componentWillUnmount() {
    window.removeEventListener("click", this.handleClick);
  }

  private handleClickBase() {
    this.setState({ isOpenSocialNetworks: false });
  }
  private openSocialNetworks(event: SyntheticEvent) {
    event.stopPropagation();
    this.setState({ isOpenSocialNetworks: true });
  }

  public render() {
    return (
      <div className="announcement-actions">
        <div
          className="action"
          onClick={this.props.onToggleFavorite}
          data-active={this.props.isFavorite}
        >
          <StarBorderIcon />
        </div>
        <div
          className="action"
          onClick={this.props.onToggleComparison}
          data-active={this.props.isCompared}
        >
          <CompareIcon />
        </div>
        <div
          className="action"
          data-active={this.state.isOpenSocialNetworks}
          onClick={this.openSocialNetworks.bind(this)}
        >
          <ShareIcon />
          {this.state.isOpenSocialNetworks && (
            <SocialNetworks
              link={`${PAGE_URL}/announcement/${this.props.id}`}
            />
          )}
        </div>
        <div
          className="action"
          onClick={this.props.onHide}
          data-active={this.props.isHidden}
        >
          <CrossIcon />
        </div>
      </div>
    );
  }
}

export default AnnouncementActions;
