import "./AnnouncementViewBodyInfo.scss";

import AnnouncementBuyInfoContainer from "../../../../containers/announcement/buyInfoContainer/AnnouncementBuyInfoContainer";
import AnnouncementOptionsContainer from "../../../../containers/announcement/optionsContainer/AnnouncementOptionsContainer";
import AnnouncementViewBodyDescription from "./description/AnnouncementViewBodyDescription";
import AnnouncementViewBodyInfoContact from "./contact/AnnouncementViewBodyInfoContact";
import CarHistory from "../../../../components/car/carHistory/CarHistory";
import React from "react";
import RecomendedAnnouncementsContainer from "../../../../containers/announcement/results/recomended/RecomendedAnnouncementsContainer";

interface Props {
  announcement?: App.Announcement.Announcement;
}
interface State {}

class AnnouncementViewBodyInfo extends React.Component<Props, State> {
  public render() {
    return (
      <div className="announcement-view-body__info">
        <div className="text-info">
          {this.props.announcement && (
            <AnnouncementViewBodyInfoContact
              announcement={this.props.announcement}
            />
          )}
          <AnnouncementViewBodyDescription
            description={this.props.announcement?.description}
          />
          {this.props.announcement && (
            <CarHistory steps={this.props.announcement.history} />
          )}
          {this.props.announcement && (
            <AnnouncementOptionsContainer
              announcement={this.props.announcement}
            />
          )}
          <RecomendedAnnouncementsContainer />
        </div>

        <AnnouncementBuyInfoContainer announcement={this.props.announcement} />
      </div>
    );
  }
}

export default AnnouncementViewBodyInfo;
