import "./CompareCarRow.scss";

import React from "react";

interface Props {
  head: JSX.Element;
  values: Array<JSX.Element>;
  className?: string;
  classes?: Array<string>;
  text?: Array<string>;
  isHideHead?: boolean;
  showOnlyDifference?: boolean;
}
interface State {}

class CompareCarRow extends React.Component<Props, State> {
  private get isShow(): boolean {
    if (!this.props.showOnlyDifference) return true;
    const values: Set<string> = new Set();
    return (
      this.props.text?.some((v) => {
        values.add(v);
        return values.size > 1;
      }) || false
    );
  }
  public render() {
    return (
      <>
        {this.isShow && (
          <div className={`compare-car__row ${this.props.className || ""}`}>
            <div className="compare-car__row-head">{this.props.head}</div>
            {this.props.values.map((value, index) => (
              <div
                className={
                  this.props.classes && this.props.classes[index]
                    ? `compare-car__row-value ${this.props.classes[index]}`
                    : "compare-car__row-value"
                }
                key={index}
              >
                {index === 0 && (
                  <div className="head">
                    {this.props.head}
                    <div></div>
                  </div>
                )}
                {/* <div className="head">{this.props.head}</div> */}
                <div className="value">{value}</div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
}

export default CompareCarRow;
