import "../labelText/LabelText.scss";

import React, { RefObject, SyntheticEvent } from "react";

interface Props {
  name?: string;
  maxLength?: number;
  value: string;
  onChange: (value: string) => void;

  correct?: boolean;
  uncorrect?: boolean;
}
interface State {
  value: string;
  ref: RefObject<HTMLInputElement>;
  autocompleteOptions: any;
}

class LabelCity extends React.Component<Props, State> {
  private updateInterval: number;
  constructor(props: Props) {
    super(props);
    this.updateInterval = window.setInterval(this.handleUpdate.bind(this), 50);
    this.state = {
      ref: React.createRef(),
      value: "",
      autocompleteOptions: {
        boundedBy: [
          [51.5, 32.5],
          [56.2, 23.3],
        ],
      },
    };
  }
  static defaultProps = {
    value: "",
    onChange: () => {},
  };

  public componentDidMount() {
    this.start();
  }
  public componentWillUnmount() {
    window.clearInterval(this.updateInterval);
  }

  private start() {
    // @ts-ignore
    if (!window.ymaps) {
      setTimeout(() => this.start(), 300);
    } else {
      // @ts-ignore
      window.ymaps.ready(
        () =>
          // @ts-ignore
          new window.ymaps.SuggestView(
            this.state.ref.current,
            this.state.autocompleteOptions
          )
      );
    }
  }

  private handleChange(event: SyntheticEvent<HTMLInputElement>) {
    this.props.onChange(event.currentTarget.value);
  }

  private handleUpdate() {
    const { current } = this.state.ref;
    if (current && current.value !== this.props.value) {
      this.props.onChange(current.value);
    }
  }

  public render() {
    return (
      <div className="label-text">
        {this.props.name && <div className="name">{this.props.name}</div>}
        <input
          data-correct={this.props.correct}
          data-uncorrect={this.props.uncorrect}
          type="text"
          value={this.props.value}
          onChange={this.handleChange.bind(this)}
          placeholder="Город"
          ref={this.state.ref}
          maxLength={this.props.maxLength}
        />
      </div>
    );
  }
}

export default LabelCity;
