import {
  annnouncement,
  brands,
  carcasses,
  colors,
  drives,
  models,
  transmissions,
} from "./database";

export function delay(data: any): Promise<any> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, 0);
  });
}

export function getCar(car: {
  brand_id: number;
  model_id: number;
  carcass_id: number;
  transmission_id: number;
  motor_type_id: number;
  drive_id: number;
  color_id: number;
}): App.Car.Car | false {
  const brand: App.Car.Brand | void = brands.find(
    (brand) => brand.id === car.brand_id
  );
  const model: App.Car.Model | void = models.find(
    (model) => model.id === car.model_id
  );
  const carcass: App.Car.Carcass | void = carcasses.find(
    (carcass) => carcass.id === car.carcass_id
  );
  const transmission: App.Car.Transmission | void = transmissions.find(
    (transmission) => transmission.id === car.transmission_id
  );
  const motor: App.Car.Motor = {
    id: 1,
    volume: 2.8,
    power: 322,
    type: "Бензин",
  };
  const drive: App.Car.Drive | void = drives.find(
    (drive) => drive.id === car.drive_id
  );
  const color: App.Car.Color | void = colors.find(
    (color) => color.id === car.color_id
  );

  if (brand && model && carcass && transmission && drive && color) {
    return { brand, model, carcass, transmission, motor, drive, color };
  }

  return false;
}

export function getAnnouncementForResponse():
  | App.Announcement.Announcement
  | false {
  const car = getCar(annnouncement.car);
  if (!car) {
    return false;
  }
  // @ts-ignore
  return Object.assign({}, annnouncement, {
    car,
  });
}
