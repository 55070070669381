import {
  changeFilterAnnouncementsAdvanced,
  researchAnnouncementsAdvanced,
} from "../../../store/actions/announcements/advanced";

import AdvancedFilterBlock from "../block/Block";
import CarFilters from "../../filters/carFilters/CarFilters";
import { FiltersStore } from "../../../store/reducers/FiltersReducer";
import React from "react";
import { Unsubscribe } from "redux";
import store from "../../../store/store";

interface Props {}
interface State {
  filters: FiltersStore;
  car: Array<App.AdvancedFilter.CarFilter>;
  other: App.AdvancedFilter.SelectedFilter;
}

class AdvancedFiltersContainer extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      filters: this.getFilters(),
      ...this.parseHash(),
    };
  }

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
    store.dispatch(
      changeFilterAnnouncementsAdvanced({
        car: this.state.car,
        other: this.state.other,
      })
    );
  }
  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.state.car !== prevState.car ||
      this.state.other !== prevState.other
    ) {
      this.saveHash();
    }
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }
  public componentDidCatch() {
    this.setState(this.parseHash());
  }

  private handleUpdateStore() {
    this.setState({ filters: this.getFilters() });
  }

  // location hash
  private getHash() {
    return (
      "#" + JSON.stringify({ car: this.state.car, other: this.state.other })
    );
  }
  private saveHash() {
    setTimeout(() => {
      window.location.hash = this.getHash();
    });
  }

  private parseHash(): App.Announcement.FiltersForSearch {
    try {
      const data = JSON.parse(decodeURI(window.location.hash.slice(1)));
      if (data.car.length === 0) {
        data.car[0] = { brand: null, model: null };
      }
      return data;
    } catch {
      return {
        car: [{ brand: 0, model: null }],
        other: [],
      };
    }
  }

  // update state
  private getFilters(): FiltersStore {
    return store.getState().filters;
  }
  private handleChange(other: App.AdvancedFilter.SelectedFilter) {
    this.saveFilter({ car: this.state.car, other });
  }
  private handleChangeCarFilter(car: Array<App.AdvancedFilter.CarFilter>) {
    this.saveFilter({ car, other: this.state.other });
  }
  private saveFilter(values: App.Announcement.FiltersForSearch) {
    this.setState(values);
    store.dispatch(changeFilterAnnouncementsAdvanced(values));
    // this.saveHash();
  }

  private search() {
    store.dispatch(researchAnnouncementsAdvanced());
  }

  public render() {
    return (
      <>
        <AdvancedFilterBlock
          id="#main"
          className="main"
          title="Основные"
          filters={this.state.filters.main}
          selected={this.state.other}
          onChange={this.handleChange.bind(this)}
        >
          <CarFilters
            car={this.state.car}
            onChange={this.handleChangeCarFilter.bind(this)}
          />
        </AdvancedFilterBlock>
        <AdvancedFilterBlock
          id="#visibility"
          className="visibility"
          title="Обзор"
          filters={this.state.filters.visibility}
          selected={this.state.other}
          onChange={this.handleChange.bind(this)}
        />
        <AdvancedFilterBlock
          id="#salon"
          className="salon"
          title="Салон"
          filters={this.state.filters.salon}
          selected={this.state.other}
          onChange={this.handleChange.bind(this)}
        />
        <AdvancedFilterBlock
          id="#comfort"
          className="comfort"
          title="Комфорт"
          filters={this.state.filters.comfort}
          selected={this.state.other}
          onChange={this.handleChange.bind(this)}
        />
        <AdvancedFilterBlock
          id="#multimedia"
          className="multimedia"
          title="Мультимедия"
          filters={this.state.filters.multimedia}
          selected={this.state.other}
          onChange={this.handleChange.bind(this)}
        />
        <AdvancedFilterBlock
          id="#safety"
          className="safety"
          title="Безопасность"
          filters={this.state.filters.safety}
          selected={this.state.other}
          onChange={this.handleChange.bind(this)}
        />
        <AdvancedFilterBlock
          id="#other"
          className="other"
          title="Прочее"
          filters={this.state.filters.other}
          selected={this.state.other}
          onChange={this.handleChange.bind(this)}
        />
      </>
    );
  }
}

export default AdvancedFiltersContainer;
