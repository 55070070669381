import React from "react";
import "./SubTitle.scss";
interface Props {
  className: string;
}
class SubTitle extends React.Component<Props> {
  static defaultProps = {
    className: "",
  };
  public render() {
    return (
      <h2 className={"sub-title " + this.props.className}>
        {this.props.children}
      </h2>
    );
  }
}

export default SubTitle;
