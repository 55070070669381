import "./List.scss";

import React, { RefObject } from "react";

import CarouselArrowTab from "./arrow/ArrowTab";
import CarouselListImage from "./image/Image";

interface Props {
  images: Array<string>;
  active: number;
  onChange: (active: number) => void;
}
interface State {
  ref: RefObject<HTMLDivElement>;
}

class CarouselImagesList extends React.Component<Props, State> {
  private handleWheel: (event: WheelEvent) => void;
  private handleTouchMove: (event: TouchEvent) => void;

  constructor(props: Props) {
    super(props);

    this.handleWheel = this.baseHandleWheel.bind(this);
    this.handleTouchMove = this.baseHandleTouchMove.bind(this);

    this.state = {
      ref: React.createRef(),
    };
  }

  public componentDidMount() {
    if (this.state.ref.current) {
      // this.state.ref.current.addEventListener("wheel", this.handleWheel);
      // this.state.ref.current.addEventListener(
      //   "touchmove",
      //   this.handleTouchMove
      // );
    }
  }
  public componentWillUnmount() {
    if (this.state.ref.current) {
      this.state.ref.current.removeEventListener("wheel", this.handleWheel);
      this.state.ref.current.removeEventListener(
        "touchmove",
        this.handleTouchMove
      );
    }
  }
  private get elemHeight(): number {
    try {
      if (this.state.ref.current) {
        const children = this.state.ref.current.children[0];
        if (children) {
          return (
            children.getBoundingClientRect().height +
            parseFloat(getComputedStyle(children).marginBottom)
          );
        }
      }
      throw new Error();
    } catch {
      return 0;
    }
  }
  private get elemWidth(): number {
    try {
      if (this.state.ref.current) {
        const children = this.state.ref.current.children[0];
        if (children) {
          return (
            children.getBoundingClientRect().width +
            parseFloat(getComputedStyle(children).marginRight)
          );
        }
      }
      throw new Error();
    } catch {
      return 0;
    }
  }

  private baseHandleWheel(event: WheelEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
  private baseHandleTouchMove(event: TouchEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  private scrollBack() {
    this.props.onChange(this.props.active - 1);
    if (this.state.ref.current) {
      this.state.ref.current.scrollBy({
        top: -this.elemHeight,
        left: -this.elemWidth,
        behavior: "smooth",
      });
    }
  }
  private scrollForvard() {
    this.props.onChange(this.props.active + 1);
    if (this.state.ref.current) {
      this.state.ref.current.scrollBy({
        top: this.elemHeight,
        left: this.elemWidth,
        behavior: "smooth",
      });
    }
  }

  public render() {
    return (
      <div className="carousel__list">
        <CarouselArrowTab
          type="previous"
          onClick={this.scrollBack.bind(this)}
        />

        <div className="carousel__images-container">
          <div className="carousel__images" ref={this.state.ref}>
            {this.props.images.map((image, index) => (
              <CarouselListImage
                key={index}
                index={index}
                image={image}
                active={this.props.active === index}
                onClick={this.props.onChange}
              />
            ))}
          </div>
        </div>

        <CarouselArrowTab type="next" onClick={this.scrollForvard.bind(this)} />
      </div>
    );
  }
}

export default CarouselImagesList;
