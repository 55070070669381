import {
  Actions,
  addMessageType,
  removeMessageType,
} from "../actions/messages";

export interface MessageStore {
  messages: Array<App.Messages.Message>;
}

class MessageReducer {
  public get defaultStore(): MessageStore {
    return {
      messages: [],
    };
  }

  public store(store: MessageStore, action: Actions): MessageStore {
    switch (action.type) {
      case addMessageType:
        store.messages = store.messages.concat(action.message);
        break;
      case removeMessageType:
        store.messages = store.messages.filter((m) => m.id !== action.id);
        break;
      default:
        break;
    }
    return store;
  }
}

export default new MessageReducer();
