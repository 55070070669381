import "./CompareCarView.scss";

import CompareCarContainer from "../../containers/compareCar/CompareCarContainer";
import React from "react";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps {}
interface State {}

class CompareCarView extends React.Component<Props, State> {
  public componentDidMount() {
    document.title = "Сравнение авто";
    window.scrollTo({ top: 0 });
  }
  public render() {
    return (
      <div className="compare-car-view">
        <CompareCarContainer goBack={this.props.history.goBack} />
      </div>
    );
  }
}

export default CompareCarView;
