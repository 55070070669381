import { SyntheticEvent } from "react";

export function isTarget(event: SyntheticEvent | Event) {
  return event.target === event.currentTarget;
}
export function prevent(event: SyntheticEvent | Event) {
  event.preventDefault();
}
export function stop(event: SyntheticEvent | Event) {
  event.stopPropagation();
}
export function preventAndStop(event: SyntheticEvent | Event) {
  event.preventDefault();
  event.stopPropagation();
}
export function getHandleWheel(callback: (percent: number) => void) {
  return (event: React.WheelEvent) => callback(getScrollPart(event));
}

export function getScrollPart(event: React.WheelEvent): number {
  return (
    Math.max(
      (event.currentTarget.scrollTop + event.currentTarget.clientHeight) /
        event.currentTarget.scrollHeight,
      0
    ) * 100
  );
}
