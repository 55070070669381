import "./ChangePasswordForm.scss";

import React, { SyntheticEvent } from "react";

import { ChangePasswordData } from "../../../services/abstract/UserService";
import LabelText from "../../custom/labels/labelText/LabelText";
import SubTitle from "../../custom/subtitle/SubTitle";

interface Props {
  value: ChangePasswordData;
  loading: boolean;
  onChange: (value: ChangePasswordData) => void;
  onSubmit: () => void;
}
interface State {}
class ChangePasswordForm extends React.Component<Props, State> {
  private get isValide() {
    return (
      this.isValideOldPassword &&
      this.isValideNewPassword &&
      this.isValideComparisonPassword
    );
  }

  private get isValideOldPassword() {
    return this.props.value.oldPassword.length > 3;
  }
  private get isInvalideOldPassword() {
    return (
      this.props.value.oldPassword.length > 0 &&
      this.props.value.oldPassword.length <= 3
    );
  }

  private get isValideNewPassword() {
    return (
      this.props.value.newPassword.length > 3 &&
      this.props.value.newPassword !== this.props.value.oldPassword
    );
  }
  private get isInvalideNewPassword() {
    return (
      this.props.value.newPassword.length > 0 &&
      (this.props.value.newPassword.length <= 3 ||
        this.props.value.newPassword === this.props.value.oldPassword)
    );
  }

  private get isValideComparisonPassword() {
    return (
      this.props.value.comparisonNewPassword.length > 0 &&
      this.props.value.comparisonNewPassword === this.props.value.newPassword
    );
  }
  private get isInvalideComparisonPassword() {
    return (
      this.props.value.comparisonNewPassword.length > 0 &&
      this.props.value.comparisonNewPassword !== this.props.value.newPassword
    );
  }

  private handleChange(field: "old" | "new" | "comp", v: string) {
    const value = Object.assign({}, this.props.value);
    switch (field) {
      case "old":
        value.oldPassword = v;
        break;
      case "new":
        value.newPassword = v;
        break;
      case "comp":
        value.comparisonNewPassword = v;
        break;
      default:
        return;
    }
    this.props.onChange(value);
  }

  private handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    if (this.isValide) {
      this.props.onSubmit();
    }
  }
  public render() {
    return (
      <form
        className="change-password-form"
        onSubmit={this.handleSubmit.bind(this)}
        data-disabled={this.props.loading}
      >
        <SubTitle>Смена пароля</SubTitle>
        <LabelText
          type="password"
          disabled={this.props.loading}
          value={this.props.value.oldPassword}
          onChange={(value) => this.handleChange("old", value)}
          correct={this.isValideOldPassword}
          uncorrect={this.isInvalideOldPassword}
          placeholder="Старый пароль"
        />
        <LabelText
          type="password"
          disabled={this.props.loading}
          value={this.props.value.newPassword}
          onChange={(value) => this.handleChange("new", value)}
          correct={this.isValideNewPassword}
          uncorrect={this.isInvalideNewPassword}
          placeholder="Новый пароль"
        />
        <LabelText
          type="password"
          disabled={this.props.loading}
          value={this.props.value.comparisonNewPassword}
          onChange={(value) => this.handleChange("comp", value)}
          correct={this.isValideComparisonPassword}
          uncorrect={this.isInvalideComparisonPassword}
          placeholder="Повторите пароль"
        />
        <button disabled={!this.isValide || this.props.loading}>Сменить</button>
      </form>
    );
  }
}

export default ChangePasswordForm;
