import "./Complite.scss";
import "../Popups.scss";

import { announcement, userAnnouncements } from "../../../../../routes";

import CkeckedCircle from "../../../../../icons/CkeckedCircle";
import { NavLink } from "react-router-dom";
import Popup from "../../../../custom/popup/Popup";
import React from "react";

interface Props {
  announcementId: App.ID;
  onClose: () => void;
}
interface State {}

class CreateAnnouncementComplite extends React.Component<Props, State> {
  public render() {
    return (
      <Popup
        className="create-announcement-popup complited"
        onClose={this.props.onClose}
      >
        <CkeckedCircle />
        <b>Ваше объявление успешно создано</b>
        <b>Все объявления можно найти в личном кабинете</b>
        <div className="buttons">
          <NavLink to={announcement(this.props.announcementId)}>
            <button>Перейти к объявлению</button>
          </NavLink>
          <NavLink to={userAnnouncements}>
            <button>Личный кабинет</button>
          </NavLink>
        </div>
      </Popup>
    );
  }
}

export default CreateAnnouncementComplite;
