import CarService, { BrandData } from "../../../services/abstract/CarService";

import CarServiceApi from "../../../services/api/CarServiceApi";
import { OK } from "http-status-codes";
import React from "react";
import RedactBrand from "../../../components/adminPanel/redactBrand/RedactBrand";
import SMessage from "../../../struct/message/SMessage";
import { addMessage } from "../../../store/actions/messages";
import { saveBrands } from "../../../store/actions/car";
import store from "../../../store/store";

interface Props {
  onChangeActiveBrand: (brand?: App.Car.Brand) => void;
}
interface State {
  brand?: App.Car.Brand;
  brands: Array<App.Car.Brand>;
}

class RedactBrandContainer extends React.Component<Props, State> {
  private service: CarService;
  constructor(props: Props) {
    super(props);
    this.service = new CarServiceApi();
    this.state = {
      brands: [],
    };
  }

  public componentDidMount() {
    this.getBrands();
  }
  public componentDidUpdate(props: Props, state: State) {
    if (state.brand !== this.state.brand) {
      this.props.onChangeActiveBrand(this.state.brand);
    }
  }

  private async getBrands() {
    const { data, status, errors } = await this.service.getBrands();
    if (status === OK) {
      this.setState({ brands: data });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }

  private async handleSave(brand: BrandData) {
    if (this.state.brand) {
      this.update(this.state.brand.id, brand);
    } else {
      this.add(brand);
    }
  }

  private async add(brand: BrandData) {
    const { data, status, errors } = await this.service.addBrand(brand);
    if (status === OK) {
      this.updateList(data);
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }
  private async update(id: App.ID, brand: BrandData) {
    const { data, status, errors } = await this.service.updateBrand(id, brand);
    if (status === OK) {
      this.updateList(data);
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }

  private updateList(data: App.Car.Brand) {
    if (this.state.brands.some((v) => v.id === data.id)) {
      this.setState({
        brand: data,
        brands: this.state.brands.map((v) => (v.id === data.id ? data : v)),
      });
      store.dispatch(
        saveBrands(this.state.brands.map((b) => (b.id === data.id ? data : b)))
      );
    } else {
      this.setState({
        brand: data,
        brands: this.state.brands.concat(data),
      });
      store.dispatch(
        saveBrands(this.state.brands.map((b) => (b.id === data.id ? data : b)))
      );
    }
  }

  private async delete() {
    if (!this.state.brand) return false;
    const id = this.state.brand.id;
    const { status, errors } = await this.service.deleteBrand(id);
    if (status === OK) {
      this.setState({
        brand: undefined,
        brands: this.state.brands.filter((b) => b.id !== id),
      });
      store.dispatch(saveBrands(this.state.brands.filter((b) => b.id !== id)));
      return true;
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
    return false;
  }

  private handleSelectBrand(brand: App.Car.Brand) {
    if (this.state.brand && this.state.brand.id === brand.id) {
      this.setState({ brand: undefined });
    } else {
      this.setState({ brand });
    }
  }

  public render() {
    return (
      <RedactBrand
        brands={this.state.brands}
        brand={this.state.brand}
        onSave={this.handleSave.bind(this)}
        onDelete={this.delete.bind(this)}
        onSelectBrand={this.handleSelectBrand.bind(this)}
      />
    );
  }
}

export default RedactBrandContainer;
