type ADD_REQUEST = "ADD_REQUEST";
type REMOVE_REQUEST = "REMOVE_REQUEST";

interface AddRequestAction {
  type: ADD_REQUEST;
}
interface RemoveRequestAction {
  type: REMOVE_REQUEST;
}
export type Acctions = AddRequestAction | RemoveRequestAction;

export const addRequestType: ADD_REQUEST = "ADD_REQUEST";
export const removeRequestType: REMOVE_REQUEST = "REMOVE_REQUEST";

export function addRequest() {
  return { type: addRequestType };
}
export function removeRequest() {
  return { type: removeRequestType };
}
