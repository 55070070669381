import "./CreateAnnouncementSelectType.scss";

import FastType from "./type/fast/FastType";
import FullType from "./type/full/FullType";
import React from "react";
import Title from "../../../custom/title/Title";

interface Props {
  selectType: (type: App.CreateAnnouncement.Type) => void;
}
interface State {}

class CreateAnnouncementSelectType extends React.Component<Props, State> {
  public render() {
    return (
      <div className="select-type">
        <Title>Какое объявление вы хотите создать?</Title>
        <div className="types">
          <FullType selectType={this.props.selectType} />
          <FastType selectType={this.props.selectType} />
        </div>
      </div>
    );
  }
}

export default CreateAnnouncementSelectType;
