import {
  CarActions,
  loadBrandsType,
  loadModelsType,
  saveBrands,
  saveBrandsType,
  saveModels,
  saveModelsType,
} from "../actions/car";

import CarService from "../../services/abstract/CarService";
import CarServiceApi from "../../services/api/CarServiceApi";
import { OK } from "http-status-codes";
import store from "../store";

export interface CarStore {
  brands: null | Array<App.Car.Brand>;
  models: Array<Array<App.Car.Model>>;
  transmissions: null | Array<App.Car.Transmission>;
  carcasses: null | Array<App.Car.Carcass>;
  motorTypes: null | Array<App.Car.MotorType>;
}

class CarReducer {
  private service: CarService;
  constructor(service: CarService) {
    this.service = service;
  }
  public get defaultStore(): CarStore {
    return {
      brands: null,
      models: [],
      transmissions: null,
      carcasses: null,
      motorTypes: null,
    };
  }

  public store(store: CarStore, action: CarActions) {
    switch (action.type) {
      case loadBrandsType:
        this.loadBrands();
        store.brands = [];
        break;

      case saveBrandsType:
        store.brands = action.brands;
        break;

      case loadModelsType:
        if (!store.models[action.brand]) {
          store.models[action.brand] = [];
          this.loadModels(action.brand);
        }
        break;
      case saveModelsType:
        store.models[action.brand] = action.models;
        break;
      default:
        break;
    }
    return store;
  }

  private async loadBrands() {
    const { data, status } = await this.service.getBrands();
    if (status === OK) {
      store.dispatch(saveBrands(data));
    }
  }
  private async loadModels(brand: number) {
    const { data, status } = await this.service.getModels(brand);
    if (status === OK) {
      store.dispatch(saveModels(data, brand));
    }
  }
}

export default new CarReducer(new CarServiceApi());
