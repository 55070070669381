import AnnouncementList from "../../../../components/announcement/list/AnnouncementList";
import AnnouncementService from "../../../../services/abstract/AnnouncementService";
import AnnouncementServiceApi from "../../../../services/api/AnnouncementServiceApi";
import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../../struct/message/SMessage";
import SearchLabel from "../../../../components/administrateAnnouncements/searchLabel/SearchLabel";
import Time from "../../../../utils/time/Time";
import { addMessage } from "../../../../store/actions/messages";
import store from "../../../../store/store";

interface Props {}
interface State {
  page: number;
  totalPage: number;
  total: number;
  sort: number;
  keyword: string;
  isEnd: boolean;
  isShowHidden: boolean;
  loading: boolean;
  announcements: Array<App.Announcement.Announcement>;
}

class SpecAnnouncementsList extends React.Component<Props, State> {
  private announcementService: AnnouncementService;
  private timeout: number;
  constructor(props: Props) {
    super(props);
    this.announcementService = new AnnouncementServiceApi();
    this.timeout = 0;
    this.state = {
      page: 1,
      totalPage: 0,
      total: 0,
      sort: 2,
      keyword: "",
      isEnd: false,
      isShowHidden: false,
      loading: false,
      announcements: [],
    };
  }
  private static announcementPerPage = 24;

  public componentDidMount() {
    this.getAnnouncements(this.state.page, this.state.sort, this.state.keyword);
  }

  private handleChangePage(page: number) {
    this.setState({ page });
  }
  private handleChangeSort(sort: App.ID) {
    this.setState({ sort: +sort });
    this.getAnnouncements(1, +sort, this.state.keyword);
  }
  private handleChangeKeyword(keyword: string) {
    this.setState({ keyword });
    clearInterval(this.timeout);
    this.timeout = window.setTimeout(() => {
      this.getAnnouncements(1, this.state.sort, this.state.keyword);
    }, Time.second);
  }
  private handleSearch() {
    this.getAnnouncements(1, this.state.sort, this.state.keyword);
  }

  private loadMore() {
    this.getAnnouncements(
      this.state.page + 1,
      this.state.sort,
      this.state.keyword
    );
  }

  private async getAnnouncements(page: number, sort: number, keyword: string) {
    this.setState({ loading: true });
    const { data, status, errors } = await this.announcementService.search({
      page,
      keyword,
      sort,
      special: true,
      amount: SpecAnnouncementsList.announcementPerPage,
    });
    if (status === OK) {
      this.setState({
        announcements: data.items,
        total: data.total,
        page: data.page,
        totalPage: data.totalPage,
        isEnd: data.page >= data.totalPage,
      });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
    this.setState({ loading: false });
  }

  private handleToggleShowHidden() {
    this.setState({ isShowHidden: !this.state.isShowHidden });
  }
  private updateAnnouncement(announcement: App.Announcement.Announcement) {
    this.setState({
      announcements: this.state.announcements.map((a) =>
        a.id === announcement.id ? announcement : a
      ),
    });
  }

  public render() {
    return (
      <>
        <SearchLabel
          keyword={this.state.keyword}
          onChangeKeyword={this.handleChangeKeyword.bind(this)}
          onSearch={this.handleSearch.bind(this)}
        />
        <AnnouncementList
          className="special-announcement-list"
          page={this.state.page}
          totalPage={this.state.totalPage}
          sort={this.state.sort}
          title={
            <div>
              Спецпредложения <span>({this.state.total})</span>
            </div>
          }
          announcements={this.state.announcements}
          loading={this.state.loading}
          isShowHidden={this.state.isShowHidden}
          updateAnnouncement={this.updateAnnouncement.bind(this)}
          onToggleShowHidden={this.handleToggleShowHidden.bind(this)}
          onShowMore={this.loadMore.bind(this)}
          onChangePage={this.handleChangePage.bind(this)}
          onChangeSort={this.handleChangeSort.bind(this)}
        />
        {/* <AnnouncementListContainer
          page={this.state.page}
          sort={this.state.sort}
          title="Спецпредложения"
          total
          special={true}
          filters={{ other: [], car: [] }}
          onChangePage={this.handleChangePage.bind(this)}
          onChangeSort={this.handleChangeSort.bind(this)}
          errorMessage={""}
        /> */}
      </>
    );
  }
}

export default SpecAnnouncementsList;
