import "./CurrencyPicker.scss";

import React from "react";
import Select from "../../custom/select/Select";

interface Props {
  selected: App.ID;
  onChange: (id: App.ID | null) => void;
  currencyList: Array<App.Currency.Currency>;
}
interface State {}

class CurrencyPicker extends React.Component<Props, State> {
  public render() {
    return (
      <Select
        multiselect={false}
        className="currency-picker"
        placeholder="***"
        options={this.props.currencyList}
        selected={this.props.selected}
        onChange={this.props.onChange}
      />
    );
  }
}

export default CurrencyPicker;
