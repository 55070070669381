import "./CheckedAuto.scss";

import CheckmarkIcon from "../../../icons/CheckmarkIcon";
import React from "react";

interface Props {}
interface State {}

class CheckedAuto extends React.Component<Props, State> {
  public render() {
    return (
      <div className="checked-auto">
        <CheckmarkIcon />
      </div>
    );
  }
}

export default CheckedAuto;
