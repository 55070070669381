import React from "react";
import RedactBrandContainer from "../../containers/adminPanel/redactBrandContainer/RedactBrandContainer";
import RedactGenerationContainer from "../../containers/adminPanel/redactGenerationContainer/RedactGenerationContainer";
import RedactModelContainer from "../../containers/adminPanel/redactModelContainer/RedactModelContainer";
import RedactVersionContainer from "../../containers/adminPanel/redactVersionContainer/RedactVersionContainer";

interface Props {}
interface State {
  brand?: App.Car.Brand;
  model?: App.Car.Model;
  generation?: App.Car.Generation;
}

class AdminPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  private handleChangeActiveBrand(brand?: App.Car.Brand) {
    this.setState({ brand, model: undefined, generation: undefined });
  }
  private handleChangeActiveModel(model?: App.Car.Model) {
    this.setState({ model, generation: undefined });
  }
  private handleChangeActiveGeneration(generation?: App.Car.Generation) {
    this.setState({ generation });
  }
  public render() {
    return (
      <div className="admin-panel">
        <RedactBrandContainer
          onChangeActiveBrand={this.handleChangeActiveBrand.bind(this)}
        />
        {!!this.state.brand && (
          <RedactModelContainer
            brand={this.state.brand}
            onChangeActiveModel={this.handleChangeActiveModel.bind(this)}
          />
        )}
        {!!this.state.model && (
          <RedactGenerationContainer
            model={this.state.model}
            onChangeActiveGeneration={this.handleChangeActiveGeneration.bind(
              this
            )}
          />
        )}
        {!!this.state.generation && (
          <RedactVersionContainer generation={this.state.generation} />
        )}
      </div>
    );
  }
}

export default AdminPanel;
