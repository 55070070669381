class SCarcass implements App.Car.Carcass {
  public id: number;
  public name: string;
  public doors_number: number;
  public img: string;

  constructor(data?: any) {
    this.id = 0;
    this.name = "";
    this.img = "";
    this.doors_number = 0;
    data && this.init(data);
  }

  private init(data: any) {
    try {
      this.id = data.id || 0;
      this.name = data.name || "";
      this.img = data.img || "";
      this.doors_number = data.doors_number || 0;
    } catch {}
  }
}

export default SCarcass;
