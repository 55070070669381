import "./Title.scss";

import React from "react";

interface Props {
  className: string;
}
class Title extends React.Component<Props> {
  static defaultProps = {
    className: "",
  };
  public render() {
    return (
      <h1 className={"title " + this.props.className}>{this.props.children}</h1>
    );
  }
}

export default Title;
