import "./PriceDisplay.scss";

import { BYN_ID, USD_ID } from "../../../Config";

import React from "react";
import StringMethods from "../../../utils/string/StringMethods";

interface Props {
  price: number;
  currency: App.ID;
  className: string;
}
interface State {}

class PriceDisplay extends React.Component<Props, State> {
  static defaultProps = {
    currency: BYN_ID,
    className: "",
  };

  public get currency() {
    if (this.props.currency === BYN_ID) {
      return "BYN";
    } else if (this.props.currency === USD_ID) {
      return "$";
    } else {
      return "";
    }
  }

  public render() {
    return (
      <div className={"price " + this.props.className}>
        <span>{StringMethods.beautifyNumber(this.props.price)}</span>
        <span className="currency">{this.currency}</span>
      </div>
    );
  }
}

export default PriceDisplay;
