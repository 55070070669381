import "./CompareCar.scss";

import CompareCarCarcassRow from "./rows/compareCarCarcassRow/CompareCarCarcassRow";
import CompareCarCategoryOfOptions from "./compareCarCategoryOfOptions/CompareCarCategoryOfOptions";
import CompareCarEmpty from "./compareCarEmpty/CompareCarEmpty";
import CompareCarImageRow from "./rows/compareCarImageRow/CompareCarImageRow";
import CompareCarLoading from "./compareCarLoading/CompareCarLoading";
import CompareCarNameRow from "./rows/compareCarNameRow/CompareCarNameRow";
import CompareCarPhoneRow from "./rows/compareCarPhoneRow/CompareCarPhoneRow";
import CompareCarPriceRow from "./rows/compareCarPriceRow/CompareCarPriceRow";
import CompareCarTableHead from "./compareCarTableHead/CompareCarTableHead";
import { FiltersStore } from "../../store/reducers/FiltersReducer";
import React from "react";
import StringMethods from "../../utils/string/StringMethods";

export interface CompareCarOptions {
  name: string;
  options: Array<{ name: string; values: Array<string> }>;
}

interface Props {
  carsNumber: number;
  announcements: Array<App.Announcement.Announcement>;
  empty: boolean;
  remove: (id: App.ID) => void;
  getPhoneNumber: (id: App.ID) => void;
  filters: FiltersStore;
  goBack: () => void;
}
interface State {
  showDifferent: boolean;
}

class CompareCar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { showDifferent: false };
  }

  private getCategories(): Array<CompareCarOptions> {
    const options: Set<number> = new Set();
    this.props.announcements.forEach((announcement) => {
      announcement.carOptionsData.forEach((option) =>
        options.add(option.car_filter_id)
      );
    });

    const arrayOptions = Array.from(options);

    return [this.getMainCategory()].concat(
      this.getOtherCategory(
        "Обзор",
        arrayOptions,
        this.props.filters.visibility
      ),
      this.getOtherCategory("Салон", arrayOptions, this.props.filters.salon),
      this.getOtherCategory(
        "Комфорт",
        arrayOptions,
        this.props.filters.comfort
      ),
      this.getOtherCategory(
        "Безопасность",
        arrayOptions,
        this.props.filters.safety
      ),
      this.getOtherCategory(
        "Мультимедиа",
        arrayOptions,
        this.props.filters.multimedia
      ),
      this.getOtherCategory("Прочее", arrayOptions, this.props.filters.other)
    );
  }
  private getMainCategory(): CompareCarOptions {
    return {
      name: "Основные",
      options: [
        {
          name: "Год выпуска",
          values: this.props.announcements.map((e) => e.year.toString()),
        },
        {
          name: "Коробка",
          values: this.props.announcements.map((e) => e.car.transmission.name),
        },
        {
          name: "Тип двигателя",
          values: this.props.announcements.map((e) => e.car.motor.type),
        },
        {
          name: "Объем двигателя",
          values: this.props.announcements.map(
            (e) => `${e.car.motor.volume} л.`
          ),
        },
        {
          name: "Привод",
          values: this.props.announcements.map((e) => e.car.drive.name),
        },
        {
          name: "Пробег",
          values: this.props.announcements.map((e) =>
            e.distance
              ? `${StringMethods.beautifyNumber(e.distance)} км.`
              : "Новая"
          ),
        },
      ],
    };
  }
  private getOtherCategory(
    name: string,
    options: Array<number>,
    filters: Array<App.Announcement.FilterApi>
  ): CompareCarOptions {
    return {
      name,
      options: this.getOptionsForCategory(options, filters),
    };
  }
  private getOptionsForCategory(
    options: Array<number>,
    filters: Array<App.Announcement.FilterApi>
  ): Array<{ name: string; values: Array<string> }> {
    // @ts-ignore
    return filters
      .map((filter) => {
        if (options.includes(+filter.id)) {
          return {
            name: filter.name,
            values: this.props.announcements.map((announcement) => {
              const selectOption = announcement.carOptionsData.find(
                (option) => {
                  return option.car_filter_id === filter.id;
                }
              );
              if (selectOption) {
                if (filter.type === "multiselect" && selectOption.option_id) {
                  return (
                    filter.options.find(
                      (option) => option.id === selectOption.option_id
                    )?.name || "Нет"
                  );
                } else if (selectOption.value) {
                  return selectOption.value ? "Есть" : "Нет";
                }
              }
              return "Нет";
            }),
          };
        }
        return null;
      })
      .filter((option) => !!option);
  }

  private handleChangeIsShowDifferent(value: boolean) {
    this.setState({ showDifferent: value });
  }
  public render() {
    return (
      <div
        className="compare-car"
        data-empty={this.props.announcements.length === 0}
      >
        <CompareCarTableHead
          carsNumber={this.props.carsNumber}
          isShowDifferent={this.state.showDifferent}
          onChangeIsShowDifferent={this.handleChangeIsShowDifferent.bind(this)}
          goBack={this.props.goBack}
        />
        {this.props.empty && <CompareCarEmpty />}
        {!this.props.empty && this.props.announcements.length === 0 && (
          <CompareCarLoading />
        )}
        {!this.props.empty && this.props.announcements.length !== 0 && (
          <div className="compare-car-table">
            <div className="compare-car-table-body">
              <CompareCarImageRow
                announcements={this.props.announcements}
                isShowDifferent={this.state.showDifferent}
                onChangeIsShowDifferent={this.handleChangeIsShowDifferent.bind(
                  this
                )}
                onClickOnCross={this.props.remove}
              />
              <CompareCarNameRow announcements={this.props.announcements} />
              <CompareCarCarcassRow announcements={this.props.announcements} />
              <CompareCarPriceRow announcements={this.props.announcements} />
              <CompareCarPhoneRow
                announcements={this.props.announcements}
                onGetPhoneNumber={this.props.getPhoneNumber}
              />
              {this.getCategories().map((category, index) => (
                <CompareCarCategoryOfOptions
                  key={index}
                  category={category}
                  isShowDifferent={this.state.showDifferent}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CompareCar;
