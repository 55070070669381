import "./UserSettings.scss";

import ChangePasswordContainer from "../../../containers/user/changePassword/ChangePasswordContainer";
import React from "react";
import UserContactsListContainer from "../../../containers/user/contactsList/UserContactsListContainer";

interface Props {}
interface State {}

class UserSettings extends React.Component<Props, State> {
  public componentDidMount() {
    document.title = "Настройки";
    window.scrollTo({ top: 0 });
  }

  public render() {
    return (
      <div className="user-settings">
        <UserContactsListContainer />
        <ChangePasswordContainer />
      </div>
    );
  }
}

export default UserSettings;
