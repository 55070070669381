import "./CompareCarPhoneRow.scss";

import CompareCarRow from "../../compareCarRow/CompareCarRow";
import React from "react";
import StringMethods from "../../../../utils/string/StringMethods";

interface Props {
  announcements: Array<App.Announcement.Announcement>;
  onGetPhoneNumber: (id: App.ID) => void;
}
interface State {}

class CompareCarPhoneRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <CompareCarRow
        key="-5"
        className="compare-car-phone-row"
        head={<b></b>}
        values={this.props.announcements.map((announcement) => {
          return announcement.contact.phone ? (
            <a
              href={`tel:375${announcement.contact.phone}`}
              className="phone-number"
            >
              {StringMethods.beautifyPhoneNumber(announcement.contact.phone)}
            </a>
          ) : (
            <button
              className="show-number"
              onClick={() => this.props.onGetPhoneNumber(announcement.id)}
            >
              <span>Показать телефон</span> <span>Позвонить</span>
            </button>
          );
        })}
      />
    );
  }
}
export default CompareCarPhoneRow;
