import "./AnnouncementList.scss";

import React, { RefObject } from "react";

import AnnouncementListBody from "./body/AnnouncementListBody";
import AnnouncementListFooter from "./footer/AnnouncementListFooter";
import AnnouncementListHeader from "./header/AnnouncementListHeader";
import AnnouncementListTags from "./announcementListTags/AnnouncementListTags";
import { BYN_ID } from "../../../Config";

interface Props {
  page: number;
  totalPage: number;
  announcements?: Array<App.Announcement.Announcement>;
  className: string;
  sort: number;
  loading: boolean;

  displayType: boolean;
  displayFooter: boolean;
  displayHeader: boolean;
  isShowHidden: boolean;

  onChangeSort: (sort: App.ID) => void;
  onChangePage: (page: number) => void;
  onShowMore: () => void;
  onToggleShowHidden: () => void;
  updateAnnouncement: (announcement: App.Announcement.Announcement) => void;
  dropFilters?: () => void;

  id?: string;
  refLink?: RefObject<HTMLDivElement>;
  title?: string | JSX.Element;
  notFound?: boolean;
  tags?: Array<App.Tag>;
  errorMessage?: string;
}
interface State {
  displayType: App.DisplayType;
  currency: App.ID;
}

class AnnouncementList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currency: BYN_ID,
      displayType: "list",
    };
  }

  static defaultProps = {
    className: "",
    displayType: false,
    displayFooter: true,
    displayHeader: true,
    isShowHidden: false,
    loading: false,
    onChangePage() {},
    onChangeSort() {},
    onShowMore() {},
    onToggleShowHidden() {},
    updateAnnouncement() {},
  };

  private handleChangeDisplayType(displayType: App.DisplayType) {
    this.setState({ displayType });
  }
  private handleChangeCurrency(currency: App.ID) {
    this.setState({ currency });
  }

  public render() {
    return (
      <div
        id={this.props.id}
        className={"announcement-list " + this.props.className}
        ref={this.props.refLink}
      >
        {!!this.props.title && (
          <div className="announcement-list__title">{this.props.title}</div>
        )}

        <AnnouncementListTags
          tags={this.props.tags}
          dropFilters={this.props.dropFilters}
        />

        {this.props.displayHeader && (
          <AnnouncementListHeader
            displayType={this.state.displayType}
            currency={this.state.currency}
            sort={this.props.sort}
            onChangeDisplayType={this.handleChangeDisplayType.bind(this)}
            onChangeCurrency={this.handleChangeCurrency.bind(this)}
            onChangeSort={this.props.onChangeSort}
          />
        )}

        {this.props.notFound && (
          <div className="not-found-announcement">
            <p>По запросу ничего не найдено.</p>
            {this.props.errorMessage && <p>{this.props.errorMessage}</p>}
          </div>
        )}

        {!this.props.notFound && (
          <AnnouncementListBody
            displayType={this.state.displayType}
            currency={this.state.currency}
            updateAnnouncement={this.props.updateAnnouncement}
            announcements={this.props.announcements || []}
            loading={this.props.loading}
          >
            {this.props.children}
          </AnnouncementListBody>
        )}

        {this.props.displayFooter && (
          <AnnouncementListFooter
            loading={this.props.loading}
            activePage={this.props.page}
            isShowHidden={this.props.isShowHidden}
            pageNumber={Math.ceil(this.props.totalPage)}
            onChangePage={this.props.onChangePage}
            onShowMore={this.props.onShowMore}
            onToggleShowHidden={this.props.onToggleShowHidden}
          />
        )}
      </div>
    );
  }
}

export default AnnouncementList;
