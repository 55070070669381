import { AnnouncementTypeFullOptions } from "../../../../../../Config";
import CreateAnnouncementType from "../CreateAnnouncementType";
import FullTypeImage from "./FullTypeImage";
import React from "react";

interface Props {
  selectType: (type: App.CreateAnnouncement.Type) => void;
}
interface State {}

class FullType extends React.Component<Props, State> {
  public render() {
    return (
      <CreateAnnouncementType
        type="full"
        price={0}
        name={"Расширенное объявление"}
        options={AnnouncementTypeFullOptions}
        className="recomended"
        onClick={this.props.selectType}
      >
        <FullTypeImage />
      </CreateAnnouncementType>
    );
  }
}

export default FullType;
