import {
  HOME_ANNOUNCEMENT_LIST_CLASS_NAME,
  HOME_ANNOUNCEMENT_LIST_ID,
} from "../../../../Config";

import AnnouncementListContainer from "../../list/AnnouncementListContainer";
import AnnouncementListTagsController from "../../../../utils/tags/AnnouncementListTagsController";
import React from "react";
import { Unsubscribe } from "redux";
import { changeFilterAnnouncementsHome } from "../../../../store/actions/announcements/home";
import store from "../../../../store/store";

interface Props {}
interface State {
  filters: App.Announcement.FiltersForSearch;
  page: number;
  sort: number;
  tags: Array<App.Tag>;
}

class HomeAnnouncementsList extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  private announcementListTagsController: AnnouncementListTagsController;

  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.announcementListTagsController = new AnnouncementListTagsController();
    this.state = {
      filters: store.getState().home.filters,
      page: 1,
      sort: 2,
      tags: [],
    };
  }

  public componentDidMount() {
    this.setState({ tags: this.getTags() });
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  private handleUpdateStore() {
    this.setState((state) => {
      const filters = store.getState().home.filters;
      return {
        page: state.filters === filters ? state.page : 1,
        filters,
        tags: this.getTags(),
      };
    });
  }

  public handleChangePage(page: number) {
    this.setState({ page });
  }
  public handleChangeSort(sort: number) {
    this.setState({ page: 1, sort: sort });
  }

  public getTags(): Array<App.Tag> {
    const filters: Array<App.Announcement.FilterApi> = store.getState().filters
      .main;
    return this.announcementListTagsController.getTags(
      this.state.filters.other,
      filters,
      (other) => {
        store.dispatch(
          changeFilterAnnouncementsHome({
            car: this.state.filters.car,
            other,
          })
        );
      }
    );
  }

  private dropFilters() {
    store.dispatch(
      changeFilterAnnouncementsHome({
        car: [],
        other: [],
      })
    );
  }

  public render() {
    return (
      <AnnouncementListContainer
        id={HOME_ANNOUNCEMENT_LIST_ID}
        className={HOME_ANNOUNCEMENT_LIST_CLASS_NAME}
        page={this.state.page}
        sort={this.state.sort}
        filters={this.state.filters}
        onChangePage={this.handleChangePage.bind(this)}
        onChangeSort={this.handleChangeSort.bind(this)}
        tags={this.state.tags}
        dropFilters={this.dropFilters.bind(this)}
        errorMessage={
          this.state.filters.car.some((e) => e.brand) ||
          this.state.filters.other.length > 0
            ? "Попробуйте уменьшить колличество фильтров"
            : ""
        }
      />
    );
  }
}

export default HomeAnnouncementsList;
