import "./AdministrateAnnouncementsMenuOption.scss";

import React from "react";

interface Props {
  active: boolean;
  name: string;
  onClick: () => void;
  after?: string;
}
interface State {}

class AdministrateAnnouncementsMenuOption extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div
        className="administrate-announcements-menu-option"
        onClick={this.props.onClick}
      >
        <div className="mark" data-active={this.props.active}></div>
        <div className="name">{this.props.name}</div>
        {this.props.after && <div className="after">{this.props.after}</div>}
      </div>
    );
  }
}
export default AdministrateAnnouncementsMenuOption;
