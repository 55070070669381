import "./Contact.scss";

import React from "react";
import SubTitle from "../../../../../custom/subtitle/SubTitle";
import Title from "../../../../../custom/title/Title";

interface Props {
  name: string;
  city: string;
  phone: string;
  email: string;
}
interface State {}

class CreateAnnouncementMiddleInfoContact extends React.Component<
  Props,
  State
> {
  public render() {
    return (
      <div className="middle-info__contact">
        <div className="i">
          <Title>{this.props.name || "Имя"}</Title>
          <SubTitle>{this.props.city || "Город"}</SubTitle>
        </div>
        <div className="a">
          <Title>{this.props.phone || "Телефон"}</Title>
          <SubTitle>{this.props.email || "e-mail"}</SubTitle>
        </div>
      </div>
    );
  }
}

export default CreateAnnouncementMiddleInfoContact;
