import React from "react";

interface Props {
  onClick?: () => void;
}
interface State {}

class CrossIcon extends React.Component<Props, State> {
  public render() {
    return (
      <svg
        className="cross-icon"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
        onClick={this.props.onClick}
        fill="none"
      >
        <rect x1="0" y1="0" width="14" height="14" />
        <path d="M13.9844 1.42188L8.40625 7L13.9844 12.5781L12.5781 13.9844L7 8.40625L1.42188 13.9844L0.015625 12.5781L5.59375 7L0.015625 1.42188L1.42188 0.015625L7 5.59375L12.5781 0.015625L13.9844 1.42188Z" />
      </svg>
    );
  }
}

export default CrossIcon;
