import AnnouncementStat from "../stat/AnnouncementStat";
import React from "react";

interface Props {
  motor?: App.Car.Motor;
}
interface State {}

class AnnouncementStatMotor extends React.Component<Props, State> {
  public get value() {
    const stats: Array<string | null> = [];
    if (this.props.motor) {
      stats[0] = this.props.motor.type ? `${this.props.motor.type}` : null;
      stats[1] = this.props.motor.volume
        ? `${this.props.motor.volume} л.`
        : null;
      stats[2] = this.props.motor.power
        ? `${this.props.motor.power} л.с.`
        : null;
    }
    return stats.filter((e) => !!e).join(" / ");
  }
  public render() {
    return <AnnouncementStat name={"Двигатель"} value={this.value} />;
  }
}

export default AnnouncementStatMotor;
