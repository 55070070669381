import CreateAnnouncementComplite from "../../components/announcement/create/popups/complite/Complite";
import CreateAnnouncementProgress from "../../components/announcement/create/progress/Progress";
import CreateAnnouncementSelectType from "../../components/announcement/create/selectType/CreateAnnouncementSelectType";
import React from "react";
import { RouteComponentProps } from "react-router";
import { signin } from "../../routes";

interface Props extends RouteComponentProps {}
interface State {
  type: App.CreateAnnouncement.Type | null;
  id: App.ID;
  complited: boolean;
}

class CreateAnnouncement extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      type: null,
      id: 0,
      complited: false,
    };
  }
  public componentDidMount() {
    document.title = "Создание объявления";
    window.scrollTo({ top: 0 });
  }

  private toSelectType() {
    this.setState({ type: null });
  }
  private toSignin() {
    this.props.history.push(signin);
  }
  private selectType(type: App.CreateAnnouncement.Type) {
    this.setState({ type });
  }
  private complite(id: App.ID) {
    this.setState({ complited: true, id });
  }
  private restart() {
    this.setState({ type: null, complited: false });
  }
  public render() {
    return (
      <div className="create-announcement-view">
        {!this.state.type && (
          <CreateAnnouncementSelectType
            selectType={this.selectType.bind(this)}
          />
        )}
        {!!this.state.type && (
          <CreateAnnouncementProgress
            type={this.state.type}
            toSignin={this.toSignin.bind(this)}
            toSelectType={this.toSelectType.bind(this)}
            complite={this.complite.bind(this)}
          />
        )}
        {this.state.complited && (
          <CreateAnnouncementComplite
            announcementId={this.state.id}
            onClose={this.restart.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default CreateAnnouncement;
