import React from "react";

interface Props {}
interface State {}

class PlayArrow extends React.Component<Props, State> {
  public render() {
    return (
      <svg
        width="11"
        height="14"
        viewBox="0 0 11 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.015625 0.015625L10.9844 7L0.015625 13.9844V0.015625Z" />
      </svg>
    );
  }
}

export default PlayArrow;
