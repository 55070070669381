import AnnouncementService from "../../services/abstract/AnnouncementService";
import AnnouncementServiceApi from "../../services/api/AnnouncementServiceApi";
import CompareCar from "../../components/compareCar/CompareCar";
import { FiltersStore } from "../../store/reducers/FiltersReducer";
import { OK } from "http-status-codes";
import React from "react";
import SAnnouncement from "../../struct/announcement/SAnnouncement";
import SMessage from "../../struct/message/SMessage";
import { Unsubscribe } from "redux";
import UserService from "../../services/abstract/UserService";
import UserServiceApi from "../../services/api/UserServiceApi";
import { addMessage } from "../../store/actions/messages";
import { removeFromCompare } from "../../store/actions/compareCars";
import store from "../../store/store";

interface Props {
  goBack: () => void;
}
interface State {
  cars: Array<App.ID>;
  loading: boolean;
  announcements: Array<App.Announcement.Announcement>;
  filters: FiltersStore;
}

class CompareCarContainer extends React.Component<Props, State> {
  private service: AnnouncementService;
  private userService: UserService;
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.service = new AnnouncementServiceApi();
    this.userService = new UserServiceApi();
    this.unsubscribe = () => {};
    this.state = {
      cars: this.getCars(),
      loading: false,
      announcements: [],
      filters: this.getFilters(),
    };
  }

  public componentDidMount() {
    this.getAnnouncments();
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }

  private handleUpdateStore() {
    this.setState({
      cars: this.getCars(),
      filters: this.getFilters(),
    });
  }

  private getCars() {
    return Array.from(store.getState().compareCar.cars);
  }
  private getFilters() {
    return store.getState().filters;
  }

  private async getAnnouncments() {
    const announcements: Array<Promise<App.Announcement.Announcement>> = [];
    this.state.cars.forEach((announcement_id) => {
      announcements.push(this.getAnnouncment(announcement_id));
    });
    await Promise.all(announcements).then((list) => {
      this.setState({
        loading: false,
        announcements: list.filter((e) => e.id !== 0),
      });
    });
  }
  private async getAnnouncment(
    id: App.ID
  ): Promise<App.Announcement.Announcement> {
    const { data, status, errors } = await this.service.getById(id);
    if (status === OK) {
      return data;
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
      store.dispatch(removeFromCompare(id));
      // @ts-ignore
      return new SAnnouncement();
    }
  }
  private remove(id: App.ID) {
    store.dispatch(removeFromCompare(id));
    this.setState({
      announcements: this.state.announcements.filter((e) => e.id !== id),
    });
  }
  private async getPhoneNumber(id: App.ID) {
    const announcement = this.state.announcements.find((a) => a.id === id);
    if (!announcement || !announcement.contact.id) {
      store.dispatch(
        addMessage(
          new SMessage("Контакт или объявление не существует", "warning")
        )
      );
      return;
    }
    const { data, status } = await this.userService.getPhoneNumberForContact(
      announcement.contact.id
    );
    if (status === OK) {
      this.setState({
        announcements: this.state.announcements.map((a) => {
          if (a.id === id) {
            a.contact.phone = data;
          }
          return a;
        }),
      });
    } else {
      store.dispatch(
        addMessage(new SMessage("Не удалось загрузить номер телефона"))
      );
    }
  }

  public render() {
    return (
      <CompareCar
        empty={this.state.cars.length === 0}
        announcements={this.state.announcements}
        carsNumber={this.state.cars.length}
        filters={this.state.filters}
        remove={this.remove.bind(this)}
        goBack={this.props.goBack}
        getPhoneNumber={this.getPhoneNumber.bind(this)}
      />
    );
  }
}

export default CompareCarContainer;
