import LabelSearch from "../../../components/custom/labels/labelSearch/LabelSearch";
import React from "react";
import { Unsubscribe } from "redux";
import { loadBrands } from "../../../store/actions/car";
import store from "../../../store/store";

interface Props {
  selected: number | null;
  onChange: (selected: number | null, brand?: App.Info) => void;
  placeholder?: boolean;
}
interface State {
  selected: App.Info | null;
  brands: Array<App.Car.Brand>;
}

class LabelSearchBrand extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;

  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      selected: null,
      brands: [],
    };
  }

  static defaultProps = {
    selected: null,
    onChange() {},
  };

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
    this.handleUpdateStore();
  }
  public componentDidUpdate(props: Props) {
    if (this.props.selected === props.selected) return;
    if (this.props.selected) {
      this.setState({
        selected:
          this.state.brands.find((brand) => brand.id === this.props.selected) ||
          null,
      });
    } else {
      this.setState({
        selected: null,
      });
    }
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }
  public handleChange(brand: App.Info | null) {
    if (brand) {
      this.props.onChange(brand.id, brand);
    } else {
      this.props.onChange(null);
    }
  }

  private handleUpdateStore() {
    const { brands } = store.getState().car;
    if (brands) {
      this.setState({ brands });
    } else {
      store.dispatch(loadBrands());
    }
  }

  public render() {
    return (
      <LabelSearch
        placeholder={this.props.placeholder}
        name={"Марка"}
        options={this.state.brands}
        selected={this.state.selected}
        onChange={this.handleChange.bind(this)}
      />
    );
  }
}

export default LabelSearchBrand;
