export interface BrandSearchOptions {
  keyword?: string;
}
export interface ModelSearchOptions {
  keyword?: string;
  brand_id?: number;
}
export interface BrandData {
  logo?: File;
  delete_logo?: boolean;
  name: string;
  country: string;
  city: string;
  main: boolean;
}
export interface ModelData {
  name: string;
  brandId: number;
  country: string;
}
export interface GenerationData {
  name: string;
  modelId: number;
  start: number;
  end: number | null;
}
export interface VersionData {
  generationId: number;
  name: string;
  motor: number;
  carcass: number;
  transmission: number;
  drive: number;
}
export interface MotorData {
  type: number;
  power: number;
  volume: number;
}
abstract class CarService {
  abstract getBrands(): Promise<App.Response<Array<App.Car.Brand>>>;
  abstract getBrand(id: App.ID): Promise<App.Response<App.Car.Brand>>;
  abstract addBrand(data: BrandData): Promise<App.Response<App.Car.Brand>>;
  abstract updateBrand(
    id: App.ID,
    data: BrandData
  ): Promise<App.Response<App.Car.Brand>>;
  abstract deleteBrand(id: App.ID): Promise<App.Response<void>>;

  abstract getModels(
    brandId: App.ID
  ): Promise<App.Response<Array<App.Car.Model>>>;
  abstract getModel(id: App.ID): Promise<App.Response<App.Car.Model>>;
  abstract addModel(data: ModelData): Promise<App.Response<App.Car.Model>>;
  abstract updateModel(
    id: App.ID,
    data: ModelData
  ): Promise<App.Response<App.Car.Model>>;
  abstract deleteModel(id: App.ID): Promise<App.Response<void>>;

  abstract getGenerations(
    modelId: number
  ): Promise<App.Response<Array<App.Car.Generation>>>;
  abstract getGeneration(id: App.ID): Promise<App.Response<App.Car.Generation>>;
  abstract addGeneration(
    data: GenerationData
  ): Promise<App.Response<App.Car.Generation>>;
  abstract updateGeneration(
    id: App.ID,
    data: GenerationData
  ): Promise<App.Response<App.Car.Generation>>;
  abstract deleteGeneration(id: App.ID): Promise<App.Response<void>>;

  abstract getVersions(
    generationId: number
  ): Promise<App.Response<Array<App.Car.Version>>>;
  abstract getVersion(id: App.ID): Promise<App.Response<App.Car.Version>>;
  abstract addVersion(
    data: VersionData
  ): Promise<App.Response<App.Car.Version>>;
  abstract updateVersion(
    id: App.ID,
    data: VersionData
  ): Promise<App.Response<App.Car.Version>>;
  abstract deleteVersion(id: App.ID): Promise<App.Response<void>>;

  abstract getMotors(): Promise<App.Response<Array<App.Car.Motor>>>;
  abstract getMotor(id: App.ID): Promise<App.Response<App.Car.Motor>>;
  abstract getMotorOptions(): Promise<
    App.Response<{ car_motor_type_id: Array<App.Info> }>
  >;
  abstract searchMotor(
    options: MotorData
  ): Promise<App.Response<Array<App.Car.Motor>>>;
  abstract addMotor(data: MotorData): Promise<App.Response<App.Car.Motor>>;
  abstract getCarcass(id: App.ID): Promise<App.Response<App.Car.Carcass>>;
  abstract getCarcasses(): Promise<App.Response<Array<App.Car.Carcass>>>;
  abstract getTransmissions(): Promise<
    App.Response<Array<App.Car.Transmission>>
  >;
  abstract getDrives(): Promise<App.Response<Array<App.Car.Drive>>>;
}

export default CarService;
