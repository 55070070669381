type LOAD_BRANDS = "LOAD_BRANDS";
type SAVE_BRANDS = "SAVE_BRANDS";
type LOAD_MODELS = "LOAD_MODELS";
type SAVE_MODELS = "SAVE_MODELS";

interface LoadBrands {
  type: LOAD_BRANDS;
}
interface SaveBrands {
  type: SAVE_BRANDS;
  brands: Array<App.Car.Brand>;
}
interface LoadModels {
  type: LOAD_MODELS;
  brand: number;
}
interface SaveModels {
  type: SAVE_MODELS;
  brand: number;
  models: Array<App.Car.Model>;
}

export type CarActions = LoadBrands | SaveBrands | LoadModels | SaveModels;

export const loadBrandsType: LOAD_BRANDS = "LOAD_BRANDS";
export const saveBrandsType: SAVE_BRANDS = "SAVE_BRANDS";
export const loadModelsType: LOAD_MODELS = "LOAD_MODELS";
export const saveModelsType: SAVE_MODELS = "SAVE_MODELS";

export function loadBrands(): LoadBrands {
  return {
    type: loadBrandsType,
  };
}
export function saveBrands(brands: Array<App.Car.Brand>): SaveBrands {
  return {
    type: saveBrandsType,
    brands,
  };
}

export function loadModels(brand: number): LoadModels {
  return {
    type: loadModelsType,
    brand,
  };
}
export function saveModels(
  models: Array<App.Car.Model>,
  brand: number
): SaveModels {
  return {
    type: saveModelsType,
    brand,
    models,
  };
}
