import SignupForm, {
  SignupData,
} from "../../../components/auth/signupForm/SignupForm";

import { CREATED } from "http-status-codes";
import React from "react";
import SignupMessage from "../../../components/auth/signupMessage/SignupMessage";
import UserService from "../../../services/abstract/UserService";
import UserServiceApi from "../../../services/api/UserServiceApi";

interface Props {}
interface State {
  value: SignupData;
  complited: boolean;
  errors: Array<string>;
}

class SignupContainer extends React.Component<Props, State> {
  private service: UserService;
  constructor(props: Props) {
    super(props);
    this.service = new UserServiceApi();
    this.state = {
      value: {
        email: "",
        name: "",
        password: "",
        passwordComparison: "",
      },
      complited: false,
      errors: [],
    };
  }

  private handleChangeChange(value: SignupData) {
    if (this.state.complited) return;
    this.setState({ value });
  }

  private async handleSubmit() {
    if (this.state.complited) return;
    this.setState({ errors: [] });
    const { status, errors } = await this.service.signup(this.state.value);
    if (status === CREATED) {
      this.setState({ complited: true });
    } else {
      this.setState({
        errors,
      });
    }
  }

  public render() {
    return (
      <>
        {this.state.complited ? (
          <SignupMessage />
        ) : (
          <SignupForm
            value={this.state.value}
            onChange={this.handleChangeChange.bind(this)}
            onSubmit={this.handleSubmit.bind(this)}
            errors={this.state.errors}
          />
        )}
      </>
    );
  }
}

export default SignupContainer;
