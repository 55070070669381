import "./BrandLogo.scss";

import React from "react";

interface Props {
  brand: App.Car.Brand;
  className: string;
  onClick: (id: number) => void;
}
interface State {}

class BrandLogo extends React.Component<Props, State> {
  static defaultProps = {
    className: "",
    onClick: () => {},
  };
  public render() {
    return (
      <div
        className="brand-logo"
        title={this.props.brand.name}
        onClick={() => this.props.onClick(+this.props.brand.id)}
      >
        <div
          className="image"
          style={{ backgroundImage: `url(${this.props.brand.src})` }}
        />
      </div>
    );
  }
}

export default BrandLogo;
