import { AnnouncementTypeFastOptions } from "../../../../../../Config";
import CreateAnnouncementType from "../CreateAnnouncementType";
import FastTypeImage from "./FastTypeImage";
import React from "react";

interface Props {
  selectType: (type: App.CreateAnnouncement.Type) => void;
}
interface State {}

class FastType extends React.Component<Props, State> {
  public render() {
    return (
      <CreateAnnouncementType
        type="fast"
        price={0}
        name={"Быстрое объявление"}
        options={AnnouncementTypeFastOptions}
        onClick={this.props.selectType}
      >
        <FastTypeImage />
      </CreateAnnouncementType>
    );
  }
}

export default FastType;
