type CHANGE_FILTERS_ANNOUNCEMENTS_ADVANCED = "CHANGE_FILTERS_ANNOUNCEMENTS_ADVANCED";
type RESEARCH_ANNOUNCEMENTS_ADVANCED = "RESEARCH_ANNOUNCEMENTS_ADVANCED";
type SAVE_NUMBER_OF_RESULTS_ANNOUNCEMENTS_ADVANCED = "SAVE_NUMBER_OF_RESULTS_ANNOUNCEMENTS_ADVANCED";

interface ChangeFiltersAction {
  type: CHANGE_FILTERS_ANNOUNCEMENTS_ADVANCED;
  filters: App.Announcement.FiltersForSearch;
}
interface ResearchAction {
  type: RESEARCH_ANNOUNCEMENTS_ADVANCED;
}
interface SaveNumberOfResultsAction {
  type: SAVE_NUMBER_OF_RESULTS_ANNOUNCEMENTS_ADVANCED;
  number: number;
}

export type AnnouncementsAdvancedActions =
  | ChangeFiltersAction
  | ResearchAction
  | SaveNumberOfResultsAction;

export const changeFiltersAnnouncementsAdvancedType: CHANGE_FILTERS_ANNOUNCEMENTS_ADVANCED =
  "CHANGE_FILTERS_ANNOUNCEMENTS_ADVANCED";
export const researchAnnouncementsAdvancedType: RESEARCH_ANNOUNCEMENTS_ADVANCED =
  "RESEARCH_ANNOUNCEMENTS_ADVANCED";
export const saveNumberOfResultsType: SAVE_NUMBER_OF_RESULTS_ANNOUNCEMENTS_ADVANCED =
  "SAVE_NUMBER_OF_RESULTS_ANNOUNCEMENTS_ADVANCED";

export function changeFilterAnnouncementsAdvanced(
  filters: App.Announcement.FiltersForSearch
): ChangeFiltersAction {
  return {
    type: changeFiltersAnnouncementsAdvancedType,
    filters,
  };
}
export function researchAnnouncementsAdvanced(): ResearchAction {
  return {
    type: researchAnnouncementsAdvancedType,
  };
}
export function saveNumberOfResults(number: number): SaveNumberOfResultsAction {
  return {
    type: saveNumberOfResultsType,
    number,
  };
}
