import "./Sort.scss";

import React from "react";
import Select from "../../../../custom/select/Select";

interface Props {
  sort: number;
  onChange: (sort: App.ID) => void;
}
interface State {}

class AnnouncementSort extends React.Component<Props, State> {
  static options = [
    { id: 1, name: "Сначала старые объявления" },
    { id: 2, name: "Сначала новые объявления" },
    { id: 3, name: "Сначала дешевые" },
    { id: 4, name: "Сначала дорогие" },
    { id: 5, name: "Сначала старые авто" },
    { id: 6, name: "Сначала новые авто" },
    { id: 7, name: "Сначала меньший пробег" },
    { id: 8, name: "Сначала больший пробег" },
    { id: 9, name: "По названию A-Z, А-Я" },
    { id: 10, name: "По названию Я-А, Z-A" },
  ];
  public render() {
    return (
      <div className="announcement-list__sort">
        <Select
          multiselect={false}
          options={AnnouncementSort.options}
          selected={this.props.sort}
          onChange={(sort) => {
            sort && this.props.onChange(sort);
          }}
          placeholder="Сортировка"
        />
      </div>
    );
  }
}

export default AnnouncementSort;
