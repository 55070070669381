import "./Description.scss";

import React from "react";

interface Props {
  description?: string;
}
interface State {}

class CreateAnnouncementMiddleInfoDescription extends React.Component<
  Props,
  State
> {
  public render() {
    return (
      <div className="middle-info__description">
        {this.props.description || "ОПИСАНИЕ"}
      </div>
    );
  }
}

export default CreateAnnouncementMiddleInfoDescription;
