import "./Tabs.scss";

import { NavLink } from "react-router-dom";
import React from "react";

interface Props {}
interface State {
  hash: string;
}

class AdvancedFilterHeaderTabs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hash: "",
    };
  }

  public componentDidMount() {
    this.hash = window.location.hash;
  }

  private set hash(hash: string) {
    this.setState({ hash });
    const block = document.getElementById(hash);
    if (block) {
      window.scrollTo({
        top: block.offsetTop - 80,
        behavior: "smooth",
      });
    }
  }

  private handleClick(hash: string) {
    this.hash = hash;
  }

  public render() {
    return (
      <div className="advanced-filter__tabs">
        <NavLink
          onClick={() => this.handleClick("#main")}
          to="#main"
          className="tab main"
        >
          Основное
        </NavLink>
        <NavLink
          onClick={() => this.handleClick("#visibility")}
          to="#visibility"
          className="tab"
        >
          Обзор
        </NavLink>
        <NavLink
          onClick={() => this.handleClick("#salon")}
          to="#salon"
          className="tab"
        >
          Салон
        </NavLink>
        <NavLink
          onClick={() => this.handleClick("#multimedia")}
          to="#multimedia"
          className="tab"
        >
          Мультимедия
        </NavLink>
        <NavLink
          onClick={() => this.handleClick("#safety")}
          to="#safety"
          className="tab"
        >
          Безопасность
        </NavLink>
        <NavLink
          onClick={() => this.handleClick("#comfort")}
          to="#comfort"
          className="tab"
        >
          Комфорт
        </NavLink>
        <NavLink
          onClick={() => this.handleClick("#other")}
          to="#other"
          className="tab"
        >
          Прочее
        </NavLink>
      </div>
    );
  }
}

export default AdvancedFilterHeaderTabs;
