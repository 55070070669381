import "./SelectPage.scss";

import LabelNumber from "../../custom/labels/labelNumber/LabelNumber";
import React from "react";
import { stop } from "../../../utils/html/EventMethods";

interface Props {
  pageNumber: number;
  activePage: number;
  onChange: (page: number) => void;
}
interface State {
  isOpen: boolean;
  page: number;
}

class SelectPage extends React.Component<Props, State> {
  private handleClickOnWindow: () => void;
  constructor(props: Props) {
    super(props);
    this.handleClickOnWindow = this.close.bind(this);
    this.state = {
      isOpen: false,
      page: 1,
    };
  }

  public componentDidMount() {
    window.addEventListener("click", this.handleClickOnWindow);
  }
  public componentWillUnmount() {
    window.removeEventListener("click", this.handleClickOnWindow);
  }

  public open() {
    this.setState({
      page: this.props.activePage,
      isOpen: true,
    });
  }
  public close() {
    this.setState({
      isOpen: false,
    });
  }
  private handleChange(page: number) {
    this.setState({ page });
  }
  private handleSubmit() {
    this.close();
    this.props.onChange(this.state.page);
  }

  public render() {
    return (
      <div className="pagination__page-select" onClick={stop}>
        <div className="pagination__page" onClick={this.open.bind(this)}>
          ...
        </div>
        {this.state.isOpen && (
          <div className="page-select-container">
            <div className="page-select-container__title">
              Страницы 1 — {this.props.pageNumber}
            </div>
            <LabelNumber
              value={this.state.page}
              onChange={this.handleChange.bind(this)}
              maxLength={5}
              max={this.props.pageNumber}
            />
            <div className="buttons">
              <button onClick={this.close.bind(this)} className="close">
                отмена
              </button>
              <button onClick={this.handleSubmit.bind(this)}>перейти</button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SelectPage;
