import "./AdminPanelList.scss";

import LabelText from "../../custom/labels/labelText/LabelText";
import React from "react";

interface DefaultProps<T> {
  items: Array<T>;
  onSelect: (value: T) => void;
  selected?: App.ID;
}
interface PropsBrand extends DefaultProps<App.Car.Brand> {}
interface PropsModel extends DefaultProps<App.Car.Model> {}
interface PropsGeneration extends DefaultProps<App.Car.Generation> {}
interface PropsVersion extends DefaultProps<App.Car.Version> {}
type Props = PropsBrand | PropsModel | PropsGeneration | PropsVersion;

interface State {
  isShowAll: boolean;

  keyword: string;
}

class AdminPanelList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isShowAll: false,
      keyword: "",
    };
  }

  private handleChange(keyword: string) {
    this.setState({ keyword });
  }
  private handleToggleShowAll() {
    this.setState({ isShowAll: !this.state.isShowAll });
  }
  public render() {
    // @ts-ignore
    let items = this.props.items.filter(
      (e: App.Info) =>
        e.id === this.props.selected ||
        e.name.toLowerCase().includes(this.state.keyword.toLowerCase())
    );
    if (items.length === 0) {
      items = this.props.items;
    }
    return (
      <div className="admin-panel-list">
        <LabelText
          placeholder="Поиск"
          className="admin-panel-list__search"
          value={this.state.keyword}
          onChange={this.handleChange.bind(this)}
        />
        <div className="admin-panel-list__items">
          {items
            .slice(0, this.state.isShowAll ? undefined : 10)
            .map((e: any) => (
              <div
                key={e.id}
                data-active={this.props.selected === e.id}
                className="admin-panel-list__item"
                onClick={() => this.props.onSelect(e)}
                title={e.name}
              >
                {e.name}
              </div>
            ))}
        </div>
        {items.length > 10 && (
          <span
            className="admin-panel-list__show-all"
            onClick={this.handleToggleShowAll.bind(this)}
          >
            {this.state.isShowAll ? "Скрыть лишние" : "Показать все"}
          </span>
        )}
      </div>
    );
  }
}

export default AdminPanelList;
