import AnnouncementStat from "../stat/AnnouncementStat";
import React from "react";

interface Props {
  carcass?: App.Car.Carcass | string;
}
interface State {}

class AnnouncementStatCarcass extends React.Component<Props, State> {
  public get value() {
    if (this.props.carcass) {
      if (typeof this.props.carcass === "string") {
        return this.props.carcass;
      } else {
        return `${this.props.carcass.name} ${this.props.carcass.doors_number} дв.`;
      }
    } else {
      return "";
    }
  }
  public render() {
    return <AnnouncementStat name={"Кузов"} value={this.value} />;
  }
}

export default AnnouncementStatCarcass;
