import sha256 from "sha256";

class StringMethods {
  static getPhoneNumber(phoneNumber: string): string {
    return phoneNumber
      .split("")
      .filter((symbol) => +symbol === parseInt(symbol))
      .join("")
      .slice(3);
  }

  static beautifyPhoneNumber(phone: string): string {
    phone = phone.toString().trim();
    if (phone.length !== 9) {
      return phone;
    }
    return `+375(${phone.slice(0, 2)})${phone.slice(2, 5)}-${phone.slice(
      5,
      7
    )}-${phone.slice(7, 9)}`;
  }

  static beautifyNumber(number: number | null, order: number = 0): string {
    if (number) {
      const sign = (number / Math.abs(number) || 1) > 0 ? "" : "-";
      number = Math.abs(number);
      if (order === 0) {
        number = +number.toFixed(0);
      }
      if (number % 1 !== 0) {
        const whole = number.toString().split(".")[0];
        const thousandths = (
          number.toString().split(".")[1] + new Array(order).fill("0").join("")
        ).slice(0, order);
        return `${sign}${StringMethods.beautifyNumber(
          +whole
        )}.${StringMethods.beautifyNumber(+thousandths)}`;
      }

      return (
        sign +
        number
          .toString()
          .split("")
          .reverse()
          .map((e, i) => ((i + 1) % 3 ? e : " " + e))
          .reverse()
          .join("")
          .trim()
      );
    } else {
      return "0";
    }
  }

  static sha256(str: string): string {
    return sha256(str);
  }

  static toBase64(str: string): string {
    return btoa(str);
  }
  static fromBase64(str: string): string {
    return atob(str);
  }
}

export default StringMethods;
