import "./Tab.scss";

import React from "react";

interface Props {
  index: number;
  active: boolean;
  onSelect: (index: number) => void;
}
interface State {}

class AnnouncementCardCarouselTab extends React.Component<Props, State> {
  private handleMouseEnter() {
    this.props.onSelect(this.props.index);
  }
  public render() {
    return (
      <div
        className="announcement-card__carousel-tab"
        data-active={this.props.active}
        onMouseEnter={this.handleMouseEnter.bind(this)}
      >
        <div className="mark"></div>
      </div>
    );
  }
}

export default AnnouncementCardCarouselTab;
