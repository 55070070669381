import "./Image.scss";

import React from "react";

interface Props {
  index: number;
  active: boolean;
  image: string;
  onClick: (index: number) => void;
}
interface State {}

class CarouselListImage extends React.Component<Props, State> {
  public render() {
    return (
      <div
        className="carousel__list-image"
        data-active={this.props.active}
        style={{ backgroundImage: `url(${this.props.image})` }}
        onClick={() => this.props.onClick(this.props.index)}
      ></div>
    );
  }
}

export default CarouselListImage;
