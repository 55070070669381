import "./Message.scss";

import CrossIcon from "../../../icons/Cross";
import ErrorIcon from "../../../icons/Error";
import NotificationIcon from "../../../icons/Notification";
import React from "react";
import Time from "../../../utils/time/Time";
import WarningIcon from "../../../icons/Warning";

// import ErrorIcon from "../assets/error.png";
// import NotiesIcon from "../assets/noties.png";

interface Props {
  message: App.Messages.Message;
  remove: (id: number) => void;
}
interface State {
  isDelete: boolean;
}

class Message extends React.Component<Props, State> {
  private timerRemove: number;
  private timerDelete: number;
  constructor(props: Props) {
    super(props);
    this.state = {
      isDelete: false,
    };
    this.timerRemove = 0;
    this.timerDelete = 0;
  }

  static timeToRemove = Time.second * 10;
  static timeToDelete = Time.second * 1;

  public componentDidMount() {
    this.timerRemove = window.setTimeout(
      this.remove.bind(this),
      Message.timeToRemove
    );
  }

  public componentWillUnmount() {
    clearTimeout(this.timerRemove);
    clearTimeout(this.timerDelete);
  }

  private get title() {
    switch (this.props.message.type) {
      case "error":
        return "Ошибка";
      case "warning":
        return "Внимание";
      case "notification":
        return "Уведомление";
      default:
        return "";
    }
  }
  private get icon() {
    switch (this.props.message.type) {
      case "error":
        return <ErrorIcon />;
      case "warning":
        return <WarningIcon />;
      case "notification":
        return <NotificationIcon />;
      default:
        return <></>;
    }
  }

  private remove() {
    if (this.state.isDelete) return;
    clearTimeout(this.timerRemove);
    this.setState({ isDelete: true });
    this.timerDelete = window.setTimeout(
      () => this.props.remove(this.props.message.id),
      Message.timeToDelete
    );
  }

  public render() {
    return (
      <div
        className="message"
        data-delete={this.state.isDelete}
        data-type={this.props.message.type}
      >
        <div className="cross-icon-container" onClick={this.remove.bind(this)}>
          <CrossIcon />
        </div>
        <div className="message-title">
          {this.icon}
          <strong>{this.title}</strong>
        </div>
        <div className="message-text">{this.props.message.text}</div>
      </div>
    );
  }
}

export default Message;
