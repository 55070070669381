import AnnouncementService from "../../../services/abstract/AnnouncementService";
import AnnouncementServiceApi from "../../../services/api/AnnouncementServiceApi";
import ColorPicker from "../../../components/custom/colorPicker/ColorPicker";
import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../struct/message/SMessage";
import { addMessage } from "../../../store/actions/messages";
import store from "../../../store/store";

interface Props {
  color: App.ID;
  onChange: (id: App.ID | null, info?: App.Info) => void;
}
interface State {
  colors: Array<App.Car.Color>;
}

class CreateAnnouncementColor extends React.Component<Props, State> {
  private service: AnnouncementService;
  constructor(props: Props) {
    super(props);
    this.service = new AnnouncementServiceApi();
    this.state = {
      colors: [],
    };
  }

  public componentDidMount() {
    this.getColors();
  }

  private async getColors() {
    const { data, status, errors } = await this.service.getFiltersByCategory(5);
    if (status === OK) {
      // @ts-ignore
      const color: App.Announcement.ColorFilter = data.find(
        (e) => e.type === "color-picker"
      );
      if (color) {
        this.setState({ colors: color.options });
      } else {
        store.dispatch(
          addMessage(new SMessage("Цвета не найдены.", "warning"))
        );
      }
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }

  public render() {
    return (
      <ColorPicker
        selected={this.props.color}
        onChange={this.props.onChange}
        colors={this.state.colors}
      />
    );
  }
}

export default CreateAnnouncementColor;
