import "./Footer.scss";

import ArrowBackImg from "../../../../../assets/icons/gray-arrow-back.svg";
import React from "react";

interface Props {
  complited: boolean;
  disabled: boolean;
  onGoBack: () => void;
  onGoNext: () => void;
  onSave: () => void;
}
interface State {}

class CreateAnnouncementStepFooter extends React.Component<Props, State> {
  public render() {
    return (
      <div className="create-announcement-step__footer">
        <img src={ArrowBackImg} alt="" onClick={this.props.onGoBack} />
        {!this.props.complited && (
          <button onClick={this.props.onGoNext} disabled={this.props.disabled}>
            Далее
          </button>
        )}
        {this.props.complited && (
          <button onClick={this.props.onSave} disabled={this.props.disabled}>
            Завершить
          </button>
        )}
      </div>
    );
  }
}

export default CreateAnnouncementStepFooter;
