import AnnouncementListContainer from "../../announcement/list/AnnouncementListContainer";
import AnnouncementService from "../../../services/abstract/AnnouncementService";
import AnnouncementServiceApi from "../../../services/api/AnnouncementServiceApi";
import React from "react";

interface Props {}
interface State {
  page: number;
  sort: number;
}

class UserFavoriteAnnouncementsContainer extends React.Component<Props, State> {
  private service: AnnouncementService;
  constructor(props: Props) {
    super(props);
    this.service = new AnnouncementServiceApi();
    this.state = {
      page: 1,
      sort: 0,
    };
  }

  private handleChangePage(page: number) {
    this.setState({ page });
  }
  private handleChangeSort(sort: number) {
    this.setState({ sort, page: 1 });
  }
  private search() {
    return this.service.getByFilters({
      page: this.state.page,
      sort: this.state.sort,
      favorite: true,
    });
  }
  public render() {
    return (
      <AnnouncementListContainer
        className="space"
        page={this.state.page}
        sort={this.state.sort}
        search={this.search.bind(this)}
        onChangePage={this.handleChangePage.bind(this)}
        onChangeSort={this.handleChangeSort.bind(this)}
        errorMessage={""}
      />
    );
  }
}

export default UserFavoriteAnnouncementsContainer;
