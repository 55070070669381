import "./ControllAnnouncement.scss";

import AnnouncementCardCarousel from "../card/carousel/Carousel";
import AnnouncementCardInfo from "../card/info/AnnouncementCardInfo";
import AnnouncementName from "../../car/name/AnnouncementName";
import { NavLink } from "react-router-dom";
import React from "react";
import Time from "../../../utils/time/Time";
import { redactAnnouncement } from "../../../routes";

interface Props {
  delete: (id: App.ID) => void;
  announcement: App.Announcement.Announcement;
}
interface State {}

class ControllAnnouncement extends React.Component<Props, State> {
  public render() {
    return (
      <div className="controll-announcement">
        <AnnouncementCardCarousel
          id={this.props.announcement.id}
          images={this.props.announcement.images.map((e) => e.path)}
        />
        <div className="text">
          <div className="controll-announcement__state" data-active={true}>
            Активно
          </div>
          <div className="controll-announcement__info">
            <AnnouncementName announcement={this.props.announcement} />
            <AnnouncementCardInfo announcement={this.props.announcement} />
            <div className="description">
              {this.props.announcement.description}
            </div>
            <div className="date">
              {Time.beautifyDate(this.props.announcement.created)}
            </div>
          </div>
          <div className="controll-announcement__actions">
            <NavLink
              className="action"
              to={redactAnnouncement(this.props.announcement.id)}
            >
              Редактировать
            </NavLink>

            <div
              className="action delete"
              onClick={() => this.props.delete(this.props.announcement.id)}
            >
              Удалить
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ControllAnnouncement;
