import "./CompareCarCategoryOfOptions.scss";

import { CompareCarOptions } from "../CompareCar";
import CompareCarRow from "../compareCarRow/CompareCarRow";
import ExpandMoreIcon from "./assets/expand_more.png";
import React from "react";

interface Props {
  category: CompareCarOptions;
  isShowDifferent: boolean;
}
interface State {
  isOpen: boolean;
}

class CompareCarCategoryOfOptions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }
  private toggleOpenOptions() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  public render() {
    return (
      <>
        {this.props.category.options.length > 0 && (
          <CompareCarRow
            className="category-head"
            head={
              <div onClick={this.toggleOpenOptions.bind(this)}>
                {this.props.category.name}{" "}
                <img
                  src={ExpandMoreIcon}
                  alt=""
                  data-open={this.state.isOpen}
                />
              </div>
            }
            values={[]}
          />
        )}

        {this.state.isOpen &&
          this.props.category.options.map((option, index) => (
            <CompareCarRow
              showOnlyDifference={this.props.isShowDifferent}
              key={index}
              head={<div>{option.name}</div>}
              values={option.values.map((value) => (
                <div className="value">{value}</div>
              ))}
              classes={option.values.map((value) =>
                value === "Нет" ? "empty" : "good"
              )}
              text={option.values}
            />
          ))}
      </>
    );
  }
}
export default CompareCarCategoryOfOptions;
