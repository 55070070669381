import "./HomeFilterButtons.scss";

import { announcements, filters } from "../../../routes";

import { NavLink } from "react-router-dom";
import React from "react";

interface Props {
  onStartSearch: () => void;
}
interface State {}

class HomeFilterButtons extends React.Component<Props, State> {
  public render() {
    return (
      <div className="home-filter__buttons">
        <div className="controls">
          <NavLink to={filters} className="to-advanced-filters">
            Расширеный фильтр
          </NavLink>
          <NavLink to={announcements} className="to-announcements">
            Показать все объявления
          </NavLink>
        </div>
        <button onClick={this.props.onStartSearch} className="search">
          Найти
        </button>
      </div>
    );
  }
}

export default HomeFilterButtons;
