import "./Radio.scss";

import React from "react";

interface Props {
  active: boolean;
  onClick: () => void;
}
interface State {}

class Radio extends React.Component<Props, State> {
  static defaultProps = {
    onClick() {},
  };
  public render() {
    return (
      <div
        className="radio-button"
        data-active={this.props.active}
        onClick={this.props.onClick}
      >
        <div className="mark"></div>
      </div>
    );
  }
}

export default Radio;
