import "./SignupForm.scss";

import React, { SyntheticEvent } from "react";

import LabelText from "../../custom/labels/labelText/LabelText";
import { NavLink } from "react-router-dom";
import RegExp from "../../../utils/string/RegExp";
import { signin } from "../../../routes";

export type SignupData = {
  email: string;
  name: string;
  password: string;
  passwordComparison: string;
};
interface Props {
  value: SignupData;
  errors: Array<string>;
  onChange: (value: SignupData) => void;
  onSubmit: () => void;
}
interface State {}

class SignupForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  private get isUncorrectEmail() {
    return !(this.props.value.email.length === 0 || this.isCorrectEmail);
  }
  private get isUncorrectName() {
    return !(this.props.value.name.length === 0 || this.isCorrectName);
  }
  private get isUncorrectPassword() {
    return !(this.props.value.password.length === 0 || this.isCorrectPassword);
  }
  private get isUncorrectPasswordComparison() {
    return !(
      this.props.value.password.length === 0 || this.isCorrectPasswordComparison
    );
  }
  private get isCorrectEmail() {
    const match = this.props.value.email.match(RegExp.email);
    if (!match) return false;
    return match[0] === this.props.value.email;
  }
  private get isCorrectName() {
    const match = this.props.value.name.match(/[A-Za-zА-Яа-я]*/);
    if (!match) return false;
    return (
      this.props.value.name.length > 0 && match[0] === this.props.value.name
    );
  }
  private get isCorrectPassword() {
    return (
      this.props.value.password.length > 3 &&
      this.props.value.password.length <= 32
    );
  }
  private get isCorrectPasswordComparison() {
    return (
      this.isCorrectPassword &&
      this.props.value.password === this.props.value.passwordComparison
    );
  }
  private get isCorrect() {
    return (
      this.isCorrectEmail &&
      this.isCorrectPassword &&
      this.isCorrectPasswordComparison
    );
  }

  private handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    if (this.isCorrect) {
      this.props.onSubmit();
    }
  }

  private handleChangeEmail(value: string) {
    this.props.onChange(Object.assign({}, this.props.value, { email: value }));
  }
  private handleChangeName(value: string) {
    this.props.onChange(Object.assign({}, this.props.value, { name: value }));
  }
  private handleChangePassword(value: string) {
    this.props.onChange(
      Object.assign({}, this.props.value, { password: value })
    );
  }
  private handleChangePasswordComparison(value: string) {
    this.props.onChange(
      Object.assign({}, this.props.value, { passwordComparison: value })
    );
  }

  public render() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)} className="signup-form">
        <div className="signup-form-header">
          <h3>Зарегистрируйтесь бесплатно</h3>
        </div>
        <LabelText
          name="Email"
          type="email"
          uncorrect={this.isUncorrectEmail}
          correct={this.isCorrectEmail}
          value={this.props.value.email}
          onChange={this.handleChangeEmail.bind(this)}
          required
        />
        <LabelText
          name="Имя"
          type="text"
          uncorrect={this.isUncorrectName}
          correct={this.isCorrectName}
          value={this.props.value.name}
          onChange={this.handleChangeName.bind(this)}
          required
        />
        <LabelText
          name="Пароль"
          type="password"
          uncorrect={this.isUncorrectPassword}
          correct={this.isCorrectPassword}
          value={this.props.value.password}
          onChange={this.handleChangePassword.bind(this)}
          minLength={4}
          maxLength={32}
          required
        />
        <LabelText
          name="Повторите пароль"
          type="password"
          uncorrect={this.isUncorrectPasswordComparison}
          correct={this.isCorrectPasswordComparison}
          value={this.props.value.passwordComparison}
          onChange={this.handleChangePasswordComparison.bind(this)}
          minLength={4}
          maxLength={32}
          required
        />
        {this.props.errors.length > 0 && (
          <div className="errors">
            {this.props.errors.map((error, key) => (
              <span className="error" key={key}>
                {error}
              </span>
            ))}
          </div>
        )}
        <button disabled={!this.isCorrect}>Регистрация</button>
        <NavLink to={signin} className="to-sign-in">
          Вход
        </NavLink>
      </form>
    );
  }
}
export default SignupForm;
