import "./AdministrateAnnouncementsChangeSpecPopup.scss";

import Popup from "../../../../../custom/popup/Popup";
import React from "react";
import ReactDatePicker from "react-datepicker";

interface Props {
  spec: { from: Date | null; to: Date | null };
  disabled: boolean;
  onSubmit: (spec: { from: Date | null; to: Date | null }) => void;
  onClose: () => void;
}
interface State {
  spec: { from: Date | null; to: Date | null };
}

class AdministrateAnnouncementsChangeSpecPopup extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = { spec: props.spec };
  }
  private handleChangeSpecFrom(date: Date | null) {
    if (date && this.state.spec.to && +date > +this.state.spec.to) {
      this.setState({ spec: { from: this.state.spec.to, to: date } });
    } else {
      this.setState({ spec: { from: date, to: this.state.spec.to } });
    }
  }
  private handleChangeSpecTo(date: Date | null) {
    if (date && this.state.spec.from && +date < +this.state.spec.from) {
      this.setState({ spec: { from: date, to: this.state.spec.from } });
    } else {
      this.setState({ spec: { from: this.state.spec.from, to: date } });
    }
  }
  public render() {
    return (
      <Popup
        className="administrate-announcements-change-spec-popup"
        onClose={this.props.onClose}
      >
        <h3>Спецпредложение</h3>
        <div className="spec-range">
          <label>
            <span>От</span>
            <ReactDatePicker
              showTimeSelect
              selected={this.state.spec.from}
              onChange={this.handleChangeSpecFrom.bind(this)}
              dateFormat="dd.MM.yyyy HH:MM"
              disabled={this.props.disabled}
            />
          </label>
          <label>
            <span>До</span>
            <ReactDatePicker
              showTimeSelect
              selected={this.state.spec.to}
              onChange={this.handleChangeSpecTo.bind(this)}
              dateFormat="dd.MM.yyyy HH:MM"
              disabled={this.props.disabled}
            />
          </label>
        </div>

        <div className="buttons">
          <button
            onClick={() => this.props.onSubmit(this.state.spec)}
            disabled={this.props.disabled}
          >
            Сохранить
          </button>
          <button onClick={this.props.onClose} disabled={this.props.disabled}>
            Отмена
          </button>
        </div>
      </Popup>
    );
  }
}
export default AdministrateAnnouncementsChangeSpecPopup;
