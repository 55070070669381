import "./AnnouncementOption.scss";

import AnnouncementOptionName from "./name/AnnouncementOptionName";
import React from "react";

interface Props {
  category: string;
  options: Array<string>;
}
interface State {
  isOpen: boolean;
}

class AnnouncementOption extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  private handleClick() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  public render() {
    return (
      <div className="announcement-option">
        <AnnouncementOptionName
          name={this.props.category}
          optionsNumber={this.props.options.length}
          onClick={this.handleClick.bind(this)}
          isOpen={this.state.isOpen}
        />

        {this.state.isOpen && (
          <ul className="options">
            {this.props.options.map((option) => (
              <li key={option}> {option}</li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default AnnouncementOption;
