import LabelSearch from "../../../components/custom/labels/labelSearch/LabelSearch";
import React from "react";
import { Unsubscribe } from "redux";
import { loadModels } from "../../../store/actions/car";
import store from "../../../store/store";

interface Props {
  disabled?: boolean;
  brand: number | null;
  selected: number | null;
  onChange: (value: App.ID | null, model?: App.Info) => void;
  placeholder?: boolean;
}
interface State {
  models: Array<App.Car.Model>;
}

class LabelSearchModel extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      models: [],
    };
  }

  static defaultProps = {
    selected: null,
    brand: null,
    onChange() {},
  };

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
    if (this.props.brand) {
      this.getModels(this.props.brand);
    }
  }
  public componentDidUpdate(props: Props) {
    if (props.brand === this.props.brand) return;
    if (this.props.brand) {
      this.getModels(this.props.brand);
    }
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }
  public handleChange(model: App.Info | null) {
    if (model) {
      this.props.onChange(model.id || 0, model);
    } else {
      this.props.onChange(null);
    }
  }

  private handleUpdateStore() {
    if (!this.props.brand) {
      return;
    }
    this.getModels(this.props.brand);
  }

  private getModels(brand: number) {
    const { models } = store.getState().car;
    if (models[brand]) {
      this.setState({ models: models[brand] });
    } else {
      store.dispatch(loadModels(brand));
    }
  }
  public render() {
    return (
      <LabelSearch
        placeholder={this.props.placeholder}
        name={"Модель"}
        options={this.state.models}
        selected={this.state.models.find((e) => e.id === this.props.selected)}
        onChange={this.handleChange.bind(this)}
        disabled={this.props.disabled}
      />
    );
  }
}

export default LabelSearchModel;
