import "./AnnouncementCardFooter.scss";

import CompareIcon from "../../../../icons/CompareIcon";
import CrossIcon from "../../../../icons/Cross";
import PhoneIcon from "../../../../icons/PhoneIcon";
import React from "react";
import StarBorderIcon from "../../../../icons/StarBorderIcon";

interface Props {
  phoneNumber: string;
  isFavorite: boolean;
  isHidden: boolean;
  isCompared: boolean;
  onToggleFavorite: () => void;
  onToggleComparison: () => void;
  onHide: () => void;
}
interface State {}

class AnnouncementCardFooter extends React.Component<Props, State> {
  public render() {
    return (
      <div className="announcement-card__footer">
        <div
          className="action favorite"
          data-active={this.props.isFavorite}
          onClick={this.props.onToggleFavorite}
        >
          <StarBorderIcon />
          <span>Избранное</span>
        </div>
        <div
          className="action compare"
          data-active={this.props.isCompared}
          onClick={this.props.onToggleComparison}
        >
          <CompareIcon />
          <span>Сравнить</span>
        </div>
        <div
          className="action hide"
          onClick={this.props.onHide}
          data-active={this.props.isHidden}
        >
          <CrossIcon />
          <span>Скрыть</span>
        </div>
        {this.props.phoneNumber ? (
          <a href={`tel:375${this.props.phoneNumber}`} className="action call">
            <PhoneIcon />
            <span>Позвонить</span>
          </a>
        ) : (
          <div className="action dont-call ">
            <PhoneIcon />
            <span>Нет номера</span>
          </div>
        )}
      </div>
    );
  }
}

export default AnnouncementCardFooter;
