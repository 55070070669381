import "./UserContactsList.scss";

import AddContactContainer from "../../../containers/user/addContact/AddContactContainer";
import React from "react";
import RefreshIcon from "../../../icons/Refresh";
import SubTitle from "../../custom/subtitle/SubTitle";
import UserContact from "../contact/Contact";

interface Props {
  contacts: Array<App.User.Contact>;
  loading: boolean;
  onDelete: (contact: App.User.Contact) => void;
  refresh: () => void;
}
interface State {
  isOpenForm: boolean;
}

class UserContactsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenForm: false,
    };
  }

  private open() {
    this.setState({ isOpenForm: true });
  }
  public render() {
    return (
      <div className="user-contacts-list" data-disabled={this.props.loading}>
        <SubTitle>
          Контакты <RefreshIcon fill="black" onClick={this.props.refresh} />
        </SubTitle>
        {this.state.isOpenForm && <AddContactContainer />}
        {this.props.contacts.map((contact) => (
          <UserContact
            key={contact.id}
            contact={contact}
            disabled={this.props.loading}
            onDelete={this.props.onDelete}
          />
        ))}
        {this.state.isOpenForm || (
          <button className="add-contact-button" onClick={this.open.bind(this)}>
            Добавить контакт
          </button>
        )}
      </div>
    );
  }
}

export default UserContactsList;
