import PopularBrands from "../../../components/filters/popularBrands/PopularBrands";
import React from "react";
import { Unsubscribe } from "redux";
import { loadBrands } from "../../../store/actions/car";
import store from "../../../store/store";

interface Props {
  onSelectBrand: (brandId: number) => void;
}
interface State {
  brands: Array<App.Car.Brand>;
}

class PopularBrandsContainer extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      brands: this.getPopularBrands(),
    };
  }

  public componentDidMount() {
    this.handleUpdateStore();
    store.subscribe(this.handleUpdateStore.bind(this));
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  private handleUpdateStore() {
    if (this.state.brands.length) {
      return;
    }
    this.setState({ brands: this.getPopularBrands() });
  }

  private loadBrands() {
    store.dispatch(loadBrands());
  }
  private getPopularBrands() {
    try {
      const { brands } = store.getState().car;
      if (!brands) {
        throw new Error();
      }
      return brands?.filter((b) => b.popular) || this.state.brands;
    } catch {
      this.loadBrands();
      return [];
    }
  }

  public render() {
    return (
      <PopularBrands
        onClick={this.props.onSelectBrand}
        brands={this.state.brands}
      />
    );
  }
}

export default PopularBrandsContainer;
