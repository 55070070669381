import "./SpecAnnouncements.scss";

import AnnouncementCard from "../../card/AnnouncementCard";
import { NavLink } from "react-router-dom";
import React from "react";
import SubTitle from "../../../custom/subtitle/SubTitle";
import { special } from "../../../../routes";

interface Props {
  announcements: Array<App.Announcement.Announcement>;
}
interface State {}

class SpecAnnouncements extends React.Component<Props, State> {
  public render() {
    return (
      <>
        {this.props.announcements.length > 0 && (
          <div className="spec-announcements">
            <SubTitle className="spec-announcements-name">
              Спецпредложение
            </SubTitle>
            {this.props.announcements.map((announcement) => (
              <AnnouncementCard
                key={announcement.id}
                announcement={announcement}
              />
            ))}
            <NavLink to={special} className="link-to-special">
              Все спецпредложения
            </NavLink>
          </div>
        )}
      </>
    );
  }
}
export default SpecAnnouncements;
