import AnnouncementService from "../../../services/abstract/AnnouncementService";
import AnnouncementServiceApi from "../../../services/api/AnnouncementServiceApi";
import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../struct/message/SMessage";
import SearchByText from "../../../components/announcement/search/byText/SearchByText";
import Time from "../../../utils/time/Time";
import { addMessage } from "../../../store/actions/messages";
import store from "../../../store/store";

interface Props {}
interface State {
  keyword: string;
  announcments: Array<App.Announcement.Announcement>;
  loading: boolean;
}

class SearchByTextContainer extends React.Component<Props, State> {
  private timeoutForSearch: number;
  private service: AnnouncementService;
  constructor(props: Props) {
    super(props);
    this.timeoutForSearch = 0;
    this.service = new AnnouncementServiceApi();
    this.state = {
      keyword: "",
      announcments: [],
      loading: false,
    };
  }

  public componentWillUnmount() {
    clearTimeout(this.timeoutForSearch);
  }

  private get loading() {
    return this.state.loading;
  }
  private set loading(loading: boolean) {
    this.setState({ loading });
  }

  public async getAnnouncments() {
    if (this.loading) {
      this.createTimeoutSearch();
    }
    this.loading = true;
    const { data, status, errors } = await this.service.search({
      keyword: this.state.keyword,
      page: 1,
    });

    if (status === OK) {
      this.setState({ announcments: data.items });
      if (data.items.length === 0) {
        store.dispatch(
          addMessage(new SMessage("Не найдено объявлений.", "warning"))
        );
      }
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
    this.loading = false;
  }

  private handleChangeKeyword(keyword: string) {
    if (keyword !== this.state.keyword) {
      this.createTimeoutSearch();
    }
    this.setState({ keyword });
  }

  private createTimeoutSearch() {
    clearTimeout(this.timeoutForSearch);
    this.timeoutForSearch = window.setTimeout(
      this.getAnnouncments.bind(this),
      Time.second
    );
  }

  public render() {
    return (
      <SearchByText
        loading={this.state.loading}
        keyword={this.state.keyword}
        announcments={this.state.announcments}
        handleChangeKeyword={this.handleChangeKeyword.bind(this)}
        onSearch={this.getAnnouncments.bind(this)}
      />
    );
  }
}

export default SearchByTextContainer;
