import {
  Actions,
  loadCurrencyType,
  saveCurrency,
  saveCurrencyType,
} from "../actions/currency";
import { BYN_ID, USD_ID } from "../../Config";

import CurrencyService from "../../services/abstract/CurrencyService";
import CurrencyServiceApi from "../../services/api/CurrencyServiceApi";
import { OK } from "http-status-codes";
import SMessage from "../../struct/message/SMessage";
import { addMessage } from "../actions/messages";
import store from "../store";

export interface CurrencyStore {
  BYN: App.Currency.Currency;
  USD: App.Currency.Currency;
  isLoaded: boolean;
}

class CurrencyReducer {
  private service: CurrencyService;
  constructor(service: CurrencyService) {
    this.service = service;
  }
  get defaultStore(): CurrencyStore {
    return {
      BYN: { id: BYN_ID, name: "BYN", multiplier: 1 },
      USD: { id: USD_ID, name: "USD", multiplier: 1 },
      isLoaded: false,
    };
  }

  public store(state: CurrencyStore, action: Actions) {
    switch (action.type) {
      case loadCurrencyType:
        this.getCurrency();
        break;
      case saveCurrencyType:
        state.USD = action.USD;

        state.isLoaded = true;
        break;
      default:
        break;
    }
    return state;
  }
  private async getCurrency() {
    const { data, status, errors } = await this.service.getCurrency();
    if (status === OK) {
      const USD = data.find((c) => c.id === USD_ID);

      if (USD) {
        USD.multiplier = 1 / USD.multiplier;
        store.dispatch(saveCurrency(USD));
      } else {
        store.dispatch(
          addMessage(
            new SMessage("Не удалось загрузить курс для USD", "warning")
          )
        );
      }
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }
}

export default new CurrencyReducer(new CurrencyServiceApi());
