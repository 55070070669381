import Axios, { AxiosResponse } from "axios";
import { logout, saveSession } from "../../store/actions/user";

import store from "../../store/store";

type DataSession = AxiosResponse<{
  access_token: string;
  refresh_token: string;
  token_type: "Bearer";
  expires_in: number;
}>;

class JWT {
  private isRefresh: boolean;
  private refresh: Promise<string>;

  constructor() {
    this.isRefresh = false;
    this.refresh = new Promise((resolve) => resolve(""));
  }

  public async getAuth(): Promise<string> {
    let tokens = store.getState().user.session;
    if (!tokens.accessToken || !tokens.refreshToken) {
      // eslint-disable-next-line
      throw { status: false, data: ["Вы не авторизованы"] };
    }
    if (!this.check(tokens.accessToken)) {
      return `Bearer ${await this.refreshToken(tokens)}`;
    } else {
      return `Bearer ${tokens.accessToken}`;
    }
  }
  public async refreshToken(session: App.User.Session): Promise<string> {
    if (!this.isRefresh) {
      this.isRefresh = true;
      this.refresh = this.updateSession(session).then((response) => {
        store.dispatch(
          saveSession({
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token,
          })
        );
        this.isRefresh = false;
        return response.data.access_token;
      });
      await this.refresh;
      this.isRefresh = false;
    }

    return this.refresh
      .then((access) => {
        return access;
      })
      .catch(() => {
        store.dispatch(logout());
        // eslint-disable-next-line
        throw { status: false, data: ["Вы не авторизованы"] };
      });
  }
  public async updateSession(session: App.User.Session): Promise<DataSession> {
    return Axios.post("/api/auth/refresh/", {
      refresh_token: session.refreshToken,
      access_token: session.accessToken,
    });
  }
  public check(token: string) {
    try {
      const [, payload] = token.split(".");
      const decodedPayload: { exp: number } = JSON.parse(atob(payload));
      if (decodedPayload.exp < Date.now() / 1000 + 10) {
        return false;
      }
      return true;
    } catch {
      return false;
    }
  }
}

export default new JWT();
