import "./Popup.scss";

import React, { SyntheticEvent } from "react";

interface Props {
  className: string;
  onClose?: () => void;
}
interface State {}

class Popup extends React.Component<Props, State> {
  static defaultProps = {
    className: "",
  };
  private handleClickShadow(event: SyntheticEvent<HTMLDivElement>) {
    if (this.props.onClose && event.currentTarget === event.target) {
      this.props.onClose();
    }
  }
  public render() {
    return (
      <div
        className="popup-back"
        onMouseDown={this.handleClickShadow.bind(this)}
      >
        <div className={"popup " + this.props.className}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Popup;
