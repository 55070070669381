import "./ConfirmationContainer.scss";

import { OK } from "http-status-codes";
import React from "react";
import UserService from "../../services/abstract/UserService";
import UserServiceApi from "../../services/api/UserServiceApi";

interface Props {
  hash: string;
  toSignin: () => void;
}
interface State {
  flag: "confirm" | "error" | null;
  errors: string;
}

class ConfirmationContainer extends React.Component<Props, State> {
  private service: UserService;
  constructor(props: Props) {
    super(props);
    this.service = new UserServiceApi();
    this.state = { flag: null, errors: "" };
  }

  public componentDidMount() {
    this.confirmation();
  }

  private async confirmation() {
    const { status, errors } = await this.service.confirmationEmail(
      this.props.hash
    );
    if (status === OK) {
      this.setState({ flag: "confirm" });
    } else {
      this.setState({ flag: "error", errors: errors.join("") });
    }
  }
  public render() {
    return (
      <>
        {this.state.flag === "confirm" && (
          <div className="confirm-success">
            <div>Спасибо, e-mail адрес подтвержден</div>
            <button onClick={this.props.toSignin}>Войти</button>
          </div>
        )}
        {this.state.flag === "error" && (
          <div className="confirm-error">{this.state.errors}</div>
        )}
        {this.state.flag === null && (
          <div className="confirm-loading">Загрузка...</div>
        )}
      </>
    );
  }
}

export default ConfirmationContainer;
