import {
  addToCompare,
  removeFromCompare,
} from "../../../store/actions/compareCars";

import AnnouncementActions from "../../../components/announcement/actions/AnnouncementActions";
import AnnouncementCardFooter from "../../../components/announcement/card/footer/AnnouncementCardFooter";
import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../struct/message/SMessage";
import { Unsubscribe } from "redux";
import UserService from "../../../services/abstract/UserService";
import UserServiceApi from "../../../services/api/UserServiceApi";
import { addMessage } from "../../../store/actions/messages";
import store from "../../../store/store";

interface Props {
  announcement: App.Announcement.Announcement;
  onUpdateAnnouncement: (announcement: App.Announcement.Announcement) => void;
  informationCard?: boolean;
}
interface State {
  isHidden: boolean;
  isCompared: boolean;
}

class AnnouncementActionsContainer extends React.Component<Props, State> {
  private service: UserService;
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.service = new UserServiceApi();
    this.unsubscribe = () => {};
    this.state = {
      isHidden: false,
      isCompared: this.isCompared,
    };
  }

  private get isCompared() {
    return store.getState().compareCar.cars.has(this.props.announcement.id);
  }

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
    this.getPhoneNumber();
  }
  public componentDidUpdate(prevProps: Props) {
    if (this.props.announcement.id !== prevProps.announcement.id) {
      this.setState({ isHidden: false });
    }
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }

  private handleUpdateStore() {
    this.setState({
      isCompared: this.isCompared,
    });
  }

  private async handleToggleFavorite() {
    const { status, errors } = await this.service.toggleFavorite(
      this.props.announcement.id
    );

    if (status === OK) {
      const announcement: App.Announcement.Announcement = Object.assign(
        this.props.announcement
      );
      announcement.favorite = !announcement.favorite;
      this.props.onUpdateAnnouncement(announcement);
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }
  private handleToggleComparison() {
    this.state.isCompared
      ? store.dispatch(removeFromCompare(this.props.announcement.id))
      : store.dispatch(addToCompare(this.props.announcement.id));
  }
  private async handleHide() {
    const { status, errors } = await this.service.toggleHide(
      this.props.announcement.id
    );
    if (status === OK) {
      this.setState({ isHidden: !this.state.isHidden });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }

  private async getPhoneNumber() {
    if (!this.props.announcement.contact.id) return;
    const { data, status } = await this.service.getPhoneNumberForContact(
      this.props.announcement.contact.id
    );
    if (status === OK) {
      const announcement: App.Announcement.Announcement = Object.assign(
        this.props.announcement
      );
      announcement.contact.phone = data.toString();
      this.props.onUpdateAnnouncement(announcement);
    } else {
      store.dispatch(addMessage(new SMessage("Не удалось загрузить номер.")));
    }
  }

  public render() {
    return (
      <>
        {!!this.props.informationCard ? (
          <AnnouncementActions
            id={this.props.announcement.id}
            isFavorite={this.props.announcement.favorite}
            isCompared={this.state.isCompared}
            isHidden={this.state.isHidden}
            onToggleFavorite={this.handleToggleFavorite.bind(this)}
            onToggleComparison={this.handleToggleComparison.bind(this)}
            onHide={this.handleHide.bind(this)}
          />
        ) : (
          <AnnouncementCardFooter
            phoneNumber={this.props.announcement.contact.phone}
            isFavorite={this.props.announcement.favorite}
            isHidden={this.state.isHidden}
            isCompared={this.state.isCompared}
            onToggleFavorite={this.handleToggleFavorite.bind(this)}
            onToggleComparison={this.handleToggleComparison.bind(this)}
            onHide={this.handleHide.bind(this)}
          />
        )}
      </>
    );
  }
}

export default AnnouncementActionsContainer;
