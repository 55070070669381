import { BASE_URL } from "../../Config";

class SBrand implements App.Car.Brand {
  public id: number;
  public name: string;
  public country: string;
  public city: string;
  public src: string;
  public popular: boolean;

  constructor(data?: any) {
    this.id = 0;
    this.name = "";
    this.src = "";
    this.country = "";
    this.city = "";
    this.popular = false;

    data && this.init(data);
  }

  private init(data: any) {
    try {
      this.id = data.id || 0;
      this.name = data.name || "";
      this.country = data.country || "";
      this.city = data.city || "";
      this.src = data.logo ? BASE_URL + data.logo : "";
      this.popular = data.main === 1;
    } catch {}
  }
}

export default SBrand;
