import React, { SyntheticEvent } from "react";

import AdminPanelForm from "../form/AdminPanelForm";
import AdminPanelList from "../list/AdminPanelList";
import { GenerationData } from "../../../services/abstract/CarService";
import LabelText from "../../custom/labels/labelText/LabelText";
import ObjectMethods from "../../../utils/object/ObjectMethods";
import Title from "../../custom/title/Title";

interface Props {
  generations: Array<App.Car.Generation>;
  onSave: (brandData: GenerationData) => void;
  onDelete: () => Promise<boolean>;
  onSelectGeneration: (generation: App.Car.Generation) => void;
  generation?: App.Car.Generation;
}
interface State {
  loading: boolean;
  isOpenPopupDelete: boolean;
  name: string;
  modelId: number;
  start: string;
  end: string;
}

class RedactGeneration extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      isOpenPopupDelete: false,
      name: "",
      modelId: 0,
      start: "",
      end: "",
    };
  }
  static regForName = /[^A-zА-я0-9 -]{1,}/;

  public componentDidUpdate(props: Props) {
    if (!ObjectMethods.compare(props.generation, this.props.generation)) {
      if (this.props.generation) {
        const { name, start, end, modelId } = this.props.generation;
        this.setState({
          name,
          start: "" + (start || ""),
          end: "" + (end || ""),
          modelId,
        });
      } else {
        this.setState({ name: "", start: "", end: "", modelId: 0 });
      }
    }
  }

  private get correctName() {
    return !!(
      this.state.name.length &&
      !this.state.name.match(RedactGeneration.regForName)
    );
  }
  private get uncorrectName() {
    return !!(
      this.state.name.length &&
      this.state.name.match(RedactGeneration.regForName)
    );
  }
  private get correctStart() {
    const start = +this.state.start;
    const end = +this.state.end || +new Date().getFullYear();
    return start !== 0 && start <= end;
  }
  private get uncorrectStart() {
    const start = +this.state.start;
    const end = +this.state.end || +new Date().getFullYear();
    return start !== 0 && start > end;
  }
  private get correctEnd() {
    const start = +this.state.start;
    const end = +this.state.end || +new Date().getFullYear();
    return !!(end && end >= start);
  }
  private get uncorrectEnd() {
    const start = +this.state.start;
    const end = +this.state.end || +new Date().getFullYear();
    return !!(end || end < start);
  }
  private get disabled() {
    return (
      this.state.loading ||
      !(this.correctName && this.correctStart && this.correctEnd)
    );
  }

  private handleChangeName(name: string) {
    this.setState({ name });
  }
  private handleChangeStart(start: string) {
    this.setState({
      start: start
        .split("")
        .filter((e) => +e || e === "0")
        .join(""),
    });
  }
  private handleChangeEnd(end: string) {
    this.setState({
      end: end
        .split("")
        .filter((e) => +e || e === "0")
        .join(""),
    });
  }

  private async handleClick() {
    this.setState({ loading: true });
    await this.props.onSave({
      name: this.state.name,
      start: +this.state.start,
      end: +this.state.end || null,
      modelId: this.state.modelId,
    });
    this.setState({ loading: false });
  }

  private handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!this.disabled) {
      this.handleClick();
    }
  }
  private openDeletePopup(event: SyntheticEvent<HTMLButtonElement>) {
    event.preventDefault();
    this.setState({ isOpenPopupDelete: true });
  }
  private closeDeletePopup(event?: SyntheticEvent) {
    event?.preventDefault();
    this.setState({ isOpenPopupDelete: false });
  }
  private async deleteBrand(event?: SyntheticEvent) {
    event?.preventDefault();
    this.setState({ loading: true });
    const result = await this.props.onDelete();
    if (result) {
      this.setState({ loading: false, isOpenPopupDelete: false });
    } else {
      this.setState({ loading: false });
    }
  }

  public render() {
    return (
      <>
        <Title>Поколение</Title>
        <AdminPanelList
          items={this.props.generations}
          selected={this.props.generation?.id}
          onSelect={this.props.onSelectGeneration}
        />
        <AdminPanelForm
          loading={this.state.loading}
          question="Удалить поколение?"
          isOpenPopup={this.state.isOpenPopupDelete}
          onSubmit={this.handleSubmit.bind(this)}
          onClose={this.closeDeletePopup.bind(this)}
          onDelete={this.deleteBrand.bind(this)}
        >
          <LabelText
            uncorrect={this.uncorrectName}
            correct={this.correctName}
            placeholder="Название"
            onChange={this.handleChangeName.bind(this)}
            value={this.state.name}
            maxLength={32}
          />
          <LabelText
            uncorrect={this.uncorrectStart}
            correct={this.correctStart}
            placeholder="Начало выпуска"
            onChange={this.handleChangeStart.bind(this)}
            value={this.state.start}
            maxLength={4}
          />
          <LabelText
            uncorrect={this.uncorrectEnd}
            correct={this.correctEnd}
            placeholder="Конец выпуска"
            onChange={this.handleChangeEnd.bind(this)}
            value={this.state.end}
            maxLength={4}
          />
          <div className="buttons">
            <button
              onClick={this.handleClick.bind(this)}
              disabled={this.disabled}
            >
              Сохранить
            </button>
            <button
              className="remove"
              onClick={this.openDeletePopup.bind(this)}
              disabled={!this.props.generation || this.state.loading}
            >
              Удалить
            </button>
          </div>
        </AdminPanelForm>
      </>
    );
  }
}
export default RedactGeneration;
