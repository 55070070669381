import {
  Actions,
  loadFiltersType,
  saveFilters,
  saveFiltersType,
} from "../actions/filters";

import AnnouncementService from "../../services/abstract/AnnouncementService";
import AnnouncementServiceApi from "../../services/api/AnnouncementServiceApi";
import { FILTER_MAP } from "../../Config";
import { OK } from "http-status-codes";
import SMessage from "../../struct/message/SMessage";
import { addMessage } from "../actions/messages";
import store from "../store";

export interface FiltersStore {
  main: Array<App.Announcement.FilterApi>;
  visibility: Array<App.Announcement.FilterApi>;
  salon: Array<App.Announcement.FilterApi>;
  multimedia: Array<App.Announcement.FilterApi>;
  safety: Array<App.Announcement.FilterApi>;
  comfort: Array<App.Announcement.FilterApi>;
  other: Array<App.Announcement.FilterApi>;
}

class FiltersReducer {
  private service: AnnouncementService;
  constructor(service: AnnouncementService) {
    this.service = service;
  }

  public get defaultStore(): FiltersStore {
    return {
      main: [],
      visibility: [],
      salon: [],
      multimedia: [],
      safety: [],
      comfort: [],
      other: [],
    };
  }

  public store(store: FiltersStore, action: Actions) {
    switch (action.type) {
      case saveFiltersType:
        store = Object.assign(store, action.filters);
        break;
      case loadFiltersType:
        FILTER_MAP.forEach((filter) => this.getFilters(filter.id));
        break;

      default:
        break;
    }
    return store;
  }

  private async getFilters(category: App.Announcement.Category) {
    const { data, status, errors } = await this.service.getFiltersByCategory(
      category
    );
    if (status === OK) {
      this.saveFilters(category, data);
    } else {
      errors.forEach((e) => store.dispatch(addMessage(new SMessage(e))));
    }
  }

  private saveFilters(
    category: App.Announcement.Category,
    data: Array<App.Announcement.FilterApi>
  ) {
    const filters: FiltersStore = store.getState().filters;
    switch (FILTER_MAP.find((e) => e.id === category)?.category) {
      case "main":
        filters.main = filters.main
          ? filters.main.concat(data.filter((e) => e.id > 4))
          : data.filter((e) => e.id > 4);
        break;
      case "visibility":
        filters.visibility = filters.visibility
          ? filters.visibility.concat(data)
          : data;
        break;
      case "salon":
        filters.salon = filters.salon ? filters.salon.concat(data) : data;
        break;
      case "multimedia":
        filters.multimedia = filters.multimedia
          ? filters.multimedia.concat(data)
          : data;
        break;
      case "safety":
        filters.safety = filters.safety ? filters.safety.concat(data) : data;
        break;
      case "comfort":
        filters.comfort = filters.comfort ? filters.comfort.concat(data) : data;
        break;
      case "other":
        filters.other = filters.other ? filters.other.concat(data) : data;
        break;
      default:
        break;
    }
    store.dispatch(saveFilters(filters));
  }
}

export default new FiltersReducer(new AnnouncementServiceApi());
