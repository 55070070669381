type ADD_TO_COMPARE = "ADD_TO_COMPARE";
type REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";

interface AddToCompareAction {
  type: ADD_TO_COMPARE;
  id: App.ID;
}
interface RemoveFromCompareAction {
  type: REMOVE_FROM_COMPARE;
  id: App.ID;
}

export type Actions = AddToCompareAction | RemoveFromCompareAction;

export const addToCompareType: ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const removeFromCompareType: REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";

export function addToCompare(id: App.ID): AddToCompareAction {
  return { type: addToCompareType, id };
}
export function removeFromCompare(id: App.ID): RemoveFromCompareAction {
  return { type: removeFromCompareType, id };
}
