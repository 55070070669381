import "./DisplayTypes.scss";

import ListDisplayType from "./types/List";
import React from "react";
import TileDisplayType from "./types/Tile";

interface Props {
  active: App.DisplayType;
  onClick: (type: App.DisplayType) => void;
}
interface State {}

class DisplayTypes extends React.Component<Props, State> {
  static defaultProps = {
    onClick() {},
  };

  public render() {
    return (
      <div className="announcement-list__display-types">
        <ListDisplayType
          onClick={this.props.onClick}
          active={this.props.active}
        />
        <TileDisplayType
          onClick={this.props.onClick}
          active={this.props.active}
        />
      </div>
    );
  }
}
export default DisplayTypes;
