import React from "react";

interface Props {}
interface State {}

class CompareIcon extends React.Component<Props, State> {
  public render() {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17.5 14.1797V2.5H5.82031V14.1797H17.5ZM17.5 0.820312C17.9427 0.820312 18.3333 0.989583 18.6719 1.32812C19.0104 1.66667 19.1797 2.05729 19.1797 2.5V14.1797C19.1797 14.6224 19.0104 15.013 18.6719 15.3516C18.3333 15.6641 17.9427 15.8203 17.5 15.8203H5.82031C5.3776 15.8203 4.98698 15.6641 4.64844 15.3516C4.33594 15.013 4.17969 14.6224 4.17969 14.1797V2.5C4.17969 2.05729 4.33594 1.66667 4.64844 1.32812C4.98698 0.989583 5.3776 0.820312 5.82031 0.820312H17.5ZM2.5 4.17969V17.5H15.8203V19.1797H2.5C2.05729 19.1797 1.66667 19.0104 1.32812 18.6719C0.989583 18.3333 0.820312 17.9427 0.820312 17.5V4.17969H2.5Z" />
      </svg>
    );
  }
}
export default CompareIcon;
