import {
  AnnouncementsActions,
  changeFiltersAnnouncementsType,
  researchAnnouncementsType,
} from "../../actions/announcements/announcements";

export interface AnnouncementsStore {
  filters: App.Announcement.FiltersForSearch;
  research: number;
}

class AnnouncementsReducer {
  public get defaultStore(): AnnouncementsStore {
    return {
      filters: {
        car: [{ brand: null, model: null }],
        other: [],
      },
      research: 0,
    };
  }
  public store(store: AnnouncementsStore, action: AnnouncementsActions) {
    switch (action.type) {
      case changeFiltersAnnouncementsType:
        store.filters = action.filters;
        break;

      case researchAnnouncementsType:
        store.research = +new Date();
        break;

      default:
        break;
    }
    return store;
  }
}

export default new AnnouncementsReducer();
