import { AddContactValue } from "../../containers/user/addContact/AddContactContainer";
import { SigninData } from "../../components/auth/signinForm/SigninForm";
import { SignupData } from "../../components/auth/signupForm/SignupForm";

export interface SaveContactData extends AddContactValue {
  code: string;
}
export interface ChangePasswordData {
  oldPassword: string;
  newPassword: string;
  comparisonNewPassword: string;
}
export interface MessageForAdministration {
  text: string;
  name: string;
  phone_number: string;
}

export default abstract class UserService {
  public abstract confirmationEmail(key: string): Promise<App.Response<void>>;
  public abstract signin(
    data: SigninData
  ): Promise<App.Response<App.User.Session>>;
  public abstract signup(data: SignupData): Promise<App.Response<void>>;
  public abstract updateSession(
    session: App.User.Session
  ): Promise<App.Response<App.User.Session>>;
  public abstract logout(
    session: App.User.Session
  ): Promise<App.Response<boolean>>;
  public abstract getUserInfo(
    session?: App.User.Session
  ): Promise<App.Response<App.User.User>>;
  public abstract getSmsCode(phone: string): Promise<App.Response<void>>;
  public abstract saveContact(
    data: SaveContactData,
    session?: App.User.Session
  ): Promise<App.Response<Array<App.User.Contact>>>;
  public abstract deleteContact(
    id: App.ID,
    session?: App.User.Session
  ): Promise<App.Response<void>>;
  public abstract getPhoneNumberForContact(
    contactId: App.ID
  ): Promise<App.Response<string>>;

  public abstract toggleFavorite(id: App.ID): Promise<App.Response<void>>;
  public abstract toggleHide(id: App.ID): Promise<App.Response<void>>;
  public abstract getUserAnnouncements(
    page: number,
    sort?: number,
    favorite?: boolean,
    session?: App.User.Session
  ): Promise<App.Response<App.ListMetadata<App.Announcement.Announcement>>>;
  public abstract changePassword(
    data: ChangePasswordData,
    session?: App.User.Session
  ): Promise<App.Response<App.User.Session>>;
  public abstract sendMessageToAdministration(
    data: MessageForAdministration
  ): Promise<App.Response<void>>;
  public abstract getSearchHistory(): Promise<
    App.Response<Array<App.FiltersHistory>>
  >;
  public abstract getViewHistory(params: {
    page: number;
    sort: number;
    amount?: number;
  }): Promise<App.Response<App.ListMetadata<App.Announcement.Announcement>>>;
}
