import "./AdministrateAnnouncementsList.scss";

import React, { SyntheticEvent } from "react";

import AdministrateAnnouncementsListItem from "./administrateAnnouncementsListItem/AdministrateAnnouncementsListItem";

interface Props {
  announcemnets: Array<App.Announcement.Announcement>;
  isEnd: boolean;
  loading: boolean;

  loadMore: () => void;
  onChangeChecked: (id: App.ID, checked: boolean) => Promise<boolean>;
  onChangeSpec: (
    id: App.ID,
    spec: { from: Date | null; to: Date | null }
  ) => Promise<boolean>;
  onDelete: (id: App.ID) => Promise<boolean>;
}
interface State {}

class AdministrateAnnouncementsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  private handleWheel(event: SyntheticEvent) {
    const { bottom } = event.currentTarget.getBoundingClientRect();
    if (
      bottom - 100 < window.innerHeight &&
      !this.props.isEnd &&
      !this.props.loading
    ) {
      this.props.loadMore();
    }
  }

  public render() {
    return (
      <div
        className="administrate-announcements-list"
        onWheel={this.handleWheel.bind(this)}
      >
        {this.props.announcemnets.map((announcement) => (
          <AdministrateAnnouncementsListItem
            key={announcement.id}
            announcement={announcement}
            onChangeChecked={this.props.onChangeChecked}
            onChangeSpec={this.props.onChangeSpec}
            onDelete={this.props.onDelete}
          />
        ))}
      </div>
    );
  }
}
export default AdministrateAnnouncementsList;
