import AnnouncementStat from "../stat/AnnouncementStat";
import React from "react";

interface Props {
  transmission?: App.Car.Transmission | string;
}
interface State {}

class AnnouncementStatTransmission extends React.Component<Props, State> {
  public get value() {
    if (!this.props.transmission) {
      return "";
    } else if (typeof this.props.transmission === "string") {
      return this.props.transmission;
    } else {
      return this.props.transmission.name;
    }
  }
  public render() {
    return <AnnouncementStat name={"Коробка"} value={this.value} />;
  }
}

export default AnnouncementStatTransmission;
