import AdministrateAnnouncements from "../../components/administrateAnnouncements/AdministrateAnnouncements";
import AnnouncementService from "../../services/abstract/AnnouncementService";
import AnnouncementServiceApi from "../../services/api/AnnouncementServiceApi";
import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../struct/message/SMessage";
import Time from "../../utils/time/Time";
import { addMessage } from "../../store/actions/messages";
import store from "../../store/store";

interface Props {}
interface State {
  announcements: Array<App.Announcement.Announcement>;
  loading: boolean;
  keyword: string;
  sort: string;
  newCheck: number;
  newSpec: number;
  total: number;
  page: number;
  isEnd: boolean;
}

class AdministrateAnnouncementsContainer extends React.Component<Props, State> {
  private announcementService: AnnouncementService;
  private timeout: number;
  constructor(props: Props) {
    super(props);
    this.announcementService = new AnnouncementServiceApi();
    this.timeout = 0;
    this.state = {
      announcements: [],

      loading: false,
      keyword: "",
      sort: "",
      newCheck: 0,
      newSpec: 0,
      total: 0,
      page: 1,
      isEnd: false,
    };
  }

  private static announcementPerPage = 4;

  public componentDidMount() {
    this.getAnnouncements(this.state.page, this.state.keyword);
  }

  private handleChangeSort(sort: string) {
    this.setState({ sort });
    this.getAnnouncements(1, this.state.keyword);
  }
  private handleChangeKeyword(keyword: string) {
    this.setState({ keyword });
    window.clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
      this.getAnnouncements(1, this.state.keyword);
    }, Time.second);
  }

  private async handleChangeChecked(
    id: App.ID,
    checked: boolean
  ): Promise<boolean> {
    this.setState({
      loading: true,
    });
    const {
      status,
      errors,
    } = await this.announcementService.toggleCheckedFlag({ id, checked });
    if (status === OK) {
      this.setState({
        announcements: this.state.announcements.map((announcement) => {
          if (id === announcement.id) {
            announcement.checked = checked;
          }
          return announcement;
        }),
      });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
    this.setState({
      loading: false,
    });
    return status === OK;
  }
  private async handleChangeSpec(
    id: App.ID,
    specialOffer: { from: Date | null; to: Date | null }
  ): Promise<boolean> {
    this.setState({
      loading: true,
    });
    const { status, errors } = await this.announcementService.saveSpecOffer({
      id,
      specialOffer,
    });
    if (status === OK) {
      this.setState({
        announcements: this.state.announcements.map((announcement) => {
          if (id === announcement.id) {
            announcement.specialOffer = specialOffer;
          }
          return announcement;
        }),
      });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
    this.setState({
      loading: false,
    });
    return status === OK;
  }
  private async handleDelete(id: App.ID): Promise<boolean> {
    this.setState({
      loading: true,
    });
    const {
      status,
      errors,
    } = await this.announcementService.removeAnnouncement(id);
    if (status === OK) {
      this.setState({
        announcements: this.state.announcements
          .slice(
            0,
            this.state.announcements.length -
              AdministrateAnnouncementsContainer.announcementPerPage +
              1
          )
          .filter((annnouncement) => annnouncement.id !== id),
      });
      this.getAnnouncements(this.state.page, this.state.keyword);
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
    this.setState({
      loading: false,
    });
    return status === OK;
  }

  private loadMore() {
    this.getAnnouncements(this.state.page + 1, this.state.keyword);
  }

  private async getAnnouncements(page: number, keyword: string) {
    this.setState({ loading: true });
    const { data, status, errors } = await this.announcementService.search({
      page,
      keyword,
      amount: AdministrateAnnouncementsContainer.announcementPerPage,
      showAll: true,
    });
    if (status === OK) {
      this.setState({
        announcements:
          data.page === 1
            ? data.items
            : this.state.announcements.concat(data.items),
        total: data.total,
        page: data.page,
        isEnd: data.page >= data.totalPage,
      });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
    this.setState({ loading: false });
  }

  public render() {
    return (
      <AdministrateAnnouncements
        announcements={this.state.announcements}
        loading={this.state.loading}
        isEnd={this.state.isEnd}
        keyword={this.state.keyword}
        sort={this.state.sort}
        newCheck={this.state.newCheck}
        newSpec={this.state.newSpec}
        loadMore={this.loadMore.bind(this)}
        onChangeSort={this.handleChangeSort.bind(this)}
        onChangeKeyword={this.handleChangeKeyword.bind(this)}
        onChangeChecked={this.handleChangeChecked.bind(this)}
        onChangeSpec={this.handleChangeSpec.bind(this)}
        onDelete={this.handleDelete.bind(this)}
      />
    );
  }
}
export default AdministrateAnnouncementsContainer;
