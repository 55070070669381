import "./AnnouncementStat.scss";

import React from "react";
import StringMethods from "../../../../utils/string/StringMethods";

interface Props {
  name: string;
  value?: number | string;
  valueName?: string;
}
interface State {}

class AnnouncementStat extends React.Component<Props, State> {
  get value(): string {
    if (typeof this.props.value === "number") {
      return StringMethods.beautifyNumber(this.props.value);
      // } else if (typeof this.props.value === "boolean") {
      //   return this.props.value ? "Да" : "Нет";
    } else {
      return this.props.value || "";
    }
  }
  public render() {
    return (
      <div className="announcement-stat">
        <div className="name">{this.props.name}:</div>
        <div className="value">
          {this.value} {this.value ? this.props.valueName : ""}
        </div>
      </div>
    );
  }
}

export default AnnouncementStat;
