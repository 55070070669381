import "./ButtonPrice.scss";

import { BYN_ID, USD_ID } from "../../../Config";

import React from "react";
import StringMethods from "../../../utils/string/StringMethods";

interface Props {
  price: number;
  disabled?: boolean;
  currency?: App.ID;
}
interface State {}

class ButtonPrice extends React.Component<Props, State> {
  public get currency() {
    if (this.props.currency === BYN_ID) {
      return "BYN";
    } else if (this.props.currency === USD_ID) {
      return "USD";
    } else {
      return "";
    }
  }
  public render() {
    return (
      <button className="button-price" disabled={this.props.disabled}>
        <sub>
          {this.props.price === 0 && <span className="value">Цена</span>}
          {this.props.price > 0 && (
            <>
              <span className="value">
                {StringMethods.beautifyNumber(this.props.price)}
              </span>
              <span className="currency">{this.currency}</span>
            </>
          )}
        </sub>
      </button>
    );
  }
}

export default ButtonPrice;
