import AnnouncementStat from "../stat/AnnouncementStat";
import React from "react";

interface Props {
  color?: App.Car.Color | string;
}
interface State {}

class AnnouncementStatColor extends React.Component<Props, State> {
  public get value() {
    if (!this.props.color) {
      return "";
    } else if (typeof this.props.color === "string") {
      return this.props.color;
    } else {
      return this.props.color.name;
    }
  }
  public render() {
    return <AnnouncementStat name={"Цвет"} value={this.value} />;
  }
}

export default AnnouncementStatColor;
