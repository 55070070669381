import "./ColorPicker.scss";

import React from "react";

interface Props {
  colors: Array<App.Car.Color>;
  selected: App.ID;
  onChange: (id: App.ID | null, info?: App.Info) => void;
}
interface State {}

class ColorPicker extends React.Component<Props, State> {
  public render() {
    return (
      <div className="color-picker">
        <div className="name">Цвет</div>
        <div className="colors">
          {this.props.colors.map((color) => (
            <div
              className="color"
              key={color.id}
              title={color.name}
              style={{ backgroundColor: color.hex }}
              onClick={() =>
                this.props.onChange(
                  color.id === this.props.selected ? 0 : color.id,
                  color
                )
              }
            >
              {this.props.selected === color.id && (
                <div className="selected-mark"></div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ColorPicker;
