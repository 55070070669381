import "./LabelTextarea.scss";

import React, { SyntheticEvent } from "react";

interface Props {
  name: string;
  value: string;
  onChange: (value: string) => void;
}
interface State {}

class LabelTextarea extends React.Component<Props, State> {
  static defaultProps = {
    value: "",
    onChange() {},
  };
  handleChange(el: SyntheticEvent<HTMLTextAreaElement>) {
    this.props.onChange(el.currentTarget.value);
  }
  public render() {
    return (
      <div className="label-textarea">
        <div className="name">{this.props.name}</div>
        <textarea
          value={this.props.value}
          onChange={this.handleChange.bind(this)}
        ></textarea>
      </div>
    );
  }
}

export default LabelTextarea;
