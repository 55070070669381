export const home = "/";
export const announcements = "/announcements";
export const news = "/";
export const filters = "/advanced-filters";
export const auth = "/auth";
export const signin = auth + "/signin";
export const signup = auth + "/signup";
export const sell = "/sell";
export const user = "/user";
export const userAnnouncements = "/user/announcements";
export const userSearchHistory = "/user/search-history";
export const userViewHistory = "/user/view-history";
export const userSettings = "/user/settings";
export const compareCar = "/compare-car";
export const special = "/special";
export const adminPanel = "/admin-panel";
export const administrate = "/administrate";
export const administrateAnnouncements = "/administrate/announcements";
export const administrateUsers = "/administrate/users";
export const administrateSupport = "/administrate/support";
export const administrateStatistic = "/administrate/statistic";
export const confirmation = "/confirmation/:hash";
export const notFound = "/not-found";
export function announcement(id: number | string = ":id") {
  return `/announcement/${id}`;
}
export function redactAnnouncement(id: number | string = ":id") {
  return `/redact/announcement/${id}`;
}
