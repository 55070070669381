import CarService from "../../../../../services/abstract/CarService";
import CarServiceApi from "../../../../../services/api/CarServiceApi";
import { OK } from "http-status-codes";

interface FiltersForGetVersionOption {
  motorType?: string;
  carcass?: string;
  transmission?: string;
  drive?: string;
}

class CreateAnnouncementStepMain {
  private service: CarService;
  constructor() {
    this.service = new CarServiceApi();
  }

  public getYears(generations: Array<App.Car.Generation>) {
    const years: Set<number> = new Set();

    generations.forEach((g) => {
      let year = g.start;
      const end = g.end || new Date().getFullYear();
      do {
        years.add(year);
        year++;
      } while (year <= end);
    });

    return Array.from(years)
      .sort((a, b) => (a > b ? 1 : -1))
      .map((year) => ({
        id: year,
        name: "" + year,
      }));
  }
  public getMotorTypes(
    versions: Array<App.Car.Version>,
    filters: FiltersForGetVersionOption
  ) {
    const motorTypes: Set<string> = new Set();

    this.filterVersions(versions, filters).forEach((version) => {
      motorTypes.add(version.motor.type);
    });

    return Array.from(motorTypes).map((name, id) => ({ id: name, name }));
  }

  public getCarcasses(
    versions: Array<App.Car.Version>,
    filters: FiltersForGetVersionOption
  ) {
    const carcasses: Set<string> = new Set();

    this.filterVersions(versions, filters).forEach((version) => {
      carcasses.add(version.carcass.name);
    });

    return Array.from(carcasses).map((name, id) => ({ id: name, name }));
  }

  public getTransmissions(
    versions: Array<App.Car.Version>,
    filters: FiltersForGetVersionOption
  ) {
    const transmissions: Set<string> = new Set();

    this.filterVersions(versions, filters).forEach((version) => {
      transmissions.add(version.transmission.name);
    });

    return Array.from(transmissions).map((name, id) => ({ id: name, name }));
  }
  public getDrives(
    versions: Array<App.Car.Version>,
    filters: FiltersForGetVersionOption
  ) {
    const drives: Set<string> = new Set();

    this.filterVersions(versions, filters).forEach((version) => {
      drives.add(version.drive.name);
    });

    return Array.from(drives).map((name, id) => ({ id: name, name }));
  }
  public getCarVersions(
    versions: Array<App.Car.Version>,
    filters: FiltersForGetVersionOption
  ) {
    const carVersions: Set<App.Car.Version> = new Set();

    this.filterVersions(versions, filters).forEach((version) => {
      carVersions.add(version);
    });

    return Array.from(carVersions).map((version) => ({
      id: version.id,
      name: `${version.motor.volume} л. ${version.motor.power} л.с.`,
    }));
  }

  // REQUESTS
  public async getGeneration(
    modelId: number
  ): Promise<Array<App.Car.Generation>> {
    const { data, status } = await this.service.getGenerations(modelId);
    if (status === OK) {
      return data;
    }
    return [];
  }

  public async getVersions(
    generations: Array<App.Car.Generation>,
    year: number
  ): Promise<Array<App.Car.Version>> {
    let versions: Array<App.Car.Version> = [];
    generations = generations.filter(this.filterByYear(year));
    for (let generation of generations) {
      const { data, status } = await this.service.getVersions(+generation.id);
      if (status === OK) {
        versions = versions.concat(data);
      }
    }
    return versions;
  }

  // FILTERS

  private filterVersions(
    versions: Array<App.Car.Version>,
    filters: FiltersForGetVersionOption
  ) {
    return versions
      .filter(this.filterByCarcass(filters.carcass))
      .filter(this.filterByMotorType(filters.motorType))
      .filter(this.filterByTransmission(filters.transmission))
      .filter(this.filterByDrive(filters.drive));
  }

  private filterByYear(year: number) {
    return year
      ? (generation: App.Car.Generation) =>
          generation.start <= year &&
          (!generation.end || generation.end >= year)
      : () => true;
  }
  private filterByMotorType(motorType?: string) {
    return motorType
      ? (version: App.Car.Version) => version.motor.type === motorType
      : () => true;
  }
  private filterByCarcass(carcass?: string) {
    return carcass
      ? (version: App.Car.Version) => version.carcass.name === carcass
      : () => true;
  }
  private filterByTransmission(transmission?: string) {
    return transmission
      ? (version: App.Car.Version) => version.transmission.name === transmission
      : () => true;
  }
  private filterByDrive(drive?: string) {
    return drive
      ? (version: App.Car.Version) => version.drive.name === drive
      : () => true;
  }
}

export default CreateAnnouncementStepMain;
