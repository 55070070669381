import "./Select.scss";

import React, { SyntheticEvent } from "react";

import ArrowUpIcon from "../../../assets/icons/arrow-up.svg";
import SelectOption from "./option/Option";

type Props = Multiselect | Singleselect;
interface StandartProps {
  name?: string;
  placeholder: string;
  className: string;
  options: Array<App.Info>;
  disabled: boolean;
}
interface Multiselect extends StandartProps {
  multiselect: true;
  selected: Array<number | string>;
  onChange: (selected: Array<number | string>, option?: App.Info) => void;
}
interface Singleselect extends StandartProps {
  multiselect: false;
  selected: number | string | null;
  onChange: (selected: number | string | null, option?: App.Info) => void;
}

interface State {
  isOpen: boolean;
}

class Select extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.close = this.close.bind(this);
  }

  static defaultProps = {
    disabled: false,
    placeholder: "",
    className: "",
    onChange() {},
  };

  public componentDidMount() {
    window.addEventListener("click", this.close);
  }
  public componentWillUnmount() {
    window.removeEventListener("click", this.close);
  }

  private get label() {
    if (this.props.multiselect) {
      if (this.props.selected.length > 1) {
        return `Выбрано ${this.props.selected.length}`;
      } else if (this.props.selected.length === 1) {
        return this.findLabel(this.props.selected[0]);
      } else {
        return this.props.placeholder;
      }
    } else {
      return this.findLabel(this.props.selected);
    }
  }
  private findLabel(id?: number | string | null) {
    const option = this.props.options.find((option) => option.id === id);
    return option?.name || this.props.placeholder;
  }

  private open(event: SyntheticEvent) {
    if (this.props.disabled) {
      return;
    }
    setTimeout(() => this.setState({ isOpen: true }));
  }
  private close() {
    this.setState({ isOpen: false });
  }

  private isSelected(id: number | string) {
    if (this.props.multiselect) {
      return this.props.selected.includes(id);
    }
    return this.props.selected === id;
  }

  private togglePick(id: number | string, isSelect: boolean) {
    const option = this.props.options.find((e) => e.id === id);
    if (!option || this.props.disabled) {
      return;
    }
    if (this.props.multiselect) {
      if (isSelect) {
        this.props.onChange(
          this.props.selected.filter((e) => e !== id),
          option
        );
      } else {
        this.props.onChange(this.props.selected.concat(id), option);
      }
    } else {
      if (isSelect) {
        this.props.onChange(null, option);
      } else {
        this.props.onChange(id, option);
      }
    }
  }

  public render() {
    return (
      <div
        className={"select " + this.props.className}
        data-disabled={this.props.disabled}
      >
        {this.props.name && <div className="name">{this.props.name}</div>}
        <div
          className="label"
          data-disabled={this.props.disabled || false}
          data-open={this.state.isOpen}
          onClick={this.open.bind(this)}
        >
          <span>{this.label}</span>
          <img src={ArrowUpIcon} alt="" />
        </div>
        {this.state.isOpen && (
          <div className="options">
            {this.props.options.map((option) => (
              <SelectOption
                multiselect={this.props.multiselect}
                option={option}
                key={option.id}
                selected={this.isSelected(option.id)}
                onClick={this.togglePick.bind(this)}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default Select;
