import AnnouncementListContainer from "../../announcement/list/AnnouncementListContainer";
import React from "react";
import UserService from "../../../services/abstract/UserService";
import UserServiceApi from "../../../services/api/UserServiceApi";

interface Props {}
interface State {
  page: number;
  sort: number;
}

class UserViewHistoryContainer extends React.Component<Props, State> {
  private service: UserService;
  constructor(props: Props) {
    super(props);
    this.service = new UserServiceApi();
    this.state = {
      page: 1,
      sort: 2,
    };
  }

  public componentDidMount() {
    this.getHistory();
  }

  private handleChangePage(page: number) {
    this.setState({ page });
  }
  private handleChangeSort(sort: number) {
    this.setState({ sort, page: 1 });
  }

  private async getHistory() {
    return this.service.getViewHistory({
      page: this.state.page,
      sort: this.state.sort,
    });
  }
  public render() {
    return (
      <AnnouncementListContainer
        className="space"
        search={this.getHistory.bind(this)}
        page={this.state.page}
        sort={this.state.sort}
        onChangePage={this.handleChangePage.bind(this)}
        onChangeSort={this.handleChangeSort.bind(this)}
      />
    );
  }
}

export default UserViewHistoryContainer;
