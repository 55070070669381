import "./Auth.scss";

import { Route, Switch } from "react-router";
import { signin, signup } from "../../routes";

import React from "react";
import SigninContainer from "../../containers/auth/signin/SigninContainer";
import SignupContainer from "../../containers/auth/signup/SignupContainer";

interface Props {}
interface State {}

class AuthView extends React.Component<Props, State> {
  public componentDidMount() {
    document.title = "Авторизация";
    window.scrollTo({ top: 0 });
  }
  public render() {
    return (
      <div className="auth-view">
        <Switch>
          <Route path={signin} component={SigninContainer} />
          <Route path={signup} component={SignupContainer} />
        </Switch>
      </div>
    );
  }
}

export default AuthView;
