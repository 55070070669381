export const PAGE_URL = "https://devserv1.sprobegom.by";
export const BASE_URL = "https://devserv1.sprobegom.by/server";
export const PROD = false;
export const AMOUNT_FOR_ANNOUNCEMENTS = 9;
export const AMOUNT_FOR_COMPARISON = 5;

export const BRAND_FILTER_ID = 1;
export const MODEL_FILTER_ID = 2;
export const YEAR_FILTER_ID = 4;
export const CARCASS_FILTER_ID = 5;
export const TRANSMISSION_FILTER_ID = 6;
export const MOTOR_TYPE_FILTER_ID = 7;
export const DRIVE_FILTER_ID = 8;
export const DISTANCE_FILTER_ID = 10;
export const MOTOR_VOLUME_FILTER_ID = 11;
export const PRICE_FILTER_ID = 12;
export const MOTOR_POWER_FILTER_ID = 13;

export const AnnouncementTypeFastOptions: Array<App.Announcement.AnnouncementTypeOption> = [
  { type: "good", text: "Время создания — 5-10 мин" },
  { type: "normal", text: "Неполная информация об автомобиле" },
  { type: "normal", text: "Низкая вероятность продажи" },
];
export const AnnouncementTypeFullOptions: Array<App.Announcement.AnnouncementTypeOption> = [
  { type: "normal", text: "Время создания — 10-15 мин" },
  { type: "good", text: "Вся информация об автомобиле" },
  { type: "good", text: "Высокая вероятность продажи" },
];

export const PRICE_MIN = 0;
export const PRICE_STEP = 100;
export const PRICE_MAX = 1000000;

export const VOLUME_MIN = 0;
export const VOLUME_STEP = 0.1;
export const VOLUME_MAX = 10;

export const DISTANCE_MIN = 0;
export const DISTANCE_STEP = 1000;
export const DISTANCE_MAX = 10000000;

export const YEAR_MIN = 1900;
export const YEAR_STEP = 1;
export const YEAR_MAX = +new Date().getFullYear();

export function DEFAULT_ANNOUNCEMENT(): App.Announcement.Announcement {
  // @ts-ignore
  return {
    id: 0,
    images: [],
    currency: BYN_ID,
    USD: 0,
    BYN: 0,
    carOptionsData: [],
    car: {
      brand: { id: 0, name: "Марка" },
      model: { id: 0, name: "Модель" },
      generation: { id: 0, name: "I" },
      carcass: { id: 0, name: "", doors_number: 0, img: "" },
      transmission: { id: 0, name: "", gearsNumber: 0 },
      motor: { id: 0, type: "", volume: 0, power: 0, typeId: 0 },
      drive: { id: 0, name: "" },
      color: { id: 0, name: "", hex: "rgba(0,0,0,0)" },
      version: 0,
      vin: "",
    },
    price: 0,
    year: 0,
    distance: 0,
    description: "",
    state: "",
    options: [
      { category: "Обзор", options: [] },
      { category: "Салон", options: [] },
      {
        category: "Мультимедия",
        options: [],
      },
      {
        category: "Безопасность",
        options: [],
      },
      { category: "Комфорт", options: [] },
      { category: "Прочее", options: [] },
    ],
    spec: false,
    checked: false,
    swap: false,
    contact: {
      id: 1,
      name: "Имя",
      city: "Город",
      phone: "Телефон",
      email: "email",
    },
    favorite: false,
    created: 0,
    last_update: 0,
  };
}

export const ANNOUNCEMENT_FILTER_CATEGORIES = {
  main: { name: "Основные", step: 1 },
  photo: { name: "Фото", step: 2 },
  visibility: { id: 6, name: "Обзор", step: 3 },
  salon: { id: 10, name: "Салон", step: 4 },
  multimedia: { id: 9, name: "Мультимедия", step: 5 },
  safety: { id: 12, name: "Безопасность", step: 6 },
  comfort: { id: 11, name: "Комфорт", step: 7 },
  other: { id: 13, name: "Прочее", step: 8 },
  contact: { name: "Контакт", step: 9 },
};

export function DEFAULT_STEP_DATA() {
  return {
    checkboxList: [],
    multiselectList: [],
    selectList: [],
    numberList: [],
  };
}

export const HOME_ANNOUNCEMENT_LIST_ID = "home-announcement-list";
export const HOME_ANNOUNCEMENT_LIST_CLASS_NAME = "home-announcement-list";
export const ANNOUNCEMENT_PAGE_ANNOUNCEMENT_LIST_ID =
  "announcement-page-announcement-list";
export const ANNOUNCEMENT_PAGE_ANNOUNCEMENT_LIST_CLASS_NAME =
  "announcement-page-announcement-list";
export const ADVANCED_PAGE_ANNOUNCEMENT_LIST_ID = "advanced-announcement-list";
export const ADVANCED_PAGE_ANNOUNCEMENT_LIST_CLASS_NAME =
  "advanced-announcement-list";

export const CONTACT_PHONES = ["445090598"];
export const FATAL_ERROR = "Критическая ошибка.";
export const FATAL_ERROR_SERVER = "Критическая ошибка сервера.";
export const FILTER_MAP = [
  { id: 1, category: "main" },
  { id: 2, category: "main" },
  { id: 3, category: "main" },
  { id: 4, category: "other" },
  { id: 5, category: "main" },
  { id: 6, category: "visibility" },
  { id: 7, category: "comfort" },
  { id: 8, category: "safety" },
  { id: 9, category: "multimedia" },
  { id: 10, category: "salon" },
  { id: 11, category: "comfort" },
  { id: 12, category: "safety" },
  { id: 13, category: "other" },
];

export const BYN_ID = 27;
export const USD_ID = 5;
export const BANNED_FILTERS = [17, 19, 20, 21, 22, 23, 52];
