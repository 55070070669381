import UserService, {
  ChangePasswordData,
} from "../../../services/abstract/UserService";

import ChangePasswordForm from "../../../components/user/changePasswordForm/ChangePasswordForm";
import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../struct/message/SMessage";
import UserServiceApi from "../../../services/api/UserServiceApi";
import { addMessage } from "../../../store/actions/messages";
import store from "../../../store/store";

interface Props {}
interface State {
  loading: boolean;
  value: ChangePasswordData;
}

class ChangePasswordContainer extends React.Component<Props, State> {
  private service: UserService;
  constructor(props: Props) {
    super(props);
    this.service = new UserServiceApi();
    this.state = {
      loading: false,
      value: {
        oldPassword: "",
        newPassword: "",
        comparisonNewPassword: "",
      },
    };
  }
  private handleChange(value: ChangePasswordData) {
    this.setState({ value });
  }
  private async handleSubmit() {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const { status, errors } = await this.service.changePassword(
      this.state.value
    );
    this.setState({ loading: false });
    if (status === OK) {
      this.setState({
        value: { oldPassword: "", newPassword: "", comparisonNewPassword: "" },
      });
      store.dispatch(
        addMessage(new SMessage("Пароль успешно изменён", "notification"))
      );
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }
  public render() {
    return (
      <ChangePasswordForm
        value={this.state.value}
        loading={this.state.loading}
        onChange={this.handleChange.bind(this)}
        onSubmit={this.handleSubmit.bind(this)}
      />
    );
  }
}

export default ChangePasswordContainer;
