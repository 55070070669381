class AnnouncementListTagsController {
  public getTags(
    selected: Array<App.AdvancedFilter.SelectedFilterValue>,
    filters: Array<App.Announcement.FilterApi>,
    update?: (selected: Array<App.AdvancedFilter.SelectedFilterValue>) => void
  ): Array<App.Tag> {
    const tags: Array<App.Tag> = [];
    selected.forEach((selectedFilter) => {
      switch (selectedFilter.type) {
        case "checkbox": {
          const tag = this.getTagForCheckbox(
            selectedFilter,
            selected,
            filters,
            update
          );
          tag && tags.push(tag);
          break;
        }

        case "range": {
          const { from, to } = this.getTagForRange(
            selectedFilter,
            selected,
            filters,
            update
          );
          from && tags.push(from);
          to && tags.push(to);

          break;
        }

        case "picker": {
          this.getTagForPicker(
            selectedFilter,
            selected,
            filters,
            update
          ).forEach((tag) => {
            tags.push(tag);
          });

          break;
        }
      }
    });
    return tags;
  }

  private getTagForCheckbox(
    selectedFilter: App.AdvancedFilter.SelectedCheckbox,
    selected: Array<App.AdvancedFilter.SelectedFilterValue>,
    filters: Array<App.Announcement.FilterApi>,
    update?: (selected: Array<App.AdvancedFilter.SelectedFilterValue>) => void
  ): App.Tag | void {
    const filter: App.Announcement.FilterApi | void = filters.find(
      (f) => f.id === selectedFilter.id
    );
    if (filter) {
      const tag: App.Tag = {
        name: filter.name,
      };
      if (update) {
        tag.remove = () =>
          update(selected.filter((f) => f.id !== selectedFilter.id));
      }
      return tag;
    }
  }

  private getTagForRange(
    selectedFilter: App.AdvancedFilter.SelectedRange,
    selected: Array<App.AdvancedFilter.SelectedFilterValue>,
    filters: Array<App.Announcement.FilterApi>,
    update?: (selected: Array<App.AdvancedFilter.SelectedFilterValue>) => void
  ): { from: App.Tag | null; to: App.Tag | null } {
    const filter = filters.find((f) => f.id === selectedFilter.id);

    let from: null | App.Tag = null;
    let to: null | App.Tag = null;

    if (filter?.type === "range") {
      if (selectedFilter.value[0] !== filter.min) {
        from = {
          name: `от ${selectedFilter.value[0]}`,
        };
        if (update) {
          from.remove = () =>
            update(
              selected.map((f) => {
                if (f.id !== selectedFilter.id || f.type !== "range") return f;
                f.value[0] = filter.min;
                return f;
              })
            );
        }
      }
      if (selectedFilter.value[1] !== filter.max) {
        to = {
          name: `до ${selectedFilter.value[1]}`,
        };

        if (update) {
          to.remove = () =>
            update(
              selected.map((f) => {
                if (f.id !== selectedFilter.id || f.type !== "range") return f;
                f.value[1] = filter.max;
                return f;
              })
            );
        }
      }
    }

    return { from, to };
  }

  private getTagForPicker(
    selectedFilter: App.AdvancedFilter.SelectedArray,
    selected: Array<App.AdvancedFilter.SelectedFilterValue>,
    filters: Array<App.Announcement.FilterApi>,
    update?: (selected: Array<App.AdvancedFilter.SelectedFilterValue>) => void
  ): Array<App.Tag> {
    const tags: Array<App.Tag> = [];
    const filter = filters.find((f) => f.id === selectedFilter.id);
    if (filter?.type !== "multiselect") return tags;
    const options = filter.options.filter((o) =>
      selectedFilter.value.includes(o.id)
    );

    options.forEach((option) => {
      const tag: App.Tag = {
        name: option.name,
      };

      if (update) {
        tag.remove = () => {
          update(
            selected.map((f) => {
              if (f.id !== selectedFilter.id || f.type !== "picker") return f;
              f.value = f.value.filter((fo) => fo !== option.id);
              return f;
            })
          );
        };
      }
      tags.push(tag);
    });

    return tags;
  }
}

export default AnnouncementListTagsController;
