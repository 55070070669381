import "./Home.scss";

import CheckedAnnouncementsList from "../../containers/announcement/results/checked/CheckedAnnouncementsList";
import HomeAnnouncementsList from "../../containers/announcement/results/home/HomeAnnouncementsList";
import HomeHead from "./head/HomeHead";
import NewAnnouncementsList from "../../containers/announcement/results/new/NewAnnouncementsList";
import React from "react";

interface Props {}
interface State {}

class HomeView extends React.Component<Props, State> {
  public componentDidMount() {
    document.title = "Главная";
    window.scrollTo({ top: 0 });
  }
  public render() {
    return (
      <div className="home">
        <HomeHead />
        <NewAnnouncementsList />
        <CheckedAnnouncementsList />
        <HomeAnnouncementsList />
      </div>
    );
  }
}

export default HomeView;
