import "./AnnouncementBuyInfo.scss";

import { BYN_ID, USD_ID } from "../../../Config";

import AnnouncementContact from "../contact/Contact";
import AnnouncementName from "../../car/name/AnnouncementName";
import PriceDisplay from "../../price/display/PriceDisplay";
import React from "react";

interface Props {
  announcement?: App.Announcement.Announcement;
  phoneNumber?: string;
  getPhoneNumber: () => void;
}
interface State {}

class AnnouncementBuyInfo extends React.Component<Props, State> {
  public render() {
    return (
      <div className={"announcement-buy-info"}>
        <AnnouncementName announcement={this.props.announcement} />
        <PriceDisplay
          price={this.props.announcement?.BYN || 0}
          currency={BYN_ID}
        />
        <PriceDisplay
          className="additional"
          currency={USD_ID}
          price={this.props.announcement?.USD || 0}
        />
        <AnnouncementContact
          contact={this.props.announcement?.contact}
          phoneNumber={this.props.phoneNumber}
          getPhoneNumber={this.props.getPhoneNumber}
        />
      </div>
    );
  }
}

export default AnnouncementBuyInfo;
