type CHANGE_FILTERS_ANNOUNCEMENTS = "CHANGE_FILTERS_ANNOUNCEMENTS";
type RESEARCH_ANNOUNCEMENTS = "RESEARCH_ANNOUNCEMENTS";

interface ChangeFiltersAction {
  type: CHANGE_FILTERS_ANNOUNCEMENTS;
  filters: App.Announcement.FiltersForSearch;
}
interface ResearchAction {
  type: RESEARCH_ANNOUNCEMENTS;
}

export type AnnouncementsActions = ChangeFiltersAction | ResearchAction;

export const changeFiltersAnnouncementsType: CHANGE_FILTERS_ANNOUNCEMENTS =
  "CHANGE_FILTERS_ANNOUNCEMENTS";
export const researchAnnouncementsType: RESEARCH_ANNOUNCEMENTS =
  "RESEARCH_ANNOUNCEMENTS";

export function changeFilterAnnouncements(
  filters: App.Announcement.FiltersForSearch
): ChangeFiltersAction {
  return {
    type: changeFiltersAnnouncementsType,
    filters,
  };
}
export function researchAnnouncements(): ResearchAction {
  return {
    type: researchAnnouncementsType,
  };
}
