import "./AdministrateAnnouncementsListItemStats.scss";

import React from "react";
import Time from "../../../../../utils/time/Time";

interface Props {
  announcement: App.Announcement.Announcement;
}
interface State {}

class AdministrateAnnouncementsListItemStats extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="administrate-announcements-list-item-stats">
        <div className="stat">
          <span>ID:</span>
          <span>{this.props.announcement.id}</span>
        </div>
        <div className="stat">
          <span>Просмотров:</span>
          <span>{this.props.announcement.viewsTotal}</span>
        </div>
        <div className="stat">
          <span>Создано:</span>
          <span>{Time.beautifyDateTime(this.props.announcement.created)}</span>
        </div>
      </div>
    );
  }
}
export default AdministrateAnnouncementsListItemStats;
