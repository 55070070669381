import AdminPanelContainer from "../../containers/adminPanel/AdminPanelContainer";
import React from "react";
import { RouteComponentProps } from "react-router";
import { signin } from "../../routes";

interface Props extends RouteComponentProps {}
interface State {}

class AdminPanelView extends React.Component<Props, State> {
  public componentDidMount() {
    document.title = "Админ-панель";
    window.scrollTo({ top: 0 });
  }
  private goToSignin() {
    this.props.history.push(signin);
  }
  public render() {
    return (
      <div className="admin-panel">
        <AdminPanelContainer goToSignin={this.goToSignin.bind(this)} />
      </div>
    );
  }
}

export default AdminPanelView;
