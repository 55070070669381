import { announcement, signin } from "../../routes";

import CreateAnnouncementProgress from "../../components/announcement/create/progress/Progress";
import React from "react";
import { RouteComponentProps } from "react-router";
import Updated from "../../components/announcement/create/popups/update/Updated";

interface Props extends RouteComponentProps<{ id: string }> {}
interface State {
  type: App.CreateAnnouncement.Type | null;
  id: App.ID;
  complited: boolean;
}

class RedactAnnouncementView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      type: null,
      id: 0,
      complited: false,
    };
  }
  public componentDidMount() {
    document.title = `Редактирование объявления №${this.props.match.params.id}`;
    window.scrollTo({ top: 0 });
  }

  private complite(id: App.ID) {
    this.setState({ complited: true, id });
  }
  private toAnnouncement() {
    this.props.history.push(announcement(this.props.match.params.id));
  }
  private toSignin() {
    this.props.history.push(signin);
  }

  public render() {
    return (
      <div className="redact-announcement">
        <CreateAnnouncementProgress
          type="full"
          toSelectType={() => {}}
          toSignin={this.toSignin.bind(this)}
          complite={this.complite.bind(this)}
          redact={this.props.match.params.id}
        />
        {this.state.complited && (
          <Updated
            announcementId={this.props.match.params.id}
            onClose={this.toAnnouncement.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default RedactAnnouncementView;
