import "./SpecialView.scss";

import React from "react";
import SpecAnnouncementsList from "../../containers/announcement/results/special/SpecialAnnouncementsList";

interface Props {}
interface State {}

class SpecialView extends React.Component<Props, State> {
  public componentDidMount() {
    document.title = "Спецпредложения";
    window.scrollTo({ top: 0 });
  }
  public render() {
    return (
      <div className="special-view">
        <SpecAnnouncementsList />
      </div>
    );
  }
}

export default SpecialView;
