import AnnouncementOption from "./option/AnnouncementOption";
import React from "react";

interface Props {
  options: Array<App.Announcement.Option>;
}
interface State {}

class AnnouncementOptions extends React.Component<Props, State> {
  public render() {
    return (
      <>
        {this.props.options.length > 0 && (
          <div className="announcement-options">
            {this.props.options.map((option) => (
              <AnnouncementOption key={option.category} {...option} />
            ))}
          </div>
        )}
      </>
    );
  }
}

export default AnnouncementOptions;
