import React from "react";
import { Unsubscribe } from "redux";
import UserNavMenu from "../../../components/user/navMenu/UserNavMenu";
import { logout } from "../../../store/actions/user";
import store from "../../../store/store";

interface Props {
  goToHome: () => void;
}
interface State {
  user: App.User.User;
}

class UserMenuContainer extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      user: this.getUser(),
    };
  }

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
  }
  public componentWillUnmount() {
    this.unsubscribe();
  }
  private logout() {
    store.dispatch(logout());
    this.props.goToHome();
  }

  private handleUpdateStore() {
    this.setState({ user: this.getUser() });
  }

  private getUser(): App.User.User {
    const user = store.getState().user;
    return {
      id: 1,
      name: user.name,
      contacts: [],
      role: user.role,
      email: user.email,
    };
  }

  public render() {
    return (
      <UserNavMenu logout={this.logout.bind(this)} user={this.state.user} />
    );
  }
}

export default UserMenuContainer;
