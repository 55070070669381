import ConfirmationContainer from "../../containers/confirmation/ConfirmationContainer";
import React from "react";
import { RouteComponentProps } from "react-router";
import { signin } from "../../routes";

interface Props extends RouteComponentProps<{ hash: string }> {}
interface State {}

class ConfirmationView extends React.Component<Props, State> {
  public componentDidMount() {
    document.title = "Подтверждение почты";
    window.scrollTo({ top: 0 });
  }
  private toSignin() {
    this.props.history.push(signin);
  }
  public render() {
    return (
      <div className="confirmation">
        <ConfirmationContainer
          toSignin={this.toSignin.bind(this)}
          hash={this.props.match.params.hash}
        />
      </div>
    );
  }
}

export default ConfirmationView;
