import CreateAnnouncementVinError from "../../../components/announcement/create/popups/vinError/VinError";
import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../struct/message/SMessage";
import UserService from "../../../services/abstract/UserService";
import UserServiceApi from "../../../services/api/UserServiceApi";
import { addMessage } from "../../../store/actions/messages";
import store from "../../../store/store";

interface Props {
  vin: string;
  closePopup: () => void;
}
interface State {
  loading: boolean;
}

class VinErrorContainer extends React.Component<Props, State> {
  private service: UserService;
  constructor(props: Props) {
    super(props);
    this.service = new UserServiceApi();
    this.state = {
      loading: false,
    };
  }
  private async handleBook(name: string, phone_number: string) {
    this.setState({ loading: true });
    const { status, errors } = await this.service.sendMessageToAdministration({
      text: `Vin занят ${this.props.vin}`,
      phone_number,
      name,
    });
    if (status === OK) {
      this.props.closePopup();
      store.dispatch(
        addMessage(
          new SMessage("Ваша заявка добавлена в обработку", "notification")
        )
      );
    } else {
      errors.forEach((error) => {
        store.dispatch(addMessage(new SMessage(error)));
      });
    }
    this.setState({ loading: false });
  }

  public render() {
    return (
      <CreateAnnouncementVinError
        onBook={this.handleBook.bind(this)}
        onClose={this.props.closePopup}
        loading={this.state.loading}
      />
    );
  }
}

export default VinErrorContainer;
