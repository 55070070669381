import "./SocialNetworkLink.scss";

import React from "react";

interface Props {
  href: string;
  image: string;
}
interface State {}

class SocialNetworkLink extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <a
        className="social-network-link"
        href={this.props.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={this.props.image} alt="" />
      </a>
    );
  }
}
export default SocialNetworkLink;
