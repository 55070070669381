import "./CarFilter.scss";

import { loadBrands, loadModels } from "../../../store/actions/car";

import React from "react";
import Select from "../../../components/custom/select/Select";
import { Unsubscribe } from "redux";
import store from "../../../store/store";

interface Props {
  add?: () => void;
  remove?: () => void;
  car: App.AdvancedFilter.CarFilter;
  onChange: (car: App.AdvancedFilter.CarFilter) => void;
}
interface State {
  brands: Array<App.Info>;
  models: Array<App.Info>;
}

class CarFilter extends React.Component<Props, State> {
  private unsubscribe: Unsubscribe;
  constructor(props: Props) {
    super(props);
    this.unsubscribe = () => {};
    this.state = {
      brands: [],
      models: [],
    };
  }

  public componentDidMount() {
    this.unsubscribe = store.subscribe(this.handleUpdateStore.bind(this));
    this.handleUpdateStore();
  }

  public componentWillUnmount() {
    this.unsubscribe();
  }

  private handleUpdateStore() {
    const { car } = store.getState();
    if (!car.brands) {
      store.dispatch(loadBrands());
    }

    this.setState({
      brands: car.brands || [],
      models: this.props.car.brand ? car.models[+this.props.car.brand] : [],
    });
  }

  private handleChangeBrands(brand: App.ID | null) {
    this.props.onChange({ brand });
    if (brand) {
      store.dispatch(loadModels(+brand));
    } else {
      this.setState({ models: [] });
    }
  }
  private handleChangeModels(model: App.ID | null) {
    this.props.onChange({ brand: this.props.car.brand, model });
  }

  public render() {
    return (
      <div className="car-filter">
        <div className="brand">
          <Select
            multiselect={false}
            name="Марка"
            options={this.state.brands}
            selected={this.props.car.brand || null}
            onChange={this.handleChangeBrands.bind(this)}
          />
          <div className="first-actions">
            {this.props.add && (
              <button onClick={this.props.add} className="button-action">
                +
              </button>
            )}
            {this.props.remove && (
              <button onClick={this.props.remove} className="button-action">
                -
              </button>
            )}
          </div>
        </div>
        <div className="model">
          <Select
            disabled={!this.props.car.brand}
            name="Модель"
            multiselect={false}
            options={this.state.models || []}
            selected={this.props.car.model || null}
            onChange={this.handleChangeModels.bind(this)}
          />
        </div>

        <div className="second-actions">
          <button onClick={this.props.add}>
            <span className="mark">+</span>
            <span>Добавить модель</span>
          </button>
          {this.props.remove && (
            <button onClick={this.props.remove}>
              <span className="mark">-</span>
              <span>Удалить модель</span>
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default CarFilter;
