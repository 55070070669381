import "./Stats.scss";

import React from "react";

interface Props {
  announcement: App.Announcement.Announcement;
}
interface State {}

class CarStats extends React.Component<Props, State> {
  public render() {
    return (
      <div className="announcement-stats">
        <div className="stat">
          <div className="name">Год выпуска</div>
          <div className="value">{this.props.announcement.year}</div>
        </div>
        <div className="stat">
          <div className="name">Кузов</div>
          <div className="value">
            {this.props.announcement.car.carcass.name}
          </div>
        </div>
        <div className="stat">
          <div className="name">Коробка</div>
          <div className="value">
            {this.props.announcement.car.transmission.name}
          </div>
        </div>
        <div className="stat">
          <div className="name">Тип двигателя</div>
          <div className="value">{this.props.announcement.car.motor.type}</div>
        </div>
        <div className="stat">
          <div className="name">Объем двигателя</div>
          <div className="value">
            {this.props.announcement.car.motor.volume} л.
          </div>
        </div>
        <div className="stat">
          <div className="name">Привод</div>
          <div className="value">{this.props.announcement.car.drive.name}</div>
        </div>
      </div>
    );
  }
}

export default CarStats;
