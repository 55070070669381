import "./AnnouncementsFilters.scss";

import AnnouncementFiltersButtons from "./buttons/AnnouncementFiltersButtons";
import CarFilters from "../../../containers/filters/carFilters/CarFilters";
import Filter from "../../../utils/filter/Filter";
import React from "react";
import SubTitle from "../../../components/custom/subtitle/SubTitle";

interface Props {
  car: Array<App.AdvancedFilter.CarFilter>;
  values: Array<App.AdvancedFilter.SelectedFilterValue>;
  filters: Array<App.Announcement.FilterApi>;

  onChange: (id: App.ID, value: App.AdvancedFilter.SelectedFilterValue) => void;
  onChangeCarFilter: (filters: Array<App.AdvancedFilter.CarFilter>) => void;
  onSearch: () => void;
}
interface State {
  selected: Array<App.AdvancedFilter.SelectedFilterValue>;
}

class AnnouncementsFilters extends React.Component<Props, State> {
  private filter: Filter;
  constructor(props: Props) {
    super(props);
    this.filter = new Filter(this);
    this.state = {
      selected: props.values,
    };
  }

  public componentDidUpdate(props: Props) {
    if (this.props.values === props.values) return;
    this.setState({ selected: this.props.values });
  }

  public handleChange(value: any) {
    this.props.onChange(value.id, value);
  }

  public render() {
    return (
      <div className="announcements-filters">
        <SubTitle>Фильтр</SubTitle>

        <div className="filters">
          <CarFilters
            car={this.props.car}
            onChange={this.props.onChangeCarFilter}
          />
          {this.props.filters.map(this.filter.getFilter.bind(this.filter))}
        </div>
        <AnnouncementFiltersButtons onSearch={this.props.onSearch.bind(this)} />
      </div>
    );
  }
}

export default AnnouncementsFilters;
