import CarService, {
  MotorData,
} from "../../../../services/abstract/CarService";

import CarServiceApi from "../../../../services/api/CarServiceApi";
import MotorPicker from "../../../../components/adminPanel/redactVersion/motorPicker/MotorPicker";
import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../../struct/message/SMessage";
import { addMessage } from "../../../../store/actions/messages";
import store from "../../../../store/store";

interface Props {
  motor: App.ID;
  onChange: (motor: App.ID) => void;
}
interface State {
  motor?: App.Car.Motor;
  motors: Array<App.Car.Motor>;
  types: Array<App.Info>;
}

class MotorPickerContainer extends React.Component<Props, State> {
  private service: CarService;
  constructor(props: Props) {
    super(props);
    this.service = new CarServiceApi();
    this.state = {
      motors: [],
      types: [],
    };
  }
  public componentDidMount() {
    this.getMotorOptions();
  }
  public componentDidUpdate(props: Props) {
    if (this.props.motor !== props.motor) {
      if (this.props.motor) {
        this.getMotor(this.props.motor);
      } else {
        this.setState({ motor: undefined });
      }
    }
  }

  private async getMotorOptions() {
    const { data, status, errors } = await this.service.getMotorOptions();
    if (status === OK) {
      this.setState({
        types: Array.isArray(data.car_motor_type_id)
          ? data.car_motor_type_id
          : [],
      });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }

  private async searchMotor(options: MotorData) {
    const { data, status, errors } = await this.service.searchMotor(options);
    if (status === OK) {
      const motor = data.find((e) => e.typeId === options.type);
      if (!motor) {
        store.dispatch(
          addMessage(
            new SMessage(
              "Двигатель не существует. Добавляем двигатель в базу.",
              "warning"
            )
          )
        );
        this.addMotor(options);
      } else {
        this.setState({ motor });
        this.props.onChange(motor.id);
      }
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }
  private async addMotor(options: MotorData) {
    const { data, status, errors } = await this.service.addMotor(options);
    if (status === OK) {
      this.setState({ motor: data });
      this.props.onChange(data.id);
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }
  private async getMotor(id: App.ID) {
    if (!id) return;
    const { data, status, errors } = await this.service.getMotor(id);
    if (status === OK) {
      this.setState({ motor: data });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }

  public render() {
    return (
      <MotorPicker
        motor={this.state.motor}
        types={this.state.types}
        onSearch={this.searchMotor.bind(this)}
      />
    );
  }
}

export default MotorPickerContainer;
