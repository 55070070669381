import "./AnnouncementViewBodyInfoContact.scss";

import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../../../struct/message/SMessage";
import StringMethods from "../../../../../utils/string/StringMethods";
import UserService from "../../../../../services/abstract/UserService";
import UserServiceApi from "../../../../../services/api/UserServiceApi";
import { addMessage } from "../../../../../store/actions/messages";
import store from "../../../../../store/store";

interface Props {
  announcement: App.Announcement.Announcement;
}
interface State {
  phoneNumber: string;
  loading: boolean;
}

class AnnouncementViewBodyInfoContact extends React.Component<Props, State> {
  private service: UserService;
  constructor(props: Props) {
    super(props);
    this.service = new UserServiceApi();
    this.state = {
      phoneNumber: "",
      loading: false,
    };
  }
  private async getPhoneNumber() {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const { id } = this.props.announcement.contact;
    const { data, status } = await this.service.getPhoneNumberForContact(
      this.props.announcement.contact.id
    );
    if (status === OK) {
      this.setState({
        loading: false,
        phoneNumber:
          id === this.props.announcement.contact.id ? data.toString() : "",
      });
    } else {
      store.dispatch(addMessage(new SMessage("Не удалось загрузить номер.")));
      this.setState({ loading: false });
    }
  }
  public render() {
    return (
      <div className="announcement-view-body-info-contact">
        <div className="name">{this.props.announcement.contact.name}</div>
        <div className="city">{this.props.announcement.contact.city}</div>
        {this.state.phoneNumber ? (
          <a href={`tel:375${this.state.phoneNumber}`} className="call-link">
            {StringMethods.beautifyPhoneNumber(this.state.phoneNumber)}
          </a>
        ) : (
          <div
            className="show-phone"
            onClick={
              this.props.announcement.contact.id
                ? this.getPhoneNumber.bind(this)
                : () => {}
            }
            data-empty={!this.props.announcement.contact.id}
          >
            {this.props.announcement.contact.id
              ? "Показать номер"
              : "Нет номера"}
          </div>
        )}
      </div>
    );
  }
}

export default AnnouncementViewBodyInfoContact;
