import "./AnnouncementListHeader.scss";

import AnnouncementSort from "./sort/Sort";
import CurrencyPickerContainer from "../../../../containers/currency/picker/CurrencyPickerContainer";
import DisplayType from "./displayTypes/DisplayTypes";
import React from "react";

interface Props {
  displayType: App.DisplayType;
  onChangeDisplayType: (type: App.DisplayType) => void;

  sort: number;
  onChangeSort: (sort: App.ID) => void;

  currency: App.ID;
  onChangeCurrency: (currency: App.ID) => void;
}
interface State {}

class AnnouncementListHeader extends React.Component<Props, State> {
  public render() {
    return (
      <div className="announcement-list__header">
        <AnnouncementSort
          sort={this.props.sort}
          onChange={this.props.onChangeSort}
        />

        <div className="display">
          <DisplayType
            active={this.props.displayType}
            onClick={this.props.onChangeDisplayType}
          />

          <CurrencyPickerContainer
            selected={this.props.currency}
            onChange={this.props.onChangeCurrency.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default AnnouncementListHeader;
