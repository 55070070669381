import React, { SyntheticEvent } from "react";

import AdminPanelForm from "../form/AdminPanelForm";
import AdminPanelList from "../list/AdminPanelList";
import LabelText from "../../custom/labels/labelText/LabelText";
import { ModelData } from "../../../services/abstract/CarService";
import ObjectMethods from "../../../utils/object/ObjectMethods";
import Title from "../../custom/title/Title";

interface Props {
  models: Array<App.Car.Model>;
  onSave: (brandData: ModelData) => void;
  onDelete: () => Promise<boolean>;
  onSelectModel: (model: App.Car.Model) => void;
  model?: App.Car.Model;
}
interface State {
  loading: boolean;
  name: string;
  brandId: number;
  country: string;
  isOpenPopupDelete: boolean;
}

class RedactModel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      isOpenPopupDelete: false,
      name: "",
      brandId: 0,
      country: "",
    };
  }

  static regForName = /[^A-zА-я0-9 -]{1,}/;
  static regForCountry = /[^A-zА-я -]{1,}/;

  public componentDidUpdate(props: Props) {
    if (!ObjectMethods.compare(props.model, this.props.model)) {
      if (this.props.model) {
        const { name, country, brandId } = this.props.model;
        this.setState({ name, country, brandId });
      } else {
        this.setState({ name: "", brandId: 0, country: "" });
      }
    }
  }

  private get correctName() {
    return !!(
      this.state.name.length && !this.state.name.match(RedactModel.regForName)
    );
  }
  private get uncorrectName() {
    return !!(
      this.state.name.length && this.state.name.match(RedactModel.regForName)
    );
  }
  private get correctCountry() {
    return !!(
      this.state.country.length &&
      !this.state.country.match(RedactModel.regForCountry)
    );
  }
  private get uncorrectCountry() {
    return !!(
      this.state.country.length &&
      this.state.country.match(RedactModel.regForCountry)
    );
  }
  private get disabled() {
    return this.state.loading || !(this.correctName && !this.uncorrectCountry);
  }
  private handleChangeName(name: string) {
    this.setState({ name });
  }
  private handleChangeCountry(country: string) {
    this.setState({ country });
  }

  private async handleClick() {
    this.setState({ loading: true });
    await this.props.onSave({
      name: this.state.name,
      country: this.state.country,
      brandId: this.state.brandId,
    });
    this.setState({ loading: false });
  }

  private handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!this.disabled) {
      this.handleClick();
    }
  }
  private openDeletePopup(event: SyntheticEvent<HTMLButtonElement>) {
    event.preventDefault();
    this.setState({ isOpenPopupDelete: true });
  }
  private closeDeletePopup(event?: SyntheticEvent) {
    event?.preventDefault();
    this.setState({ isOpenPopupDelete: false });
  }
  private async deleteBrand(event?: SyntheticEvent) {
    event?.preventDefault();
    this.setState({ loading: true });
    const result = await this.props.onDelete();
    if (result) {
      this.setState({ loading: false, isOpenPopupDelete: false });
    } else {
      this.setState({ loading: false });
    }
  }

  public render() {
    return (
      <>
        <Title>Модель</Title>
        <AdminPanelList
          items={this.props.models}
          selected={this.props.model?.id}
          onSelect={this.props.onSelectModel}
        />
        <AdminPanelForm
          loading={this.state.loading}
          question="Удалить модель?"
          isOpenPopup={this.state.isOpenPopupDelete}
          onSubmit={this.handleSubmit.bind(this)}
          onClose={this.closeDeletePopup.bind(this)}
          onDelete={this.deleteBrand.bind(this)}
        >
          <LabelText
            uncorrect={this.uncorrectName}
            correct={this.correctName}
            placeholder="Название"
            onChange={this.handleChangeName.bind(this)}
            value={this.state.name}
            maxLength={32}
          />
          <LabelText
            uncorrect={this.uncorrectCountry}
            correct={this.correctCountry}
            placeholder="Страна"
            onChange={this.handleChangeCountry.bind(this)}
            value={this.state.country}
            maxLength={32}
          />
          <div className="buttons">
            <button disabled={this.disabled}>Сохранить</button>
            <button
              className="remove"
              onClick={this.openDeletePopup.bind(this)}
              disabled={!this.props.model || this.state.loading}
            >
              Удалить
            </button>
          </div>
        </AdminPanelForm>
      </>
    );
  }
}
export default RedactModel;
