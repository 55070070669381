import CarService from "../../../../services/abstract/CarService";
import CarServiceApi from "../../../../services/api/CarServiceApi";
import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../../struct/message/SMessage";
import Select from "../../../../components/custom/select/Select";
import { addMessage } from "../../../../store/actions/messages";
import store from "../../../../store/store";

interface Props {
  value: App.ID | null;
  onChange: (value: App.ID | null) => void;
}
interface State {
  drives: Array<App.Car.Drive>;
}

class DrivePickerContainer extends React.Component<Props, State> {
  private service: CarService;
  constructor(props: Props) {
    super(props);
    this.service = new CarServiceApi();
    this.state = {
      drives: [],
    };
  }
  public componentDidMount() {
    this.getDrives();
  }

  private async getDrives() {
    const { data, status, errors } = await this.service.getDrives();
    if (status === OK) {
      this.setState({
        drives: data,
      });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }

  public render() {
    return (
      <Select
        selected={this.props.value}
        multiselect={false}
        placeholder="Привод"
        onChange={this.props.onChange}
        options={this.state.drives
          .sort((a, b) => {
            return a.name > b.name ? 1 : -1;
          })
          .map((e) => {
            return { id: e.id, name: e.name };
          })}
      />
    );
  }
}

export default DrivePickerContainer;
