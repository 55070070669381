import React from "react";

interface Props {
  description?: string;
}
interface State {}

class AnnouncementViewBodyDescription extends React.Component<Props, State> {
  public render() {
    return (
      <>
        {this.props.description && (
          <p className="announcement-view-body__description">
            {this.props.description}
          </p>
        )}
      </>
    );
  }
}
export default AnnouncementViewBodyDescription;
