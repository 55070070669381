import "./CompareCarPriceRow.scss";

import { BYN_ID } from "../../../../Config";
import CompareCarRow from "../../compareCarRow/CompareCarRow";
import React from "react";
import StringMethods from "../../../../utils/string/StringMethods";

interface Props {
  announcements: Array<App.Announcement.Announcement>;
}
interface State {}

class CompareCarPriceRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <CompareCarRow
        key="-4"
        className="compare-car-price-row"
        head={<b>Цена </b>}
        values={this.props.announcements.map((a) => {
          const value = a.currency === BYN_ID ? a.BYN : a.USD;
          const currency = a.currency === BYN_ID ? "BYN" : "USD";
          return (
            <b>
              {StringMethods.beautifyNumber(value)} {currency}
            </b>
          );
        })}
      />
    );
  }
}
export default CompareCarPriceRow;
