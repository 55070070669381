type SAVE_USER = "SAVE_USER";
type SAVE_SESSION = "SAVE_SESSION";
type LOGOUT = "LOGOUT";
type CHANGE_CONTACTS = "CHANGE_CONTACTS";

interface SaveUserAction {
  type: SAVE_USER;
  user: App.User.User;
}
interface SaveSessionAction {
  type: SAVE_SESSION;
  session: App.User.Session;
}
interface LogoutAction {
  type: LOGOUT;
}
interface ChangeContactsAction {
  type: CHANGE_CONTACTS;
  contacts: Array<App.User.Contact>;
}

export type Actions =
  | SaveUserAction
  | SaveSessionAction
  | LogoutAction
  | ChangeContactsAction;

export const saveUserType: SAVE_USER = "SAVE_USER";
export const saveSessionType: SAVE_SESSION = "SAVE_SESSION";
export const logoutType: LOGOUT = "LOGOUT";
export const changeContactsType: CHANGE_CONTACTS = "CHANGE_CONTACTS";

export function saveUser(user: App.User.User): SaveUserAction {
  return {
    type: saveUserType,
    user,
  };
}
export function saveSession(session: App.User.Session): SaveSessionAction {
  return {
    type: saveSessionType,
    session,
  };
}
export function logout(): LogoutAction {
  return {
    type: logoutType,
  };
}
export function changeContacts(
  contacts: Array<App.User.Contact>
): ChangeContactsAction {
  return {
    type: changeContactsType,
    contacts,
  };
}
