class SModel implements App.Car.Model {
  public id: number;
  public name: string;
  public brandId: number;
  public year: number;
  public country: string;

  constructor(data?: any) {
    this.id = 0;
    this.name = "";
    this.brandId = 0;
    this.year = 0;
    this.country = "";
    data && this.init(data);
  }

  private init(data: any) {
    try {
      this.id = data.id || 0;
      this.name = data.name || "";
      this.brandId = data.brandId || 0;
      this.year = data.brandId || 0;
      this.country = data.country || "";
    } catch {}
  }
}

export default SModel;
