import "./ActiveImage.scss";

import React from "react";

interface Props {
  image: string;
}
interface State {}

class CarouselActiveImage extends React.Component<Props, State> {
  public render() {
    return (
      <div
        className="carousel__active-image"
        style={{
          backgroundImage: `url(${this.props.image})`,
        }}
      ></div>
    );
  }
}

export default CarouselActiveImage;
