import "./CompareCarLoading.scss";

import React from "react";
import SubTitle from "../../custom/subtitle/SubTitle";

interface Props {}
interface State {}

class CompareCarLoading extends React.Component<Props, State> {
  public render() {
    return (
      <div className="compare-car__loading">
        <SubTitle>Загрузка объявлений</SubTitle>
      </div>
    );
  }
}

export default CompareCarLoading;
