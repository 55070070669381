import {
  CreateAnnouncementAction,
  compliteType,
  getPreviewType,
  goNextStepCreateAnnouncement,
  goPreviousStepCreateAnnouncement,
  loadDataType,
  loadStepType,
  nextStepType,
  previousStepType,
  saveContactType,
  saveDataType,
  saveFilters,
  saveFiltersType,
  saveStepCreateAnnouncement,
  saveStepType,
  selectAnnouncementTypeType,
  setPreview,
  setPreviewType,
} from "../actions/createAnnouncement";

import CreateAnnouncementService from "../../services/abstract/CreateAnnouncementService";
import CreateAnnouncementServiceDefault from "../../services/default/CreateAnnouncementServiceDefault";
import { DEFAULT_ANNOUNCEMENT } from "../../Config";
import { OK } from "http-status-codes";
import store from "../store";

export interface CreateAnnouncementStore {
  type: App.CreateAnnouncement.Type;
  step: App.CreateAnnouncement.Step;
  steps: Array<[Array<App.Filter.Filter>, Array<App.Filter.Filter>]>;

  data: App.Announcement.MainData;
  stepsData: Array<App.CreateAnnouncement.StepData>;
  preview: App.Announcement.Announcement;
}

class CreateAnnouncementReducer {
  private service: CreateAnnouncementService;
  public store: (
    store: CreateAnnouncementStore,
    action: CreateAnnouncementAction
  ) => CreateAnnouncementStore;

  constructor(service: CreateAnnouncementService) {
    this.service = service;
    this.store = this.createStore.bind(this);
  }

  public get defaultStore(): CreateAnnouncementStore {
    return {
      type: "fast",
      step: 2,
      data: {},

      steps: [],
      stepsData: [],
      preview: DEFAULT_ANNOUNCEMENT(),
    };
  }

  public createStore(
    store: CreateAnnouncementStore,
    action: CreateAnnouncementAction
  ) {
    switch (action.type) {
      case selectAnnouncementTypeType:
        store.type = action.data;
        store.step = 1;
        break;
      case nextStepType:
        store.step++;
        if (store.step === 11) {
          store.step = 0;

          break;
        }
        if (store.step === 10) {
          store.type = null;
          store.data = {};
          store.stepsData = [];
          store.preview = DEFAULT_ANNOUNCEMENT();
          break;
        }
        if (store.type === "fast" && store.step > 2) {
          store.step = 9;
        }
        if (!store.steps[store.step]) {
          this.getStepFilters(store.step);
        }
        this.getPreview();
        break;
      case previousStepType:
        store.step--;
        if (store.type === "fast" && store.step > 2) {
          store.step = 2;
        }
        if (!store.steps[store.step]) {
          this.getStepFilters(store.step);
        }
        this.getPreview();
        break;

      case saveFiltersType:
        store.steps[action.step] = action.filters;
        break;

      case loadDataType:
        break;
      case saveDataType:
        store.data = action.data;
        break;

      case loadStepType:
        this.getStep(action.step);
        break;
      case saveStepType:
        store.stepsData[action.step] = action.selected;
        break;

      case getPreviewType:
        this.getPreview();
        break;
      case setPreviewType:
        if (
          store.step === 0 &&
          (action.preview.car.brand.id ||
            action.preview.options.reduce((g, e) => (g += e.options.length), 0))
        ) {
          store.type = "full";
          store.step = 1;
        }
        store.preview = action.preview;
        break;
      case saveContactType:
        this.saveContact(action.contact, action.complite);
        break;
      case compliteType:
        this.complite();
        break;
      default:
        break;
    }

    return store;
  }

  private async getStepFilters(step: App.CreateAnnouncement.Step) {
    const { data, status } = await this.service.getStepFilters(step);
    if (status === OK) {
      store.dispatch(saveFilters(step, data));
    }
  }

  // car options
  private async getStep(step: App.CreateAnnouncement.Step) {
    const { data, status } = await this.service.getStep(step);
    if (status === OK) {
      store.dispatch(saveStepCreateAnnouncement(step, data));
    }
  }
  // save car options
  private async saveStep(
    step: App.CreateAnnouncement.Step,
    Sdata: App.CreateAnnouncement.StepData
  ) {
    this.service.saveStep(step, Sdata);
  }

  // get preview announcement
  private async getPreview() {
    const { data, status } = await this.service.getPreview();
    if (status === OK) {
      store.dispatch(setPreview(data));
    }
  }
  // save contact
  private async saveContact(
    contact: App.Announcement.Contact,
    complite: boolean
  ) {
    const { status } = await this.service.saveContact(contact);
    if (status === OK) {
      if (complite) {
        this.complite();
      } else {
        store.dispatch(goPreviousStepCreateAnnouncement());
        this.getPreview();
      }
    }
  }
  // publiched announcement
  private async complite() {
    const { status } = await this.service.complite();
    if (status === OK) {
      store.dispatch(goNextStepCreateAnnouncement());
    }
  }
}

export default new CreateAnnouncementReducer(
  new CreateAnnouncementServiceDefault()
);
