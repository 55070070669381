import "./CompareCarTableHead.scss";

import ArrowBackImg from "./assets/arrow_back.png";
import LabelCheckbox from "../../custom/labels/labelCheckbox/LabelCheckbox";
import { NavLink } from "react-router-dom";
import React from "react";
import { announcements } from "../../../routes";

interface Props {
  carsNumber: number;
  isShowDifferent: boolean;
  onChangeIsShowDifferent: (isShowDifferent: boolean) => void;
  goBack: () => void;
}
interface State {}

class CompareCarTableHead extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="compare-car-table-head">
        <div className="text">
          <img
            className="go-back"
            src={ArrowBackImg}
            alt=""
            onClick={this.props.goBack}
          />
          <div className="title">Сравнение</div>
          <div className="cars-number">
            Автомобилей: {this.props.carsNumber}
          </div>
          <LabelCheckbox
            name="Показать только отличия"
            value={this.props.isShowDifferent}
            onChange={this.props.onChangeIsShowDifferent}
          />
        </div>
        <div className="action">
          <NavLink className="add" to={announcements}>
            Добавить
          </NavLink>
        </div>
      </div>
    );
  }
}
export default CompareCarTableHead;
