import { AnnouncementData } from "../../services/abstract/CreateAnnouncementService";

type CREATE_ANNOUNCEMENT_SELECT_TYPE = "CREATE_ANNOUNCEMENT_SELECT_TYPE";
type CREATE_ANNOUNCEMENT_SET_STEP = "CREATE_ANNOUNCEMENT_SET_STEP";
type CREATE_ANNOUNCEMENT_NEXT_STEP = "CREATE_ANNOUNCEMENT_NEXT_STEP";
type CREATE_ANNOUNCEMENT_PREVIOUS_STEP = "CREATE_ANNOUNCEMENT_PREVIOUS_STEP";
type CREATE_ANNOUNCEMENT_SAVE_FILTERS = "CREATE_ANNOUNCEMENT_SAVE_FILTERS";
type CREATE_ANNOUNCEMENT_LOAD_DATA = "CREATE_ANNOUNCEMENT_LOAD_DATA";
type CREATE_ANNOUNCEMENT_SAVE_DATA = "CREATE_ANNOUNCEMENT_SAVE_DATA";
type CREATE_ANNOUNCEMENT_LOAD_STEP = "CREATE_ANNOUNCEMENT_LOAD_STEP";
type CREATE_ANNOUNCEMENT_SAVE_STEP = "CREATE_ANNOUNCEMENT_SAVE_STEP";
type CREATE_ANNOUNCEMENT_LOAD_CONTACT = "CREATE_ANNOUNCEMENT_LOAD_CONTACT";
type CREATE_ANNOUNCEMENT_SAVE_CONTACT = "CREATE_ANNOUNCEMENT_SAVE_CONTACT";
type CREATE_ANNOUNCEMENT_GET_PREVIEW = "CREATE_ANNOUNCEMENT_GET_PREVIEW";
type CREATE_ANNOUNCEMENT_SET_PREVIEW = "CREATE_ANNOUNCEMENT_SET_PREVIEW";
type CREATE_ANNOUNCEMENT_COMPLITE = "CREATE_ANNOUNCEMENT_COMPLITE";

interface SelectTypeAction {
  type: CREATE_ANNOUNCEMENT_SELECT_TYPE;
  data: App.CreateAnnouncement.Type;
}
interface SetStepAction {
  type: CREATE_ANNOUNCEMENT_SET_STEP;
  step: App.CreateAnnouncement.Step;
}
interface NextStepAction {
  type: CREATE_ANNOUNCEMENT_NEXT_STEP;
}
interface PreviousStepAction {
  type: CREATE_ANNOUNCEMENT_PREVIOUS_STEP;
}
interface SaveFiltersAction {
  type: CREATE_ANNOUNCEMENT_SAVE_FILTERS;
  step: App.CreateAnnouncement.Step;
  filters: [Array<App.Filter.Filter>, Array<App.Filter.Filter>];
}
interface LoadDataAction {
  type: CREATE_ANNOUNCEMENT_LOAD_DATA;
}
interface SaveDataAction {
  type: CREATE_ANNOUNCEMENT_SAVE_DATA;
  data: AnnouncementData;
}
interface LoadStepAction {
  type: CREATE_ANNOUNCEMENT_LOAD_STEP;
  step: App.CreateAnnouncement.Step;
}
interface SaveStepAction {
  type: CREATE_ANNOUNCEMENT_SAVE_STEP;
  step: App.CreateAnnouncement.Step;
  selected: App.CreateAnnouncement.StepData;
}

interface LoadContactAction {
  type: CREATE_ANNOUNCEMENT_LOAD_CONTACT;
}
interface SaveContactAction {
  type: CREATE_ANNOUNCEMENT_SAVE_CONTACT;
  contact: App.Announcement.Contact;
  complite: boolean;
}
interface GetPreviewAction {
  type: CREATE_ANNOUNCEMENT_GET_PREVIEW;
}
interface SetPreviewAction {
  type: CREATE_ANNOUNCEMENT_SET_PREVIEW;
  preview: App.Announcement.Announcement;
}
interface CompliteCreateAnnouncementAction {
  type: CREATE_ANNOUNCEMENT_COMPLITE;
}

export type CreateAnnouncementAction =
  | SelectTypeAction
  | SetStepAction
  | NextStepAction
  | PreviousStepAction
  | SaveFiltersAction
  | LoadDataAction
  | SaveDataAction
  | LoadStepAction
  | SaveStepAction
  | LoadContactAction
  | SaveContactAction
  | GetPreviewAction
  | SetPreviewAction
  | CompliteCreateAnnouncementAction;

export const selectAnnouncementTypeType: CREATE_ANNOUNCEMENT_SELECT_TYPE =
  "CREATE_ANNOUNCEMENT_SELECT_TYPE";
export const setStepType: CREATE_ANNOUNCEMENT_SET_STEP =
  "CREATE_ANNOUNCEMENT_SET_STEP";
export const nextStepType: CREATE_ANNOUNCEMENT_NEXT_STEP =
  "CREATE_ANNOUNCEMENT_NEXT_STEP";
export const previousStepType: CREATE_ANNOUNCEMENT_PREVIOUS_STEP =
  "CREATE_ANNOUNCEMENT_PREVIOUS_STEP";
export const saveFiltersType: CREATE_ANNOUNCEMENT_SAVE_FILTERS =
  "CREATE_ANNOUNCEMENT_SAVE_FILTERS";
export const loadDataType: CREATE_ANNOUNCEMENT_LOAD_DATA =
  "CREATE_ANNOUNCEMENT_LOAD_DATA";
export const saveDataType: CREATE_ANNOUNCEMENT_SAVE_DATA =
  "CREATE_ANNOUNCEMENT_SAVE_DATA";
export const loadStepType: CREATE_ANNOUNCEMENT_LOAD_STEP =
  "CREATE_ANNOUNCEMENT_LOAD_STEP";
export const saveStepType: CREATE_ANNOUNCEMENT_SAVE_STEP =
  "CREATE_ANNOUNCEMENT_SAVE_STEP";
export const loadContactType: CREATE_ANNOUNCEMENT_LOAD_CONTACT =
  "CREATE_ANNOUNCEMENT_LOAD_CONTACT";
export const saveContactType: CREATE_ANNOUNCEMENT_SAVE_CONTACT =
  "CREATE_ANNOUNCEMENT_SAVE_CONTACT";
export const getPreviewType: CREATE_ANNOUNCEMENT_GET_PREVIEW =
  "CREATE_ANNOUNCEMENT_GET_PREVIEW";
export const setPreviewType: CREATE_ANNOUNCEMENT_SET_PREVIEW =
  "CREATE_ANNOUNCEMENT_SET_PREVIEW";
export const compliteType: CREATE_ANNOUNCEMENT_COMPLITE =
  "CREATE_ANNOUNCEMENT_COMPLITE";

export function selectAnnouncementType(
  type: App.CreateAnnouncement.Type
): SelectTypeAction {
  return {
    type: selectAnnouncementTypeType,
    data: type,
  };
}
export function setStepCreateAnnouncement(
  step: App.CreateAnnouncement.Step
): SetStepAction {
  return {
    type: setStepType,
    step,
  };
}
export function goNextStepCreateAnnouncement(): NextStepAction {
  return {
    type: nextStepType,
  };
}
export function goPreviousStepCreateAnnouncement(): PreviousStepAction {
  return {
    type: previousStepType,
  };
}

export function saveFilters(
  step: App.CreateAnnouncement.Step,
  filters: [Array<App.Filter.Filter>, Array<App.Filter.Filter>]
): SaveFiltersAction {
  return {
    type: saveFiltersType,
    step,
    filters,
  };
}

export function loadDataCreateAnnouncement(): LoadDataAction {
  return {
    type: loadDataType,
  };
}
export function saveDataCreateAnnouncement(
  data: AnnouncementData
): SaveDataAction {
  return {
    type: saveDataType,
    data,
  };
}
export function loadStepCreateAnnouncement(
  step: App.CreateAnnouncement.Step
): LoadStepAction {
  return {
    type: loadStepType,
    step,
  };
}
export function saveStepCreateAnnouncement(
  step: App.CreateAnnouncement.Step,
  selected: App.CreateAnnouncement.StepData
): SaveStepAction {
  return {
    type: saveStepType,
    step,
    selected,
  };
}
export function loadContactCreateAnnouncement(): LoadContactAction {
  return {
    type: loadContactType,
  };
}
export function saveContactCreateAnnouncement(
  contact: App.Announcement.Contact,
  complite: boolean = false
): SaveContactAction {
  return {
    type: saveContactType,
    contact,
    complite,
  };
}
export function getPreview(): GetPreviewAction {
  return {
    type: getPreviewType,
  };
}
export function setPreview(
  preview: App.Announcement.Announcement
): SetPreviewAction {
  return {
    type: setPreviewType,
    preview,
  };
}
export function setComplite(): CompliteCreateAnnouncementAction {
  return {
    type: compliteType,
  };
}
