import AnnouncementStat from "../stat/AnnouncementStat";
import React from "react";

interface Props {
  distance?: number;
}
interface State {}

class AnnouncementStatDistance extends React.Component<Props, State> {
  public get value() {
    if (this.props.distance === 0) {
      return "Новая";
    } else if (!this.props.distance) {
      return "";
    } else {
      return `${this.props.distance} км.`;
    }
  }
  public render() {
    return <AnnouncementStat name={"Пробег"} value={this.value} />;
  }
}

export default AnnouncementStatDistance;
