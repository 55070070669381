import "./AdministrateAnnouncementsListItem.scss";

import AdministrateAnnouncementsListItemActions from "./administrateAnnouncementsListItemActions/AdministrateAnnouncementsListItemActions";
import AdministrateAnnouncementsListItemStats from "./administrateAnnouncementsListItemStats/AdministrateAnnouncementsListItemStats";
import AnnouncementCardCarousel from "../../../announcement/card/carousel/Carousel";
import AnnouncementName from "../../../car/name/AnnouncementName";
import React from "react";

interface Props {
  announcement: App.Announcement.Announcement;
  onChangeChecked: (id: App.ID, checked: boolean) => Promise<boolean>;
  onChangeSpec: (
    id: App.ID,
    spec: { from: Date | null; to: Date | null }
  ) => Promise<boolean>;
  onDelete: (id: App.ID) => Promise<boolean>;
}
interface State {}

class AdministrateAnnouncementsListItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="administrate-announcements-list-item">
        <div className="image">
          <AnnouncementCardCarousel
            id={this.props.announcement.id}
            images={this.props.announcement.images.map((e) => e.path)}
          />
        </div>
        <div className="text">
          <AnnouncementName announcement={this.props.announcement} />
          <AdministrateAnnouncementsListItemStats
            announcement={this.props.announcement}
          />
          <AdministrateAnnouncementsListItemActions
            announcement={this.props.announcement}
            onChangeChecked={this.props.onChangeChecked}
            onChangeSpec={this.props.onChangeSpec}
            onDelete={this.props.onDelete}
          />
        </div>
      </div>
    );
  }
}
export default AdministrateAnnouncementsListItem;
