type ADD_MESSAGE = "ADD_MESSAGE";
type REMOVE_MESSAGE = "REMOVE_MESSAGE";

interface AddMessageAction {
  type: ADD_MESSAGE;
  message: App.Messages.Message;
}
interface RemoveMessageAction {
  type: REMOVE_MESSAGE;
  id: number;
}

export type Actions = AddMessageAction | RemoveMessageAction;

export const addMessageType: ADD_MESSAGE = "ADD_MESSAGE";
export const removeMessageType: REMOVE_MESSAGE = "REMOVE_MESSAGE";

export function addMessage(message: App.Messages.Message): AddMessageAction {
  return {
    type: addMessageType,
    message,
  };
}
export function removeMessage(id: number): RemoveMessageAction {
  return {
    type: removeMessageType,
    id,
  };
}
