import CarService from "../../../../services/abstract/CarService";
import CarServiceApi from "../../../../services/api/CarServiceApi";
import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../../struct/message/SMessage";
import Select from "../../../../components/custom/select/Select";
import { addMessage } from "../../../../store/actions/messages";
import store from "../../../../store/store";

interface Props {
  value: App.ID | null;
  onChange: (value: App.ID | null) => void;
}
interface State {
  carcasses: Array<App.Car.Carcass>;
}

class CarcassPickerContainer extends React.Component<Props, State> {
  private service: CarService;
  constructor(props: Props) {
    super(props);
    this.service = new CarServiceApi();
    this.state = {
      carcasses: [],
    };
  }
  public componentDidMount() {
    this.getCarcasses();
  }

  private async getCarcasses() {
    const { data, status, errors } = await this.service.getCarcasses();
    if (status === OK) {
      this.setState({
        carcasses: data,
      });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }

  public render() {
    return (
      <Select
        selected={this.props.value}
        multiselect={false}
        placeholder="Кузов"
        onChange={this.props.onChange}
        options={this.state.carcasses
          .sort((a, b) => {
            if (a.name === b.name) {
              return a.doors_number > b.doors_number ? 1 : -1;
            }
            return a.name > b.name ? 1 : -1;
          })
          .map((e) => {
            return { id: e.id, name: `${e.name} ${e.doors_number} дв.` };
          })}
      />
    );
  }
}

export default CarcassPickerContainer;
