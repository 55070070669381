import "./SocialNetworks.scss";

import FacebookIcon from "./assets/facebook.png";
import OkIcon from "./assets/ok.png";
import React from "react";
import SocialNetworkLink from "./socialNetworkLink/SocialNetworkLink";
import TwitterIcon from "./assets/twitter.png";
import VkIcon from "./assets/vk.png";

interface Props {
  link: string;
}
interface State {}

class SocialNetworks extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="social-networks">
        <SocialNetworkLink
          image={FacebookIcon}
          href={`https://www.facebook.com/sharer/sharer.php?u=${this.props.link}`}
        />
        <SocialNetworkLink
          image={VkIcon}
          href={` https://vk.com/share.php?url=${this.props.link}`}
        />
        <SocialNetworkLink
          image={OkIcon}
          href={`https://connect.ok.ru/offer?url=${this.props.link}`}
        />
        <SocialNetworkLink
          image={TwitterIcon}
          href={`https://twitter.com/intent/tweet?url=${this.props.link}`}
        />
      </div>
    );
  }
}
export default SocialNetworks;
