type CHANGE_FILTERS_ANNOUNCEMENTS_HOME = "CHANGE_FILTERS_ANNOUNCEMENTS_HOME";
type RESEARCH_ANNOUNCEMENTS_HOME = "RESEARCH_ANNOUNCEMENTS_HOME";

interface ChangeFiltersAction {
  type: CHANGE_FILTERS_ANNOUNCEMENTS_HOME;
  filters: App.Announcement.FiltersForSearch;
}
interface ResearchAction {
  type: RESEARCH_ANNOUNCEMENTS_HOME;
}

export type AnnouncementsHomeActions = ChangeFiltersAction | ResearchAction;

export const changeFiltersAnnouncementsHomeType: CHANGE_FILTERS_ANNOUNCEMENTS_HOME =
  "CHANGE_FILTERS_ANNOUNCEMENTS_HOME";
export const researchAnnouncementsHomeType: RESEARCH_ANNOUNCEMENTS_HOME =
  "RESEARCH_ANNOUNCEMENTS_HOME";

export function changeFilterAnnouncementsHome(
  filters: App.Announcement.FiltersForSearch
): ChangeFiltersAction {
  return {
    type: changeFiltersAnnouncementsHomeType,
    filters,
  };
}
export function researchAnnouncementsHome(): ResearchAction {
  return {
    type: researchAnnouncementsHomeType,
  };
}
