import "./VinError.scss";
import "../Popups.scss";

import BlockedCircle from "../../../../../icons/BlockedCircle";
import CrossIcon from "../../../../../icons/Cross";
import LabelText from "../../../../custom/labels/labelText/LabelText";
import Popup from "../../../../custom/popup/Popup";
import React from "react";
import RegExp from "../../../../../utils/string/RegExp";
import StringMethods from "../../../../../utils/string/StringMethods";

interface Props {
  onBook: (name: string, phoneNumber: string) => void;
  onClose: () => void;
  loading: boolean;
}
interface State {
  phoneNumber: string;
  name: string;
}

class CreateAnnouncementVinError extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phoneNumber: "",
      name: "",
    };
  }
  get isCorrectName() {
    return !!this.state.name.match(/[\D.]{1,}/);
  }
  get isUncorrectName() {
    return !this.state.name.match(/[\D.]{1,}/);
  }
  get isCorrectPhoneNumber() {
    return !!this.state.phoneNumber.match(RegExp.phone);
  }
  get isUncorrectPhoneNumber() {
    return (
      this.state.phoneNumber.length > 0 &&
      !this.state.phoneNumber.match(RegExp.phone)
    );
  }
  private handleChangePhoneNumber(phoneNumber: string) {
    this.setState({ phoneNumber });
  }
  private handleChangeName(name: string) {
    this.setState({ name });
  }
  private handleClick() {
    this.props.onBook(
      this.state.name,
      this.state.phoneNumber
        .split("")
        .filter((e) => !!e)
        .slice(-9)
        .join("")
    );
  }
  public render() {
    return (
      <Popup
        className="create-announcement-popup vin-error"
        onClose={this.props.onClose}
      >
        <BlockedCircle />
        <b>
          Невозможно продолжить создание объявления, авто с указанным VIN
          существует, необходимо связаться с администрацией
        </b>
        <a href="tel:80291234567">
          {StringMethods.beautifyPhoneNumber("291234567")}
        </a>
        <LabelText
          placeholder={"Имя"}
          value={this.state.name}
          onChange={this.handleChangeName.bind(this)}
          correct={this.isCorrectName}
          uncorrect={this.isUncorrectName}
          maxLength={32}
        />
        <LabelText
          type="phone"
          placeholder={"Введите номер телефона"}
          value={this.state.phoneNumber}
          onChange={this.handleChangePhoneNumber.bind(this)}
          correct={this.isCorrectPhoneNumber}
          uncorrect={this.isUncorrectPhoneNumber}
          maxLength={13}
        />
        <button
          disabled={
            (this.props.loading && !this.isCorrectPhoneNumber) ||
            !this.isCorrectName
          }
          onClick={this.handleClick.bind(this)}
        >
          Заказать обратный звонок
        </button>
        <CrossIcon onClick={this.props.onClose} />
      </Popup>
    );
  }
}

export default CreateAnnouncementVinError;
