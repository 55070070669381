import "./Photo.scss";

import React, { RefObject, SyntheticEvent } from "react";

import NoPhoto from "../../../assets/icons/white-add.svg";
import Radio from "../radio/Radio";

interface Props {
  main?: boolean;
  photo?: File | string;
  index: number;
  add: (photo: File | Array<File>) => void;
  change: (index: number, photo: File) => void;
  remove: (index: number) => void;
  setMain: (index: number) => void;
}
interface State {
  isActive: boolean;
  inputRef: RefObject<HTMLInputElement>;
}

class Photo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isActive: false,
      inputRef: React.createRef(),
    };
  }

  static defaultProps = {
    setMain() {},
    index: 0,
  };
  public get path() {
    if (!this.props.photo) return NoPhoto;
    return this.props.photo instanceof File
      ? URL.createObjectURL(this.props.photo)
      : this.props.photo;
  }
  private handleDrop(event: SyntheticEvent<HTMLDivElement, DragEvent>) {
    event.preventDefault();
    if (this.props.photo) {
      const files = event.nativeEvent.dataTransfer?.files || [];
      if (files.length > 0) {
        this.props.change(this.props.index, files[0]);
      }
    } else {
      this.props.add(Array.from(event.nativeEvent.dataTransfer?.files || []));
    }
    this.deactivate();
  }
  private handleDragEnter(event: SyntheticEvent) {
    event.preventDefault();
    this.activate();
  }
  private handleDragOver(event: SyntheticEvent) {
    event.preventDefault();
  }
  private handleDragLeave(event: SyntheticEvent) {
    event.preventDefault();
    this.deactivate();
  }
  private handleClick() {
    this.state.inputRef.current?.click();
  }
  private handleChange(event: SyntheticEvent<HTMLInputElement>) {
    if (event.currentTarget.files?.length) {
      this.props.photo
        ? this.props.change(this.props.index, event.currentTarget.files[0])
        : this.props.add(event.currentTarget.files[0]);
    }
  }
  private handleRemove(event: SyntheticEvent) {
    event.stopPropagation();
    this.props.remove(this.props.index);
  }
  public activate() {
    const newState = { isActive: true };
    this.setState(newState);
  }
  public deactivate() {
    const newState = { isActive: false };
    this.setState(newState);
  }

  public render() {
    return (
      <div className="photo" data-main={this.props.main}>
        <span>Главное фото</span>
        <div className="img-container" data-empty={!this.props.photo}>
          <div
            className="drop-down-container"
            data-active={this.state.isActive}
            onDrop={this.handleDrop.bind(this)}
            onDragEnter={this.handleDragEnter.bind(this)}
            onDragOver={this.handleDragOver.bind(this)}
            onDragLeave={this.handleDragLeave.bind(this)}
            onClick={this.handleClick.bind(this)}
          ></div>
          <img className="img" src={this.path} alt="" />
          <div className="remove" onClick={this.handleRemove.bind(this)}></div>
          <input
            type="file"
            ref={this.state.inputRef}
            style={{ display: "none" }}
            accept="image/*"
            onChange={this.handleChange.bind(this)}
          />
        </div>
        {this.props.main === undefined || (
          <Radio
            active={this.props.main}
            onClick={() => this.props.setMain(this.props.index)}
          />
        )}
      </div>
    );
  }
}

export default Photo;
