import "./AnnouncementCard.scss";

import AnnouncementActionsContainer from "../../../containers/announcement/actions/AnnouncementActionsContainer";
import AnnouncementCardCarousel from "./carousel/Carousel";
import AnnouncementCardInfo from "./info/AnnouncementCardInfo";
import AnnouncementName from "../../car/name/AnnouncementName";
import { BYN_ID } from "../../../Config";
import ButtonPrice from "../../price/button/ButtonPrice";
import CheckedAuto from "../../marks/checkedAuto/CheckedAuto";
import React from "react";
import SpecialAuto from "../../marks/specialAuto/SpecialAuto";

interface Props {
  announcement: App.Announcement.Announcement;
  currency: App.ID;
}
interface State {
  announcement: App.Announcement.Announcement;
}

class AnnouncementCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      announcement: props.announcement,
    };
  }

  static defaultProps = {
    currency: BYN_ID,
  };

  public componentDidUpdate(prevProps: Props) {
    if (this.props.announcement !== prevProps.announcement) {
      this.setState({ announcement: this.props.announcement });
    }
  }

  private handleUpdateAnnouncement(
    announcement: App.Announcement.Announcement
  ) {
    this.setState({ announcement });
  }

  public render() {
    return (
      <div className="announcement-card">
        <AnnouncementCardCarousel
          id={this.state.announcement.id}
          images={this.state.announcement.images.map((e) => e.path)}
        />
        <div className="marks">
          {this.state.announcement.spec && <SpecialAuto />}
          {this.state.announcement.checked && <CheckedAuto />}
        </div>

        <div className="announcement-card-info">
          <AnnouncementName announcement={this.state.announcement} />
          <AnnouncementCardInfo announcement={this.state.announcement} />
          <ButtonPrice
            price={
              this.props.currency === BYN_ID
                ? this.state.announcement.BYN
                : this.state.announcement.USD
            }
            currency={this.props.currency}
          />
          <AnnouncementActionsContainer
            announcement={this.state.announcement}
            onUpdateAnnouncement={this.handleUpdateAnnouncement.bind(this)}
          />
        </div>
      </div>
    );
  }
}
export default AnnouncementCard;
