import { DISTANCE_MAX, DISTANCE_MIN, DISTANCE_STEP } from "../../../../Config";

import LabelNumber from "../../../../components/custom/labels/labelNumber/LabelNumber";
import React from "react";

interface Props {
  distance?: number;
  onChange: (distance: number) => void;
}
interface State {}

class CreateAnnouncementDistance extends React.Component<Props, State> {
  public render() {
    return (
      <LabelNumber
        value={this.props.distance}
        onChange={this.props.onChange}
        name="Пробег"
        controlls={true}
        min={DISTANCE_MIN}
        step={DISTANCE_STEP}
        max={DISTANCE_MAX}
        maxLength={8}
      />
    );
  }
}

export default CreateAnnouncementDistance;
