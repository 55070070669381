import "./Options.scss";

import LabelSearchOption from "./option/Option";
import React from "react";

interface Props {
  options: Array<App.Info>;
  selected: App.Info | null;
  onChange: (value: App.Info | null) => void;
  keyword: string;
}
interface State {}

class LabelSearchOptions extends React.Component<Props, State> {
  public render() {
    const keyword = this.props.keyword.toLowerCase();
    return (
      <div className="label-search__options">
        {this.props.options
          .filter((option) => option.name.toLowerCase().includes(keyword))
          .map((option) => (
            <LabelSearchOption
              key={option.id}
              option={option}
              onChange={this.props.onChange}
              selected={
                this.props.selected
                  ? option.id === this.props.selected.id
                  : false
              }
            />
          ))}
      </div>
    );
  }
}

export default LabelSearchOptions;
