import { FiltersStore } from "../reducers/FiltersReducer";

type LOAD_FILTERS = "LOAD_FILTERS";
type SAVE_FILTERS = "SAVE_FILTERS";

interface LoadFilterAction {
  type: LOAD_FILTERS;
}
interface SaveFilterAction {
  type: SAVE_FILTERS;
  filters: FiltersStore;
}

export type Actions = LoadFilterAction | SaveFilterAction;

export const loadFiltersType: LOAD_FILTERS = "LOAD_FILTERS";
export const saveFiltersType: SAVE_FILTERS = "SAVE_FILTERS";

export function loadFilters(): LoadFilterAction {
  return {
    type: loadFiltersType,
  };
}
export function saveFilters(filters: FiltersStore): SaveFilterAction {
  return {
    type: saveFiltersType,
    filters,
  };
}
