import "./CompareCarEmpty.scss";

import { NavLink } from "react-router-dom";
import React from "react";
import SubTitle from "../../custom/subtitle/SubTitle";
import { announcements } from "../../../routes";

interface Props {}
interface State {}

class CompareCarEmpty extends React.Component<Props, State> {
  public render() {
    return (
      <div className="compare-car__empty">
        <SubTitle>Не выбраны авто для сравнения</SubTitle>
        <NavLink className="to-announcements" to={announcements}>
          <button>Выбрать</button>
        </NavLink>
      </div>
    );
  }
}

export default CompareCarEmpty;
