import "./UserNavMenu.scss";

import {
  userAnnouncements,
  userSearchHistory,
  userSettings,
  userViewHistory,
} from "../../../routes";

import { NavLink } from "react-router-dom";
import React from "react";

interface Props {
  user: App.User.User;
  logout: () => void;
}
interface State {}
class UserNavMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div className="user-nav-menu">
        <div className="user-info">
          <div className="name">{this.props.user.name}</div>
          <div className="email">{this.props.user.email}</div>
        </div>
        <nav>
          <NavLink className="user-nav-menu__link" to={userAnnouncements}>
            <span>Объявления</span>
          </NavLink>
          <NavLink className="user-nav-menu__link" to={userSearchHistory}>
            <span>История поиска</span>
          </NavLink>
          <NavLink className="user-nav-menu__link" to={userViewHistory}>
            <span>История просмотра</span>
          </NavLink>
          <NavLink className="user-nav-menu__link" to={userSettings}>
            <span>Настройка</span>
          </NavLink>
          <span className="user-nav-menu__link" onClick={this.props.logout}>
            Выход
          </span>
        </nav>
      </div>
    );
  }
}

export default UserNavMenu;
