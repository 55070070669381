import "./UserDeleteContactPopup.scss";

import Popup from "../../custom/popup/Popup";
import React from "react";

interface Props {
  onSubmit: () => void;
  onClose: () => void;
  disabled: boolean;
}
interface State {}

class UserDeleteContactPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <Popup className="user-delete-contact-popup" onClose={this.props.onClose}>
        <h3>Вы действительно хотите удалить контакт?</h3>
        <div className="buttons">
          <button disabled={this.props.disabled} onClick={this.props.onSubmit}>
            Удалить
          </button>
          <button disabled={this.props.disabled} onClick={this.props.onClose}>
            Отмена
          </button>
        </div>
      </Popup>
    );
  }
}
export default UserDeleteContactPopup;
