import LabelText from "../../../../components/custom/labels/labelText/LabelText";
import React from "react";
import RegExp from "../../../../utils/string/RegExp";

interface Props {
  value: string;
  disabled: boolean;
  onChange: (value: string) => void;
}
interface State {}

class LabelVin extends React.Component<Props, State> {
  private get isCorrect() {
    return this.props.value.length > 0 && !!this.props.value.match(RegExp.vin);
  }
  private get isUncorrect() {
    return this.props.value.length > 0 && !this.isCorrect;
  }
  private handleChange(value: string) {
    this.props.onChange(value.toUpperCase());
  }
  public render() {
    return (
      <LabelText
        name="VIN"
        disabled={this.props.disabled}
        className="label-vin"
        value={this.props.value}
        onChange={this.handleChange.bind(this)}
        correct={this.isCorrect}
        uncorrect={this.isUncorrect}
        maxLength={17}
      />
    );
  }
}

export default LabelVin;
