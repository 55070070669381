import "./HomeHead.scss";

import HomeFilterContainer from "../../../containers/filters/homeFilter/HomeFilterContainer";
import HomeSpecAnnouncementsList from "../../../containers/announcement/results/homeSpec/HomeSpecAnnouncementsList";
import React from "react";
import SearchByTextContainer from "../../../containers/search/searchAnnouncment/SearchAnnouncementContainer";

interface Props {}
interface State {}

class HomeHead extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  public render() {
    return (
      <div className="home-head">
        <div className="search">
          <SearchByTextContainer />
          <HomeFilterContainer />
        </div>
        <HomeSpecAnnouncementsList />
      </div>
    );
  }
}

export default HomeHead;
