import AnnouncementStat from "../stat/AnnouncementStat";
import React from "react";

interface Props {
  year?: number;
}
interface State {}

class AnnouncementStatYear extends React.Component<Props, State> {
  public get value() {
    if (!this.props.year) {
      return "";
    } else {
      return `${this.props.year} г.`;
    }
  }
  public render() {
    return <AnnouncementStat name={"Год выпуска"} value={this.value} />;
  }
}

export default AnnouncementStatYear;
