import CarService, { VersionData } from "../../../services/abstract/CarService";

import CarServiceApi from "../../../services/api/CarServiceApi";
import { OK } from "http-status-codes";
import React from "react";
import RedactVersion from "../../../components/adminPanel/redactVersion/RedactVersion";
import SMessage from "../../../struct/message/SMessage";
import { addMessage } from "../../../store/actions/messages";
import store from "../../../store/store";

interface Props {
  generation: App.Car.Generation;
}
interface State {
  version?: App.Car.Version;
  versions: Array<App.Car.Version>;
}

class RedactVersionContainer extends React.Component<Props, State> {
  private service: CarService;
  constructor(props: Props) {
    super(props);
    this.service = new CarServiceApi();
    this.state = {
      versions: [],
    };
  }

  public componentDidMount() {
    this.getVersions();
  }
  public componentDidUpdate(props: Props, state: State) {
    if (props.generation !== this.props.generation) {
      this.getVersions();
    }
  }

  private async getVersions() {
    const { data, status, errors } = await this.service.getVersions(
      +this.props.generation.id
    );
    if (status === OK) {
      this.setState({ versions: data, version: undefined });
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }

  private async handleSave(version: VersionData) {
    if (this.state.version) {
      this.update(this.state.version.id, version);
    } else {
      this.add(version);
    }
  }

  private async add(version: VersionData) {
    version.generationId = +this.props.generation.id;
    const { data, status, errors } = await this.service.addVersion(version);
    if (status === OK) {
      this.updateList(data);
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }
  private async update(id: App.ID, version: VersionData) {
    version.generationId = +this.props.generation.id;
    const { data, status, errors } = await this.service.updateVersion(
      id,
      version
    );
    if (status === OK) {
      this.updateList(data);
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
  }

  private updateList(data: App.Car.Version) {
    if (this.state.versions.some((v) => v.id === data.id)) {
      this.setState({
        version: data,
        versions: this.state.versions.map((v) => (v.id === data.id ? data : v)),
      });
    } else {
      this.setState({
        version: data,
        versions: this.state.versions.concat(data),
      });
    }
  }

  private async delete() {
    if (!this.state.version) return false;
    const id = this.state.version.id;
    const { status, errors } = await this.service.deleteVersion(id);
    if (status === OK) {
      this.setState({
        version: undefined,
        versions: this.state.versions.filter((v) => v.id !== id),
      });
      return true;
    } else {
      errors.forEach((error) =>
        store.dispatch(addMessage(new SMessage(error)))
      );
    }
    return false;
  }

  private handleSelectVersion(version: App.Car.Version) {
    if (this.state.version && this.state.version.id === version.id) {
      this.setState({ version: undefined });
    } else {
      this.setState({ version });
    }
  }

  public render() {
    return (
      <RedactVersion
        versions={this.state.versions}
        version={this.state.version}
        onSave={this.handleSave.bind(this)}
        onDelete={this.delete.bind(this)}
        onSelectVersion={this.handleSelectVersion.bind(this)}
      />
    );
  }
}

export default RedactVersionContainer;
