import ColorPicker from "../../components/custom/colorPicker/ColorPicker";
import LabelCheckbox from "../../components/custom/labels/labelCheckbox/LabelCheckbox";
import Picker from "../../components/filters/picker/Picker";
import RadioButtons from "../../components/filters/radioButtons/RadioButtons";
import React from "react";
import Slider from "../../components/filters/slider/Slider";

interface Self {
  state: {
    selected: Array<App.AdvancedFilter.SelectedFilterValue>;
  };
  handleChange: (value: App.AdvancedFilter.SelectedFilterValue) => void;
}

class Filter {
  private self: Self;
  constructor(self: Self) {
    this.self = self;
  }
  public getFilter(filter: App.Announcement.FilterApi) {
    switch (filter.type) {
      case "checkbox":
        return this.getCheckbox(filter);
      case "select":
        return this.getRadio(filter);
      case "range":
        return this.getRange(filter);
      case "multiselect":
        return this.getPicker(filter);
      case "color-picker":
        return this.getColorPicker(filter);
      default:
        return <></>;
    }
  }

  private getCheckbox(filter: App.Announcement.CheckboxFilter) {
    let filterValue = this.self.state.selected.find((s) => s.id === filter.id);
    let value: boolean = false;
    if (filterValue && filterValue.type === "checkbox") {
      value = filterValue.value;
    }
    return (
      <LabelCheckbox
        key={filter.id}
        name={filter.name}
        value={value}
        onChange={this.handleChangeCheckbox(filter.id)}
      />
    );
  }
  private getRadio(filter: App.Announcement.SelectFilter) {
    let filterValue = this.self.state.selected.find((s) => s.id === filter.id);
    let selected: App.ID | null = null;
    if (filterValue && filterValue.type === "radio") {
      selected = filterValue.value;
    }
    return (
      <RadioButtons
        key={filter.id}
        name={filter.name}
        options={filter.options}
        selected={selected}
        onChange={this.handleChangeRadio(filter.id)}
      />
    );
  }
  private getRange(filter: App.Announcement.RangeFilter) {
    let filterValue = this.self.state.selected.find((s) => s.id === filter.id);
    let value: App.RangeValue = [filter.min, filter.max];
    if (filterValue && filterValue.type === "range") {
      value = filterValue.value;
    }
    return (
      <Slider
        key={filter.id}
        name={filter.name}
        min={filter.min}
        max={filter.max}
        value={value}
        onChange={this.handleChangeRange(filter.id)}
      />
    );
  }
  private getPicker(filter: App.Announcement.MultiselectFilter) {
    let filterValue = this.self.state.selected.find((s) => s.id === filter.id);
    let picked: Array<App.ID> = [];
    if (filterValue && filterValue.type === "picker") {
      picked = filterValue.value;
    }
    return (
      <Picker
        key={filter.id}
        name={filter.name}
        options={filter.options}
        onChange={this.handleChangePicker(filter.id)}
        picked={picked}
      />
    );
  }
  private getColorPicker(filter: App.Announcement.ColorFilter) {
    let filterValue = this.self.state.selected.find((s) => s.id === filter.id);
    let selected: App.ID = 0;
    if (filterValue && filterValue.type === "radio") {
      selected = filterValue.value || 0;
    }
    return (
      <ColorPicker
        key={filter.id}
        colors={filter.options}
        onChange={this.handleChangeRadio(filter.id)}
        selected={selected}
      />
    );
  }

  private handleChangeCheckbox(id: App.ID) {
    return (value: boolean) => {
      this.self.handleChange({
        id,
        type: "checkbox",
        value,
      });
    };
  }
  private handleChangeRadio(id: App.ID) {
    return (value: App.ID | null) => {
      this.self.handleChange({
        id,
        type: "radio",
        value,
      });
    };
  }
  private handleChangePicker(id: App.ID) {
    return (value: Array<App.ID>) => {
      this.self.handleChange({
        id,
        type: "picker",
        value,
      });
    };
  }

  private handleChangeRange(id: App.ID) {
    return (value: App.RangeValue) => {
      this.self.handleChange({
        id,
        type: "range",
        value,
      });
    };
  }
}

export default Filter;
