import "./Announcement.scss";

import AnnouncementContainer from "../../containers/announcement/announcement/AnnouncementContainer";
import React from "react";
import { RouteComponentProps } from "react-router";
import SearchByTextContainer from "../../containers/search/searchAnnouncment/SearchAnnouncementContainer";
import { notFound } from "../../routes";

interface Props extends RouteComponentProps<{ id: string }> {}
interface State {}

class AnnouncementView extends React.Component<Props, State> {
  public componentDidMount() {
    document.title = `Объявление ${this.props.match.params.id}`;
    window.scrollTo({ top: 0 });
  }
  private toNotFound() {
    this.props.history.replace(notFound);
  }
  public render() {
    return (
      <div className="announcement-view">
        <SearchByTextContainer />
        <AnnouncementContainer
          toNotFound={this.toNotFound.bind(this)}
          id={this.props.match.params.id}
        />
      </div>
    );
  }
}

export default AnnouncementView;
