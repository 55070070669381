import CarFilter from "./CarFilter";
import React from "react";

interface Props {
  car: Array<App.AdvancedFilter.CarFilter>;
  transmission: boolean;
  onChange: (car: Array<App.AdvancedFilter.CarFilter>) => void;
  maxCarFilters: number;
  className: string;
}
interface State {}

class CarFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static defaultProps = {
    transmission: false,
    car: [{}],
    onChange() {},
    maxCarFilters: 5,
    className: "",
  };

  private add() {
    if (this.props.car.length < this.props.maxCarFilters) {
      this.props.onChange(this.props.car.concat({}));
    }
  }
  private remove(id: number) {
    this.props.onChange(this.props.car.filter((car, index) => index !== id));
  }

  private handleChange(index: number) {
    return (car: App.AdvancedFilter.CarFilter) =>
      this.props.onChange(
        this.props.car.map((f, i) => (i === index ? car : f))
      );
  }

  public render() {
    return (
      <div className={"car-filters " + this.props.className}>
        {this.props.car.map((car, index) => (
          <CarFilter
            key={index}
            car={car}
            onChange={this.handleChange(index)}
            add={this.add.bind(this)}
            remove={index > 0 ? () => this.remove(index) : undefined}
          />
        ))}
      </div>
    );
  }
}

export default CarFilters;
