import "./HomeFilter.scss";

import CarFilters from "../../containers/filters/carFilters/CarFilters";
import Filter from "../../utils/filter/Filter";
import HomeFilterButtons from "./buttons/HomeFilterButtons";
import PopularBrands from "../../containers/brand/popular/PopularBrandsContainer";
import React from "react";
import Title from "../custom/title/Title";

interface Props {
  car: Array<App.AdvancedFilter.CarFilter>;
  onChangeCarFilter: (filters: Array<App.AdvancedFilter.CarFilter>) => void;

  values: Array<App.AdvancedFilter.SelectedFilterValue>;
  filters: Array<App.Announcement.FilterApi>;
  onChange: (id: App.ID, value: App.AdvancedFilter.SelectedFilterValue) => void;

  onSelectBrand: (brand: App.ID) => void;
  onStatrtSearch: () => void;
}

interface State {
  selected: Array<App.AdvancedFilter.SelectedFilterValue>;
  isOpenCarFilters: boolean;
  isOpenSliders: boolean;
}

class HomeFilter extends React.Component<Props, State> {
  private filter: Filter;
  constructor(props: Props) {
    super(props);
    this.filter = new Filter(this);
    this.state = {
      selected: props.values,
      isOpenCarFilters: false,
      isOpenSliders: false,
    };
  }

  public componentDidUpdate(props: Props) {
    if (this.props.values === props.values) return;
    this.setState({ selected: this.props.values });
  }

  public toggleCarFilters() {
    this.setState({ isOpenCarFilters: !this.state.isOpenCarFilters });
  }
  public toggleSliders() {
    this.setState({ isOpenSliders: !this.state.isOpenSliders });
  }
  public handleChange(value: any) {
    this.props.onChange(value.id, value);
  }

  public render() {
    return (
      <div className="home-filter">
        <Title className="home-filter-title">Купить легковой автомобиль</Title>
        <PopularBrands onSelectBrand={this.props.onSelectBrand} />
        <CarFilters
          car={this.props.car}
          transmission={true}
          onChange={this.props.onChangeCarFilter}
        />
        <div className="filters">
          {this.props.filters.map(this.filter.getFilter.bind(this.filter))}
        </div>
        <HomeFilterButtons onStartSearch={this.props.onStatrtSearch} />
      </div>
    );
  }
}

export default HomeFilter;
