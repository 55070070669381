import ContactForm from "../../../components/user/contactForm/ContactForm";
import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../struct/message/SMessage";
import UserService from "../../../services/abstract/UserService";
import UserServiceApi from "../../../services/api/UserServiceApi";
import { addMessage } from "../../../store/actions/messages";
import { changeContacts } from "../../../store/actions/user";
import store from "../../../store/store";

export interface AddContactValue {
  phone: string;
  name: string;
}

interface Props {}
interface State {
  value: AddContactValue;
  code: string;
  loading: boolean;
  complited: boolean;
  isDisplayCodeLabel: boolean;
  startTimestampResendCode: number;
  errors: Array<string>;
}

class AddContactContainer extends React.Component<Props, State> {
  private service: UserService;
  constructor(props: Props) {
    super(props);
    this.service = new UserServiceApi();
    this.state = {
      value: {
        phone: "",
        name: "",
      },
      code: "",
      loading: false,
      complited: false,
      isDisplayCodeLabel: false,
      startTimestampResendCode: +new Date(),
      errors: [],
    };
  }

  private handleChangeValue(value: AddContactValue) {
    if (this.state.isDisplayCodeLabel) {
      return;
    }
    this.setState({
      value,
    });
  }
  private handleChangeCode(code: string) {
    this.setState({ code });
  }
  private async getSmsCode() {
    this.setState({ loading: true, errors: [] });
    const { status, errors } = await this.service.getSmsCode(
      this.state.value.phone
    );

    if (status === OK) {
      this.setState({
        isDisplayCodeLabel: true,
        startTimestampResendCode: +new Date(),
      });
    } else {
      this.setState({ errors });
    }
    this.setState({ loading: false });
  }
  private async saveContact() {
    this.setState({ loading: true, errors: [] });
    const { data, status, errors } = await this.service.saveContact({
      code: this.state.code,
      ...this.state.value,
    });
    if (status === OK) {
      this.setState({
        complited: true,
        code: "",
        isDisplayCodeLabel: false,

        value: {
          name: "",
          phone: "",
        },
      });
      store.dispatch(changeContacts(data));
      store.dispatch(
        addMessage(new SMessage("Контакт добавлен", "notification"))
      );
    } else {
      this.setState({ errors });
    }
    this.setState({ loading: false });
  }

  private restart() {
    this.setState({
      isDisplayCodeLabel: false,
    });
  }

  public render() {
    return (
      <ContactForm
        {...this.state}
        disabled={this.state.loading}
        restart={this.restart.bind(this)}
        onChange={this.handleChangeValue.bind(this)}
        onChangeCode={this.handleChangeCode.bind(this)}
        onGetSmsCode={this.getSmsCode.bind(this)}
        onSubmit={this.saveContact.bind(this)}
      />
    );
  }
}
export default AddContactContainer;
