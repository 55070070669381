import "./Carousel.scss";

import AnnouncementCardCarouselTab from "./tab/Tab";
import { NavLink } from "react-router-dom";
import NoImage from "../../../../assets/noimage.jpg";
import React from "react";

interface Props {
  id: App.ID;
  images: Array<string>;
}
interface State {
  active: number;
}

class AnnouncementCardCarousel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: 0,
    };
  }
  public get url() {
    return (
      this.props.images[this.state.active] || this.props.images[0] || NoImage
    );
  }
  public handleSelectImage(active: number) {
    const newState = { active };
    this.setState(newState);
    return newState;
  }
  public render() {
    return (
      <>
        <NavLink
          className="announcement-card__carousel"
          to={`/announcement/${this.props.id}`}
        >
          <div
            className="image"
            style={{ backgroundImage: `url(${this.url})` }}
          ></div>
          <div className="tabs">
            {this.props.images.map((image, index) => (
              <AnnouncementCardCarouselTab
                key={index}
                index={index}
                active={index === this.state.active}
                onSelect={this.handleSelectImage.bind(this)}
              />
            ))}
          </div>
        </NavLink>
        <NavLink
          to={`/announcement/${this.props.id}`}
          className="main-image"
          style={{
            backgroundImage: `url(${this.props.images[0] || this.url})`,
          }}
        />
      </>
    );
  }
}

export default AnnouncementCardCarousel;
