import AnnouncementCustomService from "../../../../services/abstract/announcements/AnnouncementCustomService";
import AnnouncementCustomServiceApi from "../../../../services/api/announcements/AnnouncementCustomServiceApi";
import { OK } from "http-status-codes";
import React from "react";
import SMessage from "../../../../struct/message/SMessage";
import SpecAnnouncements from "../../../../components/announcement/lists/spec/SpecAnnouncements";
import { addMessage } from "../../../../store/actions/messages";
import store from "../../../../store/store";

interface Props {}
interface State {
  announcements: Array<App.Announcement.Announcement>;
}

class HomeSpecAnnouncementsList extends React.Component<Props, State> {
  private service: AnnouncementCustomService;
  constructor(props: Props) {
    super(props);
    this.service = new AnnouncementCustomServiceApi();
    this.state = {
      announcements: [],
    };
  }
  public componentDidMount() {
    this.loadAnnouncements();
  }
  public async loadAnnouncements() {
    const { data, status, errors } = await this.service.getSpec();
    if (status === OK) {
      this.setState({ announcements: data.slice(0, 2) });
    } else {
      errors.forEach((error) => {
        store.dispatch(addMessage(new SMessage(error)));
      });
    }
  }

  public render() {
    return <SpecAnnouncements announcements={this.state.announcements} />;
  }
}

export default HomeSpecAnnouncementsList;
