import "./CarHistory.scss";

import HistoryStep from "./historyStep/HistoryStep";
import React from "react";
import SubTitle from "../../custom/subtitle/SubTitle";

interface Props {
  steps: Array<App.Car.CarHistory>;
}
interface State {
  isOpen: boolean;
}

class CarHistory extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }
  public render() {
    return (
      <>
        {this.props.steps.length > 0 && (
          <div className="history">
            {!this.state.isOpen && (
              <button
                className="show-history"
                onClick={() => this.setState({ isOpen: true })}
              >
                Посмотреть историю
              </button>
            )}
            {this.state.isOpen && (
              <div className="history-list">
                <SubTitle>История автомобиля</SubTitle>
                {this.props.steps.map((history) => (
                  <HistoryStep key={history.id} history={history} />
                ))}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default CarHistory;
