import "./Header.scss";

import { Circle } from "rc-progress";
import React from "react";
import SubTitle from "../../../../custom/subtitle/SubTitle";

interface Props {
  step: number;
  allSteps: number;
  name: string;
}
interface State {}

class CreateAnnouncementStepHeader extends React.Component<Props, State> {
  public render() {
    return (
      <div className="create-announcement-step__header">
        <Circle
          percent={(this.props.step / this.props.allSteps) * 100}
          strokeWidth={3}
          strokeColor={"#12BBA4"}
        />
        <div className="steps">
          <span className="current">{this.props.step}</span>
          <span className="all">/{this.props.allSteps}</span>
        </div>
        <SubTitle>{this.props.name}</SubTitle>
      </div>
    );
  }
}

export default CreateAnnouncementStepHeader;
