import "./Option.scss";

import CkeckedCircle from "../../../../icons/CkeckedCircle";
import React from "react";

interface Props {
  id: number | string;
  name: string;
  selected: boolean;
  className: string;
  onClick: (id: number | string, picked: boolean) => void;
}
interface State {}

class PickerOption extends React.Component<Props, State> {
  static defaultProps = {
    className: "",
  };
  public render() {
    return (
      <div
        title={this.props.name}
        className={"picker__option " + this.props.className}
        data-selected={this.props.selected}
        onClick={() => this.props.onClick(this.props.id, this.props.selected)}
      >
        {this.props.selected && <CkeckedCircle />}
        <span>{this.props.name}</span>
      </div>
    );
  }
}
export default PickerOption;
